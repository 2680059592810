<template>
  <!-- 부서 추가 -->
  <div class="sub-department">
    <!-- S: 부서 추가 -->
    <PageTitle :title="'부서 추가'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('기본정보') }}
          </strong>
        </div>
      </div>
      <div class="contents-data-form-wrap">
        <v-data-table hide-default-header hide-default-footer>
          <template v-slot:colgroup>
            <colgroup>
              <col style="width: 220px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:body="props">
            <tr>
              <th>
                <span>부서정보</span>
              </th>
              <td>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'부서명'"
                  :required="true"
                  :placeholder="'부서명 입력'"
                ></GSInput>
                <GSSwitches
                  :size="Constants.SWITCHES_SIZES.LARGE"
                  :desc-position="Constants.SWITCHES_POSITION.TOP"
                  :description="'상태 활성화'"
                  :required="true"
                  style="margin-top: 16px"
                ></GSSwitches>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'설명'"
                  :placeholder="'설명 입력'"
                  style="margin-top: 16px"
                ></GSInput>
                <div class="gsr-input-top-position" style="margin-top: 16px">
                  <div class="label-box">
                    <span>구성원</span>
                  </div>
                  <v-autocomplete
                    v-model="members"
                    class="gsr-chips-autocomplete"
                    :items="organizationMembers"
                    item-title="name"
                    clearable
                    chips
                    closable-chips
                    multiple
                    placeholder="구성원 검색"
                    :menu-props="{ class: 'gsr-chips-autocomplete-layer' }"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :class="item.raw.active === true ? 'active' : 'deactivate'"
                      >
                        {{ item.raw.name }} &lt;{{ item.raw.id }}@gsretail.com&gt;
                        <i class="state-icon"></i>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item v-bind="props">
                        <div class="org-member-info">
                          <v-avatar :image="item.raw.img" transition=""></v-avatar>
                          <div class="org-member-info-text">
                            <p class="text">{{ item.raw.name }} / {{ item.raw.org }}</p>
                            <p class="sub-text">({{ item.raw.id }}) {{ item.raw.position }}</p>
                          </div>
                        </div>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </div>
              </td>
            </tr>
            <tr class="border-0">
              <td colspan="2" class="contents-data-form-btn-area">
                <div class="contents-data-form-btn-list">
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.MEDIUM"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'취소'"
                    width="100"
                  ></GSButton>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                    :size="Constants.BUTTON_SIZES.MEDIUM"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :text="'저장'"
                    width="100"
                  ></GSButton>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('Workspace') }} <span>0</span>{{ translate('건') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <v-list>
            <v-list-item>
              <GSButton
                class="edit-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :text="'선택삭제'"
                width="73"
                disabled
              ></GSButton>
            </v-list-item>
            <v-list-item>
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'Workspace 추가'"
                width="144"
              ></GSButton>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <div class="contents-data-table-wrap">
        <v-data-table
          :headers="deptListHeader"
          :items="deptListDate"
          :items-per-page="10"
          hide-default-footer
        >
          <template v-slot:colgroup>
            <colgroup>
              <col style="width: 240px" />
              <col style="width: 532px" />
              <col style="width: 420px" />
              <col style="width: 108px" />
              <col style="width: 180px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:item.member="{ item }">
            <ul class="member-list">
              <li v-for="(subItem, subIndex) in item.member" :key="subIndex">
                <span>{{ subItem }}</span>
              </li>
            </ul>
          </template>
          <template v-slot:item.state="{ item }">
            <span class="state active" v-if="item.state === true">이용중</span>
            <span class="state" v-if="item.state === false">이용안함</span>
          </template>
          <template v-slot:no-data>
            <div class="no-data-msg">
              <p>검색결과가 없습니다.<br />관리자에게 문의해주세요.</p>
            </div>
          </template>
        </v-data-table>
        <Pagination :dataLength="deptListDate.length"></Pagination>
      </div>
    </div>
    <!-- E: 부서 추가 -->
    <!-- S: 부서 정보 -->
    <PageTitle :title="'부서 정보'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('기본정보') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <v-list>
            <v-list-item>
              <GSButton
                class="edit-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'편집'"
                width="67"
                @click="openAddDepartmentDialog"
              ></GSButton>
            </v-list-item>
          </v-list>
          <v-dialog v-model="addDepartmentDialog" class="gsr-dialog" width="600">
            <v-card>
              <div class="dialog-title-wrap">
                <strong class="dialog-title">부서 추가</strong>
                <div class="dialog-close-btn-box">
                  <v-btn class="dialog-close-btn" @click="closeAddDepartmentDialog"></v-btn>
                </div>
              </div>
              <div class="dialog-tab-contents-area">
                <div class="dialog-tab-title">
                  <span>부서정보</span>
                </div>
                <div class="dialog-tab-contents">
                  <ul class="dialog-tab-contents-list">
                    <li>
                      <GSInput
                        :variant="Constants.INPUT_VARIANTS.OUTLINED"
                        :size="Constants.INPUT_SIZES.MEDIUM"
                        :desc-position="Constants.INPUT_POSITION.TOP"
                        :description="'부서명'"
                        :required="true"
                        :placeholder="'부서명 입력'"
                      ></GSInput>
                    </li>
                    <li>
                      <GSSwitches
                        :size="Constants.SWITCHES_SIZES.LARGE"
                        :desc-position="Constants.SWITCHES_POSITION.TOP"
                        :description="'상태 활성화'"
                        :required="true"
                      ></GSSwitches>
                    </li>
                    <li>
                      <GSInput
                        :variant="Constants.INPUT_VARIANTS.OUTLINED"
                        :size="Constants.INPUT_SIZES.MEDIUM"
                        :desc-position="Constants.INPUT_POSITION.TOP"
                        :description="'설명'"
                        :placeholder="'설명 입력'"
                      ></GSInput>
                    </li>
                    <li>
                      <div class="gsr-input-top-position">
                        <div class="label-box">
                          <span>구성원</span>
                        </div>
                        <v-autocomplete
                          v-model="members"
                          class="gsr-chips-autocomplete"
                          :items="organizationMembers"
                          item-title="name"
                          clearable
                          chips
                          closable-chips
                          multiple
                          placeholder="구성원 검색"
                          :menu-props="{ class: 'gsr-chips-autocomplete-layer' }"
                        >
                          <template v-slot:chip="{ props, item }">
                            <v-chip
                              v-bind="props"
                              :class="item.raw.active === true ? 'active' : 'deactivate'"
                            >
                              {{ item.raw.name }} &lt;{{ item.raw.id }}@gsretail.com&gt;
                              <i class="state-icon"></i>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props">
                              <div class="org-member-info">
                                <v-avatar :image="item.raw.img" transition=""></v-avatar>
                                <div class="org-member-info-text">
                                  <p class="text">{{ item.raw.name }} / {{ item.raw.org }}</p>
                                  <p class="sub-text">
                                    ({{ item.raw.id }}) {{ item.raw.position }}
                                  </p>
                                </div>
                              </div>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="dialog-button-wrap">
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'닫기'"
                  width="57"
                  @click="closeAddDepartmentDialog"
                ></GSButton>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :text="'저장하기'"
                  width="81"
                ></GSButton>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="contents-profile-wrap">
        <div class="contents-profile-left">
          <div class="contents-profile-text">
            <span class="state">이용중</span>
            <span class="title">우리동네GS팀</span>
            <span class="sub-title">우리동네GS 광고성 메시지 발송</span>
          </div>
        </div>
        <div class="contents-profile-right">
          <div class="contents-profile-info">
            <span>생성일자</span>
            <p>2024.01.03 13:32:05</p>
          </div>
          <div class="contents-profile-info">
            <span>구성원</span>
            <v-expansion-panels>
              <v-expansion-panel>
                <template v-slot:title>
                  <p>{{ member.length }}명</p>
                </template>
                <template v-slot:text>
                  <ul class="accordion-contents-list">
                    <li v-for="(item, index) in member" :key="index">
                      <span class="info">{{ item.info }}</span>
                      <span class="state active" v-if="item.state === true">이용중</span>
                      <span class="state" v-if="item.state === false">이용안함</span>
                    </li>
                  </ul>
                </template>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('Workspace') }} <span>10</span>{{ translate('건') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <v-list>
            <v-list-item>
              <GSButton
                class="confirm-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :text="'선택삭제'"
                width="73"
              ></GSButton>
            </v-list-item>
            <v-list-item>
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'Workspace 추가'"
                width="144"
                @click="openAddWorkspaceDialog"
              ></GSButton>
            </v-list-item>
          </v-list>
          <v-dialog v-model="addWorkspaceDialog" class="gsr-dialog add-workspace-layer" width="600">
            <v-card>
              <div class="dialog-title-wrap">
                <strong class="dialog-title">Workspace 등록</strong>
                <div class="dialog-close-btn-box">
                  <v-btn class="dialog-close-btn" @click="closeAddWorkspaceDialog"></v-btn>
                </div>
              </div>
              <div class="dialog-tab-contents-wrap">
                <div class="dialog-tab-contents-area">
                  <div class="dialog-tab-title">
                    <span>기본정보</span>
                  </div>
                  <div class="dialog-tab-contents">
                    <ul class="dialog-tab-contents-list">
                      <li>
                        <GSInput
                          :variant="Constants.INPUT_VARIANTS.OUTLINED"
                          :size="Constants.INPUT_SIZES.MEDIUM"
                          :desc-position="Constants.INPUT_POSITION.TOP"
                          :description="'Workspace명'"
                          :required="true"
                        ></GSInput>
                      </li>
                      <li>
                        <GSSwitches
                          :size="Constants.SWITCHES_SIZES.LARGE"
                          :desc-position="Constants.SWITCHES_POSITION.TOP"
                          :description="'상태 활성화'"
                          :required="true"
                        ></GSSwitches>
                      </li>
                      <li>
                        <GSInput
                          :variant="Constants.INPUT_VARIANTS.OUTLINED"
                          :size="Constants.INPUT_SIZES.MEDIUM"
                          :desc-position="Constants.INPUT_POSITION.TOP"
                          :description="'설명'"
                          :placeholder="'설명 입력'"
                        ></GSInput>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="dialog-tab-contents-area">
                  <div class="dialog-tab-title">
                    <span>서비스 연결</span>
                  </div>
                  <div class="dialog-tab-contents">
                    <ul class="dialog-tab-contents-list">
                      <li class="column">
                        <GSSelects
                          :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                          :size="Constants.SELECTS_SIZES.MEDIUM"
                          :desc-position="Constants.SELECTS_POSITION.TOP"
                          :description="'서비스'"
                          :required="true"
                        ></GSSelects>
                        <p class="detail">
                          원하는 서비스가 없는 경우 조직 페이지에서 서비스 등록하고 연결해 주세요.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="dialog-tab-contents-area column">
                  <div class="dialog-tab-title">
                    <span>발송 채널 유형</span>
                  </div>
                  <div class="dialog-info">
                    <strong class="title">계약된 발송 채널이 없습니다.</strong>
                    <p class="text">
                      실시간 알림이나 공지, 이벤트, 할인 쿠폰을 SMS/LMS/MMS, 카카오 알림톡, PUSH,
                      Email로 발송할 수 있습니다. 사용을 원하시면 관리자에게 문의해 주세요.
                    </p>
                  </div>
                </div>
                <div class="dialog-tab-contents-area">
                  <div class="dialog-tab-title">
                    <span>발송 채널 유형</span>
                  </div>
                  <div class="dialog-tab-contents">
                    <ul class="dialog-tab-contents-list">
                      <li class="column">
                        <GSSwitches
                          :size="Constants.SWITCHES_SIZES.LARGE"
                          :desc-position="Constants.SWITCHES_POSITION.TOP"
                          :description="'SMS / LMS / MMS'"
                          :required="true"
                        ></GSSwitches>
                        <GSSelects
                          :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                          :size="Constants.SELECTS_SIZES.MEDIUM"
                          :desc-position="Constants.SELECTS_POSITION.TOP"
                          style="margin-top: 8px"
                        ></GSSelects>
                      </li>
                      <li class="column">
                        <GSSwitches
                          :size="Constants.SWITCHES_SIZES.LARGE"
                          :desc-position="Constants.SWITCHES_POSITION.TOP"
                          :description="'KakaoTalk'"
                          :required="true"
                        ></GSSwitches>
                        <GSSelects
                          :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                          :size="Constants.SELECTS_SIZES.MEDIUM"
                          :desc-position="Constants.SELECTS_POSITION.TOP"
                          style="margin-top: 8px"
                        ></GSSelects>
                      </li>
                      <li class="column">
                        <GSSwitches
                          :size="Constants.SWITCHES_SIZES.LARGE"
                          :desc-position="Constants.SWITCHES_POSITION.TOP"
                          :description="'PUSH'"
                          :required="true"
                        ></GSSwitches>
                        <GSSelects
                          :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                          :size="Constants.SELECTS_SIZES.MEDIUM"
                          :desc-position="Constants.SELECTS_POSITION.TOP"
                          style="margin-top: 8px"
                        ></GSSelects>
                      </li>
                      <li>
                        <div class="dialog-tab-email-text">
                          <span class="label">Email</span>
                          <p class="text">
                            계약된 채널이 아닙니다. 사용을 원하시면 관리자에게 문의해 주세요
                          </p>
                          <p class="text">조직 페이지에서 발송 채널을 등록 후 사용해 주세요.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="dialog-button-wrap">
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'닫기'"
                  width="57"
                  @click="closeAddWorkspaceDialog"
                ></GSButton>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :text="'저장하기'"
                  width="81"
                ></GSButton>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="contents-data-table-wrap">
        <v-data-table
          :headers="workspaceHeader"
          :items="workspaceData"
          :items-per-page="10"
          hide-default-footer
        >
          <template v-slot:colgroup>
            <colgroup>
              <col style="width: 200px" />
              <col style="width: 480px" />
              <col style="width: 599px" />
              <col style="width: 200px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:item.serviceName="{ item }">
            <div class="service-name">
              <v-avatar>
                <img :src="item.img" alt="" />
              </v-avatar>
              <span>{{ item.name }}</span>
            </div>
          </template>
          <template v-slot:item.state="{ item }">
            <span class="state active" v-if="item.state === true">이용중</span>
            <span class="state" v-if="item.state === false">이용안함</span>
          </template>
          <template v-slot:item.modify="{ item }">
            <GSButton
              :class="'select-date-today-btn'"
              :variant="Constants.BUTTON_VARIANTS.OUTLINED"
              :size="Constants.BUTTON_SIZES.TINY"
              :color="Constants.BUTTON_COLORS.SECONDARY"
              :text="'수정'"
              width="70"
            ></GSButton>
          </template>
          <template v-slot:no-data>
            <div class="no-data-msg">
              <p>검색결과가 없습니다.<br />관리자에게 문의해주세요.</p>
            </div>
          </template>
        </v-data-table>
        <Pagination :dataLength="workspaceData.length"></Pagination>
      </div>
    </div>
    <!-- E: 부서 정보 -->
  </div>
</template>

<script setup lang="ts">


import { ref } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSInput from '@/components/ui/GSInput.vue'
import GSSwitches from '@/components/ui/GSSwitches.vue'
import GSAutocompletes from '@/components/ui/GSAutocompletes.vue'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()


const member = [
  { info: '서*수 (seohm1004@gsretail.com)', state: true },
  { info: '김*미 (haemi1004@gsretail.com)', state: true },
  { info: '조*주 (chomj90@gsretail.com)', state: true },
  { info: '박*복 (park.drangonb1@gsretail.com)', state: false }
]

const addDepartmentDialog = ref(false)
const openAddDepartmentDialog = () => {
  addDepartmentDialog.value = true
}
const closeAddDepartmentDialog = () => {
  addDepartmentDialog.value = false
}

const members = ref()
const organizationMembers = [
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미1',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동1',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리1',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미2',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동2',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리2',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미3',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동3',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리3',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미4',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동4',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리4',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미5',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동5',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리5',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미6',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동6',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리6',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미7',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동7',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리7',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미8',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동8',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리8',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미9',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동9',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리9',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미10',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동10',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리10',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  }
]

const deptListHeader = [
  {
    align: 'start',
    title: '부서명',
    value: 'deptName'
  },
  {
    align: 'start',
    title: '설명',
    value: 'explain'
  },
  {
    align: 'start',
    title: '구성원',
    value: 'member'
  },
  {
    align: 'center',
    title: 'Workspace',
    value: 'workspace'
  },
  {
    align: 'center',
    title: '생성일',
    value: 'creationDate'
  },
  {
    align: 'center',
    title: '상태',
    value: 'state'
  }
]
const deptListDate = [
  // {
  //   deptName: '우리동네GS팀',
  //   explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)'
  //   ],
  //   workspace: '2',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
  // {
  //   deptName: '수퍼DX팀',
  //   explain: 'GS더프레시 광고성 / 정보성 메시지 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //   ],
  //   workspace: '2',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: false
  // },
  // {
  //   deptName: 'O4O기획팀',
  //   explain: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)'
  //   ],
  //   workspace: '10',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
  // {
  //   deptName: '우리동네GS팀',
  //   explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //   ],
  //   workspace: '2',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
  // {
  //   deptName: '우리동네GS팀',
  //   explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)'
  //   ],
  //   workspace: '2',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
  // {
  //   deptName: '수퍼DX팀',
  //   explain: 'GS더프레시 광고성 / 정보성 메시지 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //   ],
  //   workspace: '2',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: false
  // },
  // {
  //   deptName: 'O4O기획팀',
  //   explain: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //   ],
  //   workspace: '10',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
  // {
  //   deptName: '통합서비스TFT팀',
  //   explain: '샌디 플랫폼 테스트 메시지 발송 진행',
  //   member: [
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //   ],
  //   workspace: '8',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
  // {
  //   deptName: '통합서비스TFT팀',
  //   explain: '샌디 플랫폼 테스트 메시지 발송 진행',
  //   member: [
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //   ],
  //   workspace: '9',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
  // {
  //   deptName: '우리동네GS팀',
  //   explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
  //   member: [
  //     '서*미 (seohm1004@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '제갈*명 (jjjseohm1004@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)',
  //     '조*주 (chomj90@gsretail.com)'
  //   ],
  //   workspace: '8',
  //   creationDate: '2024.01.16  11:00:00',
  //   state: true
  // },
]

const workspaceHeader = [
  {
    align: 'start',
    title: '서비스명',
    value: 'serviceName'
  },
  {
    align: 'start',
    title: 'Workspace',
    value: 'workspace'
  },
  {
    align: 'start',
    title: '설명',
    value: 'detail'
  },
  {
    align: 'center',
    title: '상태',
    value: 'state'
  },
  {
    align: 'center',
    title: '정보 수정',
    value: 'modify'
  }
]
const workspaceData = [
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: '우리동네GS ',
    workspace: '우리동네GS 광고성 메시지',
    detail: '고객 광고메시지 대량 발송 진행 ',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS25 ',
    workspace: '우리동네GS 광고성 메시지',
    detail: '운영환경, 실제 고객발송',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_3.jpg',
    name: 'GS SHOP ',
    workspace: '우리동네GS 광고성 메시지',
    detail: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_4.jpg',
    name: 'GS THE FRESH ',
    workspace: 'GS리테일 통합멤버십 정보성 메시지',
    detail: '마케팅팀 500호점 오픈 기념 행사 App Push 진행',
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_5.jpg',
    name: 'GS리테일 통합멤버십 ',
    workspace: 'GS리테일 통합멤버십 정보성 메시지',
    detail: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_4.jpg',
    name: '우리동네GS ',
    workspace: 'GS리테일 통합멤버십 정보성 메시지',
    detail: '마케팅팀 500호점 오픈 기념 행사 App Push 진행',
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_3.jpg',
    name: '우리동네GS ',
    workspace: 'GS리테일 통합멤버십 정보성 메시지',
    detail: 'O4O 기획팀 주관 실시간 정보성 메시지 진행',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: '우리동네GS ',
    workspace: 'GS리테일 통합멤버십 정보성 메시지',
    detail: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: 'GS GS THE FRESH ',
    workspace: 'GS리테일 통합멤버십 정보성 메시지',
    detail: '회사 약관동의 메일 발송',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS리테일',
    workspace: 'GS리테일 통합멤버십 정보성 메시지',
    detail: '운영환경, 실제 고객발송',
    state: true
  }
]

const addWorkspaceDialog = ref(false)
const openAddWorkspaceDialog = () => {
  addWorkspaceDialog.value = true
}
const closeAddWorkspaceDialog = () => {
  addWorkspaceDialog.value = false
}
</script>
