<template>
  <div :class="descPosition" class="gsr-input">
    <div class="label-box">
      <span
        v-if="descPosition != INPUT_POSITION.DEFAULT && typeof description === 'string'"
        :class="{ required: required }"
        >{{ translate(description) }}
      </span>
    </div>
    <v-text-field
      :disabled="disabled"
      v-model="inputValue"
      spellcheck="false"
      :class="computedClassName"
      :label="translate(label)"
      :placeholder="placeholder ? translate(placeholder) : ''"
      :rules="mappedRules"
      :error-messages="errorMessage"
      :messages="successMessage"
      :maxlength="maxLength"
      v-on:input="props.validType === INPUT_VALIDTYPE.MENUAL ? null : validate()"
      v-bind:style="width !== undefined ? { width: width + 'px' } : undefined"
    >

        <!-- 오른쪽 끝에 글자 수 표시 -->
      <!-- 글자 수 카운터 -->
      <template v-if="showCounter" v-slot:append-inner>
        <span :class="['char-counter', { 'max-reached': inputValue.length >= maxLength }]">
          {{ inputValue.length }}/{{ maxLength }}자
        </span>
      </template>

      <template v-if="iconPosition == INPUT_ICONPOSITION.LEFT" v-slot:prepend-inner>
        <v-icon></v-icon>
      </template>
      <template v-else-if="iconPosition == INPUT_ICONPOSITION.RIGHT" v-slot:append-inner>
        <v-icon></v-icon>
      </template>
    </v-text-field>
  </div>
</template>
<script setup lang="ts">
import { defineProps, ref, watch, defineEmits, computed } from 'vue'
import {
  INPUT_VARIANTS,
  INPUT_SIZES,
  INPUT_POSITION,
  INPUT_ICONPOSITION,
  INPUT_VALIDTYPE,
  INPUT_VALIDSTYLE
} from '@/constants/inputConstants'
import type {
  InputSizes,
  InputVariants,
  InputPositions,
  InputIconpositions,
  InputValidtype,
  InputValidstyle
} from '@/constants/inputConstants'
import { getMappedRules, validationRuleMap } from '@/core/plugins/validationRules'
import { useCommonI18n } from '@/core/plugins/global-util'

interface Props {
  variant: InputVariants
  size: InputSizes
  label?: string
  placeholder?: string
  className?: string | string[]
  disabled?: boolean
  description?: string
  required?: boolean
  descPosition: InputPositions
  iconPosition: InputIconpositions
  validType?: InputValidtype | undefined
  validationStyle?: InputValidstyle | undefined
  rules: string[]
  showSuccessMessage?: boolean
  width?: number
  showCounter?: boolean // 카운터 노출 여부
  maxLength?: number
}

const { translate } = useCommonI18n()

const props = withDefaults(defineProps<Props>(), {
  variant: INPUT_VARIANTS.STANDARD,
  size: INPUT_SIZES.MEDIUM,
  label: '',
  className: '',
  descPosition: INPUT_POSITION.DEFAULT,
  iconPosition: INPUT_ICONPOSITION.DEFAULT,
  showSuccessMessage: false,
  rules: () => [] as string[],
  defaultValue: '',
  showCounter: false, // 기본값: 카운터 노출
  maxLength: 32,
})

const dynamicClasses = ref<string[]>([])
const error = ref<boolean>(false)
const errorMessage = ref<string>('')
const successMessage = ref<string>('Success')
  const maxLength = computed(() => props.maxLength) // props에서 maxLength를 가져옵니다.
// 유효성 검사 규칙 정의
// const mappedRules = computed(() => getMappedRules(props.rules))

const mappedRules = computed(() =>
  props.validType == INPUT_VALIDTYPE.SYNC ? getMappedRules(props.rules) : getMappedRules([''])
)

const computedClassName = computed(() => {
  const baseClassNames = Array.isArray(props.className) ? props.className : [props.className]
  return [...baseClassNames, props.variant, props.size, props.iconPosition, ...dynamicClasses.value]
})

const emit = defineEmits(['update-input'])

const inputValue = ref<string>(props.defaultValue || '')

watch(inputValue, (newValue, oldValue) => {
  if (props.validType === INPUT_VALIDTYPE.MENUAL) {
    if (oldValue !== newValue) {
      error.value = false
      errorMessage.value = ''
      removeClass('gsr-input-msg-text gsr-input-msg-error')
    }
  }
  emit('update-input', newValue)
})

const toggleClasses = (errorClass: string, successClass: string, hasError: boolean) => {
  if (hasError) {
    removeClass(successClass)
    addClass(errorClass)
  } else {
    removeClass(errorClass)
    if (props.showSuccessMessage) {
      addClass(successClass)
    }
  }
}

const validate = (): boolean => {
  for (const rule of props.rules) {
    const validationFn = validationRuleMap[rule]
    if (validationFn) {
      const result = validationFn(inputValue.value)
      if (typeof result === 'string') {
        error.value = true
        errorMessage.value = result

        const errorClass =
          props.validationStyle === INPUT_VALIDSTYLE.BASIC
            ? 'gsr-input-msg-text gsr-input-msg-error'
            : 'gsr-input-msg-card gsr-input-msg-error'
        const successClass =
          props.validationStyle === INPUT_VALIDSTYLE.BASIC
            ? 'gsr-input-msg-text gsr-input-msg-success'
            : 'gsr-input-msg-card gsr-input-msg-success'

        toggleClasses(errorClass, successClass, true)
        return false
      }
    }
  }

  error.value = false
  errorMessage.value = ''

  const errorClass =
    props.validationStyle === INPUT_VALIDSTYLE.BASIC
      ? 'gsr-input-msg-text gsr-input-msg-error'
      : 'gsr-input-msg-card gsr-input-msg-error'
  const successClass =
    props.validationStyle === INPUT_VALIDSTYLE.BASIC
      ? 'gsr-input-msg-text gsr-input-msg-success'
      : 'gsr-input-msg-card gsr-input-msg-success'

  toggleClasses(errorClass, successClass, false)
  return true
}

const addClass = (className: string) => {
  if (!dynamicClasses.value.includes(className)) {
    dynamicClasses.value.push(className)
  }
}

const removeClass = (className: string) => {
  dynamicClasses.value = dynamicClasses.value.filter((cls) => cls !== className)
}

defineExpose({
  validate
})
</script>
