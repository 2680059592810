<template>
    <div class="dashboard">
    <!-- 타이틀 -->
    <div class="contents-title-wrap">
      <strong class="contents-title">{{ translate('대시보드') }}</strong>
    </div>
    <!-- 총 사용현황 -->
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('사용현황') }}
          </strong>
          <p class="contents-sub-title-detail">
            {{ translate('2024. 08. 01 ~ 2024. 08 . 31 기준') }}
          </p>
        </div>
      </div>
      <div class="dashboard-contents usage-status">
        <v-list>
          <v-list-item v-for="(item, index) in usageStatus" :key="index">
            <template v-slot:prepend>
              <v-icon></v-icon>
            </template>
            <div class="title">
              <strong>{{ item.title }}</strong>
              <span>{{ item.subTitle }}</span>
            </div>
            <div class="contents">
              <strong>{{ item.value }}</strong>
              <span>{{ item.unit }}</span>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <!-- 채널별 사용 현황 -->
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('채널별 사용 현황') }}
          </strong>
          <p class="contents-sub-title-detail">
            {{ translate('2024. 08. 01 ~ 2024. 08 . 31 기준') }}
          </p>
        </div>
      </div>
      <div class="dashboard-contents month-usage-status">
        <v-list>
          <v-list-item v-for="(item, index) in monthUsageStatus" :key="index">
            <div class="title">
              <strong>{{ item.title }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{ item.textValue }}</strong>
                <span>{{ item.textUnit }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value" v-if="!item.costMsg">{{ item.costValue }}</strong>
                <span class="cost-unit" v-if="!item.costMsg">{{ item.costUnit }}</span>
                <strong class="cost-msg" v-if="item.costMsg">{{ item.costMsg }}</strong>
                <span class="cost-sub-msg" v-if="item.costMsg">{{ item.costSubMsg }}</span>
              </div>
              <div class="change-value-box" :class="getResult(item.monthChange)">
                <v-icon></v-icon>
                <span class="change-value">{{ item.monthChange }}%</span>
                <span class="sub-text">(전월 동기간 대비)</span>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <!-- 채널별 발송 현황, 발송비율 -->
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('채널별 발송 현황') }}
          </strong>
          <p class="contents-sub-title-detail">
            {{ translate('2024. 08. 01 ~ 2024. 08 . 31 기준') }}
          </p>
        </div>
        <div class="contents-sub-title-button">
          <ul>
            <li>
              <GSSelects
                :itemsList="channelData"
                :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                :size="Constants.SELECTS_SIZES.SMALL"
                :width="136"
              ></GSSelects>
            </li>
          </ul>
        </div>
      </div>
      <div class="dashboard-contents chart">
        <canvas ref="channelChartCanvas"></canvas>
      </div>
    </div>
    <div class="contents-area-wrap">
      <div class="contents-area">
        <div class="contents-sub-title-wrap">
          <div class="contents-sub-title-text">
            <strong class="contents-sub-title">
              {{ translate('발송 비율') }}
            </strong>
            <p class="contents-sub-title-detail">
              {{ translate('2024. 08. 01 ~ 2024. 08 . 31 기준') }}
            </p>
          </div>
          <div class="contents-sub-title-button">
            <GSSelects
              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
              :size="Constants.SELECTS_SIZES.SMALL"
              :itemsList="[{ label: '전체', value: '1' }, { label: '옵션1', value: '2' }]"
            ></GSSelects>
          </div>
        </div>
        <div class="dashboard-contents chart">
          <canvas ref="sendingChartCanvasSecondary"></canvas>
        </div>
        <p class="contents-info">
          월 단위 기준 발송 비율이 높은 순으로 최대 5개까지 안내해 드립니다.
        </p>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
// S: 다국어
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()
const i18n = useI18n()

i18n.locale.value = 'kr'

// @ts-ignore
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}
// E: 다국어

import { ref, onMounted } from 'vue'
import * as Constants from '@/constants/index'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'

// S: chart js
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import GSDatepicker from '@/components/ui/GSDatepicker.vue'

Chart.register(...registerables, ChartDataLabels)

const channelChartCanvas = ref<HTMLCanvasElement | null>(null)
const sendingChartCanvasPrimary = ref<HTMLCanvasElement | null>(null)
const sendingChartCanvasSecondary = ref<HTMLCanvasElement | null>(null)

// 라인 차트
const createLineChartOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      position: 'top',
      align: 'start',
      fullSize: false,
      labels: {
        color: '#67676F',
        font: {
          size: 13,
          family: 'Pretendard'
        },
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      min: 0,
      max: 900000,
      ticks: {
        stepSize: 100000
      }
    }
  }
})

// 도넛 차트
const createDoughnutChartOptions = () => ({
  radius: '140',
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        color: '#67676F',
        font: { size: 13, family: 'Pretendard' },
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
        padding: 12
      }
    },
    datalabels: {
      formatter: (value: number, context: any) => {
        const dataset = context.chart.data.datasets[0]
        const total = dataset.data.reduce((acc: number, val: number) => acc + val, 0)
        const percentage = (value / total) * 100
        return `${Math.round(percentage)}%`
      },
      display: true,
      color: '#ffffff',
      font: { size: 15, family: 'Pretendard' }
    }
  },
  layout: {
    padding: {
      left: 50,
      right: 50
    }
  }
})

const createChart = (ctx: CanvasRenderingContext2D, type: string, data: any, options: any) => {
  new Chart(ctx, { type, data, options })
}

onMounted(() => {
  // 라인 차트 데이터
  if (channelChartCanvas.value) {
    const channelCtx = channelChartCanvas.value.getContext('2d')
    if (channelCtx) {
      createChart(
        channelCtx,
        'line',
        {
          // labels: [
          //   '1월',
          //   '2월'
          // ],
          labels: [
            '1월',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '2월',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '3월',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '4월',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '5월',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '6월',
          ],
          datasets: [
            {
              label: 'SMS',
              data: [
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
                470000,
                320000,
                490000,
                320000,
                480000,
                470000,
                480000,
                320000,
                490000,
                480000,
                520000,
                380000,
                260000,
                360000,
                380000,
                270000,
                520000,
                260000,
                480000,
                210000,
              ],
              backgroundColor: '#2768FF',
              borderColor: '#2768FF',
              // pointRadius: 0,
            },
            {
              label: 'LMS',
              data: [450000, 730000, 500000, 520000],
              backgroundColor: '#17D427',
              borderColor: '#17D427'
            },
            {
              label: 'MMS',
              data: [280000, 290000, 110000, 710000],
              backgroundColor: '#FDC535',
              borderColor: '#FDC535'
            },
            {
              label: 'PUSH',
              data: [330000, 320000, 200000, 890000],
              backgroundColor: '#F67BA1',
              borderColor: '#F67BA1'
            },
            {
              label: '카카오 알림톡',
              data: [200000, 480000, 320000, 680000],
              backgroundColor: '#FF6E30',
              borderColor: '#FF6E30'
            },
            {
              label: 'Email',
              data: [520000, 530000, 170000, 120000],
              backgroundColor: '#8E53FB',
              borderColor: '#8E53FB'
            }
          ]
        },
        createLineChartOptions('top')
      )
    }
  }

  // 도넛 차트 1 데이터
  if (sendingChartCanvasPrimary.value) {
    const sendingCtx = sendingChartCanvasPrimary.value.getContext('2d')
    if (sendingCtx) {
      createChart(
        sendingCtx,
        'doughnut',
        {
          labels: ['SMS', 'LMS', 'MMS', 'PUSH', '카카오 알림톡', 'Email'],
          datasets: [
            {
              data: [25, 32, 13, 11, 12, 10],
              backgroundColor: ['#2768FF', '#21BF2F', '#F9B301', '#F67BA1', '#FF6E30', '#8E53FB']
            }
          ]
        },
        createDoughnutChartOptions('right')
      )
    }
  }

  // 도넛 차트 2 데이터
  if (sendingChartCanvasSecondary.value) {
    const sending2Ctx = sendingChartCanvasSecondary.value.getContext('2d')
    if (sending2Ctx) {
      createChart(
        sending2Ctx,
        'doughnut',
        {
          labels: [
            '[이벤트] (무통장) 입금확인 완료안내_T커머스, TC리모콘',
            '[메시지 발송] (GS SHOP} 티꿀 #{교환기간}안내',
            '[메시지발송] (GS SHOP) 적립금 소멸안내',
            '[이벤트] 입금 금액확인 및 예치금 전환안내_T커머스'
          ],
          datasets: [
            {
              data: [25, 32, 23, 20],
              backgroundColor: ['#004BF9', '#3873FA', '#709AFC', '#CCDBFE']
            }
          ]
        },
        createDoughnutChartOptions('right')
      )
    }
  }
})
// E: chart js

// const getTodayDate = () => {
//   const today = new Date()
//   const year = today.getFullYear()
//   const month = String(today.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1
//   const day = String(today.getDate()).padStart(2, '0') // 날짜를 2자리로 맞춤
//   return `${year}-${month}-${day}`
// }

// const confirmDate = () => {
//   console.log('확인')
//   searchStartDate.value = changeDate(selectedStartDate.value)
//   searchEndDate.value = changeDate(selectedEndDate.value)
//   openDatePicker.value = false
// }

// const openDatePicker = ref(false)
// const searchStartDate = ref(getTodayDate())
// const searchEndDate = ref(getTodayDate())
// const selectedStartDate = ref<Date | null>(null)
// const selectedEndDate = ref<Date | null>(null)

// const changeDate = (date: Date | null) => {
//   return date ? date.toLocaleDateString('en-CA') : ''
// }

// const daysInMonth = (year: number, month: number) => {
//   return new Date(year, month, 0).getDate() // month는 1월이 0, 2월이 1로 시작됨
// }

// const formatDateInput = (e: Event, type: string) => {
//   let input = (e.target as HTMLInputElement).value.replace(/\D/g, '') // 숫자 외 문자 제거

//   // 4번째 입력 시 하이픈(-) 추가
//   if (input.length > 4) {
//     input = `${input.slice(0, 4)}-${input.slice(4)}`
//   }

//   // 7번째 입력 시 하이픈(-) 추가
//   if (input.length > 7) {
//     input = `${input.slice(0, 7)}-${input.slice(7)}`
//   }

//   // 입력 길이를 10자로 제한 (YYYY-MM-DD)
//   if (input.length > 10) {
//     input = input.slice(0, 10)
//   }

//   // 유효한 MM과 DD 입력을 위한 제어
//   const year = parseInt(input.slice(0, 4), 10)
//   const month = parseInt(input.slice(5, 7), 10)
//   const day = parseInt(input.slice(8, 10), 10)

//   // MM에 대한 유효성 검사 (01~12)
//   if (month && (month < 1 || month > 12)) {
//     input = `${input.slice(0, 4)}-` // 잘못된 월 입력 시, 하이픈 뒤로 잘라냄
//   }

//   // DD에 대한 유효성 검사 (1~월별 최대 일수)
//   if (month && day && (day < 1 || day > daysInMonth(year, month))) {
//     input = `${input.slice(0, 7)}-` // 잘못된 일 입력 시, 일 부분을 잘라냄
//   }

//   // 필드에 따라 값을 저장 (start 또는 end)
//   if (type === 'start') {
//     searchStartDate.value = input
//   } else if (type === 'end') {
//     searchEndDate.value = input
//   }
// }

// 검색 영역 - 기간
const datePeriod = ref(['오늘', '1개월', '3개월', '6개월'])
// 검색 영역 - 조직
const organizationList = ref([
  '[HBU] 전사배부',
  '[HBU] TV홈쇼핑 마케팅팀',
  '[HBU] 마케팅팀',
  '[HBU] 모바일 사업부',
  '[PBU] 우리동네GS팀',
  '[PBU] 통합서비스TFT',
  '[HBU] 모바일 사업부',
  '[PBU] 우리동네GS팀',
  '[PBU] 통합서비스TFT',
  '[HBU] 전사배부 2',
  '[HBU] TV홈쇼핑 마케팅팀 2',
  '[HBU] 마케팅팀 2',
  '[HBU] 모바일 사업부 2',
  '[PBU] 우리동네GS팀 2',
  '[PBU] 통합서비스TFT 2',
  '[HBU] 모바일 사업부 2',
  '[PBU] 우리동네GS팀 2',
  '[PBU] 통합서비스TFT 2'
])
// workspace autocomplete
// const menuWorkspaceVisible = ref(false) // 메뉴 열림 상태 관리
// const showWorkspaceAutocomplete = ref(false) // autocomplete 보여줄지 말지 결정
// function handleWorkspaceMenuOpen(isOpen: boolean) {
//   if (isOpen) {
//     // 메뉴가 열리면 autocomplete 보이게 함
//     setTimeout(() => {
//       showWorkspaceAutocomplete.value = true
//     }, 280)
//   } else {
//     // 메뉴가 닫히면 autocomplete 숨김
//     showWorkspaceAutocomplete.value = false
//   }
// }

const dialog = ref(false)
const openDialog = () => {
  dialog.value = true
}
const closeDialog = () => {
  dialog.value = false
}
const activeIndex = ref(null)
const isDisabled = ref(false)
const activeDataBtn = (index: number) => {
  if (isDisabled.value) return
  activeIndex.value = index
  isDisabled.value = true

  setTimeout(() => {
    activeIndex.value = null
    isDisabled.value = false
  }, 300)
}
// 총 사용 현황
const usageStatus = [
  { title: '사용요금', value: '999,999,999', unit: '원' },
  { title: '발송건수', value: '999,999,999', unit: '건' },
  { title: '성공건수', subTitle: '(대체성공 포함)', value: '999,999,999', unit: '건' }
]
// 채널별 사용 현황
const monthUsageStatus = [
  {
    title: 'SMS',
    textValue: '300,000',
    textUnit: '건',
    costValue: '300,000',
    costUnit: '원',
    monthChange: '5'
  },
  {
    title: 'SMS',
    textValue: '400,000',
    textUnit: '건',
    costValue: '400,000',
    costUnit: '원',
    monthChange: '-3'
  },
  {
    title: 'LMS',
    textValue: '500,000',
    textUnit: '건',
    costValue: '500,000',
    costUnit: '원',
    monthChange: '-5'
  },
  {
    title: '카카오알림톡',
    textValue: '200,000',
    textUnit: '건',
    costValue: '200,000',
    costUnit: '원',
    monthChange: '-5'
  },
  {
    title: 'PUSH',
    textValue: '0',
    textUnit: '건',
    costMsg: '발송 비용 없음',
    costSubMsg: '(인프라 사용료 대체)',
    monthChange: '11.5'
  },
  {
    title: 'Email',
    textValue: '0',
    textUnit: '건',
    costMsg: '발송 비용 없음',
    costSubMsg: '(인프라 사용료 대체)',
    monthChange: '-5'
  }
]
// 채널별 사용 현황 - 전월 동기간 대비
const getResult = (value) => {
  if (value > 0) {
    return 'up'
  } else if (value < 0) {
    return 'down'
  } else {
    return ''
  }
}

// const channelData = ['전체', '활성화']
const channelData = [
  { label: '전체', value: '' },
  { label: '활성화', value: '' }
]
</script>
