export * from './buttonConstants'
export * from './inputConstants'
export * from './selectsConstants'
// export * from './autocompletesConstants'
export * from './checkboxConstants'
export * from './radioConstants'
export * from './switchesConstants'
export * from './autocompletesConstants'
// export * from './textareaConstants'
// export * from './datatableConstants'
// export * from './avatarsConstants'
// export * from './datepickerConstants'
// export * from './modalConstants'
// export * from './dialogConstants'

/** axios request Headers */
export const requestHeaders = {
  'x-tenant-id': '019145d8-0390-7c28-85c6-cc16452b7106'
}
