<template>
  <div class="work-space-list-wrap">
    <PageTitle :title="'Workspace 목록'"></PageTitle>
    <div class="data-table-area">
      <v-container>
        <v-row>
          <v-col>
            <div class="data-table-contents data-table">
              <div class="contents-sub-title-wrap">
                <div class="contents-sub-title-text">
                  <strong class="contents-sub-title">
                    {{ translate('조회내역') }} <span>{{ searchResult }}</span
                    >{{ translate('건') }}
                  </strong>
                </div>
                <div class="contents-sub-title-button">
                  <ul>
                    <li>
                      <v-checkbox
                        label="내 Workspace만 보기"
                        class="gsr-checkbox-medium gsr-checkbox-primary"
                      ></v-checkbox>
                    </li>
                    <li>
                      <GSSearch></GSSearch>
                    </li>
                    <li>
                      <GSSelects
                        :defaultValue="{ label: '10개씩 보기', value: '10' }"
                        :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                        :size="Constants.SELECTS_SIZES.SMALL"
                        style="width: 120px"
                        :itemsList="dataView"
                        v-model="dataView[0]"
                      ></GSSelects>
                    </li>
                  </ul>
                </div>
              </div>
              <v-data-table
                :headers="dataTableHeader"
                :items="workSpaceDataList"
                :items-per-page="10"
                :class="dataTableHeader.length > 12 ? 'scroll-x' : ''"
                hide-default-footer
              >
                <template #no-data>
                  <p>해당 내역이 없습니다.</p>
                </template>
                <template v-slot:item.state="{ item }">
                  <span v-if="item.state === '이용중'" class="processing">이용중</span>
                  <span v-else>{{ item.state }}</span>
                </template>
              </v-data-table>
              <div class="guide-info">
                <strong class="title">
                  미확인 건수는 발송일자 기준 24시간 이후 확인할 수 있습니다.
                </strong>
              </div>
              <v-pagination
                length="10"
                v-if="workSpaceDataList.length > 10"
                :total-visible="6"
                :show-first-last-page="true"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
interface DataTableHeader {
  align?: 'start' | 'center' | 'end'
  title?: string
  value: string
  sortable?: boolean
}

// S: 다국어
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()
// E: 다국어

import { ref } from 'vue'
import PageTitle from '@/components/common/PageTitle.vue'
import * as Constants from '@/constants/index'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSSearch from '@/components/ui/GSSearch.vue'
import GSButton from '@/components/ui/GSButton.vue'
// import * as Constants from '@/constants/index'
// import { ref } from 'vue'

// 조회 내역 건 수
const searchResult = ref('2,999')
const members = ref()
const organizationMembers = [
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미1',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동1',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리1',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미2',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동2',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리2',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미3',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동3',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리3',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미4',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동4',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리4',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미5',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동5',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리5',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미6',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동6',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리6',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미7',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동7',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리7',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미8',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동8',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리8',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미9',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동9',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리9',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미10',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동10',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리10',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  }
]

const dataView = ref([
  {
    label: '10개씩 보기',
    value: '10'
  },
  {
    label: '20개씩 보기',
    value: '20'
  },
  {
    label: '30개씩 보기',
    value: '30'
  }
])
const dataTableHeader = ref<DataTableHeader[]>([
  {
    align: 'start',
    title: 'Workspace 이름',
    value: 'workspacename',
    sortable: false
  },
  {
    align: 'start',
    title: '설명',
    value: 'description',
    sortable: false
  },
  {
    align: 'center',
    title: '상태',
    value: 'state',
    sortable: false
  }
])
const workSpaceDataList = ref([
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용안함'
  },
  {
    workspacename: 'HBU CX팀 수기발송',
    description: '고객 광고메시지 대량 발송 진행',
    state: '이용중'
  }
])
// 데이터 테이블 data
</script>
