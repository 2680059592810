<template>
  <v-dialog v-model="openDialog" class="dialog-layer search-error-layer" persistent>
    <!-- 아이콘 + 타이틀 + 텍스트 + 버튼 -->
    <v-card v-if="alertType === 'basic'" class="gsr-popup type-a">
      <!-- 아이콘 -->
      <div class="gsr-popup-icon">
        <i v-if="alertIconType == 'warning'" class="popup-icon-type-a"></i>
        <i v-if="alertIconType === 'success'" class="popup-icon-type-b"></i>
        <i v-if="alertIconType === 'danger'" class="popup-icon-type-c"></i>
      </div>
      <!-- 타이틀 -->
      <div class="gsr-popup-title">{{ translate(props.alertTitle || '') }}</div>
      <!-- 텍스트 -->
      <p class="gsr-popup-text">{{ translate(props.alertText || '') }}</p>
      <!-- 버튼 -->
      <div class="gsr-popup-btn">
        <GSButton
          v-if="isAlertDefaultBtn"
          ref="cancelBtn"
          class="cancel"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :width="btnDefaultSize"
          :text="translate(props.btnDefaultText || '')"
          @click="handleAction('cancel')"
        ></GSButton>
        <GSButton
          v-if="isAlertPrimaryBtn"
          ref="confirmBtn"
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :width="btnPrimarySize"
          :text="translate(props.btnPrimaryText || '')"
          @click="handleAction('confirm')"
        ></GSButton>
      </div>
    </v-card>
    <!-- 타이틀 + 텍스트 + 버튼  -->
    <v-card v-if="alertType === 'title'" class="gsr-popup type-b">
      <!-- 타이틀 -->
      <div class="gsr-popup-title">{{ translate(props.alertTitle || '') }}</div>
      <!-- 텍스트 -->
      <p class="gsr-popup-text">{{ translate(props.alertText || '') }}</p>
      <!-- 버튼 -->
      <div class="gsr-popup-btn">
        <GSButton
          v-if="isAlertDefaultBtn"
          ref="cancelBtn"
          class="cancel"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :width="btnDefaultSize"
          :text="translate(props.btnDefaultText || '')"
          @click="handleAction('cancel')"
        ></GSButton>
        <GSButton
          v-if="isAlertPrimaryBtn"
          ref="confirmBtn"
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :width="btnPrimarySize"
          :text="translate(props.btnPrimaryText || '')"
          @click="handleAction('confirm')"
        ></GSButton>
      </div>
    </v-card>
    <!-- 타이틀 + 서브 타이틀 +  버튼-->
    <v-card v-if="alertType === 'nonTitle'" class="gsr-popup type-c">
      <div class="title-area">
        <!-- 타이틀 -->
        <div class="gsr-popup-title">{{ translate(props.alertTitle || '') }}</div>
        <!-- 서브 타이틀-->
        <div class="gsr-popup-title">{{ translate(props.alertSubTitle) }}</div>
      </div>
      <!-- 버튼 -->
      <div class="gsr-popup-btn">
        <GSButton
          v-if="isAlertDefaultBtn"
          ref="cancelBtn"
          class="cancel"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :width="btnDefaultSize"
          :text="translate(props.btnDefaultText || '')"
          @click="handleAction('cancel')"
        ></GSButton>
        <GSButton
          v-if="isAlertPrimaryBtn"
          ref="confirmBtn"
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :width="btnPrimarySize"
          :text="translate(props.btnPrimaryText || '')"
          @click="handleAction('confirm')"
        ></GSButton>
      </div>
    </v-card>
    <!-- 아이콘 + 타이틀 + 서브타이틀 + 버튼  -->
    <v-card v-if="alertType === 'iconTitle'" class="gsr-popup type-d">
      <!-- 아이콘 -->
      <div class="gsr-popup-icon">
        <i v-if="alertIconType == 'warning'" class="popup-icon-type-a"></i>
        <i v-if="alertIconType === 'success'" class="popup-icon-type-b"></i>
        <i v-if="alertIconType === 'danger'" class="popup-icon-type-c"></i>
      </div>
      <div class="title-area">
        <!-- 타이틀 -->
        <div class="gsr-popup-title">{{ translate(props.alertTitle || '') }}</div>
        <!-- 서브 타이틀-->
        <div class="gsr-popup-title">{{ translate(props.alertSubTitle) }}</div>
      </div>
      <!-- 버튼 -->
      <div class="gsr-popup-btn">
        <GSButton
          v-if="isAlertDefaultBtn"
          ref="cancelBtn"
          class="cancel"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :width="btnDefaultSize"
          :text="translate(props.btnDefaultText || '')"
          @click="handleAction('cancel')"
        ></GSButton>
        <GSButton
          v-if="isAlertPrimaryBtn"
          ref="confirmBtn"
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :width="btnPrimarySize"
          :text="translate(props.btnPrimaryText || '')"
          @click="handleAction('confirm')"
        ></GSButton>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import * as Constants from '@/constants/index'
import { ref, defineEmits } from 'vue'
import GSButton from './GSButton.vue'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()

const openDialog = ref(true)

interface Props {
  alertType: string
  isAlertDefaultBtn?: boolean
  isAlertPrimaryBtn?: boolean
  alertIconType?: string
  alertTitle?: string
  alertSubTitle?: string
  alertText?: string
  btnPrimaryText?: string
  btnDefaultText?: string
  btnDefaultSize?: number
  btnPrimarySize?: number
}

// 기본값 설정
const props = withDefaults(defineProps<Props>(), {
  alertSubTitle: '',
  btnDefaultSize: 80,
  btnPrimarySize: 80
})
const emit = defineEmits<{
  (e: 'action', actionType: string): void
}>()

const handleAction = (actionType: string) => {
  emit('action', actionType)

}
</script>
