<template>
  <v-menu class="gsr-time-picker-selector" :close-on-content-click="false">
    <template #activator="{ props }">
      <div class="gsr-time-picker" v-bind="props">
        <v-select class="ampm" :items="time" v-model="time[0]" readonly></v-select>
        <v-text-field class="hr" placeholder="HH"></v-text-field>
        <span>:</span>
        <v-text-field class="min" placeholder="MM"></v-text-field>
      </div>
    </template>
    <GSTimepickerSelector></GSTimepickerSelector>
  </v-menu>
</template>

<script setup lang="js">
import GSTimepickerSelector from './GSTimepickerSelector.vue'

const time = ['오전', '오후']
</script>
