<template>
  <div class="sending-msg-push">
    <PageTitle :title="'E-MAIL'"></PageTitle>
    <ServiceReady></ServiceReady>
  </div>
</template>

<script setup lang="ts">
// S: 다국어
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()
const i18n = useI18n()

i18n.locale.value = 'kr'

// @ts-ignore
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}
// E: 다국어

import PageTitle from '@/components/common/PageTitle.vue';
import ServiceReady from '@/components/common/ServiceReady.vue'
</script>