export const SELECTS_VARIANTS = {
  OUTLINED: 'gsr-selects-outlined',
  STANDARD: 'gsr-selects-standard'
} as const

export const SELECTS_SIZES = {
  SMALL: 'gsr-selects-small',
  MEDIUM: 'gsr-selects-medium',
  LARGE: 'gsr-selects-large'
} as const

export const SELECTS_POSITION = {
  DEFAULT: 'gsr-selects-default-position',
  LEFT: 'gsr-selects-left-position',
  TOP: 'gsr-selects-top-position'
} as const

export const SELECTS_ICONPOSITION = {
  DEFAULT: '',
  LEFT: 'gsr-input-icon-left',
  RIGHT: 'gsr-input-icon-right'
} as const

export const SELECTS_VALIDTYPE = {
  SYNC: 'sync',
  MENUAL: 'menual'
} as const

export const SELECTS_VALIDSTYLE = {
  BASIC: 'basic',
  TABLE: 'table'
} as const

// 타입 정의
export type SelectsSizes = (typeof SELECTS_SIZES)[keyof typeof SELECTS_SIZES]
export type SelectsVariants = (typeof SELECTS_VARIANTS)[keyof typeof SELECTS_VARIANTS]
export type SelectsPositions = (typeof SELECTS_POSITION)[keyof typeof SELECTS_POSITION]
export type SelectsIconpositions = (typeof SELECTS_ICONPOSITION)[keyof typeof SELECTS_ICONPOSITION]
export type SelectsValidtype = (typeof SELECTS_VALIDTYPE)[keyof typeof SELECTS_VALIDTYPE]
export type SelectsValidstyle = (typeof SELECTS_VALIDSTYLE)[keyof typeof SELECTS_VALIDSTYLE]
