import { useI18n } from 'vue-i18n'

export function formatNumberWithComma(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/* Date -> YYYY.MM.DD */
export function convertDateToCommaDate(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}.${month}.${day}`
}

/*  YYYY.MM.DD -> Date */
export function convertCommaDateToDate(dateString: string): Date {
  const [year, month, day] = dateString.split('.').map(Number)
  return new Date(year, month - 1, day)
}

/*  오늘날짜 return Date */
export function getCurrentDate(): Date {
  return new Date()
}

/*  오늘날짜 return YYYY.MM.DD */
export function getCurrentCommaDate(): string {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}.${month}.${day}`
}

/** 공통 번역
 * message -> 번역할 메시지는 i18n.ts에 등록되어 있어야 함
 */
export const useCommonI18n = () => {
  const { t, te, locale } = useI18n()
  locale.value = 'kr'
  // 문자열 포맷팅 유틸리티
  const translate = (text: string) => {
    if (!text || text.trim() === '') {
      return ''
    }
    if (te(text)) {
      return t(text)
    } else {
      return text
    }
  }
  return {
    translate
  }
}

/** 조회 날짜 문자열 변환  */
export const totalInfoFormatDate = (dateStr: string): string => {
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/

  if (!dateRegex.test(dateStr)) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD format.')
  }

  return dateStr.replace(/-/g, '. ')
}

/* 유효성 검사 */
const convertValidDate = (dateStr: string): boolean => {
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
  return dateRegex.test(dateStr)
}

/** 검색 기간 유효성 검사  */
export const searchRangValidate = (startDate: string, endDate: string): boolean => {
  if (!convertValidDate(startDate) || !convertValidDate(endDate)) {
    return false
  }

  const sdate = new Date(startDate)
  const edate = new Date(endDate)

  if (sdate > edate) {
    return false
  }

  // const oneYearInMs = 365 * 24 * 60 * 60 * 1000 // 1년
  // 1개월
  const oneMonthInMs = 31 * 24 * 60 * 60 * 1000
  const dateDifference = edate.getTime() - sdate.getTime()

  // if (dateDifference > oneYearInMs) {
  if (dateDifference > oneMonthInMs) {
    return false
  }

  return true
}

/** 날짜 string 시간 초기화 */
export const resetDateValue = (dateString: string): Date => {
  const date = new Date(dateString)
  date.setHours(0, 0, 0, 0)
  return date
}

/** 날짜 Date 시간 초기화 */
export const resetTime = (date: Date) => {
  const resetDate = new Date(date)
  resetDate.setHours(0, 0, 0, 0)
  return resetDate
}

/** 현재 날짜 */
export const getTodayDate = (minDay?: number) => {
  const today = new Date()
  if (minDay) {
    today.setDate(today.getDate() - minDay) // 현재 날짜에서 minDay일을 뺌
  }

  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
/** 현재 달의 마지막 날 */
export const getLastDayOfCurrentMonth = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1 // 월은 0부터 시작하므로 +1

  // 현재 달의 마지막 날
  const lastDay = new Date(year, month, 0)

  const lastDayYear = lastDay.getFullYear()
  const lastDayMonth = String(lastDay.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1
  const lastDayDate = String(lastDay.getDate()).padStart(2, '0') // 날짜를 2자리로 맞춤

  return `${lastDayYear}-${lastDayMonth}-${lastDayDate}`
}

/** 다음 달의 마지막 날짜 */
export const getNextMonthLastDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const nextMonth = today.getMonth() + 1 // 다음 달 (월은 0부터 시작하므로 +1)

  // 다음 달의 첫 번째 날
  // const firstDayNextMonth = new Date(year, nextMonth, 1)

  // 다음 달의 마지막 날
  const lastDayNextMonth = new Date(year, nextMonth + 1, 0)

  const yearNextMonth = lastDayNextMonth.getFullYear()
  const monthNextMonth = String(lastDayNextMonth.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1
  const dayNextMonth = String(lastDayNextMonth.getDate()).padStart(2, '0') // 날짜를 2자리로 맞춤

  return `${yearNextMonth}-${monthNextMonth}-${dayNextMonth}`
}

/* YYYY-MM-DD 형식으로 날짜를 반환 */
export const changeDate = (date: Date | null) => {
  return date ? date.toLocaleDateString('en-CA') : '' // YYYY-MM-DD 형식으로 날짜를 반환
}

/* 특정 월의 모든 날짜를 생성하는 함수 */
export const generateDates = (start: string, end: string) => {
  const dates = []
  const currentDate = new Date(start)
  const endDate = new Date(end)

  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().split('T')[0]) // 'YYYY-MM-DD' 형식
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

/** 배열에서 label을 키로 사용하여 객체를 생성하는 함수  */
export const extractLabelsAsKeys = (array: [any]) => {
  return array.reduce((acc, item) => {
    let key = item.label.toLowerCase()
    if (['카카오알림톡', '카카오친구톡'].includes(item.label)) {
      key = item.label === '카카오알림톡' ? 'kakaoalert' : 'kakaofriend'
    }
    acc[key] = item
    return acc
  }, {})
}

/** lineChart 배열에서 label을 키로 사용하여 객체를 생성하는 함수  */
export const lineExtractLabelsAsKeys = (items: [any], dates: [string]) => {
  // 항목 배열을 객체로 변환
  const itemMap = extractLabelsAsKeys(items)

  // 날짜 배열에 맞춰 값 반환
  const result = Object.entries(itemMap).reduce((acc: any, [key, item]: [string, any]) => {
    acc[key] = dates.map((date) => {
      const index = item.date.findIndex((d: string) => d === date)
      return index !== -1 ? item.value[index] : 0
    })
    acc[key] = {
      ...item,
      value: acc[key]
    }
    return acc
  }, {})

  return result
}

/** 데이터를 label과 value를 각각의 배열로 분리하는 함수 */
export const separateLabelsAndValues = (data: any[], key?: string) => {
  const labels: string[] = []
  const values: number[] = []

  data.forEach((item) => {
    if (key) {
      labels.push(`[${item[key]}] ${item.label}`)
    } else {
      let itemlabel = item.label
      if (item.label === 'EMAIL') {
        itemlabel = 'Email'
      }
      labels.push(itemlabel)
    }
    values.push(item.value)
  })

  return { labels, values }
}

/** 배열 안에 모든 값이 0인지 확인하는 함수 , 모든 값이 0이면 true, 그렇지 않으면 false */
export const isAllZero = (arr: number[]): boolean => {
  return arr.every((value) => value === 0)
}

/** 조직리스트에서 동일한 name의 obj 추출 */
export const orgListExtract = (items: any, name: string) => {
  const orgList = items.filter((item: any) => item.organization_name === name)
  return orgList[0]
}

/**
 * JSON 문자열을 객체로 변환하는 함수
 * @param jsonString - 변환할 JSON 문자열
 * @returns 변환된 객체 또는 변환 중 오류가 발생한 경우 null
 */
export const jsonParse = (jsonString: string): any => {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    console.error('JSON parsing error:', error)
    return null
  }
}
/** 공통 코드 추출 */
export const GetCmmCode = (key: string) => {
  const jsonString = window.sessionStorage.getItem('cmmCode')

  if (jsonString) {
    try {
      const cmmCode = JSON.parse(jsonString)[key]
      console.log('cmmCode', cmmCode)

      return cmmCode.map((item: any) => {
        const { name, ...rest } = item
        return { label: name, ...rest }
      })
    } catch (error) {
      console.error('JSON parsing error:', error)
      return null
    }
  }
}

/** 값이 없으면 true */
export const isNull = (value: any) => {
  let strValue = value

  if (typeof value === 'string') {
    strValue = value.trim()
  }
  if (strValue === null || strValue === undefined || strValue === '') {
    return true
  } else {
    return false
  }
}
