<template>
  <v-menu v-model="openDatePicker" class="gsr-select-date" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-form class="gsr-select-date-form" v-bind="props" @click="handleDateFormClick">
        <div class="gsr-select-date-input">
          <v-text-field
            placeholder="YYYY-MM-DD"
            @input="(e: Event) => formatDateInput(e, 'start')"
            v-model="searchStartDate"
            maxlength="10"
          ></v-text-field>
          ~
          <v-text-field
            placeholder="YYYY-MM-DD"
            @input="(e: Event) => formatDateInput(e, 'end')"
            v-model="searchEndDate"
          ></v-text-field>
        </div>
        <div
          :class="
            searchStartDate ? 'gsr-select-date-clear-btn on' : 'gsr-select-date-clear-btn off'
          "
        >
          <v-btn @click="clearDate" @click.stop></v-btn>
        </div>
      </v-form>
    </template>

    <v-card class="gsr-select-date-layer">
      <v-row no-gutters>
        <v-col>
          <v-date-picker
            hide-header
            show-adjacent-months
            :key="datePickerKey1"
            v-model="selectedDate1"
            :max="selectedDate2 ? selectedDate2 : endMax"
          >
            <!-- 
          :show-adjacent-months="false"
                  :month="currentMonth1"
            :year="currentYear1"
            @update:modelValue="handleDateChange1"
            @update:month="onMonthUpdate1"
            @update:year="onYearUpdate1"
            multiple="range"
             -->
          </v-date-picker>
        </v-col>
        <v-col>
          <v-date-picker
            hide-header
            show-adjacent-months
            :key="datePickerKey2"
            v-model="selectedDate2"
            :min="selectedDate1"
            :max="endMax"
          >
            <!-- 
          :show-adjacent-months="false"
          :month="currentMonth2"
            :year="currentYear2"
            @update:modelValue="handleDateChange2"
            @update:month="onMonthUpdate2"
            @update:year="onYearUpdate2"
            multiple="range"
             -->
          </v-date-picker>
        </v-col>
      </v-row>
      <v-row no-gutters class="gsr-select-date-btn-area">
        <!-- <GSButton
          :class="'select-date-today-btn'"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :text="'오늘'"
          :width="48"
          @click="setToday()"
        ></GSButton> -->
        <v-list class="gsr-select-date-btn-list">
          <v-list-item>
            <!-- <GSButton
              :class="'select-date-reset-btn'"
              :variant="Constants.BUTTON_VARIANTS.OUTLINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.SECONDARY"
              :width="32"
              @click="resetDate()"
            ></GSButton> -->
          </v-list-item>
          <v-list-item>
            <GSButton
              :variant="Constants.BUTTON_VARIANTS.CONTAINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.INHERIT"
              :text="'닫기'"
              :width="48"
              @click="closeDate()"
            ></GSButton>
          </v-list-item>
          <v-list-item>
            <GSButton
              :class="'select-date-confirm-btn'"
              :variant="Constants.BUTTON_VARIANTS.CONTAINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.PRIMARY"
              :text="'선택완료'"
              :width="69"
              @click="cofirmDate()"
            ></GSButton>
          </v-list-item>
        </v-list>
      </v-row>
    </v-card>
  </v-menu>
</template>
<script setup lang="ts">
import { ref, watch, onMounted, defineEmits, onUnmounted } from 'vue'
import * as Constants from '@/constants/index'
import GSButton from '@/components/ui/GSButton.vue'
import { changeDate, resetTime } from '@/core/plugins/global-util'

interface Props {
  startDate?: string | null
  endDate?: string | null
  className?: string | string[]
  endMax?: string // 최대값 설정
}

const props = withDefaults(defineProps<Props>(), {
  className: ''
})

const openDatePicker = ref(false)

const searchStartDate = ref<string>(props.startDate || '') // props로 받은 시작일
const searchEndDate = ref<string>(props.endDate || '') // props로 받은 종료일
const selectedDate1 = ref<Date[]>([])
const selectedDate2 = ref<Date[]>([])
// 확인전 변수 값 , emit으로 부모에게 전달
const selectedStartDate = ref<string>('')
const selectedEndDate = ref<string>('')

const currentDate1 = ref(new Date())

// 오늘 날짜에서 한 달 더한 날짜 계산
// const currentDate2 = computed(() => {
//   const date = new Date(currentDate1.value) // 현재 날짜 복사
//   date.setMonth(date.getMonth() + 1) // 한 달 추가
//   return date
// })

/* 상위컴포넌트에서 시작일 변경 감지 */
watch(
  () => props.startDate,
  (newValue) => {
    searchStartDate.value = newValue
    // endMax가 있을 경우, 시작일이 endMax보다 클경우 시작일 초기화
    if (newValue && newValue.length === 10 && props.endMax) {
      const endMaxDate = new Date(props.endMax)
      const vldDate = new Date(newValue)
      if (endMaxDate < vldDate) {
        searchStartDate.value = ''
      }
    }
  }
)
/* 상위컴포넌트에서 종료일 변경 감지 */
watch(
  () => props.endDate,
  (newValue) => {
    searchEndDate.value = newValue
    // endMax가 있을 경우, 마지막일이 endMax보다 클경우 시작일 초기화
    if (newValue && newValue.length === 10 && props.endMax) {
      const endMaxDate = new Date(props.endMax)
      const vldDate = new Date(newValue)
      if (endMaxDate < vldDate) {
        searchEndDate.value = ''
      }
    }
  }
)

const currentMonth1 = ref(currentDate1.value.getMonth()) // 0 = 1월, 11 = 12월
const currentYear1 = ref(new Date().getFullYear())

const currentMonth2 = ref(new Date().getMonth() + 1) // 기본값: 다음 달
const currentYear2 = ref(new Date().getFullYear()) // 현재 연도

const datePickerKey1 = ref(0) // key 값 초기화
const datePickerKey2 = ref(0) // key 값 초기화

const emit = defineEmits(['update-start-date', 'update-end-date'])

/* 선택완료 */
const cofirmDate = () => {
  // const sdate = new Date(searchStartDate.value)
  // const edate = new Date(searchEndDate.value)

  // console.log('cofirmDate', selectedDate1.value.length, selectedDate2.value)

  // todo 왜 스트링으로 들어올때 있는지 , 배열로 들어오는지 분석해야 됨
  let emitDate1 = selectedDate1.value.length > 0 ? selectedDate1.value[0] : selectedDate1.value
  let emitDate2 = selectedDate2.value.length > 0 ? selectedDate2.value[0] : selectedDate2.value
  if (isNaN(emitDate1.getTime())) {
    emit('update-start-date', '')
  } else {
    emit('update-start-date', changeDate(resetTime(emitDate1)))
  }
  if (isNaN(emitDate2.getTime())) {
    emit('update-end-date', '')
  } else {
    emit('update-end-date', changeDate(resetTime(emitDate2)))
  }
  //추후 개발시 주석해제
  // emit('update-start-date', selectedStartDate.value)
  // emit('update-end-date', selectedEndDate.value)
  // searchStartDate.value = selectedStartDate.value
  // searchEndDate.value = selectedEndDate.value

  // if (sdate > edate) {
  //   searchStartDate.value = changeDate(selectedStartDate.value)
  //   searchEndDate.value = ''
  // } else {
  //   searchStartDate.value = changeDate(selectedStartDate.value)
  //   searchEndDate.value = changeDate(selectedEndDate.value)
  // }
  openDatePicker.value = false
}

const forceUpdateDatePicker1 = () => {
  datePickerKey1.value += 1 // key 값 변경 -> v-date-picker 강제 재렌더링
}

const forceUpdateDatePicker2 = () => {
  datePickerKey2.value += 1 // key 값 변경 -> v-date-picker 강제 재렌더링
}

/* 범위 내 모든 날짜를 계산 */
const calculateDateRange = (start: Date, end: Date): Date[] => {
  const range = []
  const current = new Date(start)

  while (current <= end) {
    range.push(new Date(current))
    current.setDate(current.getDate() + 1)
  }

  return range
}

/* 날짜를 기준으로 두 배열로 분리 */
const splitDatesByMonth = (dates: Date[], referenceDate: Date) => {
  const referenceMonth = referenceDate.getMonth()
  const referenceYear = referenceDate.getFullYear()

  const datesBeforeReferenceMonth = dates.filter(
    (date) =>
      date.getFullYear() < referenceYear ||
      (date.getFullYear() === referenceYear && date.getMonth() < referenceMonth)
  )

  const datesAfterReferenceMonth = dates.filter(
    (date) =>
      date.getFullYear() > referenceYear ||
      (date.getFullYear() === referenceYear && date.getMonth() >= referenceMonth)
  )

  return { datesBeforeReferenceMonth, datesAfterReferenceMonth }
}

/** 시작 picker 변경 */
const handleDateChange1 = () => {
  console.log('handleDateChange1:', changeDate(resetTime(selectedDate1.value[0])))
  // 두 번째 피커 값이 여러 개일 경우
  const startDate = selectedDate1.value[0]
  const endDate = selectedDate1.value[selectedDate1.value.length - 1]

  // 시작과 종료 날짜 정렬
  const [start, end] = [startDate, endDate].sort((a, b) => a.getTime() - b.getTime())

  //날짜 입력 필드에 값 설정
  selectedStartDate.value = changeDate(resetTime(start))
  selectedEndDate.value = changeDate(resetTime(end))

  // 범위 내 날짜 계산
  const range = calculateDateRange(start, end)

  // 두 번째 피커 기준으로 날짜 분리
  const { datesBeforeReferenceMonth, datesAfterReferenceMonth } = splitDatesByMonth(range, endDate)
  selectedDate1.value = datesBeforeReferenceMonth // 실제로는 빈배열 들어감
  selectedDate2.value = datesAfterReferenceMonth
}

// 두 번째 피커 값 변경 시 처리
const handleDateChange2 = () => {
  // 달력의 rang 표시
  if (selectedDate1.value.length === 1 && selectedDate2.value.length === 1) {
    const startDate = selectedDate1.value[0]
    const endDate = selectedDate2.value[0]
    console.log('selectedDate2.value:', selectedDate2.value)
    console.log('endDate:', endDate)
    // 시작과 종료 날짜 정렬
    const [start, end] = [startDate, endDate].sort((a, b) => a.getTime() - b.getTime())

    // //날짜 입력 필드에 값 설정
    selectedStartDate.value = changeDate(resetTime(start))
    selectedEndDate.value = changeDate(resetTime(end))

    // 범위 내 날짜 계산
    const range = calculateDateRange(start, end)

    // 두 번째 피커 기준으로 날짜 분리
    const { datesBeforeReferenceMonth, datesAfterReferenceMonth } = splitDatesByMonth(
      range,
      endDate
    )

    selectedDate1.value = datesBeforeReferenceMonth
    selectedDate2.value = datesAfterReferenceMonth
  } else {
    // 두 번째 피커 값이 여러 개일 경우
    const startDate = selectedDate2.value[0]
    const endDate = selectedDate2.value[selectedDate2.value.length - 1]

    // 시작과 종료 날짜 정렬
    const [start, end] = [startDate, endDate].sort((a, b) => a.getTime() - b.getTime())

    //날짜 입력 필드에 값 설정
    selectedStartDate.value = changeDate(resetTime(start))
    selectedEndDate.value = changeDate(resetTime(end))

    // 범위 내 날짜 계산
    const range = calculateDateRange(start, end)

    // 두 번째 피커 기준으로 날짜 분리
    const { datesBeforeReferenceMonth, datesAfterReferenceMonth } = splitDatesByMonth(
      range,
      endDate
    )
    selectedDate1.value = datesBeforeReferenceMonth // 실제로는 빈배열 들어감
    selectedDate2.value = datesAfterReferenceMonth
  }
}

const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate() // month는 1월이 0, 2월이 1로 시작됨
}
const formatDateInput = (e: Event, type: string) => {
  let input = (e.target as HTMLInputElement).value.replace(/\D/g, '') // 숫자 외 문자 제거

  // 4번째 입력 시 하이픈(-) 추가
  if (input.length > 4) {
    input = `${input.slice(0, 4)}-${input.slice(4)}`
  }

  // 7번째 입력 시 하이픈(-) 추가
  if (input.length > 7) {
    input = `${input.slice(0, 7)}-${input.slice(7)}`
  }

  // 입력 길이를 10자로 제한 (YYYY-MM-DD)
  if (input.length > 10) {
    input = input.slice(0, 10)
  }

  // 유효한 MM과 DD 입력을 위한 제어
  const year = parseInt(input.slice(0, 4), 10)
  const month = parseInt(input.slice(5, 7), 10)
  const day = parseInt(input.slice(8, 10), 10)

  // MM에 대한 유효성 검사 (01~12)
  if (month && (month < 1 || month > 12)) {
    input = `${input.slice(0, 4)}-` // 잘못된 월 입력 시, 하이픈 뒤로 잘라냄
  }

  // DD에 대한 유효성 검사 (1~월별 최대 일수)
  if (month && day && (day < 1 || day > daysInMonth(year, month))) {
    input = `${input.slice(0, 7)}-` // 잘못된 일 입력 시, 일 부분을 잘라냄
  }

  // 필드에 따라 값을 저장 (start 또는 end)
  if (type === 'start') {
    searchStartDate.value = input
    emit('update-start-date', input)
  } else if (type === 'end') {
    searchEndDate.value = input
    emit('update-end-date', input)
  }
}

const clearDate = () => {
  searchStartDate.value = ''
  searchEndDate.value = ''
  emit('update-start-date', '')
  emit('update-end-date', '')
}

const onMonthUpdate1 = (newMonth: number) => {
  console.log('월 변경됨 1:', newMonth)
  currentMonth1.value = newMonth
  if (currentMonth1.value + 1 === currentMonth2.value) {
    return
  } else {
    forceUpdateDatePicker2()
    currentMonth2.value = currentMonth1.value + 1
  }
}

const onMonthUpdate2 = (newMonth: number) => {
  console.log('월 변경됨 2:', newMonth)
  currentMonth2.value = newMonth
  if (currentMonth1.value + 1 === currentMonth2.value) {
    return
  } else {
    forceUpdateDatePicker1()
    currentMonth1.value = currentMonth2.value - 1
  }
  // currentMonth1.value = newMonth
  // console.log('월 변경됨 1:', currentMonth1.value)
}

const onYearUpdate1 = (newYear: number) => {
  console.log('연도 변경됨 1:', newYear)
  currentYear1.value = newYear
  if (currentYear1.value + 1 === currentYear2.value) {
    return
  } else {
    forceUpdateDatePicker2()
    currentYear2.value = currentYear1.value + 1
  }
}

const onYearUpdate2 = (newYear: number) => {
  console.log('연도 변경됨 2:', newYear)
  currentYear2.value = newYear
  if (currentYear1.value + 1 === currentYear2.value) {
    return
  } else {
    forceUpdateDatePicker1()
    currentYear1.value = currentYear2.value + 1
  }
}

/* 날짜 입력 필드 클릭 시 */
const handleDateFormClick = (e: Event) => {
  if (openDatePicker.value) {
    e.stopPropagation() // 메뉴가 열렸다면 클릭 전파를 막음
  } else {
    openDatePicker.value = true // 메뉴를 열기
  }
}

/* 이전 버튼 클릭 */
const handlePrevClick = () => {
  console.log('이전 버튼이 클릭되었습니다!')
  const prevButtons = document.querySelectorAll('i.mdi-chevron-left.mdi.v-icon')
  const triggerButton = prevButtons[1] as HTMLElement
  triggerButton.click()
}

/* 다음 버튼 클릭 */
const handleNextClick = () => {
  console.log('다음 버튼이 클릭되었습니다!')
  const nextButtons = document.querySelectorAll('i.mdi-chevron-right.mdi.v-icon')
  const triggerButton = nextButtons[0] as HTMLElement
  triggerButton.click()
}

/* 오늘 */
const setToday = () => {
  const today = new Date()
  selectedDate1.value = [resetTime(today)]
  selectedDate2.value = [resetTime(today)]
}

/* 초기화 */
const resetDate = () => {
  const today = new Date()
  selectedDate1.value = [resetTime(today)]
  selectedDate2.value = [resetTime(today)]
}

/* 취소 */
const closeDate = () => {
  selectedStartDate.value = ''
  selectedEndDate.value = ''

  openDatePicker.value = false
}

/* 첫번째 달력 날짜 변경 시 처리 */
watch(
  selectedDate1,
  (newValue) => {
    if (newValue.length === 1 && newValue[0]) {
      console.log('length 1')
      const firstDate = new Date(newValue[0])
      const nextMonth = firstDate.getMonth() + 1 // 다음 달 계산

      if (nextMonth > 11) {
        currentMonth2.value = 0 // 12월이면 1월로 변경
        currentYear2.value = firstDate.getFullYear() + 1 // 연도 증가
      } else {
        currentMonth2.value = nextMonth // 다음 달
        currentYear2.value = firstDate.getFullYear() // 동일 연도
      }
    } else if (newValue.length > 1 && newValue[0]) {
      console.log('length 2')
    } else {
      // console.log('else')
    }

    // console.log('currentMonth1 :: ', currentMonth1.value)
    // console.log('currentMonth2 :: ', currentMonth2.value)
    //  else {
    //   // 첫 번째 값이 없을 경우, 현재 날짜 기준으로 설정
    //   const today = new Date()
    //   currentMonth2.value = today.getMonth() + 1 > 11 ? 0 : today.getMonth() + 1
    //   curre ntYear2.value = today.getMonth() + 1 > 11 ? today.getFullYear() + 1 : today.getFullYear()
    // }
  },
  { immediate: true }
)

watch(currentMonth1, (newValue, oldValue) => {
  // console.log('currentMonth1 Watch ::: ', newValue, oldValue)
  // console.log('currentMonth1 Value ::: ', currentMonth1.value)
  if (currentMonth1.value === currentMonth2.value) {
    forceUpdateDatePicker1()
    // console.log('동일1', currentMonth1.value, currentMonth2.value)
    currentMonth1.value = currentMonth2.value - 1
  } else {
    // console.log('다름1', currentMonth1.value, currentMonth2.value)
  }
})

watch(currentMonth2, (newValue, oldValue) => {
  // console.log('currentMonth2 Watch ::: ', newValue, oldValue)
  // console.log('currentMonth2 Value ::: ', currentMonth2.value)

  // if (newValue === 1 && oldValue === 12) {
  //   // 12월에서 1월로 넘어갈 때
  //   console.log('넘어가기')
  //   currentYear2.value += 1
  // } else if (newValue === 12 && oldValue === 1) {
  //   // 1월에서 12월로 넘어갈 때 (예: 뒤로 가기)
  //   currentYear2.value -= 1
  //   console.log('내려가기')
  // }

  if (currentMonth2.value === currentMonth1.value) {
    forceUpdateDatePicker2()
    console.log('동일2', currentMonth1.value, currentMonth2.value)
    currentMonth2.value = currentMonth1.value + 1
  } else {
    console.log('다름2', currentMonth1.value, currentMonth2.value)
  }
})

/* v-menu 열림/닫힘 감지 */
watch(openDatePicker, (isOpen) => {
  if (isOpen) {
    console.log('v-menu 열림')
    //초기 값이 있을 경우
    const startDate = new Date(searchStartDate.value)
    const endDate = new Date(searchEndDate.value)
    selectedDate1.value = [startDate]
    selectedDate2.value = [endDate]
    console.log('startDate: :', startDate)
    console.log('endDate: :', endDate)
    // if (startDate && endDate) {
    //   // 시작과 종료 날짜 정렬
    //   const [start, end] = [startDate, endDate].sort((a, b) => a.getTime() - b.getTime())

    //   // 범위 내 날짜 계산
    //   const range = calculateDateRange(start, end)

    //   // 두 번째 피커 기준으로 날짜 분리
    //   const { datesBeforeReferenceMonth, datesAfterReferenceMonth } = splitDatesByMonth(
    //     range,
    //     endDate
    //   )
    //   console.log('datesBeforeReferenceMonth:', datesBeforeReferenceMonth)
    //   console.log('datesAfterReferenceMonth:', datesAfterReferenceMonth)
    //   selectedDate1.value = datesBeforeReferenceMonth
    //   selectedDate2.value = datesAfterReferenceMonth
    // }
    setTimeout(() => {
      const prevButtons = document.querySelectorAll('i.mdi-chevron-left.mdi.v-icon')
      prevButtons.forEach((button) => {
        const prevButton = button as HTMLElement // 타입 캐스팅
        if (!prevButton.hasAttribute('data-event-bound')) {
          // todo: 이전 버튼 탐지 및 이벤트 추가
          // prevButton.addEventListener('click', handlePrevClick)
          prevButton.setAttribute('data-event-bound', 'true')
          // console.log('이전 버튼 이벤트 연결 완료:', prevButton)
        }
      })

      // 다음 버튼 탐지 및 이벤트 추가
      const nextButtons = document.querySelectorAll('i.mdi-chevron-right.mdi.v-icon')

      nextButtons.forEach((button) => {
        const nextButton = button as HTMLElement // 타입 캐스팅
        if (!nextButton.hasAttribute('data-event-bound')) {
          // todo: 다음 버튼 탐지 및 이벤트 추가
          // nextButton.addEventListener('click', handleNextClick)
          nextButton.setAttribute('data-event-bound', 'true')
          // console.log('다음 버튼 이벤트 리스너 연결 완료:', nextButton)
        }
      })
    }, 50) // DOM 렌더링 대기 시간
  } else {
    console.log('v-menu 닫힘')
    // 이전 버튼 이벤트 제거
    const prevButtons = document.querySelectorAll('i.mdi-chevron-left.mdi.v-icon')
    prevButtons.forEach((button) => {
      const prevButton = button as HTMLElement // 타입 캐스팅
      if (prevButton.hasAttribute('data-event-bound')) {
        prevButton.removeEventListener('click', handlePrevClick)
        prevButton.removeAttribute('data-event-bound')
        // console.log(`이전 버튼 이벤트 제거 완료: `, prevButton)
      }
    })

    // 다음 버튼 이벤트 제거
    const nextButtons = document.querySelectorAll('i.mdi-chevron-right.mdi.v-icon')
    nextButtons.forEach((button) => {
      const nextButton = button as HTMLElement // 타입 캐스팅
      if (nextButton.hasAttribute('data-event-bound')) {
        nextButton.removeEventListener('click', handleNextClick)
        nextButton.removeAttribute('data-event-bound')
        // console.log(`다음 버튼 이벤트 제거 완료: `, nextButton)
      }
    })
    // if (selectedDate1.value.length > 0 && selectedDate1.value[0] instanceof Date) {
    //   // 배열에 값이 있고, 첫 번째 값이 Date 객체인지 확인
    //   return selectedDate1.value[0].toLocaleDateString('ko-KR') // 한국 날짜 형식
    // } else {
    //   // 배열이 비어 있거나 첫 번째 값이 없을 경우 기본값 반환
    //   return new Date().toLocaleDateString('ko-KR') // 현재 날짜 반환
    // }

    // firstSelectedDate1.value = false
    // firstSelectedDate2.value = false
  }
})

/** 선택 메뉴 외부의 스크롤 발생시 선택 메뉴 close  */
const closeAllOverlays = () => {
  openDatePicker.value = false
}

onMounted(() => {
  if (currentMonth2.value == 12) {
    currentYear2.value += 1
  }
  window.addEventListener('scroll', closeAllOverlays)
})
onUnmounted(() => {
  window.removeEventListener('scroll', closeAllOverlays)
})
</script>
