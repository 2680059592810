<template>
  <div class="service-setting">
    <PageTitle :title="'서비스 설정'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('서비스 정보') }}
          </strong>
        </div>
      </div>
      <div class="contents-data-form-wrap">
        <v-data-table hide-default-header hide-default-footer>
          <template v-slot:colgroup>
            <colgroup>
              <col style="width: 220px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:body="props">
            <tr>
              <th>
                <span>기본정보</span>
              </th>
              <td>
                <GSImages></GSImages>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'서비스명'"
                  :placeholder="'서비스명 입력'"
                  :required="true"
                  style="margin-top: 16px; width: 600px"
                ></GSInput>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'광고메시지 발송시간'"
                  :required="true"
                  style="margin-top: 16px"
                ></GSInput>
                <GSSwitches
                  :size="Constants.SWITCHES_SIZES.LARGE"
                  :desc-position="Constants.SWITCHES_POSITION.TOP"
                  :description="'상태 활성화'"
                  :required="true"
                  style="margin-top: 16px"
                ></GSSwitches>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'설명'"
                  :placeholder="'설명 입력'"
                  style="margin-top: 16px"
                ></GSInput>
              </td>
            </tr>
            <tr class="border-0">
              <td colspan="2" class="contents-data-form-btn-area">
                <div class="contents-data-form-btn-list">
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.MEDIUM"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'취소'"
                    width="100"
                    @click="openCancelDialog"
                  ></GSButton>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                    :size="Constants.BUTTON_SIZES.MEDIUM"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :text="'저장'"
                    width="100"
                    @click="openSaveDialog"
                  ></GSButton>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-tabs-wrap">
        <v-tabs height="44" v-model="tab">
          <v-tab width="141">SMS / LMS / MMS</v-tab>
          <v-tab width="81">KakaoTalk</v-tab>
          <v-tab width="50">PUSH</v-tab>
          <v-tab width="46">Email</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <!-- 상세정보 -->
          <v-tabs-window-item value="0">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>0</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'SMS / LMS / MMS 추가'"
                      width="184"
                      @click="openAutoSaveDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                hide-default-footer
                :headers="smsHeader"
                :items="smsData"
                :items-per-page="10"
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 360px" />
                    <col style="width: 460px" />
                    <col style="width: 300px" />
                    <col style="width: 300px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />SMS / LMS / MMS를 추가해 주세요.</p>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tabs-window-item>
          <v-tabs-window-item value="1">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>1</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'SMS / LMS / MMS 추가'"
                      width="184"
                      @click="openAutoSaveDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                hide-default-footer
                :headers="smsHeader"
                :items="smsData"
                :items-per-page="10"
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 360px" />
                    <col style="width: 460px" />
                    <col style="width: 300px" />
                    <col style="width: 300px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />SMS / LMS / MMS를 추가해 주세요.</p>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tabs-window-item>
          <v-tabs-window-item value="2">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>2</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'SMS / LMS / MMS 추가'"
                      width="184"
                      @click="openAutoSaveDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                hide-default-footer
                :headers="smsHeader"
                :items="smsData"
                :items-per-page="10"
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 360px" />
                    <col style="width: 460px" />
                    <col style="width: 300px" />
                    <col style="width: 300px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />SMS / LMS / MMS를 추가해 주세요.</p>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tabs-window-item>
          <v-tabs-window-item value="3">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>3</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'SMS / LMS / MMS 추가'"
                      width="184"
                      @click="openAutoSaveDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                hide-default-footer
                :headers="smsHeader"
                :items="smsData"
                :items-per-page="10"
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 360px" />
                    <col style="width: 460px" />
                    <col style="width: 300px" />
                    <col style="width: 300px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />SMS / LMS / MMS를 추가해 주세요.</p>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
  <!-- 서비스 정보 저장 dialog -->
  <v-dialog class="gsr-dialog" v-model="autoSaveDialog" width="400">
    <v-card>
      <div class="dialog-contents-wrap">
        <p class="text">
          채널 정보를 추가하려면 서비스 정보 저장이 필요해요. 서비스 정보를 저장하시겠어요?
        </p>
      </div>
      <div class="dialog-button-wrap">
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :text="'취소'"
          width="59"
          @click="closeAutoSaveDialog"
        ></GSButton>
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'저장하기'"
          width="80"
          @click="openSmsDialog"
        ></GSButton>
      </div>
    </v-card>
  </v-dialog>
  <!-- 변경사항 저장 dialog -->
  <v-dialog class="gsr-dialog" v-model="saveDialog" width="400">
    <v-card>
      <div class="dialog-contents-wrap">
        <p class="text">변경사항을 저장하시겠어요?</p>
      </div>
      <div class="dialog-button-wrap">
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :text="'취소'"
          @click="closeCancelDialog"
          width="59"
        ></GSButton>
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'확인'"
          width="80"
        ></GSButton>
      </div>
    </v-card>
  </v-dialog>
  <!-- 취소 dialog -->
  <v-dialog class="gsr-dialog" v-model="cancelDialog" width="400">
    <v-card>
      <div class="dialog-title-wrap">
        <strong class="dialog-title">다음에 진행하시겠어요?</strong>
        <div class="dialog-close-btn-box">
          <v-btn class="dialog-close-btn" @click="closeCancelDialog"></v-btn>
        </div>
      </div>
      <div class="dialog-contents-wrap">
        <p class="sub-text">지금 닫으시면 작성한 내용들은 모두 저장되지 않아요.</p>
      </div>
      <div class="dialog-button-wrap">
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :text="'취소'"
          @click="closeCancelDialog"
          width="59"
        ></GSButton>
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'확인'"
          width="80"
        ></GSButton>
      </div>
    </v-card>
  </v-dialog>
  <!-- sms lms mms 추가 dialog -->
  <v-dialog class="gsr-dialog service-add-layer" width="444" v-model="smsDialog">
    <v-card>
      <div class="dialog-title-wrap">
        <strong class="dialog-title">SMS / LMS / MMS 추가</strong>
        <div class="dialog-close-btn-box">
          <v-btn class="dialog-close-btn" @click="closeSmsDialog"></v-btn>
        </div>
      </div>
      <div class="dialog-contents-wrap">
        <div class="info-area">
          <GSInput
            :variant="Constants.INPUT_VARIANTS.OUTLINED"
            :size="Constants.INPUT_SIZES.SMALL"
            :desc-position="Constants.INPUT_POSITION.TOP"
            :description="'보내는 사람'"
            :required="true"
            :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
          ></GSInput>
          <GSInput
            :variant="Constants.INPUT_VARIANTS.OUTLINED"
            :size="Constants.INPUT_SIZES.SMALL"
            :desc-position="Constants.INPUT_POSITION.TOP"
            :description="'발신번호'"
            :required="true"
            :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
          ></GSInput>
          <GSSwitches
            :size="Constants.SWITCHES_SIZES.LARGE"
            :desc-position="Constants.SWITCHES_POSITION.TOP"
            :description="'트래킹 여부'"
            :required="true"
          ></GSSwitches>
          <GSSelects
            :variant="Constants.SELECTS_VARIANTS.OUTLINED"
            :size="Constants.SELECTS_SIZES.SMALL"
            :desc-position="Constants.SELECTS_POSITION.TOP"
            :description="'유입 성과분석'"
            :required="true"
          ></GSSelects>
        </div>
      </div>
      <div class="dialog-button-wrap">
        <div>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.TEXT"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'저장 후 계속 추가'"
            width="119"
          ></GSButton>
        </div>
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :text="'닫기'"
          width="57"
          @click="closeSmsDialog"
        ></GSButton>
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'저장하기'"
          width="81"
        ></GSButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import { ref } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSInput from '@/components/ui/GSInput.vue'
import GSSwitches from '@/components/ui/GSSwitches.vue'
import GSImages from '@/components/ui/GSImages.vue'
import { useCommonI18n } from '@/core/plugins/global-util'


const { translate } = useCommonI18n()


// img upload
const defaultImage = '/src/assets/images/img_register_default.jpg'
const imageUrl = ref<string>(defaultImage)

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (file) {
    imageUrl.value = URL.createObjectURL(file)
  } else {
    imageUrl.value = defaultImage
  }
}

const tab = ref([0])

const smsHeader = [
  {
    align: 'start',
    title: '보낸 사람',
    value: 'from'
  },
  {
    align: 'start',
    title: '발신채널 Key',
    value: 'key'
  },
  {
    align: 'center',
    title: '트래킹 여부',
    value: 'tracking'
  },
  {
    align: 'center',
    title: '유입 성과분석',
    value: 'analysis'
  },
  {
    align: 'center',
    title: '정보 수정',
    value: 'modify'
  }
]
const smsData = []

// dialog
const cancelDialog = ref(false)
const openCancelDialog = () => {
  cancelDialog.value = true
}
const closeCancelDialog = () => {
  cancelDialog.value = false
}
// dialog
const saveDialog = ref(false)
const openSaveDialog = () => {
  saveDialog.value = true
}
const closeSaveDialog = () => {
  saveDialog.value = false
}
// dialog
const autoSaveDialog = ref(false)
const openAutoSaveDialog = () => {
  autoSaveDialog.value = true
}
const closeAutoSaveDialog = () => {
  autoSaveDialog.value = false
}
// dialog
const smsDialog = ref(false)
const openSmsDialog = () => {
  smsDialog.value = true
}
const closeSmsDialog = () => {
  smsDialog.value = false
}
</script>
