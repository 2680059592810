<template>
  <div class="dashboard">
    <!-- 타이틀 -->
    <div class="contents-title-wrap">
      <strong class="contents-title">{{ translate('대시보드') }}</strong>
    </div>
    <!-- 검색 영역 -->
    <div class="data-table-search-area no-wrap">
      <div class="search-option-area">
        <!-- 검색 - 조직 -->
        <div class="gsr-select-autocomplete">
          <div class="label-box">
            <span class="label required">{{ translate('조직') }}</span>
          </div>
          <v-menu
            v-model="openSearchOrg"
            :close-on-content-click="false"
            transition="fade-transition"
            @update:modelValue="handleOrgMenuOpen"
          >
            <template #activator="{ props }">
              <v-btn v-bind="props" class="gsr-select-autocomplete-activator">
                <span>{{ selectedOrganization?.organization_name }}</span>
              </v-btn>
            </template>
            <v-card v-click-outside="onClickOutsideOrg">
              <v-autocomplete
                v-if="showOrgAutocomplete"
                ref="organizationListRef"
                v-model="selectedOrganization"
                :offset-y="true"
                transition="slide-y-transition"
                menu
                :clearable="true"
                :items="organizationList"
                placeholder="검색어 입력"
                :close-on-content-click="false"
                class="gsr-select-autocomplete-search"
                :menu-props="{ class: 'gsr-select-autocomplete-search-list' }"
                item-title="organization_name"
                item-value="organization_id"
                no-data-text="검색결과가 없습니다."
                return-object
                :open-text="selectedOrganization?.organization_name"
                @keydown="onKeydownOrg"
              >
                <template v-slot:prepend-inner>
                  <span class="title">{{ translate('조직 검색') }}</span>
                </template>
              </v-autocomplete>
            </v-card>
          </v-menu>
        </div>
        <!-- 검색 - 기간 -->
        <div class="option-date">
          <div class="label-box">
            <span class="label required">{{ translate('기간') }}</span>
          </div>
          <v-btn-toggle mandatory class="date-range-btn-toggle">
            <v-btn
              v-for="(item, index) in datePeriod"
              :key="item.value"
              :value="item.value"
              :class="{ active: activeIndexDateBtn === index }"
              @click="selectedPeriodBtn(item.value, index)"
              :disabled="isDisabledDateBtn"
            >
              {{ item.text }}
            </v-btn>
          </v-btn-toggle>
          <GSDatepicker
            :startDate="searchStartDate"
            :endDate="searchEndDate"
            :endMax="getTodayDate(2)"
            @update-start-date="($event) => (searchStartDate = $event)"
            @update-end-date="($event) => (searchEndDate = $event)"
          ></GSDatepicker>
        </div>
        <div class="search-btn-area">
          <GSButton
            class="reset-btn"
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
            :width="32"
            @click="refreshData"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'조회'"
            :width="128"
            @click="searchData"
          ></GSButton>
        </div>
      </div>
      <GSAlert
        v-if="searchErrDialog"
        alertType="iconTitle"
        alertIconType="warning"
        alertTitle="1개월 이내로 설정하여 조회해 주세요."
        isAlertPrimaryBtn
        btnPrimaryText="확인"
        @action="() => (searchErrDialog = false)"
      ></GSAlert>
    </div>
    <div class="guide-info">
      <strong class="title">
        대시보드 조회 데이터는 당일이 아닌 전전일(그제) 기준으로 제공됩니다. (통신사 발송 결과
        데이터는 발송 후 24시간 후에 최종 수신되어 집계됩니다.)
      </strong>
    </div>
    <!-- 총 사용현황 -->
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('총 사용 현황') }}
          </strong>
          <p class="contents-sub-title-detail" v-if="totalCostInfo !== null">
            {{ totalInfoStartDate }} {{ totalInfoEndDate && ` ~ ${totalInfoEndDate}` }}
            {{ ' 기준' }}
          </p>
        </div>
      </div>
      <div class="dashboard-contents usage-status">
        <v-list>
          <!-- <v-list-item v-for="(item, index) in usageStatus" :key="index">
            <template v-slot:prepend>
              <v-icon></v-icon>
            </template>
            <div class="title">
              <strong>{{ item.title }}</strong>
              <span>{{ item.subTitle }}</span>
            </div>
            <div class="contents">
              <strong>{{ item.value }}</strong>
              <span>{{ item.unit }}</span>
            </div>
          </v-list-item> -->

          <v-list-item>
            <template v-slot:prepend>
              <v-icon></v-icon>
            </template>
            <div class="title">
              <strong>{{ translate('사용요금') }}</strong>
            </div>
            <div class="contents">
              <strong>{{
                totalCostInfo ? formatNumberWithComma(totalCostInfo.use_cost) : 0
              }}</strong>
              <span>{{ translate('원') }}</span>
            </div>
          </v-list-item>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon></v-icon>
            </template>
            <div class="title">
              <strong>{{ translate('발송건수') }}</strong>
            </div>
            <div class="contents">
              <strong>{{
                totalCostInfo ? formatNumberWithComma(totalCostInfo.sent_cnt) : 0
              }}</strong>
              <span>{{ translate('건') }}</span>
            </div>
          </v-list-item>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon></v-icon>
            </template>
            <div class="title">
              <strong>{{ translate('성공건수') }}</strong>
              <span>{{ translate('(대체성공 포함)') }}</span>
            </div>
            <div class="contents">
              <strong>{{
                totalCostInfo ? formatNumberWithComma(totalCostInfo.succ_cnt) : 0
              }}</strong>
              <span>{{ translate('건') }}</span>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <!-- 채널별 사용 현황 -->
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('채널별 사용 현황') }}
          </strong>
          <p class="contents-sub-title-detail" v-if="totalCostInfo !== null">
            {{ totalInfoStartDate }} {{ totalInfoEndDate && ` ~ ${totalInfoEndDate}` }}
            {{ ' 기준' }}
          </p>
        </div>
      </div>
      <div class="dashboard-contents month-usage-status">
        <v-list>
          <v-list-item>
            <div class="title">
              <strong>{{ translate('SMS') }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{
                  channelCostInfo?.sms ? formatNumberWithComma(channelCostInfo?.sms.succ_cnt) : 0
                }}</strong>
                <span>{{ translate('건') }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value">{{
                  channelCostInfo?.sms ? formatNumberWithComma(channelCostInfo?.sms.use_cost) : 0
                }}</strong>
                <span class="cost-unit">{{ translate('원') }}</span>
                <strong class="cost-msg"></strong>
                <span class="cost-sub-msg"></span>
              </div>
            </div>
          </v-list-item>
          <v-list-item>
            <div class="title">
              <strong>{{ translate('LMS') }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{
                  channelCostInfo?.lms ? formatNumberWithComma(channelCostInfo?.lms.succ_cnt) : 0
                }}</strong>
                <span>{{ translate('건') }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value">{{
                  channelCostInfo?.lms ? formatNumberWithComma(channelCostInfo?.lms.use_cost) : 0
                }}</strong>
                <span class="cost-unit">{{ translate('원') }}</span>
                <strong class="cost-msg"></strong>
                <span class="cost-sub-msg"></span>
              </div>
            </div>
          </v-list-item>
          <v-list-item>
            <div class="title">
              <strong>{{ translate('MMS') }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{
                  channelCostInfo?.mms ? formatNumberWithComma(channelCostInfo?.mms.succ_cnt) : 0
                }}</strong>
                <span>{{ translate('건') }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value">{{
                  channelCostInfo?.mms ? formatNumberWithComma(channelCostInfo?.mms.use_cost) : 0
                }}</strong>
                <span class="cost-unit">{{ translate('원') }}</span>
                <strong class="cost-msg"></strong>
                <span class="cost-sub-msg"></span>
              </div>
            </div>
          </v-list-item>
          <v-list-item>
            <div class="title">
              <strong>{{ translate('카카오 알림톡') }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{
                  channelCostInfo?.kakaoalert
                    ? formatNumberWithComma(channelCostInfo?.kakaoalert.succ_cnt)
                    : 0
                }}</strong>
                <span>{{ translate('건') }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value">{{
                  channelCostInfo?.kakaoalert
                    ? formatNumberWithComma(channelCostInfo?.kakaoalert.use_cost)
                    : 0
                }}</strong>
                <span class="cost-unit">{{ translate('원') }}</span>
                <strong class="cost-msg"></strong>
                <span class="cost-sub-msg"></span>
              </div>
            </div>
          </v-list-item>
          <v-list-item>
            <div class="title">
              <strong>{{ translate('카카오 친구톡') }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{
                  channelCostInfo?.kakaofriend
                    ? formatNumberWithComma(channelCostInfo?.kakaofriend.succ_cnt)
                    : 0
                }}</strong>
                <span>{{ translate('건') }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value">{{
                  channelCostInfo?.kakaofriend
                    ? formatNumberWithComma(channelCostInfo?.kakaofriend.use_cost)
                    : 0
                }}</strong>
                <span class="cost-unit">{{ translate('원') }}</span>
                <strong class="cost-msg"></strong>
                <span class="cost-sub-msg"></span>
              </div>
            </div>
          </v-list-item>
          <v-list-item>
            <div class="title">
              <strong>{{ translate('PUSH') }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{
                  channelCostInfo?.push ? formatNumberWithComma(channelCostInfo?.push.succ_cnt) : 0
                }}</strong>
                <span>{{ translate('건') }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value"></strong>
                <span class="cost-unit"></span>
                <strong class="cost-msg">{{ translate('발송 비용 없음') }}</strong>
                <span class="cost-sub-msg">{{ translate('(인프라 사용료 대체)') }}</span>
              </div>
            </div>
          </v-list-item>
          <v-list-item>
            <div class="title">
              <strong>{{ translate('Email') }}</strong>
            </div>
            <div class="contents">
              <div class="text-value-box">
                <strong>{{
                  channelCostInfo?.email
                    ? formatNumberWithComma(channelCostInfo?.email.succ_cnt)
                    : 0
                }}</strong>
                <span>{{ translate('건') }}</span>
              </div>
              <div class="cost-value-box">
                <strong class="cost-value"></strong>
                <span class="cost-unit"></span>
                <strong class="cost-msg">{{ translate('발송 비용 없음') }}</strong>
                <span class="cost-sub-msg">{{ translate('(인프라 사용료 대체)') }}</span>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <!-- 채널별 발송 , 발송비율 -->
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('채널별 발송량 추이') }}
          </strong>
          <p class="contents-sub-title-detail" v-if="totalCostInfo !== null">
            {{ totalInfoStartDate }} {{ totalInfoEndDate && ` ~ ${totalInfoEndDate}` }}
            {{ ' 기준' }}
          </p>
        </div>
      </div>

      <div class="dashboard-contents chart line-chart">
        <GSNoData
          :text="noDataText"
          :subText="noDataSubText"
          :className="linChartVisible ? 'none' : ''"
        ></GSNoData>
        <canvas v-show="linChartVisible" ref="channelChartCanvas"></canvas>
        <!-- v-show="linChartVisible" -->
      </div>
    </div>
    <div class="contents-area-wrap">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('발송비율') }}
          </strong>
          <p class="contents-sub-title-detail" v-if="totalCostInfo !== null">
            {{ totalInfoStartDate }} {{ totalInfoEndDate && ` ~ ${totalInfoEndDate}` }}
            {{ ' 기준' }}
          </p>
        </div>
      </div>
      <div class="content-chart-wrap">
        <div class="contents-area">
          <div class="dashboard-contents chart">
            <div class="dashboard-content-chart-title-area">
              <p class="dashboard-content-chart-title">{{ translate('발송 채널') }}</p>
              <p class="dashboard-content-chart-sub-title">{{ translate('차트 단위 : 율(%)') }}</p>
            </div>
            <div class="chart-content">
              <GSNoData
                :text="noDataText"
                :subText="noDataSubText"
                :className="channelChartVisible ? 'none' : ''"
              ></GSNoData>
              <canvas
                v-show="channelChartVisible"
                ref="sendingChartCanvasPrimary"
                width="600"
              ></canvas>
            </div>
          </div>
        </div>
        <div class="contents-area">
          <div class="dashboard-contents chart">
            <div class="dashboard-content-chart-title-area">
              <p class="dashboard-content-chart-title">{{ translate('메시지 유형') }}</p>
              <p class="dashboard-content-chart-sub-title">
                {{ translate('차트 단위 : 율(%)') }}
              </p>
              <div class="contents-sub-title-button">
                <GSSelects
                  v-model="selectedChannel"
                  :defaultValue="''"
                  :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                  :size="Constants.SELECTS_SIZES.SMALL"
                  :width="136"
                  :itemsList="channelList"
                  @update-seleted="(value) => (selectedChannel = value)"
                ></GSSelects>
              </div>
            </div>
            <div class="chart-content">
              <GSNoData
                :text="noDataText"
                :subText="noDataSubText"
                :className="workspaceChartVisible ? 'none' : ''"
              ></GSNoData>
              <canvas v-show="workspaceChartVisible" ref="sendingChartCanvasSecondary"></canvas>
            </div>
          </div>
          <p class="contents-info">
            {{ translate('조회기간 기준 발송 비율이 높은 순으로 최대 5개까지 안내 드립니다.') }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <GSAlert
    v-if="errorDialog"
    alertType="basic"
    alertIconType="warning"
    alertTitle="요청하신 처리중 오류가 발생했습니다."
    alertText="네트워크 상태를 확인한 후 다시 시도해 주세요. 문제가
        지속될 경우, 잠시 후 다시 시도하거나 관리자(sendy@gsretail.com)에게 문의해 주시기 바랍니다."
    isAlertPrimaryBtn
    btnPrimaryText="확인"
    @action="() => (errorDialog = false)"
  ></GSAlert>

  <GSLoading v-if="visibleLoading"></GSLoading>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import * as Constants from '@/constants/index'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'

// S: chart js
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import GSDatepicker from '@/components/ui/GSDatepicker.vue'
import {
  changeDate,
  extractLabelsAsKeys,
  formatNumberWithComma,
  generateDates,
  getTodayDate,
  isAllZero,
  lineExtractLabelsAsKeys,
  orgListExtract,
  resetTime,
  searchRangValidate,
  separateLabelsAndValues,
  useCommonI18n
} from '@/core/plugins/global-util'
import useAxios from '@/core/plugins/AxiosApi'
import { commonError, commonResponse } from '@/core/plugins/global-plugin'
import GSAlert from '@/components/ui/GSAlert.vue'
import GSNoData from '@/components/ui/GSNoData.vue'
import GSLoading from '@/components/ui/GSLoading.vue'
interface Organization {
  organization_id: string
  organization_name: string
}

interface TotalCostInfo {
  use_cost: number
  sent_cnt: number
  succ_cnt: number
}

// interface DashboardList {
//   log_date: string // 발송일자 (string)
//   channels: string // 채널 (string)
//   cost: number // 사용금액 (float)
//   send_count: number // 발송건수 (int)
//   success_count: number // 성공건수 (int)
//   fail_count: number // 실패건수 (int)
//   unconfirmed_count: number // 미확인 건수 (int)
//   substitute_channels: string | null // 대체채널 (string | null)
//   substitute_success_count: number // 대체 성공 건수 (int)
//   substitute_fail_count: number // 대체 실패 건수 (int)
//   substitute_cost: number // 대체 발송 비용 (float)
//   success_rate: number // 성공률 (float)
//   workspaces: string // workspace (string)
// }

const { translate } = useCommonI18n()
const { $axios } = useAxios()

Chart.register(...registerables, ChartDataLabels)

/* DOM manipulation variable */
const openSearchOrg = ref(false) // 조직 메뉴  open
const searchErrDialog = ref(false) // 검색 조건 오류 팝업
const errorDialog = ref(false)

/* API request field & logic variable */
/* API response variable */
const totalCostInfo = ref<TotalCostInfo | null>(null) // 총 사용 현황
const channelCostInfo = ref<any | null>(null) // 채널별 사용 현황
const lineChartTotal = ref<any | null>(null) // 라인 차트 정보
const lineLabels = ref<any>([]) // 라인 차트 라벨
const lineDatasets = ref<any>([]) // 라인 차트 데이터
const channelChartLabels = ref<any>([]) // 발송 채널 차트 라벨
const channelChartDatasets = ref<any>([]) // 라인 차트 데이터

const workspaceChartLabels = ref<any>([]) // 메시지 유형 차트 라벨
const workspaceChartDatasets = ref<any>([]) // 메시징 유형 차트 데이터
const selectedChannel = ref<any | null>(null) // 선택된 채널

const showOrgAutocomplete = ref(false) // 조직 메뉴

const linChartVisible = ref(false) // 라인 차트 표시 여부
const channelChartVisible = ref(false)
const workspaceChartVisible = ref(false)

const noDataText = '조회된 데이터가 없습니다.'
const noDataSubText = '조회 조건을 설정해 주세요'

/* Data variable */
const datePeriod = ref([
  { value: '1w', text: '1주일' },
  { value: '1m', text: '1개월' }
  // todo 추후에 늘리 예정 확정은 아님
  // { value: '3m', text: '3개월' },
  // { value: '6m', text: '6개월' }
])

const channelList = ref([
  { label: '전체', value: '' },
  { label: 'SMS', value: 'SMS' },
  { label: 'LMS', value: 'LMS' },
  { label: 'MMS', value: 'MMS' },
  { label: '카카오알림톡', value: 'KAKAO-ALIMTALK' },
  { label: '카카오친구톡', value: 'KAKAO-FRIENDTALK' },
  { label: 'PUSH', value: 'PUSH' },
  { label: 'EMAIL', value: 'EMAIL' }
])

/* Display variable */
const preOrganization = ref<Organization | null>(null) // 이전에 선택된 조직
const totalInfoStartDate = ref<string>('') //사용현황 시작일
const totalInfoEndDate = ref<string>('') //사용현황 종료일
const visibleLoading = ref<boolean>(false) // 로딩바
/* Reference variable */
const organizationListRef = ref<HTMLElement | null>(null) // 조직 메뉴

// 검색 영역 - 조직
const organizationList = ref<Organization[]>([])

const selectedOrganization = ref<Organization | null>(null) // 선택된 조직

const searchStartDate = ref(getTodayDate(2))
const searchEndDate = ref(getTodayDate(2))
const selectedPeriod = ref<string>('1w') // 선택된 기간
const activeIndexDateBtn = ref<number | null>() // 기간 버튼 선택 활성화
const isDisabledDateBtn = ref(false)

// Chart.register({
//   id: 'fixChartArea',
//   beforeDraw(chart) {
//     const chartArea = chart.chartArea
//     chartArea.left = 50 // 고정된 위치
//     chartArea.right = chart.width - 50
//   }
// })
const channelChartCanvas = ref<HTMLCanvasElement | null>(null) // 채널별 발송 현황 차트
const sendingChartCanvasPrimary = ref<HTMLCanvasElement | null>(null) // 발송 채널 차트
const sendingChartCanvasSecondary = ref<HTMLCanvasElement | null>(null) // 메시지 유형 차트
// let chartInstance: Chart | null = null
// if (chartInstance) {
//   chartInstance.destroy() // 기존 차트 삭제
// }

// 라인 차트 옵션
const createLineChartOptions = (position: string, yMax: number) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      position: position,
      align: 'center',
      fullSize: false,
      labels: {
        color: '#67676F',
        font: {
          size: 13,
          family: 'Pretendard',
          style: 'normal' // 정자체로 설정
        },
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        padding: 10 // 범례 간 내부 간격
      }
    },
    layout: {
      padding: {
        top: 20, // 차트와 범례 사이 간격
        bottom: 20,
        left: 0,
        right: 0
      }
    },
    // tooltip: {
    //   callbacks: {
    //     label: function (context) {
    //       // 툴팁에 값 표시 형식
    //       return `${context.dataset.label}: ${context.raw.toLocaleString()}원`
    //     }
    //   },
    //   backgroundColor: '#ffffff',
    //   titleColor: '#000000', // 툴팁 타이틀 텍스트 색상 (예: 흰색)
    //   bodyColor: '#242428',
    //   titleFont: {
    //     family: 'Pretendard', // 타이틀 폰트 패밀리
    //     size: 14, // 타이틀 폰트 크기
    //     weight: 'bold' // 타이틀 폰트 스타일
    //   },
    //   bodyFont: {
    //     family: 'Pretendard', // 본문 폰트 패밀리
    //     size: 12 // 본문 폰트 크기
    //   },
    //   borderColor: '#d7d7df', // 툴팁 테두리 색상 (예: 흰색)
    //   borderWidth: 1, // 툴팁 테두리 두께
    //   padding: {
    //     top: 12,
    //     bottom: 12,
    //     left: 16,
    //     right: 16
    //   }, // 툴팁 내부 여백 조정
    //   cornerRadius: 8, // 툴팁 모서리 둥글게
    //   titleMarginBottom: 12 // title과 body 사이 간격 조정
    // }
    tooltip: {
      enabled: false, // 기본 툴팁 비활성화
      external: (context: any) => {
        // 툴팁 모델 가져오기
        const tooltipModel = context.tooltip

        // 기존 툴팁 엘리먼트 가져오기
        let tooltipEl = document.getElementById('chartjs-tooltip')
        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.id = 'chartjs-tooltip'
          tooltipEl.classList.add('chartjs-tooltip')
          document.body.appendChild(tooltipEl)
        }

        // 툴팁 숨기기
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0'
          return
        }

        // 툴팁 내용 생성
        if (tooltipModel.body) {
          // const title = tooltipModel.title || []
          // const body = tooltipModel.body.map((b) => b.lines)
          const dataPoints = tooltipModel.dataPoints

          let innerHtml = `<div class="title"><strong>${tooltipModel.title[0]}</strong></div>`

          dataPoints.forEach((dataPoint: any) => {
            const color = dataPoint.dataset.borderColor
            innerHtml += `
      <div class="content">
        <div class="label">
          <div style="background-color: ${color};" class="label-icon"></div>
          <div class="label-text">${dataPoint.dataset.label} </div>
        </div>
        <div class="data-text"><span>${dataPoint.raw.toLocaleString()}</span></div>
      </div>
    `
          })

          tooltipEl.innerHTML = innerHtml
        }

        // 툴팁 위치 계산
        const chartCanvas = context.chart.canvas.getBoundingClientRect()
        let tooltipX = chartCanvas.left + window.scrollX + tooltipModel.caretX - 50 // 오른쪽 약간 여백
        let tooltipY = chartCanvas.top + window.scrollY + tooltipModel.caretY // 차트에 더 가까이 위치

        // 툴팁 크기
        const tooltipWidth = tooltipEl.offsetWidth || 150 // 기본 너비 값
        // const tooltipHeight = tooltipEl.offsetHeight || 50 // 기본 높이 값

        // 화면 경계 내로 툴팁 위치 조정
        const windowWidth = window.innerWidth
        // const windowHeight = window.innerHeight

        if (tooltipX + tooltipWidth > windowWidth) {
          tooltipX = windowWidth - tooltipWidth - 20 // 오른쪽 경계 안으로 이동
        }
        if (tooltipX < 10) {
          tooltipX = 10
        }
        if (tooltipY < 10) {
          tooltipY = chartCanvas.top + window.scrollY + tooltipModel.caretY + 10 // 툴팁이 위로 넘어가면 아래로 표시
        }

        // 툴팁 스타일 설정
        tooltipEl.style.opacity = '1'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.left = `${tooltipX}px`
        tooltipEl.style.top = `${tooltipY}px`
        tooltipEl.style.pointerEvents = 'none'
        tooltipEl.style.transition = 'transform 0.2s ease-in-out'
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        callback: function (value: any, index: any) {
          const date = lineLabels.value[index]
          if (!date) return ''

          // YYYY-MM-DD 형식에서 MM-DD만 반환
          const [, month, day] = date.split('-')
          return `${month}-${day}` // MM-DD 형식 반환
        },
        font: {
          size: 12, // 글꼴 크기
          family: 'Pretendard, Arial, sans-serif', // 폰트 패밀리 설정
          weight: 'normal', // 굵기 설정
          style: 'normal' // 정자 방지
        },
        align: 'end', // 라벨을 왼쪽 정렬
        clip: false,
        rotation: 0, // 라벨 회전 각도
        autoSkip: false, // 라벨 자동 간격 조정 활성화

        autoSkipPadding: 20, // 라벨 간 최소 간격 설정
        maxTicksLimit: lineLabels.value.length, // 라벨 최대 수 (X축 데이터 전체 표시)
        minRotation: 0, // 최소 회전 각도
        maxRotation: 0 // 최대 회전 각도
      }
    },
    y: {
      grid: {
        drawBorder: true // 축 선 보이도록 설정
      },
      min: 0,
      max: yMax,
      // y축 라벨 설정
      ticks: {
        // stepSize: 100000
        stepSize: yMax / 5
      },
      font: {
        size: 12,
        family: 'Pretendard, Arial, sans-serif', // 동일한 폰트 적용
        weight: 'normal',
        style: 'normal'
      }
    }
  }
})
// 도넛 차트
const createDoughnutChartPrimaryOptions = (position: string) => ({
  radius: '140',
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false // 기본 툴팁 비활성화
      // external: (context: any) => {
      //   const tooltipModel = context.tooltip
      //   let tooltipEl = document.getElementById('chartjs-tooltip')

      //   if (!tooltipEl) {
      //     tooltipEl = document.createElement('div')
      //     tooltipEl.id = 'chartjs-tooltip'
      //     tooltipEl.classList.add('chartjs-tooltip')
      //     document.body.appendChild(tooltipEl)
      //   }

      //   if (tooltipModel.opacity === 0) {
      //     tooltipEl.style.opacity = '0'
      //     return
      //   }

      //   // 툴팁 내용 생성
      //   if (tooltipModel.body) {
      //     const dataIndex = tooltipModel.dataPoints[0].dataIndex
      //     const datasetIndex = tooltipModel.dataPoints[0].datasetIndex

      //     const label = context.chart.data.labels[dataIndex]
      //     const value = context.chart.data.datasets[datasetIndex].data[dataIndex]

      //     const color = tooltipModel.labelColors[0].backgroundColor

      //     let innerHtml = `
      //       <div class="title"><strong>${label}</strong></div>
      //       <div class="content">
      //         <div class="label">
      //           <div style="background-color: ${color};" class="label-icon"></div>
      //           <div class="label-text">${label}: </div>
      //         </div>
      //         <div class="data-text"><span>${value.toLocaleString()}건</span></div>
      //       </div>
      //     `

      //     tooltipEl.innerHTML = innerHtml
      //   }

      //   // 툴팁 위치 설정
      //   const position = context.chart.canvas.getBoundingClientRect()
      //   tooltipEl.style.opacity = '1'
      //   tooltipEl.style.position = 'absolute'
      //   tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 'px'
      //   tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px'
      //   tooltipEl.style.pointerEvents = 'none'
      // }
    },
    legend: {
      position: position,
      align: 'center',
      labels: {
        color: '#67676F',
        font: {
          size: 13,
          family: 'Pretendard',
          lineHeight: 130
        },
        boxWidth: 12,
        boxHeight: 12,
        usePointStyle: true,
        padding: 20
      }
    },
    datalabels: {
      formatter: (value: number) => {
        // const dataset = context.chart.data.datasets[0]
        // const total = dataset.data.reduce((acc: number, val: number) => acc + val, 0)
        // const percentage = (value / total) * 100
        // const percentage = (value / total)
        return value === 0 ? '' : `${Math.round(value)}`
      },
      display: true,
      color: '#ffffff',
      font: { size: 15, family: 'Pretendard' }
    }
  },
  layout: {
    padding: {
      top: 0, // 패딩 고정
      bottom: 20,
      left: 5,
      right: 200
    }
  }
})
const createDoughnutChartOptions = (position: string) => ({
  radius: '140',
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false // 기본 툴팁 비활성화
      // external: (context: any) => {
      //   const tooltipModel = context.tooltip
      //   let tooltipEl = document.getElementById('chartjs-tooltip')

      //   if (!tooltipEl) {
      //     tooltipEl = document.createElement('div')
      //     tooltipEl.id = 'chartjs-tooltip'
      //     tooltipEl.classList.add('chartjs-tooltip')
      //     document.body.appendChild(tooltipEl)
      //   }

      //   if (tooltipModel.opacity === 0) {
      //     tooltipEl.style.opacity = '0'
      //     return
      //   }

      //   // 툴팁 내용 생성
      //   if (tooltipModel.body) {
      //     const dataIndex = tooltipModel.dataPoints[0].dataIndex
      //     const datasetIndex = tooltipModel.dataPoints[0].datasetIndex

      //     const label = context.chart.data.labels[dataIndex]
      //     const value = context.chart.data.datasets[datasetIndex].data[dataIndex]

      //     const color = tooltipModel.labelColors[0].backgroundColor

      //     let innerHtml = `
      //       <div class="title"><strong>${label}</strong></div>
      //       <div class="content">
      //         <div class="label">
      //           <div style="background-color: ${color};" class="label-icon"></div>
      //           <div class="label-text">${label}: </div>
      //         </div>
      //         <div class="data-text"><span>${value.toLocaleString()}</span></div>
      //       </div>
      //     `

      //     tooltipEl.innerHTML = innerHtml
      //   }

      //   // 툴팁 위치 설정
      //   const position = context.chart.canvas.getBoundingClientRect()
      //   tooltipEl.style.opacity = '1'
      //   tooltipEl.style.position = 'absolute'
      //   tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 'px'
      //   tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px'
      //   tooltipEl.style.pointerEvents = 'none'
      // }
    },
    legend: {
      position: position,
      align: 'center', // 중앙 정렬
      labels: {
        color: '#67676F',
        font: { size: 12, family: 'Pretendard' },
        boxWidth: 12,
        boxHeight: 12,
        usePointStyle: true,
        padding: 20,
        textAlign: 'start'
      }
    },
    datalabels: {
      formatter: (value: number) => {
        // const dataset = context.chart.data.datasets[0]
        // const total = dataset.data.reduce((acc: number, val: number) => acc + val, 0)
        // const percentage = (value / total) * 100
        return value === 0 ? '' : `${Math.round(value)}`
      },
      display: true,
      color: '#ffffff',
      font: { size: 15, family: 'Pretendard' }
    }
  },

  layout: {
    padding: {
      top: 0, // 패딩 고정
      bottom: 20,
      left: 20,
      right: 100
    }
  }
})
/*  라인 , 발송채널 차트 생성 */
const createChart = (
  ctx: CanvasRenderingContext2D,
  type: 'line' | 'bar' | 'pie' | 'doughnut', // 지원하는 차트 타입만 나열
  data: any,
  options: any
) => {
  const chart = new Chart(ctx, {
    type,
    data,
    options
  })
  // resize 이벤트 비활성화
  // let resizeTimeout: ReturnType<typeof setTimeout>
  // chart.resize = () => {
  //   clearTimeout(resizeTimeout)
  //   resizeTimeout = setTimeout(() => {
  //     chart.update()
  //   }, 400) // 지연 시간 설정
  // }
  return chart
}
/* 메시징 유형 차트 생성 */
// const createChart2 = (
//   ctx: CanvasRenderingContext2D,
//   type: 'line' | 'bar' | 'pie' | 'doughnut', // 지원하는 차트 타입만 나열
//   data: any,
//   options: any
// ) => {
//   const chart2 = new Chart(ctx, {
//     type,
//     data,
//     options
//   })
//   // resize 이벤트 비활성화
//   let resizeTimeout: ReturnType<typeof setTimeout>
//   chart2.resize = () => {
//     clearTimeout(resizeTimeout)
//     resizeTimeout = setTimeout(() => {
//       chart2.update()
//     }, 400) // 지연 시간 설정
//   }

//   return chart2
// }

const adjustCanvasResolution = (canvas: HTMLCanvasElement) => {
  const dpr = window.devicePixelRatio || 1 // 디스플레이 DPR 가져오기
  canvas.width = canvas.clientWidth * dpr
  canvas.height = canvas.clientHeight * dpr
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.scale(dpr, dpr)
    ctx.font = '12px Pretendard' // 명확히 글꼴 스타일 설정
    ctx.fontStretch = 'normal'
  }
}
// const resizeChart = (chart: Chart, canvas: HTMLCanvasElement) => {
//   const resizeObserver = new ResizeObserver(() => {
//     chart.resize() // 차트 크기 재조정
//     adjustCanvasResolution(canvas) // 캔버스 해상도 조정
//   })
//   resizeObserver.observe(canvas)
// }
const adjustCanvasPosition = (canvas: any) => {
  const dpr = window.devicePixelRatio || 1
  canvas.width = canvas.clientWidth * dpr
  canvas.height = canvas.clientHeight * dpr

  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.scale(dpr, dpr)
    ctx.translate(canvas.clientWidth / 2, canvas.clientHeight / 2) // 중앙 고정
  }
}

/** 조회 후 라인 차트 , 발송 비율 차트 생성 */
const createCharts = () => {
  // 라인 차트 데이터
  if (channelChartCanvas.value) {
    adjustCanvasResolution(channelChartCanvas.value) // 캔버스 해상도 조정
    const channelCtx = channelChartCanvas.value.getContext('2d')
    if (channelCtx) {
      if (Chart.getChart(channelChartCanvas.value)) {
        Chart.getChart(channelChartCanvas.value)?.destroy()
      }
      createChart(
        channelCtx,
        'line',
        {
          labels: lineLabels.value,
          datasets: lineDatasets.value
        },
        createLineChartOptions('top', lineChartTotal.value?.max_val)
      )
    }
  }
  // 발송 비율
  if (sendingChartCanvasPrimary.value) {
    const sendingCtx = sendingChartCanvasPrimary.value.getContext('2d')
    adjustCanvasPosition(sendingChartCanvasPrimary.value)
    if (sendingCtx) {
      if (Chart.getChart(sendingChartCanvasPrimary.value)) {
        Chart.getChart(sendingChartCanvasPrimary.value)?.destroy()
      }
      createChart(
        sendingCtx,
        'doughnut',
        {
          labels: channelChartLabels.value,
          // ['SMS', 'LMS', '카카오 알림톡', 'MMS', 'PUSH', '카카오 친구톡', 'Email'],
          datasets: [
            {
              data: channelChartDatasets.value,
              // [2335, 5000, 10000, 3000, 3500, 5000, 3000],
              backgroundColor: [
                '#2768FF',
                '#21BF2F',
                '#F9B301',
                '#FF6E30',
                '#40BFF9',
                '#F67BA1',
                '#CAB0FB'
              ]
            }
          ]
        },
        createDoughnutChartPrimaryOptions('right')
      )
    }
  }
}

/** 메시지 유형 차트만 별도로 구현 */
const createCharts2 = () => {
  // 메시지 유형
  if (sendingChartCanvasSecondary.value) {
    const sending2Ctx = sendingChartCanvasSecondary.value.getContext('2d')
    if (sending2Ctx) {
      if (Chart.getChart(sendingChartCanvasSecondary.value)) {
        Chart.getChart(sendingChartCanvasSecondary.value)?.destroy()
      }
      createChart(
        sending2Ctx,
        'doughnut',
        {
          labels: workspaceChartLabels.value,
          // [
          //   '(GS SHOP} 티꿀 #{교환기간}안내',
          //   '(무통장) 입금확인 완료안내_T커머스, TC리모콘',
          //   '입금 금액확인 및 예치금 전환안내_T커머스',
          //   '(GS SHOP) 적립금 소멸안내',
          //   '입금 금액확인 및 예치금 전환안내_T커머스'
          // ],
          datasets: [
            {
              data: workspaceChartDatasets.value,
              // [2500, 3002, 2003, 2000, 2300],
              backgroundColor: ['#003ECC', '#004BF9', '#004BF9B2', '#004BF975', '#004BF947']
            }
          ]
        },
        createDoughnutChartOptions('right')
      )
    }
  }
}

/* Dialog */
const openDialogSearch = () => {
  setTimeout(() => {
    searchErrDialog.value = true
  }, 200)
  // searchErrDialog.value = true
}

const openErrorDialog = () => {
  errorDialog.value = true
}

/* 조회 초기화 */
const refreshData = () => {
  selectedOrganization.value = orgListExtract(organizationList.value, 'HBU CX팀')
  if (searchStartDate.value === '' || searchEndDate.value === '') {
    selectedPeriodBtn('1w', null) //  기간설정
  }
}

/** 대시보드 조회 */
const searchData = () => {
  if (searchRangValidate(searchStartDate.value, searchEndDate.value)) {
    fetchGetDashboardList()
  } else {
    openDialogSearch()
  }
}

/** 선택 메뉴 외부의 스크롤 발생시 선택 메뉴 close  */
const closeAllOverlays = () => {
  openSearchOrg.value = false
  // openDatePicker.value = false
  // openSearchWorkspace.value = false
  // isChannelOpen.value = false

  if (selectedOrganization.value === null) {
    selectedOrganization.value = preOrganization.value
  }
  // if (selectedWorkspace.value === null) {
  //   selectedWorkspace.value = preWorkspace.value
  // }
  // if (refChannel.value && refChannel.value.blur) {
  //   refChannel.value.blur()
  // }
}

/** 조직 리스트 조회 */
const fetchGetOrganizationList = async () => {
  const requestParam = {
    pageable: false
  }
  await $axios
    .get('/api/organizations', {
      params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      if (response.data.data.length === 0) return
      const responseData = commonResponse(response)
      organizationList.value = responseData.data

      // selectedOrganization.value = organizationList.value[0]
      if (organizationList.value.length > 0) {
        selectedOrganization.value = orgListExtract(organizationList.value, 'HBU CX팀')
        // selectedOrganizationName.value = organizationList.value[0].organization_name
        // selectedOrganization.value = organizationList.value[0].organization_id
      }
      visibleLoading.value = false
      // 초기에 조회 값이 없을 경우 조회 실행
      if (totalCostInfo.value === null) {
        fetchGetDashboardList()
      }
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      openErrorDialog()
    })
}

/** 대시보드 조회 */
const fetchGetDashboardList = async () => {
  visibleLoading.value = true

  const requestParam = {
    organization_id: selectedOrganization.value?.organization_id,
    start_date: searchStartDate.value,
    end_date: searchEndDate.value

    //test
    // organization_id: 'GsRetailTB_user1',
    // start_date: '2024-12-01',
    // end_date: '2024-12-12'
  }

  await $axios
    .get('/api/message/statics/chart/main', {
      params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      const responseData = commonResponse(response)

      totalInfoStartDate.value = searchStartDate.value
      totalInfoEndDate.value =
        searchStartDate.value === searchEndDate.value ? '' : searchEndDate.value

      totalCostInfo.value = responseData.data.total // 총 사용 현황
      channelCostInfo.value = extractLabelsAsKeys(responseData.data.channel_cost) //채널별 사용 현황
      lineChartTotal.value = responseData.data.daily_sent?.total // 라인 차트 정보
      lineLabels.value = [
        ...generateDates(
          responseData.data.daily_sent.total?.start_dt,
          responseData.data.daily_sent.total?.end_dt
        )
      ] // 라인 차트 날짜 생성
      const lineTmpData = lineExtractLabelsAsKeys(
        responseData.data.daily_sent?.data,
        lineLabels.value
      ) // 라인 차트 데이터 tmp

      // 라인 차트 표시 여부
      let chartVisible1 = false
      linChartVisible.value = chartVisible1
      for (const key in lineTmpData) {
        if (!isAllZero(lineTmpData[key].value)) {
          chartVisible1 = true
        }
      }
      setTimeout(() => {
        linChartVisible.value = chartVisible1
      }, 0)
      // linChartVisible.value = chartVisible1

      lineDatasets.value = [
        {
          label: 'SMS',
          data: lineTmpData.sms?.value,
          backgroundColor: '#2768FF',
          borderColor: '#2768FF',
          fill: false
        },
        {
          label: 'LMS',
          data: lineTmpData.lms?.value,
          backgroundColor: '#17D427',
          borderColor: '#17D427',
          fill: false
        },
        {
          label: 'MMS',
          data: lineTmpData.mms?.value,
          backgroundColor: '#FDC535',
          borderColor: '#FDC535'
        },
        {
          label: '카카오 알림톡',
          data: lineTmpData.kakaoalert?.value,
          backgroundColor: '#FF6E30',
          borderColor: '#FF6E30'
        },
        {
          label: '카카오 친구톡',
          data: lineTmpData.kakaofriend?.value,
          backgroundColor: '#40BFF9',
          borderColor: '#40BFF9'
        },
        {
          label: 'PUSH',
          data: lineTmpData.push?.value,
          backgroundColor: '#F67BA1',
          borderColor: '#F67BA1'
        },

        {
          label: 'Email',
          data: lineTmpData.email?.value,
          backgroundColor: '#CAB0FB',
          borderColor: '#CAB0FB'
        }
      ]

      const channelChartTmpData = separateLabelsAndValues(responseData.data.channel_chart)
      channelChartLabels.value = channelChartTmpData.labels // 발송 채널 차트 라벨
      channelChartDatasets.value = channelChartTmpData.values // 라인 차트 데이터

      let chartVisible2 = false
      channelChartVisible.value = chartVisible2
      if (!isAllZero(channelChartTmpData.values)) {
        chartVisible2 = true
      }
      setTimeout(() => {
        channelChartVisible.value = chartVisible2
      }, 0)
      // channelChartVisible.value = chartVisible2

      const workspaceChartTmpData = separateLabelsAndValues(
        responseData.data.workspace_chart,
        'channel'
      )

      workspaceChartLabels.value = workspaceChartTmpData.labels // 메시지 유형 차트 라벨
      workspaceChartDatasets.value = workspaceChartTmpData.values // 메시징 유형 차트 데이터

      let chartVisible3 = false
      workspaceChartVisible.value = chartVisible3
      if (!isAllZero(workspaceChartTmpData.values)) {
        chartVisible3 = true
      }
      setTimeout(() => {
        workspaceChartVisible.value = chartVisible3
      }, 0)
      // workspaceChartVisible.value = chartVisible3

      createCharts()
      createCharts2()

      // excelOrganization.value = selectedOrganization.value?.organization_name
      // excelChannel.value = selectedChannel.value
      // excelStartDate.value = replaceDate(searchStartDate.value)
      // excelEndDate.value = replaceDate(searchEndDate.value)

      visibleLoading.value = false
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      visibleLoading.value = false
      openErrorDialog()
    })
}

/** 메시지 유형 select 변경시 조회 */
const fetchGetChartWorkspaceList = async () => {
  const requestParam = {
    organization_id: selectedOrganization.value?.organization_id,
    start_date: searchStartDate.value,
    end_date: searchEndDate.value,
    channel_id: selectedChannel.value
  }
  await $axios
    .get('/api/message/statics/chart/workspace', {
      params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      if (response.data.data.length === 0) {
        workspaceChartLabels.value = [] // 메시지 유형 차트 라벨
        workspaceChartDatasets.value = [] // 메시징 유형 차트 데이터
        workspaceChartVisible.value = false
      } else {
        const responseData = commonResponse(response)
        const workspaceChartTmpData = separateLabelsAndValues(responseData.data, 'channel')
        workspaceChartLabels.value = workspaceChartTmpData.labels // 메시지 유형 차트 라벨
        workspaceChartDatasets.value = workspaceChartTmpData.values // 메시징 유형 차트 데이터

        let chartVisible3 = false
        workspaceChartVisible.value = chartVisible3
        if (!isAllZero(workspaceChartTmpData.values)) {
          chartVisible3 = true
        }
        setTimeout(() => {
          workspaceChartVisible.value = chartVisible3
        }, 0)
      }

      createCharts2()
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      openErrorDialog()
    })
}

watch(selectedChannel, (newValue, oldValue) => {
  if (oldValue !== null && newValue !== oldValue) {
    fetchGetChartWorkspaceList()
  }
})

/* 기간 버튼 그룹 */
const selectedPeriodBtn = (value: string, index: number | null) => {
  selectedPeriod.value = value
  const today = new Date()
  let calculatedStartDate: Date

  switch (selectedPeriod.value) {
    case '1w':
      calculatedStartDate = new Date(today)
      // calculatedStartDate.setDate(today.getDate() - 7)
      calculatedStartDate.setDate(today.getDate() - 9)
      break
    case '1m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 1)
      calculatedStartDate.setDate(calculatedStartDate.getDate() - 2)
      break
    case '3m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 3)
      break
    case '6m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 6)
      break
    case '':
      return
    default:
      calculatedStartDate = new Date(today)
      calculatedStartDate.setDate(calculatedStartDate.getDate() - 2) // 현재 날짜에서 2일을 뺌

      break
  }
  searchStartDate.value = ''
  searchEndDate.value = ''
  setTimeout(() => {
    searchStartDate.value = changeDate(resetTime(calculatedStartDate))
    searchEndDate.value = getTodayDate(2)
  }, 0)

  if (index !== null) {
    activeDateBtn(index)
  }
}

/* DateBtn 활성화 */
const activeDateBtn = (index: number) => {
  if (isDisabledDateBtn.value) return
  activeIndexDateBtn.value = index
  isDisabledDateBtn.value = true

  setTimeout(() => {
    activeIndexDateBtn.value = null
    isDisabledDateBtn.value = false
  }, 300)
}

/** 조직 메뉴 esc event */
const onKeydownOrg = (event: KeyboardEvent) => {
  if (event.key === 'Escape' || event.keyCode === 27) {
    openSearchOrg.value = false
    if (selectedOrganization.value === null) {
      selectedOrganization.value = preOrganization.value
    }
  }
}

/* 조직 메뉴 벗어날 떄 */
const onClickOutsideOrg = () => {
  openSearchOrg.value = false
  if (selectedOrganization.value === null) {
    selectedOrganization.value = preOrganization.value
  }
  showOrgAutocomplete.value = false
}

/** 조직 list open */
function handleOrgMenuOpen(isOpen: boolean) {
  if (isOpen) {
    setTimeout(() => {
      showOrgAutocomplete.value = true
      nextTick(() => {
        organizationListRef.value?.focus()
      })
    }, 200)
  } else {
    showOrgAutocomplete.value = false
  }
}

// // 채널별 사용 현황 - 전월 동기간 대비
// const getResult = (value) => {
//   if (value > 0) {
//     return 'up'
//   } else if (value < 0) {
//     return 'down'
//   } else {
//     return ''
//   }
// }

// // const channelData = ['전체', '활성화']
// const channelData = [
//   { label: '전체', value: '' },
//   { label: '활성화', value: '' }
// ]

/** 조직 선택 시 */
watch(selectedOrganization, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue !== null) {
      openSearchOrg.value = false
    } else {
      preOrganization.value = oldValue
    }
    selectedChannel.value = ''
  }
  if (newValue && newValue.organization_id !== '') {
    // selectedPeriodBtn('1w', null) // 조직선택 시 기간설정
  }
})

onMounted(() => {
  fetchGetOrganizationList() // 시작시 조직메뉴 조회

  selectedPeriodBtn('1w', null) // 시작시 기간설정
  window.addEventListener('scroll', closeAllOverlays)
})
onUnmounted(() => {
  window.removeEventListener('scroll', closeAllOverlays)
})
</script>
