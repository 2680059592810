<template>
  <div class="gsr-input-top-position">
    <span>구성원</span>
    <v-autocomplete
      v-model="members"
      class="gsr-chips-autocomplete"
      :items="organizationMembers"
      item-title="name"
      clearable
      chips
      closable-chips
      multiple
      placeholder="구성원 검색"
      return-object
      :menu-props="{ class: 'gsr-chips-autocomplete-layer' }"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip v-bind="props" :class="item.raw.active === true ? 'active' : 'deactivate'">
          {{ item.raw.name }} &lt;{{ item.raw.id }}@gsretail.com&gt;
          <i class="state-icon"></i>
        </v-chip>
      </template>
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props">
          <div class="org-member-info">
            <v-avatar :image="item.raw.img" transition=""></v-avatar>
            <div class="org-member-info-text">
              <p class="text">{{ item.raw.name }} / {{ item.raw.org }}</p>
              <p class="sub-text">({{ item.raw.id }}) {{ item.raw.position }}</p>
            </div>
          </div>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>
<script setup lang="ts">
import { useCommonI18n } from '@/core/plugins/global-util';
import { ref } from 'vue'
// S: 다국어
const { translate } = useCommonI18n()
const members = ref()
const organizationMembers = [
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미1',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동1',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리1',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미2',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동2',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리2',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미3',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동3',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리3',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미4',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동4',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리4',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미5',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동5',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리5',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미6',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동6',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리6',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미7',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동7',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리7',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미8',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동8',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리8',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미9',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동9',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리9',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미10',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동10',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리10',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  }
]
</script>
