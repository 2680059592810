import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import OrganizationView from '@/views/management/OrganizationView.vue'
import DepartmentView from '@/views/management/DepartmentView.vue'
import UsersView from '@/views/management/UsersView.vue'
import UserProfile from '@/views/management/users/UserProfileView.vue'
import OrganizationManagement from '@/views/management/organization/OrganizationManagement.vue'
import SubDepartment from '@/views/management/department/SubDepartment.vue'
import ServiceSetting from '@/views/management/organization/ServiceSetting.vue'
// import Department from '@/views/management/Department.vue'
// import Users from '@/views/management/Users.vue'
// import ServiceSettings from '@/views/management/organization/ServiceSettings.vue'
// import Profile from '@/views/management/users/Profile.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/management',
    redirect: '/management/organization',
    // component: () => import('@/layouts/main/MainLayout.vue'),
    component: () => import('@/layouts/main/MainLNBLayout.vue'),
    // meta: {
    //   middleware: 'auth'
    // },
    children: [
      {
        path: '/management/organization',
        name: 'organization',
        component: OrganizationView,
        meta: {
          pageTitle: 'Organization',
          breadcrumbs: ['Organization'],
          requiresMenu: true
        }
      },
      {
        path: '/management/organization/organizationmanagement',
        name: 'organizationmanagement',
        component: OrganizationManagement,
        meta: {
          pageTitle: 'Organization Management',
          breadcrumbs: ['Organization Management'],
          requiresMenu: true
        }
      },
      {
        path: '/management/organization/servicesetting',
        name: 'servicesetting',
        component: ServiceSetting,
        meta: {
          pageTitle: 'Service Setting',
          breadcrumbs: ['Service Setting'],
          requiresMenu: true
        }
      },
      {
        path: '/management/department',
        name: 'department',
        component: DepartmentView,
        meta: {
          pageTitle: 'Department',
          breadcrumbs: ['Department'],
          requiresMenu: true
        }
      },
      {
        path: '/management/department/subdepartment',
        name: 'subdepartment',
        component: SubDepartment,
        meta: {
          pageTitle: 'Sub Department',
          breadcrumbs: ['Sub Department'],
          requiresMenu: true
        }
      },
      {
        path: '/management/users',
        name: 'users',
        component: UsersView,
        meta: {
          pageTitle: 'Users',
          breadcrumbs: ['Users'],
          requiresMenu: true
        }
      },
      {
        path: '/management/users/userprofile',
        name: 'userprofile',
        component: UserProfile,
        meta: {
          pageTitle: 'Profile',
          breadcrumbs: ['User Profile'],
          requiresMenu: true
        },
        children: [
          {
            path: 'role',
            name: 'userprofileroletab',
            component: () => import('@/views/management/users/RoleTab.vue'),
            meta: {
              pageTitle: '',
              requiresMenu: true
            }
          },
          {
            path: 'workspace',
            name: 'userprofileworkspacetab',
            component: () => import('@/views/management/users/WorkspaceTab.vue'),
            meta: {
              pageTitle: '',
              requiresMenu: true
            }
          },
          {
            path: 'audiencefilter',
            name: 'userprofileaudiencefiltertab',
            component: () => import('@/views/management/users/AudienceFilterTab.vue'),
            meta: {
              pageTitle: '',
              requiresMenu: true
            }
          }
        ]
      }
    ]
  }
  //       children: [
  //         {
  //           path: 'organizationoverviewtab',
  //           name: 'organizationoverviewtab',
  //           component: () => import('@/views/management/organization/OrganizationOverViewTab.vue'),
  //           meta: {
  //             pageTitle: 'Organization',
  //             requiresMenu: true
  //           },
  //           beforeEnter: (to, from, next) => {
  //             // 예시로 /protected-route에 직접 접근을 차단하고 /forbidden으로 리다이렉트
  //             if (from.name == 'organization' || from.name == 'organizationservicetab') {
  //               next()
  //             } else {
  //               next('/management/organization') // Home 페이지에서 외에는 접근 불가
  //             }
  //           }
  //         },
  //         {
  //           path: 'organizationservicetab',
  //           name: 'organizationservicetab',
  //           component: () => import('@/views/management/organization/OrganizationServiceTab.vue'),
  //           meta: {
  //             pageTitle: 'Organization',
  //             requiresMenu: true
  //           }
  //         },
  //         {
  //           path: '/management/organization/servicesettings',
  //           name: 'organizationservicesettings',
  //           component: ServiceSettings,
  //           redirect: '/management/organization/servicesettings/slmmstab',
  //           meta: {
  //             pageTitle: 'Organization > Service Settings',
  //             breadcrumbs: [],
  //             requiresMenu: true
  //           },
  //           beforeEnter: (to, from, next) => {
  //             // 예시로 /protected-route에 직접 접근을 차단하고 /forbidden으로 리다이렉트
  //             if (from.name == 'organizationservicetab') {
  //               next()
  //             } else {
  //               next('/management/organization') // Home 페이지에서 외에는 접근 불가
  //             }
  //           },
  //           children: [
  //             {
  //               path: 'slmmstab',
  //               name: 'organizationsettingslmmstab',
  //               component: () => import('@/views/management/organization/settings/SlmmsTab.vue'),
  //               meta: {
  //                 pageTitle: 'Organization > Service Settings',
  //                 requiresMenu: true
  //               }
  //             },
  //             {
  //               path: 'kakaotalktab',
  //               name: 'organizationsettingkakaostab',
  //               component: () =>
  //                 import('@/views/management/organization/settings/KakaoTalkTab.vue'),
  //               meta: {
  //                 pageTitle: 'Organization > Service Settings',
  //                 requiresMenu: true
  //               }
  //             },
  //             {
  //               path: 'pushtab',
  //               name: 'organizationsettingpushtab',
  //               component: () => import('@/views/management/organization/settings/PushTab.vue'),
  //               meta: {
  //                 pageTitle: 'Organization > Service Settings',
  //                 requiresMenu: true
  //               }
  //             },
  //             {
  //               path: 'emailtab',
  //               name: 'organizationsettingemailtab',
  //               component: () => import('@/views/management/organization/settings/EmailTab.vue'),
  //               meta: {
  //                 pageTitle: 'Organization > Service Settings',
  //                 requiresMenu: true
  //               }
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       path: '/management/department',
  //       name: 'department',
  //       component: Department,
  //       meta: {
  //         pageTitle: 'Department',
  //         breadcrumbs: ['Department'],
  //         requiresMenu: true
  //       },
  //       children: [
  //         {
  //           path: 'subdepartment',
  //           name: 'subdepartment',
  //           component: () => import('@/views/management/department/SubDepartment.vue'),
  //           meta: {
  //             pageTitle: 'Sub Department',
  //             breadcrumbs: ['Department', 'Sub Department'],
  //             requiresMenu: true
  //           },
  //           beforeEnter: (to, from, next) => {
  //             // 예시로 /protected-route에 직접 접근을 차단하고 /forbidden으로 리다이렉트
  //             if (from.name == 'department') {
  //               next()
  //             } else {
  //               next('/management/department') // Home 페이지에서 외에는 접근 불가
  //             }
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/management/users',
  //       redirect: '/management/users/users',
  //       children: [
  //         {
  //           path: '/management/users/users',
  //           name: 'users',
  //           component: Users,
  //           meta: {
  //             pageTitle: 'Users',
  //             breadcrumbs: ['Users'],
  //             requiresMenu: true
  //           }
  //         },
  //         {
  //           path: '/management/users/userprofile',
  //           name: 'userprofile',
  //           component: Profile,
  //           meta: {
  //             pageTitle: 'Profile',
  //             breadcrumbs: ['Profile'],
  //             requiresMenu: true
  //           },
  //           children: [
  //             {
  //               path: 'roletab',
  //               name: 'userprofileroletab',
  //               component: () => import('@/views/management/users/RoleTab.vue'),
  //               meta: {
  //                 pageTitle: '',
  //                 requiresMenu: true
  //               }
  //             },
  //             {
  //               path: 'workspacetab',
  //               name: 'userprofileworkspacetab',
  //               component: () => import('@/views/management/users/WorkspaceTab.vue'),
  //               meta: {
  //                 pageTitle: '',
  //                 requiresMenu: true
  //               }
  //             },
  //             {
  //               path: 'audiencefiltertab',
  //               name: 'userprofileaudiencefiltertab',
  //               component: () => import('@/views/management/users/AudienceFilterTab.vue'),
  //               meta: {
  //                 pageTitle: '',
  //                 requiresMenu: true
  //               }
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //     // {
  //     //   path: '/management/users',
  //     //   name: 'users',
  //     //   component: Users,
  //     //   meta: {
  //     //     pageTitle: 'Users',
  //     //     breadcrumbs: ['Users'],
  //     //     requiresMenu: true
  //     //   }
  //     // }
  //   ]
  // }
]

export default routes
