<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent, nextTick, onBeforeMount, onMounted } from 'vue'
import { RouterView } from 'vue-router'
// import { useConfigStore } from '@/stores/config'
// import { useThemeStore } from '@/stores/theme'
// import { useBodyStore } from '@/stores/body'
import { useAuthStore } from '@/stores/auth'
// import { themeConfigValue } from '@/layouts/default-layout/config/helper'
// import { initializeComponents } from '@/core/plugins/keenthemes'

export default defineComponent({
  name: 'app',
  components: {
    RouterView
  },
  setup() {
    // const configStore = useConfigStore()
    // const themeStore = useThemeStore()
    // const bodyStore = useBodyStore()
    const authStore = useAuthStore()

    // onBeforeMount(() => {
    //   /**
    //    * Overrides the layout config using saved data from localStorage
    //    * remove this to use static config (@/layouts/default-layout/config/DefaultLayoutConfig.ts)
    //    */
    //   configStore.overrideLayoutConfig()

    //   /**
    //    *  Sets a mode from configuration
    //    */
    //   themeStore.setThemeMode(themeConfigValue.value)
    // })

    // onMounted(() => {
    //   nextTick(() => {
    //     initializeComponents()

    //     bodyStore.removeBodyClassName('page-loading')
    //   })
    // })
  }
})

window.addEventListener('scroll', () => {
  // 모든 .v-overlay__content 요소 찾기
  const overlayElements = document.querySelectorAll('.v-overlay__content')

  overlayElements.forEach((overlay:any) => {
    // Vue 인스턴스에 접근하여 v-model이나 isActive를 false로 설정
    const vueInstance = overlay.__vue__

    if (vueInstance && vueInstance.isActive) {
      vueInstance.isActive = false
    }
  })
})
</script>

<style lang="scss">
// @import 'bootstrap-icons/font/bootstrap-icons.css';
// @import 'apexcharts/dist/apexcharts.css';
@import 'quill/dist/quill.snow.css';
@import 'nouislider/dist/nouislider.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'socicon/css/socicon.css';
@import 'line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'prism-themes/themes/prism-shades-of-purple.css';

// Main demo style scss
// @import 'assets/keenicons/duotone/style.css';
// @import 'assets/keenicons/outline/style.css';
// @import 'assets/keenicons/solid/style.css';
// @import 'assets/sass/element-ui.dark';
// @import 'assets/sass/plugins';
// @import 'assets/sass/style';
// @import 'assets/common';

#app {
  display: contents;
}
</style>
