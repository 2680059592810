<template>
  <v-btn
    :class="computedClassName()"
    :disabled="disabled"
    v-bind="{
      prependIcon,
      appendIcon,
      stacked
    }"
    v-bind:style="width !== undefined ? { width: width + 'px' } : undefined"
  >
    <template
      v-if="
        iconPosition == BUTTON_ICONPOSITION.LEFT ||
        BUTTON_ICONPOSITION.BOTH ||
        BUTTON_ICONPOSITION.CENTER
      "
      v-slot:prepend
    >
      <v-icon></v-icon>
    </template>
    {{ translate(text) }}
    <template
      v-if="iconPosition == BUTTON_ICONPOSITION.RIGHT || BUTTON_ICONPOSITION.BOTH"
      v-slot:append
    >
      <v-icon></v-icon>
    </template>
  </v-btn>
</template>
<script setup lang="ts">

import { defineProps } from 'vue'
import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  BUTTON_ICONPOSITION,
  BUTTON_COLORS
} from '@/constants/buttonConstants'
import type {
  ButtonSize,
  ButtonColor,
  ButtonIconpositions,
  ButtonVariant
} from '@/constants/buttonConstants'
import { useCommonI18n } from '@/core/plugins/global-util';


interface Props {
  text: string
  className?: string | string[]
  disabled?: boolean
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  iconPosition: ButtonIconpositions
  appendIcon?: string
  prependIcon?: string
  stacked?: boolean
  width?: number
  // outline?: string 테두리 클래스
}
const { translate } = useCommonI18n()
const props = withDefaults(defineProps<Props>(), {
  text: '',
  variant: BUTTON_VARIANTS.CONTAINED,
  size: BUTTON_SIZES.MEDIUM,
  color: BUTTON_COLORS.PRIMARY,
  iconPosition: BUTTON_ICONPOSITION.DEFAULT
})

const computedClassName = () => {
  const baseClassNames = Array.isArray(props.className) ? props.className : [props.className]

  return [...baseClassNames, props.variant, props.color, props.size, props.iconPosition]
}
</script>
