<template>
  <!-- no-data -->
  <div :class="['no-data-area', props.className]">
    <div>
      <p>{{ translate(props.text) }}</p>
      <p>{{ translate(props.subText) }}</p>
      <p>{{ translate(props.subTextTwo) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCommonI18n } from '@/core/plugins/global-util'

interface Props {
  text?: string
  subText?: string
  className?: string
  subTextTwo?: string
}
const props = withDefaults(defineProps<Props>(), {
  text: '',
  subText: '',
  className: '',
  subTextTwo: ''
})
const { translate } = useCommonI18n()
</script>
