<template>
  <div class="sending-statistics">
    <!-- 타이틀 -->
    <div class="contents-title-wrap">
      <strong class="contents-title">{{ translate('[HBU] TV홈쇼핑 마케팅팀 발송통계') }}</strong>
    </div>
    <!-- 검색 영역 -->
    <div class="data-table-search-area">
      <div class="search-option-area">
        <!-- 검색 - 기간 -->
        <div class="option-date">
          <div class="label-box">
            <span class="label required">{{ translate('기간') }}</span>
          </div>
          <v-btn-toggle mandatory class="date-range-btn-toggle">
            <v-btn
              v-for="(item, index) in datePeriod"
              :key="index"
              :class="{ active: activeIndex === index }"
              @click="activeDataBtn(index)"
              :disabled="isDisabled"
            >
              {{ item }}
            </v-btn>
          </v-btn-toggle>
          <v-menu v-model="openDatePicker" class="gsr-select-date" :close-on-content-click="false">
            <template #activator="{ props }">
              <v-form class="gsr-select-date-form" v-bind="props" @click="handleDateFormClick">
                <div class="gsr-select-date-input">
                  <v-text-field
                    placeholder="YYYY-MM-DD"
                    @input="(e) => formatDateInput(e, 'start')"
                    v-model="searchStartDate"
                    maxlength="10"
                  ></v-text-field>
                  ~
                  <v-text-field
                    placeholder="YYYY-MM-DD"
                    @input="(e) => formatDateInput(e, 'end')"
                    v-model="searchEndDate"
                  ></v-text-field>
                </div>
                <!-- <div class="gsr-select-date-clear-btn">
                  <v-btn></v-btn>
                </div> -->
              </v-form>
            </template>
            <v-card class="gsr-select-date-layer">
              <v-row no-gutters>
                <v-col>
                  <v-date-picker
                    hide-header
                    show-adjacent-months
                    v-model="selectedStartDate"
                    :max="selectedEndDate"
                  >
                  </v-date-picker>
                </v-col>
                <v-col>
                  <v-date-picker
                    hide-header
                    show-adjacent-months
                    v-model="selectedEndDate"
                    :min="selectedStartDate"
                  >
                  </v-date-picker>
                </v-col>
              </v-row>
              <v-row no-gutters class="gsr-select-date-btn-area">
                <GSButton
                  :class="'select-date-today-btn'"
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'오늘'"
                  :width="48"
                ></GSButton>
                <v-list class="gsr-select-date-btn-list">
                  <v-list-item>
                    <GSButton
                      :class="'select-date-reset-btn'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :width="32"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.INHERIT"
                      :text="'취소'"
                      :width="48"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      :class="'select-date-confirm-btn'"
                      :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :text="'선택완료'"
                      :width="69"
                      @click="cofirmDate()"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-card>
          </v-menu>
        </div>
        <!-- 검색 - 채널 -->
        <div class="option-select">
          <div class="label-box">
            <span class="label">{{ translate('채널') }}</span>
          </div>
          <v-select
            class="gsr-selects-outlined gsr-selects-small"
            v-model="channelList[0]"
            :items="channelList"
          >
          </v-select>
        </div>
        <!-- 검색 - 이벤트 -->
        <div class="gsr-select-autocomplete">
          <div class="label-box">
            <span>{{ translate('이벤트') }}</span>
          </div>
          <v-menu :close-on-content-click="false" transition="fade-transition">
            <template #activator="{ props }">
              <v-btn v-bind="props" class="gsr-select-autocomplete-activator">
                <span>{{ translate('전체') }}</span>
              </v-btn>
            </template>
            <v-autocomplete
              :offset-y="true"
              transition="slide-y-transition"
              menu
              :clearable="true"
              :items="workspaceList"
              placeholder="검색어 입력"
              class="gsr-select-autocomplete-search"
              :menu-props="{ class: 'gsr-select-autocomplete-search-list' }"
              :close-on-content-click="false"
            >
              <template v-slot:prepend-inner>
                <span class="title">이벤트 검색</span>
              </template>
            </v-autocomplete>
          </v-menu>
        </div>
      </div>
      <div class="search-btn-area">
        <!-- 리셋 버튼 -->
        <GSButton
          class="reset-btn"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
          :width="32"
        ></GSButton>
        <!-- 조회 버튼 -->
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'조회'"
          :width="128"
          @click="openDialog"
        ></GSButton>
      </div>
      <!-- 조회 기간 dialog -->
      <v-dialog v-model="dialog" class="dialog-layer" width="400">
        <v-card class="gsr-popup">
          <div class="gsr-popup-icon">
            <i></i>
          </div>
          <p class="gsr-popup-text">1년 이내로 설정하여 조회해 주세요.</p>
          <div class="gsr-popup-btn">
            <v-btn ref="confirmBtn" class="confirm" @click="closeDialog">확인</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <!-- 조회 영역 -->
    <div class="data-table-area">
      <v-container>
        <v-row>
          <v-col>
            <!-- 조회 - 사용현황 -->
            <div class="data-table-contents state">
              <div class="contents-sub-title-wrap">
                <div class="contents-sub-title-text">
                  <strong class="contents-sub-title">
                    {{ translate('사용현황') }}
                  </strong>
                  <p class="contents-sub-title-detail">
                    {{ translate('2024. 08. 01 ~ 2024. 08 . 31 기준') }}
                  </p>
                </div>
              </div>
              <!-- data -->
              <v-list>
                <v-list-item>
                  <div class="result-title">
                    <strong class="name charge"> 사용요금 </strong>
                    <v-btn class="tooltip-btn">
                      <v-tooltip
                        class="tooltip-layer"
                        activator="parent"
                        location="bottom start"
                        origin="auto"
                        scroll-strategy="reposition"
                      >
                        <div class="tooltip-area">
                          <strong class="tooltip-title">사용요금</strong>
                          <p class="tooltip-sub-title">
                            기간별 사용요금은 실제 청구금액과 동일하게 계산하여 제공됩니다.
                          </p>
                          <div class="tooltip-sub-area">
                            <ul class="tooltip-detail-list">
                              <li>
                                <p>사용요금 계산 방법 : <span>부가세 포함된 사용요금/1.1</span></p>
                                <p class="detail">소수점 반올림(5부터 올림)</p>
                              </li>
                              <li>
                                <p>
                                  부가세 포함된 사용금액 :
                                  <span
                                    >((성공 건수*채널 요금)+(대체 성공*대체 채널 요금))+((성공
                                    건수*채널 요금)+(대체 성공*대체 채널 요금))*10%)</span
                                  >
                                </p>
                                <p class="detail">원단위 이하 절사</p>
                              </li>
                              <li>
                                <strong>e.g. LMS 기준</strong>
                                <p>성공 건수 = 376건</p>
                                <p>사용요금 = 376* 23.637= 8,887.512원</p>
                                <p>부가세 = 8,887.512 * 10% = 888.7512원</p>
                                <p>청구금액 (vat 포함) = 8,887.512+888.7512=9,776.2632원</p>
                                <p class="price">→ 9,770원 (원단위 이하 절사)</p>
                                <p>청구금액 (vat 제외) = 9,770/1.1= 8,881.818182원</p>
                                <p class="price">→ 8,882원 (소수점 반올림으로 5부터 올림)</p>
                                <strong class="total">사용요금 = 8,882원</strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </v-tooltip>
                    </v-btn>
                  </div>
                  <div class="result">
                    <span class="num">999,999,999</span>
                    <span class="unit">원</span>
                  </div>
                </v-list-item>
                <v-list-item>
                  <div class="result-title">
                    <strong class="name send"> 발송건수 </strong>
                  </div>
                  <div class="result">
                    <span class="num">999,999,999</span>
                    <span class="unit">건</span>
                  </div>
                </v-list-item>
                <v-list-item>
                  <div class="result-title">
                    <strong class="name success">
                      성공건수
                      <span> (대체성공 포함) </span>
                    </strong>
                  </div>
                  <div class="result">
                    <span class="num">999,999,999</span>
                    <span class="unit">건</span>
                  </div>
                </v-list-item>
              </v-list>
              <!-- no-data -->
              <div class="no-data-area">
                <div>
                  <p>{{ translate('기간 및 채널을 조회해주세요.') }}</p>
                </div>
              </div>
            </div>
            <!-- 조회 - 조회내역 -->
            <div class="data-table-contents data-table">
              <div class="contents-sub-title-wrap">
                <div class="contents-sub-title-text">
                  <strong class="contents-sub-title">
                    {{ translate('조회내역') }} <span>{{ searchResult }}</span
                    >{{ translate('건') }}
                  </strong>
                  <!-- tooltip -->
                  <v-btn class="tooltip-btn">
                    <v-tooltip
                      class="tooltip-layer"
                      activator="parent"
                      location="bottom start"
                      origin="auto"
                      scroll-strategy="reposition"
                    >
                      <div class="tooltip-area">
                        <strong class="tooltip-title">발송일자</strong>
                        <p class="tooltip-sub-title">
                          발송일자의 채널별 발송통계를 확인할 수 있습니다.
                        </p>
                        <div class="tooltip-sub-area">
                          <ul class="tooltip-detail-list">
                            <li>
                              <p>사용 요금 : <span>성공 건수*채널 요금</span></p>
                              <p class="detail">
                                사용 요금은 소수점 자릿수 그대로 표현되며, 끝자리가 0인 경우 노출
                                제외됩니다
                              </p>
                              <p class="detail">
                                발송일 기준으로 성공 건수에 채널 요금이 가산되어 집계됩니다.<br />
                                e.g. 9/1 23:59:58 발송 후 9/2 00:00:01에 메시지 수신할 경우 9/1 성공
                                건수로 집계됩니다.
                              </p>
                            </li>
                            <li>
                              <div class="paragraph">
                                <p>발송건수 : 전체 발송 건수</p>
                                <p>성공건수 : 발송 성공 건수</p>
                                <p>실패건수 : 발송 실패 건수</p>
                                <p>
                                  미확인 건수 : 통신사로부터 발송 성공 및 실패여부가 확인되지 않은
                                  건수
                                </p>
                              </div>
                              <p class="detail">
                                미확인 건수는 발송일자 기준 24시간 이후부터 확인 가능하며 수신자
                                위치, 단말기의 On/Off 상태 등에 따라 확인이 지연될 수 있습니다.
                              </p>
                            </li>
                            <li>
                              <div class="paragraph">
                                <p>
                                  대체채널 : <span>발송 실패 시 다른 채널로 대체하여 발송</span>
                                </p>
                                <p>대체 채널 요금 : <span>대체 성공*대체 채널 요금</span></p>
                              </div>
                              <p class="detail">
                                대체 채널 요금은 소수점 자릿수 그대로 표현되며, 끝자리 0인 경우 노출
                                제외됩니다.
                              </p>
                              <p class="detail">
                                발송일 기준으로 대체 성공 건수에 대체 채널 요금이 가산되어
                                집계됩니다. e.g. 9/1 23:59:58 발송 후 9/2 00:00:01에 대체 메시지
                                수신할 경우 9/1 대체 성공 건수로 집계됩니다.
                              </p>
                            </li>
                            <li>
                              <div class="paragraph">
                                <p>
                                  대체 성공 :
                                  <span>채널 발송 실패 시 다른 채널로 대체 발송 시 성공 건수</span>
                                </p>
                                <p>
                                  대체 실패 :
                                  <span>채널 발송 실패 시 다른 채널로 대체 발송 시 실패 건수</span>
                                </p>
                                <p>성공률 : <span>{(성공 건수+대체 성공)/발송 건수}*100</span></p>
                              </div>
                              <p class="detail">소수점 발생 시 절사하여 표기됩니다.</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </v-tooltip>
                  </v-btn>
                </div>
                <div class="contents-sub-title-button">
                  <ul>
                    <li>
                      <GSSelects
                        :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                        :size="Constants.SELECTS_SIZES.SMALL"
                        style="width: 120px"
                        :itemsList="dataView"
                      ></GSSelects>
                    </li>
                    <li>
                      <GSButton
                        class="excel-btn"
                        :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                        :size="Constants.BUTTON_SIZES.SMALL"
                        :color="Constants.BUTTON_COLORS.SECONDARY"
                        :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                        :text="'엑셀 다운로드'"
                        :width="121"
                      ></GSButton>
                    </li>
                  </ul>
                </div>
              </div>
              <v-data-table
                :headers="dataTableHeader"
                :items="smsData"
                :items-per-page="10"
                :class="dataTableHeader.length > 12 ? 'scroll-x' : ''"
                hide-default-footer
              >
                <template #no-data>
                  <p>해당 내역이 없습니다.</p>
                </template>
              </v-data-table>
              <v-pagination
                length="10"
                v-if="smsData.length > 10"
                :total-visible="6"
                :show-first-last-page="true"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup lang="ts">
// S: 다국어
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()
const i18n = useI18n()

i18n.locale.value = 'kr'

// @ts-ignore
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}
// E: 다국어

import { ref } from 'vue'
import * as Constants from '@/constants/index'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
// import Pagination from '@/components/common/Pagination.vue'

const getTodayDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1
  const day = String(today.getDate()).padStart(2, '0') // 날짜를 2자리로 맞춤
  return `${year}-${month}-${day}`
}

const cofirmDate = () => {
  console.log('확인')
  searchStartDate.value = changeDate(selectedStartDate.value)
  searchEndDate.value = changeDate(selectedEndDate.value)
  openDatePicker.value = false
}

const openDatePicker = ref(false)
const searchStartDate = ref(getTodayDate())
const searchEndDate = ref(getTodayDate())
const selectedStartDate = ref<Date | null>(null)
const selectedEndDate = ref<Date | null>(null)

const changeDate = (date: Date | null) => {
  return date ? date.toLocaleDateString('en-CA') : ''
}

// const clickDate = () => {
//   console.log('openDatePicker.value ', openDatePicker.value)
//   openDatePicker.value = true
//   console.log('openDatePicker.value2 ', openDatePicker.value)
// }

const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate() // month는 1월이 0, 2월이 1로 시작됨
}

const formatDateInput = (e: Event, type: string) => {
  let input = (e.target as HTMLInputElement).value.replace(/\D/g, '') // 숫자 외 문자 제거

  // 4번째 입력 시 하이픈(-) 추가
  if (input.length > 4) {
    input = `${input.slice(0, 4)}-${input.slice(4)}`
  }

  // 7번째 입력 시 하이픈(-) 추가
  if (input.length > 7) {
    input = `${input.slice(0, 7)}-${input.slice(7)}`
  }

  // 입력 길이를 10자로 제한 (YYYY-MM-DD)
  if (input.length > 10) {
    input = input.slice(0, 10)
  }

  // 유효한 MM과 DD 입력을 위한 제어
  const year = parseInt(input.slice(0, 4), 10)
  const month = parseInt(input.slice(5, 7), 10)
  const day = parseInt(input.slice(8, 10), 10)

  // MM에 대한 유효성 검사 (01~12)
  if (month && (month < 1 || month > 12)) {
    input = `${input.slice(0, 4)}-` // 잘못된 월 입력 시, 하이픈 뒤로 잘라냄
  }

  // DD에 대한 유효성 검사 (1~월별 최대 일수)
  if (month && day && (day < 1 || day > daysInMonth(year, month))) {
    input = `${input.slice(0, 7)}-` // 잘못된 일 입력 시, 일 부분을 잘라냄
  }

  // 필드에 따라 값을 저장 (start 또는 end)
  if (type === 'start') {
    searchStartDate.value = input
  } else if (type === 'end') {
    searchEndDate.value = input
  }
}

// const clearInput = () => {
//   console.log('Clear')
// }

// 검색 영역 - 기간
const datePeriod = ref(['오늘', '1개월', '3개월', '6개월'])
// 검색 영역 - 채널
const channelList = ref(['전체', '채널1', '채널2', '채널3', '채널4'])
// 검색 영역 - 조직
const organizationList = ref([
  '[HBU] 전사배부',
  '[HBU] TV홈쇼핑 마케팅팀',
  '[HBU] 마케팅팀',
  '[HBU] 모바일 사업부',
  '[PBU] 우리동네GS팀',
  '[PBU] 통합서비스TFT',
  '[HBU] 모바일 사업부',
  '[PBU] 우리동네GS팀',
  '[PBU] 통합서비스TFT',
  '[HBU] 전사배부 2',
  '[HBU] TV홈쇼핑 마케팅팀 2',
  '[HBU] 마케팅팀 2',
  '[HBU] 모바일 사업부 2',
  '[PBU] 우리동네GS팀 2',
  '[PBU] 통합서비스TFT 2',
  '[HBU] 모바일 사업부 2',
  '[PBU] 우리동네GS팀 2',
  '[PBU] 통합서비스TFT 2'
])
// 검색 영역 - 워크스페이스
const workspaceList = ref([
  '전체',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_주문완료_M커머스_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_출고완료_M커머스_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_출고완료_M커머스_알림톡'
])
// 조회 내역 건 수
const searchResult = ref('2,999')
// 조회 내역
const dataView = ref([
  {
    label: '10개씩 보기',
    value: '10'
  },
  {
    label: '20개씩 보기',
    value: '20'
  },
  {
    label: '30개씩 보기',
    value: '30'
  }
])

interface DataTableHeader {
  align?: 'start' | 'center' | 'end'
  title?: string
  value: string
  replaceChannelCharge?: string
  sortable?: boolean
}
// 데이터 테이블 header
const dataTableHeader = ref<DataTableHeader[]>([
  {
    align: 'center',
    title: '발송일자',
    value: 'date',
    sortable: false
  },
  {
    align: 'center',
    title: '채널',
    value: 'channel',
    sortable: false
  },
  {
    align: 'end',
    title: '사용금액(원)',
    value: 'amountUsed',
    sortable: false
  },
  {
    align: 'end',
    title: '발송건수',
    value: 'send',
    sortable: false
  },
  {
    align: 'end',
    title: '성공건수',
    value: 'success',
    sortable: false
  },
  {
    align: 'end',
    title: '실패건수',
    value: 'fail',
    sortable: true
  },
  {
    align: 'end',
    title: '미확인 건수',
    value: 'unconfirm',
    sortable: false
  },
  {
    align: 'end',
    title: '대체채널 요금(원)',
    replaceChannelCharge: '-',
    value: 'replaceChannelCharge',
    sortable: false
  },
  {
    align: 'center',
    title: '대체채널',
    replaceChannelCharge: '-',
    value: 'replaceChannel',
    sortable: false
  },
  {
    align: 'end',
    title: '대체성공',
    value: 'replaceSuccess',
    sortable: false
  },
  {
    align: 'end',
    title: '대체실패',
    value: 'replaceFail',
    sortable: true
  },
  {
    align: 'end',
    title: '성공율(%)',
    value: 'successRate',
    sortable: true
  },
  {
    align: 'start',
    title: 'Workspace',
    value: 'workspace',
    sortable: false
  }
])
// 데이터 테이블 data
const smsData = ref([
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '1',
    unconfirm: '0',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '99',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '33',
    unconfirm: '0',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '4,000,000',
    success: '4,000,000',
    fail: '0',
    unconfirm: '1,999,967',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'LMS',
    amountUsed: '999,999,999,999',
    send: '150,000',
    success: '150,000',
    fail: '33',
    unconfirm: '150,000',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'MMS',
    amountUsed: '999,999,999,999',
    send: '40,000',
    success: '40,000',
    fail: '0',
    unconfirm: '22',
    replaceChannelCharge: '9.999',
    replaceChannel: '알림톡',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '4,500,000',
    success: '4,500,000',
    fail: '9,999',
    unconfirm: '4,500,000',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '50',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'LMS',
    amountUsed: '999,999,999,999',
    send: '130,000',
    success: '130,000',
    fail: '2',
    unconfirm: '10,000',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '98',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.29',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '130,000',
    success: '130,000',
    fail: '0',
    unconfirm: '12,345',
    replaceChannelCharge: '9,999.999',
    replaceChannel: 'LMS',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.29',
    channel: 'LMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '11',
    unconfirm: '0',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(상품 확인 후 환불/후 환불) 반품수거 안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.24',
    channel: 'MMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '100',
    unconfirm: '19,789',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '99.8',
    workspace: '(상품 확인 후 환불/후 환불) 반품수거 안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '1',
    unconfirm: '0',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '99',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '33',
    unconfirm: '0',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '4,000,000',
    success: '4,000,000',
    fail: '0',
    unconfirm: '1,999,967',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'LMS',
    amountUsed: '999,999,999,999',
    send: '150,000',
    success: '150,000',
    fail: '33',
    unconfirm: '150,000',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'MMS',
    amountUsed: '999,999,999,999',
    send: '40,000',
    success: '40,000',
    fail: '0',
    unconfirm: '22',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '4,500,000',
    success: '4,500,000',
    fail: '9,999',
    unconfirm: '4,500,000',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '50',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.31',
    channel: 'LMS',
    amountUsed: '999,999,999,999',
    send: '130,000',
    success: '130,000',
    fail: '2',
    unconfirm: '10,000',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '98',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.29',
    channel: 'SMS',
    amountUsed: '999,999,999,999',
    send: '130,000',
    success: '130,000',
    fail: '0',
    unconfirm: '12,345',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.29',
    channel: 'LMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '11',
    unconfirm: '0',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '100',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  },
  {
    date: '2024.08.24',
    channel: 'MMS',
    amountUsed: '999,999,999,999',
    send: '100,000',
    success: '100,000',
    fail: '100',
    unconfirm: '19,789',
    replaceChannelCharge: '-',
    replaceChannel: '-',
    replaceSuccess: '0',
    replaceFail: '0',
    successRate: '99.8',
    workspace: '(무통장) 입금확인 완료안내_T커머스, TC리모콘'
  }
])
// 검색 영역 - 기간 캘린더
const handleDateFormClick = (event) => {
  if (openDatePicker.value) {
    event.stopPropagation() // 메뉴가 열렸다면 클릭 전파를 막음
  } else {
    openDatePicker.value = true // 메뉴를 열기
  }
}
// org autocomplete
// const menuOrgVisible = ref(false) // 메뉴 열림 상태 관리
// const showOrgAutocomplete = ref(false) // autocomplete 보여줄지 말지 결정
// function handleOrgMenuOpen(isOpen: boolean) {
//   if (isOpen) {
//     // 메뉴가 열리면 autocomplete 보이게 함
//     setTimeout(() => {
//       showOrgAutocomplete.value = true
//     }, 280)
//   } else {
//     // 메뉴가 닫히면 autocomplete 숨김
//     showOrgAutocomplete.value = false
//   }
// }

const dialog = ref(false)
const openDialog = () => {
  dialog.value = true
}
const closeDialog = () => {
  dialog.value = false
}
const activeIndex = ref(null)
const isDisabled = ref(false)
const activeDataBtn = (index: number) => {
  if (isDisabled.value) return
  activeIndex.value = index
  isDisabled.value = true
  setTimeout(() => {
    activeIndex.value = null
    isDisabled.value = false
  }, 300)
}
</script>
