<template>
  <!-- 사용자 -->
  <div class="users">
    <PageTitle :title="'사용자'"></PageTitle>
    <div class="contents-area">
      <div class="contents-search-area">
        <div class="search-options">
          <GSSelects
            class="search-users-select-area"
            :items-list="searchUsersItems"
            :variant="Constants.SELECTS_VARIANTS.OUTLINED"
            :size="Constants.SELECTS_SIZES.SMALL"
            :desc-position="Constants.SELECTS_POSITION.LEFT"
            :description="'사용자'"
          ></GSSelects>
          <GSInput
            class="search-users-input-area"
            :variant="Constants.INPUT_VARIANTS.OUTLINED"
            :size="Constants.INPUT_SIZES.SMALL"
            :icon-position="Constants.INPUT_ICONPOSITION.RIGHT"
            :placeholder="'사용자명 입력'"
          ></GSInput>
        </div>
        <div class="search-buttons">
          <v-list>
            <v-list-item>
              <GSButton
                :class="'reset-btn'"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :icon-position="Constants.BUTTON_ICONPOSITION.CENTER"
                :width="32"
              ></GSButton>
            </v-list-item>
            <v-list-item>
              <GSButton
                class="confirm-btn"
                :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :text="'조회'"
                :width="128"
              ></GSButton>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <v-list>
            <v-list-item>
              <GSButton
                class="confirm-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :text="'선택삭제'"
                width="73"
              ></GSButton>
            </v-list-item>
            <v-list-item>
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'사용자 추가'"
                width="106"
                @click="openDialog"
              ></GSButton>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <div class="contents-data-table-wrap">
        <v-data-table
          :headers="resultHeader"
          :items="resultData"
          :items-per-page="10"
          hide-default-footer
        >
          <template v-slot:colgroup>
            <colgroup>
              <col style="width: 300px" />
              <col style="width: 740px" />
              <col style="width: 300px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:item.name="{ item }">
            <div class="users-name">
              <img :src="item.profile" />
              <span>{{ item.name }}</span>
            </div>
          </template>
          <template v-slot:item.state="{ item }">
            <span class="state active" v-if="item.state === true">이용중</span>
            <span class="state" v-if="item.state === false">이용안함</span>
          </template>
        </v-data-table>
        <Pagination :dataLength="resultData.length"></Pagination>
      </div>
    </div>
    <v-dialog v-model="dialog" class="gsr-dialog" width="444">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">유저 신규등록</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <GSImages></GSImages>
          <div class="info-area">
            <GSInput
              :variant="Constants.INPUT_VARIANTS.OUTLINED"
              :size="Constants.INPUT_SIZES.SMALL"
              :desc-position="Constants.INPUT_POSITION.TOP"
              :description="'발신번호'"
              :required="true"
              :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
            ></GSInput>
            <GSSwitches
              :size="Constants.SWITCHES_SIZES.MEDIUM"
              :desc-position="Constants.SWITCHES_POSITION.TOP"
              :description="'트래킹 여부'"
              :required="true"
            ></GSSwitches>
            <GSSelects
              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
              :size="Constants.SELECTS_SIZES.SMALL"
              :desc-position="Constants.SELECTS_POSITION.TOP"
              :description="'유입 성과분석'"
              :required="true"
            ></GSSelects>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.MEDIUM"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'닫기'"
            width="57"
            @click="closeDialog"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.MEDIUM"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'저장하기'"
            width="81"
          ></GSButton>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">


import { ref } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSSwitches from '@/components/ui/GSSwitches.vue'
import GSImages from '@/components/ui/GSImages.vue'
import GSRadio from '@/components/ui/GSRadio.vue'
import GSInput from '@/components/ui/GSInput.vue'
import GSFileinput from '@/components/ui/GSFileinput.vue'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()


// table header
const resultHeader = [
  {
    align: 'start',
    title: '사용자명',
    value: 'name',
    sortable: false
  },
  {
    align: 'start',
    title: 'ID',
    value: 'id',
    sortable: false
  },
  {
    align: 'center',
    title: '생성일',
    value: 'creationDate',
    sortable: false
  },
  {
    align: 'center',
    title: '상태',
    value: 'state',
    sortable: false
  }
]
// table data
const resultData = [
  {
    profile: '/src/assets/images/users_profile_2.jpg',
    name: '이*미',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    profile: '/src/assets/images/users_profile_1.jpg',
    name: '윤*영',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: false
  },
  {
    profile: '/src/assets/images/users_profile_1.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    profile: '/src/assets/images/users_profile_1.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    profile: '/src/assets/images/users_profile_1.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    profile: '/src/assets/images/users_profile_1.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    profile: '/src/assets/images/users_profile_2.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: false
  },
  {
    profile: '/src/assets/images/users_profile_3.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: false
  },
  {
    profile: '/src/assets/images/users_profile_1.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    profile: '/src/assets/images/users_profile_4.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    profile: '/src/assets/images/users_profile_1.jpg',
    name: 'Dam**nSeo',
    id: 'haemi***77@gsretail.com',
    creationDate: '2024.01.16  11:00:00',
    state: true
  }
]

// 신규등록 dialog
const dialog = ref(false)
const openDialog = () => {
  dialog.value = true
}
const closeDialog = () => {
  dialog.value = false
}
// 신규등록 - img 삭제 dialog
const subDialog = ref(false)
const openSubDialog = () => {
  subDialog.value = true
}
const closeSubDialog = () => {
  subDialog.value = false
}

const searchUsersItems = [
  { label: '전체', value: '1' },
  { label: '활성화', value: '2' },
  { label: '비활성화', value: '3' }
]
// img upload
const defaultImage = '/src/assets/images/img_register_default.jpg'
const imageUrl = ref<string>(defaultImage)

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (file) {
    imageUrl.value = URL.createObjectURL(file)
  } else {
    imageUrl.value = defaultImage
  }
}
</script>
