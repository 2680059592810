<template>
  <div :class="descPosition" class="gsr-file-input">
    <div class="label-box">
      <span
        v-if="descPosition != FILEINPUT_POSITION.DEFAULT && typeof description === 'string'"
        :class="{ required: required }"
        >{{ translate(description) }}
      </span>
    </div>
    <div class="gsr-file-input-area">
      <v-text-field
        class="upload-input"
        v-model="selectedFileName"
        :placeholder="placeholder"
        :readonly="true"
        v-bind:style="width !== undefined ? { width: width + 'px' } : undefined"
        hide-details
      />
      <v-btn class="upload-btn export-btn" :disabled="disabled" @click="triggerFileInput">
        {{ buttonText }}
        <template v-slot:prepend>
          <v-icon></v-icon>
        </template>
      </v-btn>
    </div>
    <input ref="fileInput" type="file" class="d-none" :accept="accept" @change="handleFileChange" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { FILEINPUT_POSITION } from '@/constants/fileinputConstants'
import type { FileinputPositions } from '@/constants/fileinputConstants'

const { t, te } = useI18n()
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}

interface Props {
  placeholder?: string
  buttonText?: string
  accept?: string
  disabled?: boolean
  description?: string
  required?: boolean
  descPosition?: FileinputPositions
  width?: number
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '파일을 첨부해주세요',
  buttonText: '파일첨부',
  accept: '*/*',
  disabled: false,
  descPosition: FILEINPUT_POSITION.DEFAULT
})

const emit = defineEmits<{
  (e: 'update:file', file: File | null): void
}>()

const fileInput = ref<HTMLInputElement | null>(null)
const selectedFileName = ref('')

const triggerFileInput = () => {
  if (!props.disabled) {
    fileInput.value?.click()
  }
}

const handleFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (!input.files?.length) return

  const file = input.files[0]
  selectedFileName.value = file.name
  emit('update:file', file)

  // 입력 초기화
  input.value = ''
}
</script>
