<template>
  <div class="gsr-radio">
    <div class="label-box">
      <span
        v-if="descPosition != RADIO_POSITION.DEFAULT && typeof description === 'string'"
        :class="{ required: required }"
        >{{ translate(description) }}</span
      >
    </div>
    <div :class="direction">
      <v-radio-group v-bind="$attrs" :mandatory="true">
        <!-- <v-radio-group :mandatory="true"> -->
        <v-radio
          v-for="(item, index) in items"
          :key="index"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
          :class="computedClassName"
        />
      </v-radio-group>
    </div>
  </div>
</template>

<script setup lang="ts">

import { defineProps, computed } from 'vue'
import {
  RADIO_COLORS,
  RADIO_SIZES,
  RADIO_POSITION,
  RADIO_DIRECTION
} from '@/constants/radioConstants'
import type {
  RadioColors,
  RadioSizes,
  RadioPositions,
  Radiodirection
} from '@/constants/radioConstants'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()

interface Item {
  label: string
  value: string | number
  disabled?: boolean
}

interface Props {
  items: Item[]
  color: RadioColors
  size: RadioSizes
  className?: string | string[]
  direction: Radiodirection
  description?: string
  required?: boolean
  descPosition: RadioPositions
}

const props = withDefaults(defineProps<Props>(), {
  color: RADIO_COLORS.PRIMARY,
  size: RADIO_SIZES.MEDIUM,
  className: '',
  descPosition: RADIO_POSITION.DEFAULT,
  direction: RADIO_DIRECTION.HORIZONTAL
})

// 라디오 버튼의 클래스명을 계산하는 computed property
const computedClassName = computed(() => {
  const baseClassNames = Array.isArray(props.className) ? props.className : [props.className]
  return [...baseClassNames, props.color, props.size]
})
</script>
