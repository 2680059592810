<template>
  <!-- 사용자 정보 -->
  <div class="users-profile">
    <PageTitle :title="'사용자 정보'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('기본정보') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <GSButton
            class="edit-btn"
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
            :text="'편집'"
            width="67"
          ></GSButton>
        </div>
      </div>
      <div class="contents-profile-wrap">
        <div class="contents-profile-left">
          <v-avatar>
            <img src="/src/assets/images/users_profile.jpg" alt="" />
          </v-avatar>
          <div class="contents-profile-text">
            <span class="state">이용중</span>
            <span class="title">이해미</span>
            <span class="sub-title">leeheamigs12777@gsretail.com</span>
          </div>
        </div>
        <div class="contents-profile-right">
          <div class="contents-profile-info">
            <span>조직</span>
            <p>GS리테일</p>
          </div>
          <div class="contents-profile-info">
            <span>생성일</span>
            <p>2024.01.03 13:32:05</p>
          </div>
        </div>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-tabs-wrap">
        <v-tabs height="44" v-model="tab">
          <v-tab width="41">Role</v-tab>
          <v-tab width="88">Workspace</v-tab>
          <v-tab width="115">Audience Filter</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <!-- role -->
          <v-tabs-window-item value="0" class="role">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="confirm-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'사용자 추가'"
                      width="106"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="roleHeader"
                :items="roleData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 300px" />
                    <col style="width: 240px" />
                    <col style="width: 800px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
              </v-data-table>
              <Pagination :dataLength="roleData.length"></Pagination>
            </div>
          </v-tabs-window-item>
          <!-- workspace -->
          <v-tabs-window-item value="1" class="workspace">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="workspaceHeader"
                :items="workspaceData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 480px" />
                    <col style="width: 860px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:item.workspace="{ item }">
                  <div class="workspace-name">
                    <v-avatar>
                      <img :src="item.img" alt="" />
                    </v-avatar>
                    <span>{{ item.name }}</span>
                  </div>
                </template>
                <template v-slot:item.state="{ item }">
                  <span class="state active" v-if="item.state === true">이용중</span>
                  <span class="state" v-if="item.state === false">이용안함</span>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />관리자에게 문의해주세요.</p>
                  </div>
                </template>
              </v-data-table>
              <Pagination :dataLength="workspaceData.length"></Pagination>
            </div>
          </v-tabs-window-item>
          <!-- audience filter -->
          <v-tabs-window-item value="2" class="audience-filter">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="confirm-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="audienceHeader"
                :items="audienceData"
                :items-per-page="10"
                hide-default-footer
                show-select
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 52px" />
                    <col style="width: 510px" />
                    <col style="width: 280px" />
                    <col style="width: 280px" />
                    <col style="width: 280px" />
                    <col style="width: auto; min-width: 178px" />
                  </colgroup>
                </template>
                <template v-slot:item.share="{ item }">
                  <div class="share-btn-area">
                    <GSButton
                      class="share-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.TINY"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'공유'"
                      width="67"
                    ></GSButton>
                    <GSButton
                      class="delete-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.TINY"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'삭제'"
                      width="67"
                    ></GSButton>
                  </div>
                </template>
              </v-data-table>
              <Pagination :dataLength="audienceData.length"></Pagination>
            </div>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">


import { ref } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()


const tab = ref(0)
// role data-table
const roleHeader = [
  {
    align: 'start',
    title: '역할',
    value: 'role'
  },
  {
    align: 'center',
    title: '종류',
    value: 'type'
  },
  {
    align: 'start',
    title: '상세설명',
    value: 'detail'
  },
  {
    align: 'center',
    title: '생성일',
    value: 'creationDate'
  }
]
const roleData = [
  {
    role: '조직 대표',
    type: '부서',
    detail: 'GS리테일',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: '부서 관리자',
    type: '부서',
    detail: '플랫폼BU / PBU 내 메시징 부서',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: '부서 관리자',
    type: '하위 부서',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: '부서 관리자',
    type: '부서',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: 'Workspace 관리자',
    type: 'Workspace',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: 'Workspace 관리자',
    type: 'Workspace',
    detail: '플랫폼BU / PBU 내 메시징 부서',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: 'Workspace 관리자',
    type: 'Workspace',
    detail: '플랫폼BU / PBU 내 메시징 부서',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: '부서 관리자',
    type: '부서',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: '부서 관리자',
    type: '부서',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    role: 'Workspace 관리자',
    type: 'Workspace',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    creationDate: '2024.01.16  11:00:00'
  }
]
// workspace table
const workspaceHeader = [
  {
    align: 'start',
    title: 'Workspace',
    value: 'workspace'
  },
  {
    align: 'start',
    title: '상세설명',
    value: 'detail'
  },
  {
    align: 'center',
    title: '상태',
    value: 'state'
  }
]
const workspaceData = [
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: '우리동네GS 광고성 메시지',
    detail: 'GS리테일',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS SHOP 정보성 메시지',
    detail: '플랫폼BU / PBU 내 메시징 부서',
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS SHOP 정보성 메시지',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_3.jpg',
    name: 'GS리테일 통합멤버십 정보성 메시지',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_4.jpg',
    name: 'GS THE FRESH 광고성 메시지',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_5.jpg',
    name: 'GS25 약관 변경 정보성 메시지',
    detail: '플랫폼BU / PBU 내 메시징 부서',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: '우리동네GS 광고성 메시지',
    detail: '플랫폼BU / PBU 내 메시징 부서',
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: '우리동네GS 광고성 메시지',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_5.jpg',
    name: 'GS25 약관 변경 정보성 메시지',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS SHOP 정보성 메시지',
    detail: '우리동네GS팀 / 우리동네GS 광고 마케팅 메시징 발송 ',
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: '우리동네GS 광고성 메시지',
    detail: 'GS리테일',
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: '우리동네GS 광고성 메시지',
    detail: 'GS리테일',
    state: false
  }
]
// audience filter table
const audienceHeader = [
  {
    align: 'start',
    title: '필터명',
    value: 'filter'
  },
  {
    align: 'end',
    title: 'Audience',
    value: 'audience'
  },
  {
    align: 'center',
    title: '마지막 수정일',
    value: 'lastModifiedData'
  },
  {
    align: 'center',
    title: '마지막 수정일',
    value: 'lastCalculatedData'
  },
  {
    align: 'center',
    title: '공유',
    value: 'share'
  }
]
const audienceData = [
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 거주 고객',
    audience: '1,200,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '10대 수도권 거주 고객',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '10대 우리동네GS 이용 고객',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '10대 우리동네GS 이용 고객',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '10대 우리동네GS 이용 고객',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '마포구 우리동네GS 수퍼 픽업/배달 이용고객',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '우리동네GS 수퍼 픽업/배달 이용고객',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    lastModifiedData: '2024.01.25  11:25:00 ',
    lastCalculatedData: '2024.01.25  11:25:00 '
  }
]
</script>
