<template>
  <div class="gsr-img-input-area">
    <div class="gsr-img-input-label">
      <span class="required">이미지</span>
    </div>
    <div class="gsr-img-input">
      <div class="img-area">
        <img :src="imageUrl" alt="" />
        <v-btn class="img-delete-btn" @click="openDeleteImgDialog">
          <span>삭제</span>
        </v-btn>
      </div>
      <v-file-input accept="image/*" @change="onFileChange"></v-file-input>
    </div>
    <ul class="gsr-img-input-info">
      <li>
        <span>업로드 가능한 파일 형식 : PNG, JPG, JPEG</span>
      </li>
      <li>
        <span>파일용량 : 4MB 이하</span>
      </li>
      <li>
        <span>권장 이미지 크기 200*200</span>
      </li>
    </ul>
  </div>
  <v-dialog v-model="deleteImgDialog" class="gsr-dialog delete-img-layer" width="400">
    <v-card>
      <div class="dialog-contents-wrap">
        <p class="text">프로필 사진을 삭제하시겠습니까?</p>
      </div>
      <div class="dialog-button-wrap">
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'확인'"
          width="80"
        ></GSButton>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue'

import * as Constants from '@/constants/index'
import GSButton from '@/components/ui/GSButton.vue'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()

const deleteImgDialog = ref(false)
const openDeleteImgDialog = () => {
  deleteImgDialog.value = true
}
const closedeleteImgDialog = () => {
  deleteImgDialog.value = false
}

const defaultImage = '/src/assets/images/img_register_default.jpg'
const imageUrl = ref<string>(defaultImage)

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (file) {
    imageUrl.value = URL.createObjectURL(file)
  } else {
    imageUrl.value = defaultImage
  }
}
</script>
