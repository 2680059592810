<template>
  <v-dialog persistent v-model="isDialogOpen" class="loading-popup-area">
    <v-card>
      <div>
        <!-- <v-img src="/src/assets/images/loading_square.gif" alt="loading gif" /> -->
        <img src="/src/assets/images/loading_square.gif" alt="loading gif" />
      </div>
      <p>{{ translate(props.message) }}</p>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}

const isDialogOpen = ref(true)

interface Props {
  message?: string
}

const props = withDefaults(defineProps<Props>(), {
  message: '잠시만 기다려주세요.'
})
</script>
