<template>
  <!-- 서비스 설정 -->
  <div class="organization-management">
    <PageTitle :title="'서비스 설정'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('기본정보') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <v-list>
            <v-list-item>
              <GSButton
                class="edit-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'편집'"
                width="67"
              ></GSButton>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <div class="contents-profile-wrap">
        <div class="contents-profile-left">
          <v-avatar class="square">
            <img src="/src/assets/images/service_profile.jpg" alt="" />
          </v-avatar>
          <div class="contents-profile-text">
            <span class="state">이용중</span>
            <span class="title">우리동네GS</span>
            <span class="sub-title">운영환경, 실제 고객 발송용</span>
          </div>
        </div>
        <div class="contents-profile-right">
          <div class="contents-profile-info">
            <span>생성일자</span>
            <p>2024.01.03 13:32:05</p>
          </div>
          <div class="contents-profile-info">
            <span>광고메시지 발송시간</span>
            <p>
              08:00:00 ~ 20:00:00
              <span class="sub-info">No time limit for sending informational messages</span>
            </p>
          </div>
        </div>
      </div>
      <div class="contents-data-form-wrap">
        <v-data-table hide-default-header hide-default-footer>
          <template v-slot:colgroup>
            <colgroup>
              <col style="width: 220px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:body="props">
            <tr>
              <th>
                <span>기본정보</span>
              </th>
              <td>
                <GSImages></GSImages>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'서비스명'"
                  :placeholder="'서비스명 입력'"
                  :required="true"
                  style="margin-top: 16px; width: 600px"
                ></GSInput>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'광고메시지 발송시간'"
                  :required="true"
                  style="margin-top: 16px"
                ></GSInput>
                <GSSwitches
                  :size="Constants.SWITCHES_SIZES.LARGE"
                  :desc-position="Constants.SWITCHES_POSITION.TOP"
                  :description="'상태 활성화'"
                  :required="true"
                  style="margin-top: 16px"
                ></GSSwitches>
                <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'설명'"
                  :placeholder="'설명 입력'"
                  style="margin-top: 16px"
                ></GSInput>
              </td>
            </tr>
            <tr class="border-0">
              <td colspan="2" class="contents-data-form-btn-area">
                <div class="contents-data-form-btn-list">
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.MEDIUM"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'취소'"
                    width="100"
                    @click="openCancelDialog"
                  ></GSButton>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                    :size="Constants.BUTTON_SIZES.MEDIUM"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :text="'저장'"
                    width="100"
                    @click="openSaveDialog"
                  ></GSButton>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-tabs-wrap">
        <v-tabs height="44" v-model="tab">
          <v-tab width="141">SMS / LMS / MMS</v-tab>
          <v-tab width="81">KakaoTalk</v-tab>
          <v-tab width="50">PUSH</v-tab>
          <v-tab width="46">Email</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <!-- SMS / LMS / MMS -->
          <v-tabs-window-item value="0" class="sms">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회목록') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="confirm-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'SMS / LMS / MMS 추가'"
                      @click="openSmsDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="smsHeader"
                :items="smsData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 360px" />
                    <col style="width: 460px" />
                    <col style="width: 300px" />
                    <col style="width: 300px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:item.tracking="{ item }">
                  <div class="tracking">
                    <span v-if="item.tracking === true">Y</span>
                    <span v-if="item.tracking === false" class="false">N</span>
                  </div>
                </template>
                <template v-slot:item.modify="{ item }">
                  <GSButton
                    :class="'select-date-today-btn'"
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.TINY"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'수정'"
                    width="50"
                  ></GSButton>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />관리자에게 문의해주세요.</p>
                  </div>
                </template>
              </v-data-table>
              <Pagination :dataLength="smsData.length"></Pagination>
            </div>
            <v-dialog v-model="smsDialog" class="gsr-dialog service-add-layer" width="444">
              <v-card>
                <div class="dialog-title-wrap">
                  <strong class="dialog-title">SMS / LMS / MMS 추가</strong>
                  <div class="dialog-close-btn-box">
                    <v-btn class="dialog-close-btn" @click="closeSmsDialog"></v-btn>
                  </div>
                </div>
                <div class="dialog-contents-wrap">
                  <div class="info-area">
                    <GSInput
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.SMALL"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'보내는 사람'"
                      :required="true"
                      :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
                    ></GSInput>
                    <GSInput
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.SMALL"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'발신번호'"
                      :required="true"
                      :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
                    ></GSInput>
                    <GSSwitches
                      :size="Constants.SWITCHES_SIZES.LARGE"
                      :desc-position="Constants.SWITCHES_POSITION.TOP"
                      :description="'트래킹 여부'"
                      :required="true"
                    ></GSSwitches>
                    <GSSelects
                      :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                      :size="Constants.SELECTS_SIZES.SMALL"
                      :desc-position="Constants.SELECTS_POSITION.TOP"
                      :description="'유입 성과분석'"
                      :required="true"
                    ></GSSelects>
                  </div>
                </div>
                <div class="dialog-button-wrap">
                  <div>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.TEXT"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :text="'저장 후 계속 추가'"
                      width="119"
                    ></GSButton>
                  </div>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'닫기'"
                    width="57"
                    @click="closeSmsDialog"
                  ></GSButton>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :text="'저장하기'"
                    width="81"
                  ></GSButton>
                </div>
              </v-card>
            </v-dialog>
          </v-tabs-window-item>
          <!-- KakaoTalk -->
          <v-tabs-window-item value="1" class="kakaotalk">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회목록') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="confirm-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'KakaoTalk 추가'"
                      @click="openKakaotalkDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="kakaotalkHeader"
                :items="kakaotalkData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 360px" />
                    <col style="width: 460px" />
                    <col style="width: 300px" />
                    <col style="width: 300px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:item.tracking="{ item }">
                  <div class="tracking">
                    <span v-if="item.tracking === true">Y</span>
                    <span v-if="item.tracking === false" class="false">N</span>
                  </div>
                </template>
                <template v-slot:item.modify="{ item }">
                  <GSButton
                    :class="'select-date-today-btn'"
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.TINY"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'수정'"
                    width="50"
                  ></GSButton>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />관리자에게 문의해주세요.</p>
                  </div>
                </template>
              </v-data-table>
              <Pagination :dataLength="kakaotalkData.length"></Pagination>
            </div>
            <v-dialog v-model="kakaotalkDialog" class="gsr-dialog service-add-layer" width="444">
              <v-card>
                <div class="dialog-title-wrap">
                  <strong class="dialog-title">KakaoTalk 추가</strong>
                  <div class="dialog-close-btn-box">
                    <v-btn class="dialog-close-btn" @click="closeKakaotalkDialog"></v-btn>
                  </div>
                </div>
                <div class="dialog-contents-wrap">
                  <div class="info-area">
                    <GSInput
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.SMALL"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'플러스친구 ID'"
                      :required="true"
                      :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
                    ></GSInput>
                    <GSInput
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.SMALL"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'발신번호'"
                      :required="true"
                      :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
                    ></GSInput>
                    <GSSwitches
                      :size="Constants.SWITCHES_SIZES.LARGE"
                      :desc-position="Constants.SWITCHES_POSITION.TOP"
                      :description="'트래킹 여부'"
                      :required="true"
                    ></GSSwitches>
                    <GSSelects
                      :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                      :size="Constants.SELECTS_SIZES.SMALL"
                      :desc-position="Constants.SELECTS_POSITION.TOP"
                      :description="'유입 성과분석'"
                      :required="true"
                    ></GSSelects>
                  </div>
                </div>
                <div class="dialog-button-wrap">
                  <div>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.TEXT"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :text="'저장 후 계속 추가'"
                      width="119"
                    ></GSButton>
                  </div>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'닫기'"
                    width="57"
                    @click="closeDialog"
                  ></GSButton>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :text="'저장하기'"
                    width="81"
                  ></GSButton>
                </div>
              </v-card>
            </v-dialog>
          </v-tabs-window-item>
          <!-- PUSH -->
          <v-tabs-window-item value="2" class="push">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회목록') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="confirm-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'PUSH 추가'"
                      @click="openPushDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table :headers="pushHeader" :items="pushData" hide-default-footer>
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 200px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:body="props">
                  <tr>
                    <td colspan="2" class="accordion-data-wrap">
                      <v-expansion-panels variant="accordion" flat>
                        <v-expansion-panel v-for="(item, index) in pushData" :key="index">
                          <v-expansion-panel-title>
                            <div class="accordion-data-title">
                              <span>{{ item.system }}</span>
                            </div>
                            <div class="accordion-data-sub-title">
                              <span>{{ item.service }}</span>
                            </div>
                          </v-expansion-panel-title>
                          <v-expansion-panel-text>
                            <div class="accordion-data-info-area">
                              <!-- 상단 영역 -->
                              <div class="accordion-data-info">
                                <ul>
                                  <li>
                                    <div class="title">
                                      <span>앱명</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.info.appName }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>시간대</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.info.time }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>트래킹 여부</span>
                                    </div>
                                    <div class="info">
                                      <span v-if="item.info.tracking === true"> Y </span>
                                      <span v-if="item.info.tracking === false"> N </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>귀속</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.info.belonging }}</span>
                                    </div>
                                  </li>
                                </ul>
                                <div class="accordion-data-btn">
                                  <div>
                                    <GSButton
                                      class="edit-btn"
                                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                      :size="Constants.BUTTON_SIZES.TINY"
                                      :color="Constants.BUTTON_COLORS.SECONDARY"
                                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                                      :text="'수정'"
                                      width="63"
                                    ></GSButton>
                                    <GSButton
                                      class="delete-btn"
                                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                      :size="Constants.BUTTON_SIZES.TINY"
                                      :color="Constants.BUTTON_COLORS.SECONDARY"
                                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                                      :text="'삭제'"
                                      width="63"
                                    ></GSButton>
                                  </div>
                                </div>
                              </div>
                              <!-- 하단 영역 -->
                              <div class="accordion-data-info">
                                <ul>
                                  <li>
                                    <div class="title">
                                      <span>OS</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.osPrimary }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>인증서 유형</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.certificateType }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>인증서 파일명</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.certificateFileName }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>팀 ID</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.teamID }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>Key ID</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.keyID }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>앱 번들 ID</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.appBundleID }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>PUSH 사운드</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.pushSound }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>배지 산출</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.badge }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>앱 Key</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.appKeyPrimary }}</span>
                                    </div>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <div class="title">
                                      <span>OS</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.osSecondary }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>API Key (1)</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.apiKeyPrimary }}</span>
                                      <GSButton
                                        class="copy-btn"
                                        :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                        :size="Constants.BUTTON_SIZES.TINY"
                                        :color="Constants.BUTTON_COLORS.SECONDARY"
                                        :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                                        :text="'복사하기'"
                                        width="93"
                                      ></GSButton>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>API Key (2)</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.apiKeySecondary }}</span>
                                      <GSButton
                                        class="copy-btn"
                                        :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                        :size="Constants.BUTTON_SIZES.TINY"
                                        :color="Constants.BUTTON_COLORS.SECONDARY"
                                        :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                                        :text="'복사하기'"
                                        width="93"
                                      ></GSButton>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>API Key (3)</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.apiKeyTertiary }}</span>
                                      <GSButton
                                        class="copy-btn"
                                        :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                        :size="Constants.BUTTON_SIZES.TINY"
                                        :color="Constants.BUTTON_COLORS.SECONDARY"
                                        :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                                        :text="'복사하기'"
                                        width="93"
                                      ></GSButton>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>GCM Project Number</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.gcmProjectNum }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>Private 사용 여부</span>
                                    </div>
                                    <div class="info">
                                      <span v-if="item.detail.privateUsing === true">사용</span>
                                      <span v-if="item.detail.privateUsing === false">미사용</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>Private 프로토콜</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.privateProtocol }}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="title">
                                      <span>앱 Key</span>
                                    </div>
                                    <div class="info">
                                      <span>{{ item.detail.appKeySecondary }}</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <v-dialog v-model="pushDialog" class="gsr-dialog" width="600">
              <v-card>
                <div class="dialog-title-wrap">
                  <strong class="dialog-title">PUSH 서비스 앱 추가</strong>
                  <div class="dialog-close-btn-box">
                    <v-btn class="dialog-close-btn" @click="closePushDialog"></v-btn>
                  </div>
                </div>
                <v-tabs height="44" v-model="dialogTab">
                  <v-tab width="35">iOS</v-tab>
                  <v-tab width="40">AOS</v-tab>
                </v-tabs>
                <v-tabs-window v-model="dialogTab">
                  <v-tabs-window-item value="0">
                    <div class="dialog-tab-contents-area">
                      <div class="dialog-tab-title">
                        <span>PUSH 발송정보</span>
                      </div>
                      <div class="dialog-tab-contents">
                        <ul class="dialog-tab-contents-list">
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'앱명'"
                              :required="true"
                            ></GSInput>
                          </li>
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'발송시간대'"
                            ></GSInput>
                          </li>
                          <li>
                            <GSSwitches
                              :size="Constants.SWITCHES_SIZES.LARGE"
                              :desc-position="Constants.SWITCHES_POSITION.TOP"
                              :description="'트래킹 여부'"
                            ></GSSwitches>
                          </li>
                          <li>
                            <GSSelects
                              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                              :size="Constants.SELECTS_SIZES.MEDIUM"
                              :desc-position="Constants.SELECTS_POSITION.TOP"
                              :description="'유입 성과분석'"
                            ></GSSelects>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-tab-contents-area">
                      <div class="dialog-tab-title">
                        <span>PUSH 인증서</span>
                      </div>
                      <div class="dialog-tab-contents">
                        <ul class="dialog-tab-contents-list">
                          <li>
                            <GSSelects
                              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                              :size="Constants.SELECTS_SIZES.MEDIUM"
                              :desc-position="Constants.SELECTS_POSITION.TOP"
                              :description="'유형'"
                              :required="true"
                            ></GSSelects>
                          </li>
                          <li>
                            <GSFileinput
                              :desc-position="Constants.FILEINPUT_POSITION.TOP"
                              :description="'파일'"
                              :required="true"
                            ></GSFileinput>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-tab-contents-area">
                      <div class="dialog-tab-title">
                        <span>발송 기기정보</span>
                      </div>
                      <div class="dialog-tab-contents">
                        <ul class="dialog-tab-contents-list">
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'팀 ID'"
                              :required="true"
                            ></GSInput>
                          </li>
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'Key ID'"
                              :required="true"
                            ></GSInput>
                          </li>
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'앱 번들 ID'"
                              :required="true"
                            ></GSInput>
                          </li>
                          <li>
                            <GSSelects
                              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                              :size="Constants.SELECTS_SIZES.MEDIUM"
                              :desc-position="Constants.SELECTS_POSITION.TOP"
                              :description="'푸시 사운드'"
                              :required="true"
                            >
                            </GSSelects>
                          </li>
                          <li>
                            <GSSwitches
                              :size="Constants.SWITCHES_SIZES.LARGE"
                              :desc-position="Constants.SWITCHES_POSITION.TOP"
                              :description="'배지 카운트 여부'"
                              :required="true"
                            ></GSSwitches>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </v-tabs-window-item>
                  <v-tabs-window-item value="1">
                    <div class="dialog-tab-contents-area">
                      <div class="dialog-tab-title">
                        <span>PUSH 발송정보</span>
                      </div>
                      <div class="dialog-tab-contents">
                        <ul class="dialog-tab-contents-list">
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'앱명'"
                              :required="true"
                            ></GSInput>
                          </li>
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'발송시간대'"
                            ></GSInput>
                          </li>
                          <li>
                            <GSSwitches
                              :size="Constants.SWITCHES_SIZES.LARGE"
                              :desc-position="Constants.SWITCHES_POSITION.TOP"
                              :description="'트래킹 여부'"
                            ></GSSwitches>
                          </li>
                          <li>
                            <GSSelects
                              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                              :size="Constants.SELECTS_SIZES.MEDIUM"
                              :desc-position="Constants.SELECTS_POSITION.TOP"
                              :description="'유입 성과분석'"
                            ></GSSelects>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-tab-contents-area">
                      <div class="dialog-tab-title">
                        <span>PUSH 인증서</span>
                      </div>
                      <div class="dialog-tab-contents">
                        <ul class="dialog-tab-contents-list">
                          <li>
                            <GSSelects
                              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                              :size="Constants.SELECTS_SIZES.MEDIUM"
                              :desc-position="Constants.SELECTS_POSITION.TOP"
                              :description="'유형'"
                              :required="true"
                            ></GSSelects>
                          </li>
                          <li>
                            <GSFileinput
                              :desc-position="Constants.FILEINPUT_POSITION.TOP"
                              :description="'파일'"
                              :required="true"
                            ></GSFileinput>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-tab-contents-area">
                      <div class="dialog-tab-title">
                        <span>발송 기기정보</span>
                      </div>
                      <div class="dialog-tab-contents">
                        <ul class="dialog-tab-contents-list">
                          <li>
                            <div class="gsr-textarea">
                              <div class="label-box">
                                <span class="required">API Key (1)</span>
                                <GSButton
                                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                  :size="Constants.BUTTON_SIZES.TINY"
                                  :color="Constants.BUTTON_COLORS.SECONDARY"
                                  :text="'복사하기'"
                                  width="73"
                                ></GSButton>
                              </div>
                              <v-textarea class="gsr-textarea-outlined" rows="4" disabled>
                              </v-textarea>
                            </div>
                          </li>
                          <li>
                            <div class="gsr-textarea">
                              <div class="label-box">
                                <span class="required">API Key (2)</span>
                                <GSButton
                                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                  :size="Constants.BUTTON_SIZES.TINY"
                                  :color="Constants.BUTTON_COLORS.SECONDARY"
                                  :text="'복사하기'"
                                  width="73"
                                ></GSButton>
                              </div>
                              <v-textarea class="gsr-textarea-outlined" rows="4" disabled>
                              </v-textarea>
                            </div>
                          </li>
                          <li>
                            <div class="gsr-textarea">
                              <div class="label-box">
                                <span class="required">API Key (3)</span>
                                <GSButton
                                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                                  :size="Constants.BUTTON_SIZES.TINY"
                                  :color="Constants.BUTTON_COLORS.SECONDARY"
                                  :text="'복사하기'"
                                  width="73"
                                ></GSButton>
                              </div>
                              <v-textarea class="gsr-textarea-outlined" rows="4" disabled>
                              </v-textarea>
                            </div>
                          </li>
                          <li>
                            <GSInput
                              :variant="Constants.INPUT_VARIANTS.OUTLINED"
                              :size="Constants.INPUT_SIZES.MEDIUM"
                              :desc-position="Constants.INPUT_POSITION.TOP"
                              :description="'GCM 프로젝트 번호'"
                              :required="true"
                            ></GSInput>
                          </li>
                          <li>
                            <GSSwitches
                              :size="Constants.SWITCHES_SIZES.LARGE"
                              :desc-position="Constants.SWITCHES_POSITION.TOP"
                              :description="'공개제한 설정'"
                              :required="true"
                            ></GSSwitches>
                          </li>
                          <li>
                            <GSSelects
                              :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                              :size="Constants.SELECTS_SIZES.MEDIUM"
                              :desc-position="Constants.SELECTS_POSITION.TOP"
                              :description="'PRIVATE Protocols'"
                              :required="true"
                            ></GSSelects>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </v-tabs-window-item>
                </v-tabs-window>
                <div class="dialog-button-wrap">
                  <div>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.TEXT"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :text="'저장 후 계속 추가'"
                      width="119"
                    ></GSButton>
                  </div>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'닫기'"
                    width="57"
                    @click="closePushDialog"
                  ></GSButton>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :text="'저장하기'"
                    width="81"
                  ></GSButton>
                </div>
              </v-card>
            </v-dialog>
          </v-tabs-window-item>
          <!-- Email -->
          <v-tabs-window-item value="3" class="email">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회목록') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="confirm-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'Email 추가'"
                      @click="openEmailDialog"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="emailHeader"
                :items="emailData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 360px" />
                    <col style="width: 230px" />
                    <col style="width: 230px" />
                    <col style="width: 300px" />
                    <col style="width: 300px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:item.tracking="{ item }">
                  <div class="tracking">
                    <span v-if="item.tracking === true">Y</span>
                    <span v-if="item.tracking === false" class="false">N</span>
                  </div>
                </template>
                <template v-slot:item.modify="{ item }">
                  <GSButton
                    :class="'select-date-today-btn'"
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.TINY"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'수정'"
                    width="50"
                  ></GSButton>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />관리자에게 문의해주세요.</p>
                  </div>
                </template>
              </v-data-table>
              <Pagination :dataLength="emailData.length"></Pagination>
            </div>
            <v-dialog v-model="emailDialog" class="gsr-dialog service-add-layer" width="444">
              <v-card>
                <div class="dialog-title-wrap">
                  <strong class="dialog-title">Email 추가</strong>
                  <div class="dialog-close-btn-box">
                    <v-btn class="dialog-close-btn" @click="closeEmailDialog"></v-btn>
                  </div>
                </div>
                <div class="dialog-contents-wrap">
                  <div class="info-area">
                    <GSInput
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.SMALL"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'보내는 사람'"
                      :required="true"
                      :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
                    ></GSInput>
                    <GSInput
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.SMALL"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'보내는 메일'"
                      :required="true"
                      :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
                    ></GSInput>
                    <GSInput
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.SMALL"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'반환 메일'"
                      :required="true"
                      :iconPosition="Constants.INPUT_ICONPOSITION.RIGHT"
                    ></GSInput>
                    <GSSwitches
                      :size="Constants.SWITCHES_SIZES.LARGE"
                      :desc-position="Constants.SWITCHES_POSITION.TOP"
                      :description="'트래킹 여부'"
                      :required="true"
                    ></GSSwitches>
                    <GSSelects
                      :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                      :size="Constants.SELECTS_SIZES.SMALL"
                      :desc-position="Constants.SELECTS_POSITION.TOP"
                      :description="'유입 성과분석'"
                      :required="true"
                    ></GSSelects>
                  </div>
                </div>
                <div class="dialog-button-wrap">
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'닫기'"
                    width="57"
                    @click="closeEmailDialog"
                  ></GSButton>
                  <GSButton
                    :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                    :size="Constants.BUTTON_SIZES.SMALL"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :text="'저장하기'"
                    width="81"
                  ></GSButton>
                </div>
              </v-card>
            </v-dialog>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">


import { ref } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSInput from '@/components/ui/GSInput.vue'
import GSSwitches from '@/components/ui/GSSwitches.vue'
import GSFileinput from '@/components/ui/GSFileinput.vue'
import GSImages from '@/components/ui/GSImages.vue'
import { useCommonI18n } from '@/core/plugins/global-util'


const { translate } = useCommonI18n()


const tab = ref(0)
const dialogTab = ref(0)

const smsDialog = ref(false)
const openSmsDialog = () => {
  smsDialog.value = true
}
const closeSmsDialog = () => {
  smsDialog.value = false
}

const kakaotalkDialog = ref(false)
const openKakaotalkDialog = () => {
  kakaotalkDialog.value = true
}
const closeKakaotalkDialog = () => {
  kakaotalkDialog.value = false
}

const pushDialog = ref(false)
const openPushDialog = () => {
  pushDialog.value = true
}
const closePushDialog = () => {
  pushDialog.value = false
}

const emailDialog = ref(false)
const openEmailDialog = () => {
  emailDialog.value = true
}
const closeEmailDialog = () => {
  emailDialog.value = false
}

const smsHeader = [
  {
    align: 'start',
    title: '보낸 사람',
    value: 'from'
  },
  {
    align: 'start',
    title: '발신번호',
    value: 'callingNumber'
  },
  {
    align: 'center',
    title: '트래킹 여부',
    value: 'tracking'
  },
  {
    align: 'center',
    title: '유입 성과분석',
    value: 'analysis'
  },
  {
    align: 'center',
    title: '정보 수정',
    value: 'modify'
  }
]
const smsData = [
  {
    from: 'GS리테일',
    callingNumber: '1899-7777',
    tracking: true,
    analysis: '한 달'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '060-1234-5678',
    tracking: true,
    analysis: '2주'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '060-1234-5678',
    tracking: false,
    analysis: '2주'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '060-1234-5678',
    tracking: true,
    analysis: '2주'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '02-999-7777',
    tracking: true,
    analysis: '1주'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '1899-7777',
    tracking: false,
    analysis: '한 달'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '1899-7777',
    tracking: true,
    analysis: '한 달'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '02-999-7777',
    tracking: true,
    analysis: '한 달'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '02-999-7777',
    tracking: true,
    analysis: '1년'
  },
  {
    from: 'GS리테일 본사',
    callingNumber: '1899-7777',
    tracking: true,
    analysis: '1년'
  }
]
const kakaotalkHeader = [
  {
    align: 'start',
    title: '보낸 사람',
    value: 'from'
  },
  {
    align: 'start',
    title: '발신채널 Key',
    value: 'key'
  },
  {
    align: 'center',
    title: '트래킹 여부',
    value: 'tracking'
  },
  {
    align: 'center',
    title: '유입 성과분석',
    value: 'analysis'
  },
  {
    align: 'center',
    title: '정보 수정',
    value: 'modify'
  }
]
const kakaotalkData = [
  {
    from: '@GS리테일',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '한 달'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '2주'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: false,
    analysis: '2주'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '2주'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '1주'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: false,
    analysis: '한 달'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '한 달'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '한 달'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '1년'
  },
  {
    from: '@GS리테일 본사',
    key: 'Ekfvneffef#3$5dfevnglkdjflef',
    tracking: true,
    analysis: '1년'
  }
]
const pushHeader = [
  {
    align: 'start',
    title: '휴대폰 운영체제'
  },
  {
    align: 'start',
    title: '발송 서비스'
  }
]
const pushData = [
  {
    system: 'IOS/AOS',
    service: '우리동네GS',
    info: {
      appName: '우리동네GS',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: 'GS SHOP',
    info: {
      appName: 'GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'iOS',
    service: 'GS SHOP',
    info: {
      appName: 'GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: '우리동네GS / GS SHOP',
    info: {
      appName: '우리동네GS / GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: '우리동네GS / GS SHOP',
    info: {
      appName: '우리동네GS / GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: 'GS SHOP',
    info: {
      appName: 'GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: 'GS SHOP',
    info: {
      appName: 'GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: 'GS SHOP',
    info: {
      appName: 'GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: '우리동네GS / GS SHOP',
    info: {
      appName: '우리동네GS / GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  },
  {
    system: 'IOS/AOS',
    service: 'GS SHOP',
    info: {
      appName: 'GS SHOP',
      time: '00:00:00 ~ 23:59:59',
      tracking: true,
      belonging: 'Months'
    },
    detail: {
      osPrimary: 'IOS',
      certificateType: 'P8',
      certificateFileName: 'apns_AutheKey_9SH89H586U_YU3PLD3493(3).p8KEY ID',
      teamID: '9SH89H586U',
      keyID: 'YU3PLD3493',
      appBundleID: 'com.gsretail.gscvs',
      pushSound: 'Default',
      badge: '사용',
      appKeyPrimary: '0539af54be29480e8b80',
      osSecondary: 'AOS',
      apiKeyPrimary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeySecondary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      apiKeyTertiary:
        'AAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGDAAAAP4IMYdj:APA91bGD',
      gcmProjectNum: '272764789209',
      privateUsing: true,
      privateProtocol: 'TCP',
      appKeySecondary: '4273035233a744218e11'
    }
  }
]
const emailHeader = [
  {
    align: 'start',
    title: '보낸 사람',
    value: 'from'
  },
  {
    align: 'start',
    title: '발신메일',
    value: 'outgoingMail'
  },
  {
    align: 'start',
    title: '반환메일',
    value: 'returnMail'
  },
  {
    align: 'center',
    title: '트래킹 여부',
    value: 'tracking'
  },
  {
    align: 'center',
    title: '유입 성과분석',
    value: 'performanceAnalysis'
  },
  {
    align: 'center',
    title: '정보 수정',
    value: 'modify'
  }
]
const emailData = [
  {
    from: 'GS리테일',
    outgoingMail: 'master@GSRetail.com',
    returnMail: 'master@GSRetail.com',
    tracking: true,
    performanceAnalysis: '한 달'
  },
  {
    from: 'GS리테일 본사',
    outgoingMail: 'mail_master@gsretail.com',
    returnMail: 'mail_master@gsretail.com',
    tracking: true,
    performanceAnalysis: '2주'
  },
  {
    from: 'GS리테일',
    outgoingMail: 'master@GSRetail.com',
    returnMail: 'master@GSRetail.com',
    tracking: false,
    performanceAnalysis: '2주'
  },
  {
    from: 'GS리테일',
    outgoingMail: 'master@GSRetail.com',
    returnMail: 'master@GSRetail.com',
    tracking: true,
    performanceAnalysis: '2주'
  },
  {
    from: 'GS리테일 본사',
    outgoingMail: 'mail_master@gsretail.com',
    returnMail: 'mail_master@gsretail.com',
    tracking: true,
    performanceAnalysis: '1주'
  },
  {
    from: 'GS리테일 본사',
    outgoingMail: 'mail_master@gsretail.com',
    returnMail: 'mail_master@gsretail.com',
    tracking: false,
    performanceAnalysis: '한 달'
  },
  {
    from: 'GS리테일 본사',
    outgoingMail: 'mail_master@gsretail.com',
    returnMail: 'mail_master@gsretail.com',
    tracking: true,
    performanceAnalysis: '한 달'
  },
  {
    from: 'GS리테일 본사',
    outgoingMail: 'master@GSRetail.com',
    returnMail: 'master@GSRetail.com',
    tracking: true,
    performanceAnalysis: '한 달'
  },
  {
    from: 'GS리테일',
    outgoingMail: 'master@GSRetail.com',
    returnMail: 'master@GSRetail.com',
    tracking: true,
    performanceAnalysis: '1년'
  },
  {
    from: 'GS리테일',
    outgoingMail: 'master@GSRetail.com',
    returnMail: 'master@GSRetail.com',
    tracking: true,
    performanceAnalysis: '1년'
  }
]
// dialog
const cancelDialog = ref(false)
const openCancelDialog = () => {
  cancelDialog.value = true
}
const closeCancelDialog = () => {
  cancelDialog.value = false
}
</script>
