export const BUTTON_VARIANTS = {
  CONTAINED: 'gsr-btn-contained',
  OUTLINED: 'gsr-btn-outlined',
  TEXT: 'gsr-btn-text'
} as const

export const BUTTON_SIZES = {
  TINY: 'gsr-btn-tiny',
  SMALL: 'gsr-btn-small',
  MEDIUM: 'gsr-btn-medium',
  LARGE: 'gsr-btn-large'
} as const

export const BUTTON_COLORS = {
  PRIMARY: 'gsr-btn-primary',
  SECONDARY: 'gsr-btn-secondary',
  INHERIT: 'gsr-btn-inherit'
} as const

export const BUTTON_ICONPOSITION = {
  DEFAULT: '',
  LEFT: 'gsr-btn-icon-left',
  RIGHT: 'gsr-btn-icon-right',
  BOTH: 'gsr-btn-icon-left gsr-btn-icon-right',
  CENTER: 'gsr-btn-icon-center'
} as const

// 타입 정의
export type ButtonVariant = (typeof BUTTON_VARIANTS)[keyof typeof BUTTON_VARIANTS]
export type ButtonSize = (typeof BUTTON_SIZES)[keyof typeof BUTTON_SIZES]
export type ButtonColor = (typeof BUTTON_COLORS)[keyof typeof BUTTON_COLORS]
export type ButtonIconpositions = (typeof BUTTON_ICONPOSITION)[keyof typeof BUTTON_ICONPOSITION]
