<template>
  <div :class="descPosition" class="gsr-select">
    <div class="label-box">
      <span
        v-if="descPosition != SELECTS_POSITION.DEFAULT && typeof description === 'string'"
        :class="{ required: required }"
        >{{ translate(description) }}
      </span>
    </div>
    <v-select
      v-model="selectedItem"
      :items="itemsList"
      item-title="label"
      item-value="value"
      :class="computedClassName"
      :label="translate(label)"
      :placeholder="placeholder ? translate(placeholder) : ''"
      :open-on-hover="true"
      :rules="mappedRules"
      :error-messages="errorMessage"
      :messages="successMessage"
      v-bind:style="width !== undefined ? { width: width + 'px' } : undefined"
      v-on:input="props.validType === SELECTS_VALIDTYPE.MENUAL ? null : validate()"
      :readonly="props.readonly"
    >
      <template v-if="iconPosition == SELECTS_ICONPOSITION.LEFT" v-slot:prepend-inner>
        <v-icon></v-icon>
      </template>
      <template v-else-if="iconPosition == SELECTS_ICONPOSITION.RIGHT" v-slot:append-inner>
        <v-icon></v-icon>
      </template>
    </v-select>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch, defineEmits, computed } from 'vue'
import {
  SELECTS_VARIANTS,
  SELECTS_SIZES,
  SELECTS_POSITION,
  SELECTS_ICONPOSITION,
  SELECTS_VALIDTYPE,
  SELECTS_VALIDSTYLE
} from '@/constants/selectsConstants'
import type {
  SelectsSizes,
  SelectsVariants,
  SelectsPositions,
  SelectsIconpositions,
  SelectsValidtype,
  SelectsValidstyle
} from '@/constants/selectsConstants'
import { getMappedRules, validationRuleMap } from '@/core/plugins/validationRules'
import { useCommonI18n } from '@/core/plugins/global-util'

interface Item {
  label: string
  value: number | string
}

interface Props {
  variant: SelectsVariants
  size: SelectsSizes
  label?: string
  placeholder?: string
  className?: string | string[]
  itemsList: Item[]
  disbaled?: boolean
  appendIcon?: string
  prependIcon?: string
  description?: string
  iconPosition: SelectsIconpositions
  required?: boolean
  descPosition: SelectsPositions
  validType?: SelectsValidtype | undefined
  validationStyle?: SelectsValidstyle | undefined
  rules: string[]
  showSuccessMessage?: boolean
  width?: number
  defaultValue?: any
  readonly?: boolean
}

const { translate } = useCommonI18n()

const props = withDefaults(defineProps<Props>(), {
  variant: SELECTS_VARIANTS.STANDARD,
  size: SELECTS_SIZES.MEDIUM,
  label: '',
  className: '',
  descPosition: SELECTS_POSITION.DEFAULT,
  iconPosition: SELECTS_ICONPOSITION.DEFAULT,
  showSuccessMessage: false,
  rules: () => [] as string[]
})

const selectedItem = ref<any>(props.defaultValue ?? null)

// 부모컴퍼넌트에서 defaultValue가 변경되었을 때 selectedItem에 적용
watch(
  () => props.defaultValue,
  (newVal) => {
    selectedItem.value = newVal
  }
)
const dynamicClasses = ref<string[]>([])
const error = ref<boolean>(false)
const errorMessage = ref<string>('')
const successMessage = ref<string>('Success')

const mappedRules = computed(() =>
  props.validType == SELECTS_VALIDTYPE.SYNC ? getMappedRules(props.rules) : getMappedRules([''])
)

const computedClassName = computed(() => {
  const baseClassNames = Array.isArray(props.className) ? props.className : [props.className]
  return [...baseClassNames, props.variant, props.size, props.iconPosition, ...dynamicClasses.value]
})

const emit = defineEmits(['update-seleted'])

watch(selectedItem, (newValue, oldValue) => {
  if (props.validType === SELECTS_VALIDTYPE.MENUAL) {
    if (oldValue !== newValue) {
      error.value = false
      errorMessage.value = ''
      removeClass('gsr-selects-msg-text gsr-selects-msg-error')
    }
  }
  emit('update-seleted', newValue)
})

const toggleClasses = (errorClass: string, successClass: string, hasError: boolean) => {
  if (hasError) {
    removeClass(successClass)
    addClass(errorClass)
  } else {
    removeClass(errorClass)
    if (props.showSuccessMessage) {
      addClass(successClass)
    }
  }
}

const validate = (): boolean => {
  for (const rule of props.rules) {
    const validationFn = validationRuleMap[rule]
    if (validationFn) {
      if (selectedItem.value !== null) {
        const result = validationFn(selectedItem.value.value)
        if (typeof result === 'string') {
          error.value = true
          errorMessage.value = result

          const errorClass =
            props.validationStyle === SELECTS_VALIDSTYLE.BASIC
              ? 'gsr-selects-msg-text gsr-selects-msg-error'
              : 'gsr-selects-msg-card gsr-selects-msg-error'
          const successClass =
            props.validationStyle === SELECTS_VALIDSTYLE.BASIC
              ? 'gsr-selects-msg-text gsr-selects-msg-success'
              : 'gsr-selects-msg-card gsr-selects-msg-success'

          toggleClasses(errorClass, successClass, true)
          return false
        }
      }
    }
  }

  error.value = false
  errorMessage.value = ''

  const errorClass =
    props.validationStyle === SELECTS_VALIDSTYLE.BASIC
      ? 'gsr-selects-msg-text gsr-selects-msg-error'
      : 'gsr-selects-msg-card gsr-selects-msg-error'
  const successClass =
    props.validationStyle === SELECTS_VALIDSTYLE.BASIC
      ? 'gsr-selects-msg-text gsr-selects-msg-success'
      : 'gsr-selects-msg-card gsr-selects-msg-success'

  toggleClasses(errorClass, successClass, false)
  return true
}

const addClass = (className: string) => {
  if (!dynamicClasses.value.includes(className)) {
    dynamicClasses.value.push(className)
  }
}

const removeClass = (className: string) => {
  dynamicClasses.value = dynamicClasses.value.filter((cls) => cls !== className)
}

defineExpose({
  validate
})
</script>
