<template>
  <div class="gsr-search">
    <v-text-field
      v-model="search"
      class="search-bar"
      :label="translate('Search')"
      clearable
      append-inner-icon="mdi-magnify"
      solo-inverted
      rounded
      hide-details
      @click:append-inner="onSearch"
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed, ref } from 'vue'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()

const search = ref('')
const onSearch = () => {
  console.log('Search executed with:', search.value)
}
</script>
