<template>
  <!-- 조직 -->
  <div class="organization">
    <PageTitle :title="'조직'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('기본정보') }}
          </strong>
        </div>
      </div>
      <div class="contents-profile-wrap">
        <div class="contents-profile-left">
          <v-avatar class="square">
            <img src="/src/assets/images/organization_profile.jpg" alt="" />
          </v-avatar>
          <div class="contents-profile-text">
            <span class="state">이용중</span>
            <span class="title">GS리테일</span>
            <span class="sub-title">법인</span>
          </div>
        </div>
        <div class="contents-profile-right">
          <div class="contents-profile-info">
            <span>오디언스</span>
            <p>1,000K</p>
          </div>
          <div class="contents-profile-info">
            <span>구성원</span>
            <p>25</p>
          </div>
          <div class="contents-profile-info">
            <span>이용서비스</span>
            <p>2</p>
          </div>
        </div>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-tabs-wrap">
        <v-tabs height="44" v-model="tab">
          <v-tab width="64">상세정보</v-tab>
          <v-tab width="50">서비스</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <!-- 상세정보 -->
          <v-tabs-window-item value="0" class="detail">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조직정보') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="edit-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'편집'"
                      width="67"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <!-- 조직 정보 table -->
            <div class="contents-data-form-wrap">
              <v-data-table hide-default-header hide-default-footer>
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 220px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:body="props">
                  <tr v-for="(item, index) in detailData" :key="index">
                    <th>
                      <span :class="item.required ? 'required' : ''">{{ item.title }}</span>
                    </th>
                    <td>
                      <span v-if="item.text" :class="item.active ? 'active' : ''">{{
                        item.text
                      }}</span>
                      <ul v-if="item.orgPresidentList" class="info-list">
                        <li v-for="(subItem, subIndex) in item.orgPresidentList" :key="subIndex">
                          <span>{{ subItem.name }}</span>
                          <span>{{ subItem.email }}</span>
                          <div v-if="subItem.active" class="state active">
                            <span>이용중</span>
                          </div>
                          <div v-else class="state">
                            <span>이용안함</span>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <!-- 조직 정보 edit -->
            <div class="contents-data-form-wrap">
              <v-data-table hide-default-header hide-default-footer>
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 220px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:body>
                  <tr>
                    <th>
                      <span>기본정보</span>
                    </th>
                    <td>
                      <GSImages></GSImages>
                      <GSInput
                        :variant="Constants.INPUT_VARIANTS.OUTLINED"
                        :size="Constants.INPUT_SIZES.MEDIUM"
                        :desc-position="Constants.INPUT_POSITION.TOP"
                        :description="'조직명'"
                        :required="true"
                        style="margin-top: 16px; width: 800px"
                      ></GSInput>
                      <GSInput
                        :variant="Constants.INPUT_VARIANTS.OUTLINED"
                        :size="Constants.INPUT_SIZES.MEDIUM"
                        :desc-position="Constants.INPUT_POSITION.TOP"
                        :description="'Slag'"
                        :required="true"
                        style="margin-top: 16px; width: 800px"
                      ></GSInput>
                      <GSSwitches
                        :size="Constants.SWITCHES_SIZES.LARGE"
                        :desc-position="Constants.SWITCHES_POSITION.TOP"
                        :description="'상태 활성화'"
                        :required="true"
                        style="margin-top: 16px"
                      ></GSSwitches>
                      <GSInput
                        :variant="Constants.INPUT_VARIANTS.OUTLINED"
                        :size="Constants.INPUT_SIZES.MEDIUM"
                        :desc-position="Constants.INPUT_POSITION.TOP"
                        :description="'설명'"
                        style="margin-top: 16px"
                      ></GSInput>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>유형</span>
                    </th>
                    <td>
                      <span>{{ detailEditData.type }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>생성일</span>
                    </th>
                    <td>
                      <span>{{ detailEditData.creationDate }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="required">조직 구성원</span>
                    </th>
                    <td>
                      <v-autocomplete
                        v-model="members"
                        class="gsr-chips-autocomplete"
                        :items="organizationMembers"
                        item-title="name"
                        clearable
                        chips
                        closable-chips
                        multiple
                        placeholder="조직 구성원 검색"
                        :menu-props="{ class: 'gsr-chips-autocomplete-layer' }"
                      >
                        <template v-slot:chip="{ props, item }">
                          <v-chip
                            v-bind="props"
                            :class="item.raw.active === true ? 'active' : 'deactivate'"
                          >
                            {{ item.raw.name }} &lt;{{ item.raw.id }}@gsretail.com&gt;
                            <i class="state-icon"></i>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props">
                            <div class="org-member-info">
                              <v-avatar :image="item.raw.img" transition=""></v-avatar>
                              <div class="org-member-info-text">
                                <p class="text">{{ item.raw.name }} / {{ item.raw.org }}</p>
                                <p class="sub-text">({{ item.raw.id }}) {{ item.raw.position }}</p>
                              </div>
                            </div>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                      <p>조직 대표는 ‘설명, 조직 구성원’ 항목만 수정할 수 있습니다.</p>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div class="contents-btn-wrap">
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.MEDIUM"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'취소'"
                  width="100"
                ></GSButton>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                  :size="Constants.BUTTON_SIZES.MEDIUM"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :text="'저장'"
                  width="100"
                ></GSButton>
              </div>
            </div>
          </v-tabs-window-item>
          <!-- 서비스 -->
          <v-tabs-window-item value="1" class="service">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회목록') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
              <div class="contents-sub-title-button">
                <v-checkbox
                  label="이용 중인 서비스만 보기"
                  class="gsr-checkbox-medium gsr-checkbox-primary"
                ></v-checkbox>
                <v-list>
                  <v-list-item>
                    <GSButton
                      class="confirm-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'선택삭제'"
                      width="73"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      class="add-btn"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'서비스 설정'"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="serviceHeader"
                :items="serviceData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 280px" />
                    <col style="width: 540px" />
                    <col style="width: 459px" />
                    <col style="width: 160px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:item.serviceName="{ item }">
                  <div class="service-name">
                    <v-avatar>
                      <img :src="item.img" alt="" />
                    </v-avatar>
                    <span>{{ item.name }}</span>
                  </div>
                </template>
                <template v-slot:item.modify="{ item }">
                  <GSButton
                    :class="'select-date-today-btn'"
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.TINY"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :text="'수정'"
                    width="70"
                  ></GSButton>
                </template>
                <template v-slot:item.channel="{ item }">
                  <div class="channel">
                    <span v-if="item.kakaotalk" class="kakaotalk">Kakao Talk</span>
                    <span v-if="item.push" class="push">PUSH</span>
                    <span v-if="item.sms" class="sms">SMS / LMS / MMS</span>
                    <span v-if="item.email" class="email">Email</span>
                  </div>
                </template>
                <template v-slot:item.state="{ item }">
                  <span class="state active" v-if="item.state === true">이용중</span>
                  <span class="state" v-if="item.state === false">이용안함</span>
                </template>
                <template v-slot:no-data>
                  <div class="no-data-msg">
                    <p>검색결과가 없습니다.<br />서비스를 설정해 주세요.</p>
                  </div>
                </template>
              </v-data-table>
              <Pagination :dataLength="serviceData.length"></Pagination>
            </div>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSInput from '@/components/ui/GSInput.vue'
import GSSwitches from '@/components/ui/GSSwitches.vue'

const { translate } = useCommonI18n()

const tab = ref(0)
const members = ref()
const detailData = [
  { title: '조직명', required: true, text: 'GS리테일' },
  { title: '영문명', required: true, text: 'gsretail' },
  { title: '상태', required: true, text: '이용중', active: true },
  { title: '종류', required: true, text: '법인' },
  {
    title: '조직대표',
    required: true,
    orgPresidentList: [
      { name: '서*수', email: 'seohm1004@gsretail.com', active: true },
      { name: '김*미', email: 'haemi1004@gsretail.com', active: true },
      { name: '조*주', email: 'chomj90@gsretail.com', active: true },
      { name: '박*복', email: 'seohm1004@gsretail.com', active: false }
    ]
  },
  { title: '생성일', text: '2024.03.30 12:20:59' },
  {
    title: '상세설명',
    text: 'GS리테일 통합 메시징 상세소개 텍스트 작성 GS리테일 통합 메시징 상세소개 텍스트 작성GS리테일 통합 메시징 상세소개 텍스트 작성GS리테일 통합 메시징 상세소개 텍스트 작성GS리테일 통합 메시징 상세소개 텍스트 작성GS리테일 통합 '
  }
]
const detailEditData = {
  type: 'Corporate',
  creationDate: '2024.03.30 13:02:58'
}
const serviceHeader = [
  {
    align: 'start',
    title: '서비스명',
    value: 'serviceName'
  },
  {
    align: 'start',
    title: '설명',
    value: 'detail'
  },
  {
    align: 'start',
    title: '발송 채널',
    value: 'channel'
  },
  {
    align: 'center',
    title: '상태',
    value: 'state'
  },
  {
    align: 'center',
    title: '정보 변경',
    value: 'modify'
  }
]
const serviceData = [
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: '우리동네GS ',
    detail: '고객 광고메시지 대량 발송 진행 ',
    kakaotalk: true,
    push: true,
    sms: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS25 ',
    detail: '운영환경, 실제 고객발송',
    kakaotalk: true,
    push: true,
    sms: true,
    email: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_3.jpg',
    name: 'GS SHOP ',
    detail: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    kakaotalk: true,
    push: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_4.jpg',
    name: 'GS THE FRESH ',
    detail: '마케팅팀 500호점 오픈 기념 행사 App Push 진행',
    push: true,
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_5.jpg',
    name: 'GS리테일 통합멤버십 ',
    detail: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    kakaotalk: true,
    push: true,
    sms: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_4.jpg',
    name: '우리동네GS ',
    detail: '마케팅팀 500호점 오픈 기념 행사 App Push 진행',
    kakaotalk: true,
    push: true,
    state: false
  },
  {
    img: '/src/assets/images/workspace_table_3.jpg',
    name: '우리동네GS ',
    detail: 'O4O 기획팀 주관 실시간 정보성 메시지 진행',
    push: true,
    sms: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: '우리동네GS ',
    detail: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    kakaotalk: true,
    push: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_1.jpg',
    name: 'GS GS THE FRESH ',
    detail: '회사 약관동의 메일 발송',
    email: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS리테일',
    detail: '운영환경, 실제 고객발송',
    sms: true,
    email: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS리테일',
    detail: '운영환경, 실제 고객발송',
    sms: true,
    email: true,
    state: true
  },
  {
    img: '/src/assets/images/workspace_table_2.jpg',
    name: 'GS리테일',
    detail: '운영환경, 실제 고객발송',
    sms: true,
    email: true,
    state: true
  }
]
const organizationMembers = [
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미1',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동1',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리1',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미2',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동2',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리2',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미3',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동3',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리3',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미4',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동4',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리4',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미5',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동5',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리5',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미6',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동6',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리6',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미7',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동7',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리7',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미8',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동8',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리8',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미9',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동9',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리9',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  },
  {
    img: '/src/assets/images/users_profile_1.jpg',
    active: true,
    name: '이해미10',
    org: 'DX COE부문',
    id: 'cutie7777',
    position: '본부장'
  },
  {
    img: '/src/assets/images/users_profile_2.jpg',
    active: true,
    name: '김길동10',
    org: '플랫폼DX부문',
    id: 'Turf.haemi1004',
    position: '부문장'
  },
  {
    img: '/src/assets/images/users_profile_3.jpg',
    name: '박빛나리10',
    org: '통합서비스TFT',
    id: 'smile7777',
    position: '팀장'
  }
]

// img upload
const defaultImage = '/src/assets/images/img_register_default.jpg'
const imageUrl = ref<string>(defaultImage)

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (file) {
    imageUrl.value = URL.createObjectURL(file)
  } else {
    imageUrl.value = defaultImage
  }
}

import GSImages from '@/components/ui/GSImages.vue'
</script>
