<template>
  <div :class="descPosition">
    <div class="label-box">
      <span
        :class="{ required: required }"
        v-if="descPosition != AUTOCOMPLETES_POSITION.DEFAULT && typeof description === 'string'"
        >{{ translate(description) }}</span
      >
    </div>
    <v-autocomplete
      :class="computedClassName"
      v-model="selectedObj"
      :clearable="true"
      :items="itemList"
      :item-title="dynamicLabel"
      :item-value="dynamicValue"
      :placeholder="placeholder ? translate(placeholder) : ''"
      v-bind:style="width !== undefined ? { width: width + 'px' } : undefined"
      no-data-text="검색결과가 없습니다."
      return-object
      @click:clear="onClear"
      @keydown="onKeydown"
    ></v-autocomplete>
  </div>
</template>
<script lang="ts" setup>
// S: 다국어

import { defineProps, ref, watch, defineEmits, computed, type ComponentPublicInstance } from 'vue'
import {
  AUTOCOMPLETES_VARIANTS,
  AUTOCOMPLETES_SIZES,
  AUTOCOMPLETES_POSITION,
  AUTOCOMPLETES_VALIDTYPE,
  AUTOCOMPLETES_VALIDSTYLE
} from '@/constants/autocompletesConstants'
import type {
  AutocompletesSizes,
  AutocompletesVariants,
  AutocompletesPositions,
  AutocompletesValidtype,
  AutocompletesValidstyle
} from '@/constants/autocompletesConstants'
import { getMappedRules, validationRuleMap } from '@/core/plugins/validationRules'
import { useCommonI18n } from '@/core/plugins/global-util'



interface Props {
  variant: AutocompletesVariants
  size: AutocompletesSizes
  label?: string
  placeholder?: string
  className?: string | string[]
  itemList: object[]
  titleString: string
  valueString: string
  disbaled?: boolean
  appendIcon?: string
  prependIcon?: string
  description?: string
  required?: boolean
  descPosition: AutocompletesPositions
  validType?: AutocompletesValidtype | undefined
  validationStyle?: AutocompletesValidstyle | undefined
  rules: string[]
  showSuccessMessage?: boolean
  width?: number
}

const props = withDefaults(defineProps<Props>(), {
  variant: AUTOCOMPLETES_VARIANTS.STANDARD,
  size: AUTOCOMPLETES_SIZES.MEDIUM,
  label: '',
  className: '',
  descPosition: AUTOCOMPLETES_POSITION.DEFAULT,
  showSuccessMessage: false,
  rules: () => [] as string[]
})

const { translate } = useCommonI18n()

const dynamicLabel = computed(() => props.titleString)
const dynamicValue = computed(() => props.valueString)

const selectedObj = ref<object | null>(null)

const openSearchOrg = ref(false)
const visibleText = ref('')

const onClickOutside = () => {
  console.log('Clicked outside autocomplete')
  openSearchOrg.value = false
  activeAutocompleteBtn()
}

const onClear = () => {
  visibleText.value = ''
}

const onKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape' || event.keyCode === 27) {
    console.log('ESC key pressed')
    openSearchOrg.value = false
    activeAutocompleteBtn()
    // ESC 키가 눌렸을 때 추가 동작을 여기에 추가
  }
}

const activeAutocompleteBtn = () => {
  const autocompleteBtn = document.querySelectorAll('.gsr-select-autocomplete-btn')
  autocompleteBtn.forEach((item) => {
    const state = item.getAttribute('aria-expanded')
    if (state == 'false') {
      item.classList.add('v-select--active-menu')
    } else {
      item.classList.remove('v-select--active-menu')
    }
  })
}

const computedClassName = computed(() => {
  const baseClassNames = Array.isArray(props.className) ? props.className : [props.className]
  // return [...baseClassNames, props.variant, props.size, ...dynamicClasses.value]
  return [...baseClassNames, props.variant, props.size]
})

/* 검색영역에 값이 set 될때 일어나는 이벤트 */
watch(selectedObj, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    console.log('Selected item has changed from', oldValue, 'to', newValue)
    if (newValue !== null) {
      // visibleText.value = newValue.name
      openSearchOrg.value = false
    }
    // 여기서 원하는 로직을 수행할 수 있습니다.
  }
})
</script>
