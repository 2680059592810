import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import SendingStatistics from '@/views/SendingStatistics.vue'
import Dashboard from '@/views/Dashboard.vue'
// import SendMessage from '@/views/SendMessage.vue'
// import MainDashboard from '@/views/workspace/Dashboard.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/sendingstatistics',
    // redirect: '/common/workspace',
    // component: () => import('@/layouts/main/MainLayout.vue'),
    // component: () => import('@/layouts/Layout.vue'),
    component: () => import('@/layouts/Layout.vue'),
    children: [
      {
        path: '/sendingstatistics',
        name: 'sendingstatistics',
        component: SendingStatistics,
        meta: {
          pageTitle: 'Sending Statistics',
          breadcrumbs: [],
          requiresAuth: true
        }
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          pageTitle: 'Dashboard',
          breadcrumbs: [],
          requiresAuth: true
        }
      }
    ]
  }
  // {
  //   path: '/sendmsg',
  //   // redirect: '/common/workspace',
  //   // component: () => import('@/layouts/main/MainLayout.vue'),
  //   // component: () => import('@/layouts/Layout.vue'),
  //   component: () => import('@/layouts/Layout.vue'),
  //   children: [
  //     {
  //       path: '/sendmsg',
  //       name: 'sendmsg',
  //       component: SendMessage,
  //       meta: {
  //         pageTitle: 'Send Message',
  //         breadcrumbs: [],
  //         requiresAuth: true
  //       }
  //     }
  //   ]
  // }
  // {
  //   path: '/dashboard',
  //   component: () => import('@/layout/Layout.vue'),
  //   children: [
  //     {
  //       path: '/dashboard',
  //       name: 'maindashboard',
  //       component: MainDashboard,
  //       meta: {
  //         pageTitle: 'maindashboard'
  //       }
  //     }
  //   ]
  // }
]

export default routes
