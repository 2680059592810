<template>
  <div class="sending-statistics">
    <!-- 타이틀 -->
    <div class="contents-title-wrap">
      <strong class="contents-title">{{ translate('발송통계') }}</strong>
    </div>
    <!-- 검색 영역 -->
    <div class="data-table-search-area">
      <div class="search-option-area">
        <!-- 검색 - 조직 -->
        <!-- 검색 영역 autocomplete -->
        <div class="gsr-select-autocomplete">
          <div class="label-box">
            <span class="required">{{ translate('조직') }}</span>
          </div>

          <v-menu
            v-model="openSearchOrg"
            :close-on-content-click="false"
            transition="fade-transition"
            @update:modelValue="handleOrgMenuOpen"
          >
            <template #activator="{ props }">
              <v-btn v-bind="props" class="gsr-select-autocomplete-activator">
                <span>{{ selectedOrganization?.organization_name }}</span>
              </v-btn>
            </template>
            <v-card v-click-outside="onClickOutsideOrg">
              <v-autocomplete
                v-if="showOrgAutocomplete"
                ref="organizationListRef"
                v-model="selectedOrganization"
                :offset-y="true"
                transition="slide-y-transition"
                menu
                :clearable="true"
                :items="organizationList"
                :placeholder="translate('조직명 입력')"
                :close-on-content-click="false"
                class="gsr-select-autocomplete-search"
                :menu-props="{ class: 'gsr-select-autocomplete-search-list' }"
                item-title="organization_name"
                item-value="organization_id"
                :no-data-text="translate('검색결과가 없습니다.')"
                return-object
                :open-text="selectedOrganization?.organization_name"
                @keydown="onKeydownOrg"
              >
                <template v-slot:prepend-inner>
                  <span class="title">{{ translate('조직 검색') }}</span>
                </template>
              </v-autocomplete>
            </v-card>
          </v-menu>
        </div>
        <!-- 검색 - 기간 -->
        <div class="option-date">
          <div class="label-box">
            <span class="label required">{{ translate('기간') }}</span>
          </div>
          <v-btn-toggle v-model="selectedPeriod" mandatory class="date-range-btn-toggle">
            <v-btn
              v-for="(item, index) in datePeriod"
              :key="item.value"
              :value="item.value"
              :class="{ active: activeIndexDateBtn === index }"
              @click="selectedPeriodBtn(item.value, index)"
              :disabled="isDisabledDateBtn"
            >
              {{ item.text }}
            </v-btn>
          </v-btn-toggle>
          <GSDatepicker
            :startDate="searchStartDate"
            :endDate="searchEndDate"
            @update-start-date="($event) => (searchStartDate = $event)"
            @update-end-date="($event) => (searchEndDate = $event)"
          ></GSDatepicker>
        </div>

        <!-- 검색 - workspace -->
        <div class="gsr-select-autocomplete">
          <div class="label-box">
            <span>{{ translate('Workspace') }}</span>
          </div>

          <v-menu
            v-model="openSearchWorkspace"
            class="gsr-select-autocomplete"
            :close-on-content-click="false"
            @update:modelValue="handleMenuOpenWorkspace"
          >
            <template #activator="{ props }">
              <v-btn v-bind="props" class="gsr-select-autocomplete-activator">
                <span>{{ selectedWorkspace?.workspace_name }}</span>
              </v-btn>
            </template>
            <v-card v-click-outside="onClickOutsideWorkspace">
              <v-autocomplete
                v-if="showAutocomplete"
                ref="refWorkspaceList"
                v-model="selectedWorkspace"
                :offset-y="true"
                transition="slide-y-transition"
                menu
                :clearable="true"
                :items="workspaceList"
                :placeholder="translate('검색어 입력')"
                class="gsr-select-autocomplete-search"
                :menu-props="{ class: 'gsr-select-autocomplete-search-list' }"
                :close-on-content-click="false"
                item-title="workspace_name"
                item-value="workspace_id"
                :no-data-text="translate('검색결과가 없습니다.')"
                return-object
                :open-text="selectedWorkspace?.workspace_name"
                @keydown="onKeydownWorkspace"
              >
                <template v-slot:prepend-inner>
                  <span class="title">{{ translate('Workspace 검색') }}</span>
                </template>
              </v-autocomplete>
            </v-card>
          </v-menu>
        </div>
        <!-- 검색 - 채널 -->
        <div class="option-select">
          <div class="label-box">
            <span class="label">{{ translate('채널') }}</span>
          </div>

          <GSSelects
            ref="refChannel"
            v-model="selectedChannel"
            :variant="Constants.SELECTS_VARIANTS.OUTLINED"
            :size="Constants.SELECTS_SIZES.MEDIUM"
            :desc-position="Constants.SELECTS_POSITION.TOP"
            required
            :itemsList="channelList"
            :defaultValue="selectedChannel"
            @update-seleted="(value) => (selectedChannel = value)"
          ></GSSelects>
        </div>
      </div>
      <div class="search-btn-area">
        <!-- 리셋 버튼 -->
        <GSButton
          class="reset-btn"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
          :width="32"
          @click="refreshData"
        ></GSButton>
        <!-- 조회 버튼 -->
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'조회'"
          :width="128"
          @click="searchData"
        ></GSButton>
      </div>

      <GSAlert
        v-if="searchErrDialog"
        alertType="iconTitle"
        alertIconType="warning"
        alertTitle="1개월 이내로 설정하여 조회해 주세요."
        isAlertPrimaryBtn
        btnPrimaryText="확인"
        @action="() => (searchErrDialog = false)"
      ></GSAlert>
    </div>
    <!-- 조회 영역 -->
    <div class="data-table-area">
      <v-container>
        <v-row>
          <v-col>
            <!-- 조회 - 사용현황 -->
            <div class="data-table-contents state">
              <div class="contents-sub-title-wrap">
                <div class="contents-sub-title-text">
                  <strong class="contents-sub-title">
                    {{ translate('사용현황') }}
                  </strong>
                  <p class="contents-sub-title-detail" v-if="totalCostInfo !== null">
                    {{ totalInfoStartDate }} {{ totalInfoEndDate && ` ~ ${totalInfoEndDate}` }}
                    {{ ' 기준' }}
                  </p>
                </div>
              </div>
              <!-- data -->
              <v-list v-if="totalCostInfo !== null">
                <!-- 사용요금 -->
                <v-list-item>
                  <div class="result-title">
                    <strong class="name charge"> {{ translate('사용요금') }} </strong>
                    <v-btn class="tooltip-btn">
                      <v-tooltip
                        class="tooltip-layer"
                        activator="parent"
                        location="bottom start"
                        origin="auto"
                        scroll-strategy="reposition"
                      >
                        <div class="tooltip-area">
                          <strong class="tooltip-title">{{ translate('사용요금') }}</strong>
                          <p class="tooltip-sub-title">
                            기간별 사용요금은 실제 청구금액과 동일하게 계산하여 제공됩니다.
                          </p>
                          <div class="tooltip-sub-area">
                            <ul class="tooltip-detail-list">
                              <li>
                                <p>사용요금 계산 방법 : <span>부가세 포함된 사용요금/1.1</span></p>
                                <p class="detail">소수점 반올림(5부터 올림)</p>
                              </li>
                              <li>
                                <p>
                                  부가세 포함된 사용금액 :
                                  <span
                                    >((성공 건수*채널 요금)+(대체 성공*대체 채널 요금))+((성공
                                    건수*채널 요금)+(대체 성공*대체 채널 요금))*10%)</span
                                  >
                                </p>
                                <p class="detail">원단위 이하 절사</p>
                              </li>
                              <li>
                                <strong>e.g. LMS 기준</strong>
                                <p>성공 건수 = 376건</p>
                                <p>사용요금 = 376* 23.637= 8,887.512원</p>
                                <p>부가세 = 8,887.512 * 10% = 888.7512원</p>
                                <p>청구금액 (vat 포함) = 8,887.512+888.7512=9,776.2632원</p>
                                <p class="price">→ 9,770원 (원단위 이하 절사)</p>
                                <p>청구금액 (vat 제외) = 9,770/1.1= 8,881.818182원</p>
                                <p class="price">→ 8,882원 (소수점 반올림으로 5부터 올림)</p>
                                <strong class="total">사용요금 = 8,882원</strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </v-tooltip>
                    </v-btn>
                  </div>
                  <div class="result">
                    <span class="num">{{ formatNumberWithComma(totalCostInfo.total_cost) }}</span>
                    <span class="unit">{{ translate('원') }}</span>
                  </div>
                </v-list-item>
                <v-list-item>
                  <div class="result-title">
                    <strong class="name send"> {{ translate('발송건수') }} </strong>
                  </div>
                  <div class="result">
                    <span class="num">{{
                      formatNumberWithComma(totalCostInfo.total_send_count)
                    }}</span>
                    <span class="unit">{{ translate('건') }}</span>
                  </div>
                </v-list-item>
                <v-list-item>
                  <div class="result-title">
                    <strong class="name success">
                      {{ translate('성공건수') }}
                      <span> {{ translate('(대체성공 포함)') }} </span>
                    </strong>
                  </div>
                  <div class="result">
                    <span class="num">{{
                      formatNumberWithComma(totalCostInfo.total_success_count)
                    }}</span>
                    <span class="unit">{{ translate('건') }}</span>
                  </div>
                </v-list-item>
              </v-list>
              <!-- no-data -->
              <GSNoData
                v-else
                text="검색결과가 없습니다."
                subText="기간 및 채널을 조회해 주세요."
              />
            </div>
            <!-- 조회 - 조회내역 -->
            <div class="data-table-contents data-table">
              <div class="contents-sub-title-wrap">
                <div class="contents-sub-title-text">
                  <strong class="contents-sub-title">
                    {{ translate('조회내역') }}
                    <span>{{ formatNumberWithComma(searchResultCnt) }}</span
                    >{{ translate('건') }}
                  </strong>
                  <!-- tooltip -->
                  <v-btn class="tooltip-btn">
                    <v-tooltip
                      class="tooltip-layer"
                      activator="parent"
                      location="bottom start"
                      origin="auto"
                      scroll-strategy="reposition"
                    >
                      <div class="tooltip-area">
                        <strong class="tooltip-title">{{ translate('조회내역') }}</strong>
                        <p class="tooltip-sub-title">
                          발송일자의 채널별 발송통계를 확인할 수 있습니다.
                        </p>
                        <div class="tooltip-sub-area">
                          <ul class="tooltip-detail-list">
                            <li>
                              <p>사용 요금 : <span>성공 건수*채널 요금</span></p>
                              <p class="detail">
                                사용 요금은 소수점 자릿수 그대로 표현되며, 끝자리가 0인 경우 노출
                                제외됩니다
                              </p>
                              <p class="detail">
                                발송일 기준으로 성공 건수에 채널 요금이 가산되어 집계됩니다.<br />
                                e.g. 9/1 23:59:58 발송 후 9/2 00:00:01에 메시지 수신할 경우 9/1 성공
                                건수로 집계됩니다.
                              </p>
                            </li>
                            <li>
                              <div class="paragraph">
                                <p>발송건수 : 전체 발송 건수</p>
                                <p>성공건수 : 발송 성공 건수</p>
                                <p>실패건수 : 발송 실패 건수</p>
                                <p>
                                  미확인 건수 : 통신사로부터 발송 성공 및 실패여부가 확인되지 않은
                                  건수
                                </p>
                              </div>
                              <p class="detail">
                                미확인 건수는 발송일자 기준 24시간 이후부터 확인 가능하며 수신자
                                위치, 단말기의 On/Off 상태 등에 따라 확인이 지연될 수 있습니다.
                              </p>
                            </li>
                            <li>
                              <div class="paragraph">
                                <p>
                                  대체채널 : <span>발송 실패 시 다른 채널로 대체하여 발송</span>
                                </p>
                                <p>대체 채널 요금 : <span>대체 성공*대체 채널 요금</span></p>
                              </div>
                              <p class="detail">
                                대체 채널 요금은 소수점 자릿수 그대로 표현되며, 끝자리 0인 경우 노출
                                제외됩니다.
                              </p>
                              <p class="detail">
                                발송일 기준으로 대체 성공 건수에 대체 채널 요금이 가산되어
                                집계됩니다. e.g. 9/1 23:59:58 발송 후 9/2 00:00:01에 대체 메시지
                                수신할 경우 9/1 대체 성공 건수로 집계됩니다.
                              </p>
                            </li>
                            <li>
                              <div class="paragraph">
                                <p>
                                  대체 성공 :
                                  <span>채널 발송 실패 시 다른 채널로 대체 발송 시 성공 건수</span>
                                </p>
                                <p>
                                  대체 실패 :
                                  <span>채널 발송 실패 시 다른 채널로 대체 발송 시 실패 건수</span>
                                </p>
                                <p>성공률 : <span>{(성공 건수+대체 성공)/발송 건수}*100</span></p>
                              </div>
                              <p class="detail">소수점 발생 시 절사하여 표기됩니다.</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </v-tooltip>
                  </v-btn>
                </div>
                <div class="contents-sub-title-button">
                  <ul>
                    <li>
                      <GSSelects
                        :defaultValue="10"
                        :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                        :size="Constants.SELECTS_SIZES.SMALL"
                        style="width: 120px"
                        :itemsList="itemsPerList"
                        @update-seleted="(value) => (itemsPerPage = value)"
                      />
                    </li>
                    <li>
                      <GSButton
                        class="excel-btn"
                        :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                        :size="Constants.BUTTON_SIZES.SMALL"
                        :color="Constants.BUTTON_COLORS.SECONDARY"
                        :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                        :text="translate('엑셀 다운로드')"
                        :width="121"
                        @click="downloadExcel"
                      />
                    </li>
                  </ul>
                </div>
                <!-- <div class="data-table-util">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerList"
                    item-title="text"
                    item-value="value"
                  ></v-select>
                  <v-btn flat @click="downloadExcel">
                    {{ translate('엑셀 다운로드') }}
                  </v-btn>
                </div> -->
              </div>
              <GSAlert
                v-if="excelDialog"
                alertType="nonTitle"
                alertTitle="조회된 내역이 없습니다. 엑셀 다운로드는 조회된 데이터가 있을 때만 가능합니다.
                    조건을 다시 설정하여 조회해 주세요."
                isAlertPrimaryBtn
                btnPrimaryText="확인"
                @action="() => (excelDialog = false)"
              ></GSAlert>

              <v-data-table
                :headers="dataTableHeader"
                :items="staticList"
                :items-per-page="itemsPerPage"
                hide-default-footer
                :fixed-header="true"
                :class="dataTableHeader.length > 12 ? 'scroll-x' : ''"
                v-model:page="currentPage"
                @page-count="handlePageCount"
                :sort-by="[{ key: 'log_date', order: 'desc' }]"
              >
                <template #no-data>
                  <p>{{ translate('해당 내역이 없습니다.') }}</p>
                </template>
                <template #[`item.cost`]="{ item }">
                  <span>{{ formatNumberWithComma(item.cost) }}</span>
                </template>
                <template #[`item.send_count`]="{ item }">
                  <span>{{ formatNumberWithComma(item.send_count) }}</span>
                </template>
                <template #[`item.success_count`]="{ item }">
                  <span>{{ formatNumberWithComma(item.success_count) }}</span>
                </template>
                <template #[`item.fail_count`]="{ item }">
                  <span>{{ formatNumberWithComma(item.fail_count) }}</span>
                </template>
                <template #[`item.unconfirmed_count`]="{ item }">
                  <span>{{ formatNumberWithComma(item.unconfirmed_count) }}</span>
                </template>
                <template #[`item.substitute_fail_count`]="{ item }">
                  <span>{{ formatNumberWithComma(item.substitute_fail_count) }}</span>
                </template>
                <template #[`item.success_rate`]="{ item }">
                  <span>{{ item.success_rate }}%</span>
                </template>
                <template #[`item.substitute_cost`]="{ item }">
                  <span v-if="item.substitute_cost === 0">{{ '-' }}</span>
                  <span v-else>{{ formatNumberWithComma(item.substitute_cost) }}</span>
                </template>
              </v-data-table>
              <v-pagination
                v-if="staticList.length > 0 && totalPages > 1"
                rounded="circle"
                :total-visible="6"
                v-model="currentPage"
                :length="totalPages"
              >
              </v-pagination>
              <GSAlert
                v-if="errorDialog"
                alertType="basic"
                alertIconType="warning"
                alertTitle="요청하신 처리중 오류가 발생했습니다."
                alertText="네트워크 상태를 확인한 후 다시 시도해 주세요. 문제가
        지속될 경우, 잠시 후 다시 시도하거나 관리자(sendy@gsretail.com)에게 문의해 주시기 바랍니다."
                isAlertPrimaryBtn
                btnPrimaryText="확인"
                @action="() => (errorDialog = false)"
              >
              </GSAlert>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  <GSLoading v-if="visibleLoading"></GSLoading>
</template>

<script setup lang="ts">
/* Global */
import { ref, watch, computed, onMounted, onUnmounted, nextTick } from 'vue'
import useAxios from '@/core/plugins/AxiosApi'

import { commonResponse, commonError } from '@/core/plugins/global-plugin'
import {
  changeDate,
  formatNumberWithComma,
  getTodayDate,
  orgListExtract,
  resetTime,
  searchRangValidate,
  useCommonI18n
} from '@/core/plugins/global-util'

/* Specific feature */
// import { DataTableHeader } from 'vuetify'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

/* UI Component */
import GSButton from '@/components/ui/GSButton.vue'
import GSLoading from '@/components/ui/GSLoading.vue'
import * as Constants from '@/constants/index'
import GSDatepicker from '@/components/ui/GSDatepicker.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSAlert from '@/components/ui/GSAlert.vue'
import GSNoData from '@/components/ui/GSNoData.vue'

/* ================================================== */

interface Organization {
  organization_id: string
  organization_name: string
}

interface Workspace {
  workspace_id: string
  workspace_name: string
}

interface TotalCostInfo {
  total_cost: number
  total_substitute_cost: string
  total_send_count: number
  total_success_count: number
}

interface StaticList {
  log_date: string // 발송일자 (string)
  channels: string // 채널 (string)
  cost: number // 사용금액 (float)
  send_count: number // 발송건수 (int)
  success_count: number // 성공건수 (int)
  fail_count: number // 실패건수 (int)
  unconfirmed_count: number // 미확인 건수 (int)
  substitute_channels: string | null // 대체채널 (string | null)
  substitute_success_count: number // 대체 성공 건수 (int)
  substitute_fail_count: number // 대체 실패 건수 (int)
  substitute_cost: number // 대체 발송 비용 (float)
  success_rate: number // 성공률 (float)
  workspaces: string // workspace (string)
}
const { translate } = useCommonI18n()
const { $axios } = useAxios()

// const { openProfileMenu, selectedOrganizationName } = useHeaderState()

/* DOM manipulation variable */
const activeIndexDateBtn = ref<number | null>() // 기간 버튼 선택 활성화
const isDisabledDateBtn = ref(false) // 검색 기간 버튼 비활성화
const searchErrDialog = ref(false) // 검색 에러 다이얼로그
const excelDialog = ref(false)
const errorDialog = ref(false)
// const openDatePicker = ref(false)
const isChannelOpen = ref(false)
const openSearchWorkspace = ref(false) // workspace 메뉴 open
const openSearchOrg = ref(false) // 조직 메뉴  open

/* API request field & logic variable */
const searchStartDate = ref(getTodayDate())
const searchEndDate = ref(getTodayDate())
// const selectedStartDate = ref<Date | null>(resetTime(new Date()))
// const selectedEndDate = ref<Date | null>(resetTime(new Date()))
const selectedPeriod = ref<string>('today') // 선택된 기간
// const selectedChannel = ref<{ label: string; value: string } | null>(null)
const selectedChannel = ref<string>('UMS') // 선택된 채널
const selectedOrganization = ref<Organization | null>(null) // 선택된 조직
const selectedWorkspace = ref<Workspace | null>(null) // 선택된 workspace
const showOrgAutocomplete = ref(false) // 조직 메뉴
const showAutocomplete = ref(false) // workspace 메뉴

/* API response variable */
const organizationList = ref<Organization[]>([])
const workspaceList = ref<Workspace[]>([{ workspace_id: '', workspace_name: '전체' }])
const preWorkspace = ref<Workspace | null>(null)
const totalCostInfo = ref<TotalCostInfo | null>(null) // 사용현황
const staticList = ref<StaticList[]>([]) // 조회내역
/* Data variable */
const datePeriod = ref([
  { value: 'today', text: '오늘' },
  { value: '1m', text: '1개월' }
  //todo 추후에 오픈
  // { value: '3m', text: '3개월' },
  // { value: '6m', text: '6개월' }
])
// const channelList = ref(['전체', 'SMS', 'LMS', 'MMS'])
const channelList = ref([
  { label: 'SMS/LMS/MMS 전체', value: 'UMS' },
  { label: 'SMS', value: 'SMS' },
  { label: 'LMS', value: 'LMS' },
  { label: 'MMS', value: 'MMS' },
  { label: '카카오알림톡', value: 'KAKAO-ALIMTALK' },
  { label: '카카오알림톡/친구톡(텍스트) 전체', value: 'KAKAO' },
  { label: '카카오친구톡(텍스트)', value: 'KAKAO-FRIENDTALK' },
  { label: 'PUSH', value: 'PUSH' },
  { label: 'EMAIL', value: 'EMAIL' }
])

/* Display variable */
const searchResultCnt = ref<number>(0) // 조회내역 건수
const totalInfoStartDate = ref<string>('') //사용현황 시작일
const totalInfoEndDate = ref<string>('') //사용현황 종료일
const visibleLoading = ref<boolean>(false) // 로딩바
const preOrganization = ref<Organization | null>(null) // 이전에 선택된 조직

/* Excel variable */
const excelOrganization = ref<string | undefined>(undefined)
const excelChannel = ref<string | undefined>(undefined)
const excelStartDate = ref<string | undefined>(undefined)
const excelEndDate = ref<string | undefined>(undefined)

/* Reference variable */
const organizationListRef = ref<HTMLElement | null>(null) // 조직 메뉴
const refWorkspaceList = ref<HTMLElement | null>(null) // workspace 메뉴
const refChannel = ref<HTMLInputElement | null>(null) // 채널 메뉴

/* Table & Pagination variable */
const itemsPerList = ref([
  { label: '10개씩 보기', value: 10 },
  { label: '30개씩 보기', value: 30 },
  { label: '50개씩 보기', value: 50 },
  { label: '100개씩 보기', value: 100 }
])
const itemsPerPage = ref<number>(10) //조회내역 테이블 페이지당 보여지는 갯수
const currentPage = ref(1)
const pageCount = ref(0)
const totalPages = computed(() => Math.ceil(staticList.value.length / itemsPerPage.value))
const dataTableHeader = ref<any>([
  {
    align: 'center',
    title: '발송일자',
    value: 'log_date',
    sortable: false
  },
  {
    align: 'center',
    title: '채널',
    value: 'channels',
    sortable: false
  },
  {
    align: 'end',
    title: '사용금액(원)',
    value: 'cost',
    sortable: false
  },
  {
    align: 'end',
    title: '발송건수',
    value: 'send_count',
    sortable: false
  },
  {
    align: 'end',
    title: '성공건수',
    value: 'success_count',
    sortable: false
  },
  {
    align: 'end',
    title: '실패건수',
    value: 'fail_count',
    sortable: true
  },
  {
    align: 'end',
    title: '미확인 건수',
    value: 'unconfirmed_count',
    sortable: false
  },
  {
    align: 'end',
    title: '대체채널 요금(원)',
    replaceChannelCharge: '-',
    value: 'substitute_cost',
    sortable: false
  },
  {
    align: 'center',
    title: '대체채널',
    value: 'substitute_channels',
    sortable: false
  },
  {
    align: 'end',
    title: '대체성공',
    value: 'substitute_success_count',
    sortable: false
  },
  {
    align: 'end',
    title: '대체실패',
    value: 'substitute_fail_count',
    sortable: true
  },
  {
    align: 'end',
    title: '성공율(%)',
    value: 'success_rate',
    sortable: true
  },
  {
    align: 'start',
    title: 'Workspace',
    value: 'workspaces',
    sortable: false
  }
])

/* ================================================== */

/* 기간 버튼 그룹 */
const selectedPeriodBtn = (value: string, index: number | null) => {
  selectedPeriod.value = value
  const today = new Date()
  let calculatedStartDate: Date

  switch (selectedPeriod.value) {
    case 'today':
      calculatedStartDate = new Date(today)
      break
    case '1m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 1)
      break
    case '3m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 3)
      break
    case '6m':
      calculatedStartDate = new Date(today)
      calculatedStartDate.setMonth(today.getMonth() - 6)
      break
    case '':
      return
    default:
      calculatedStartDate = new Date(today)
      break
  }

  searchStartDate.value = ''
  searchEndDate.value = ''
  setTimeout(() => {
    searchStartDate.value = changeDate(resetTime(calculatedStartDate))
    searchEndDate.value = getTodayDate()
  }, 0)

  if (index !== null) {
    activeDateBtn(index)
  }
}

// const daysInMonth = (year: number, month: number) => {
//   return new Date(year, month, 0).getDate()
// }

// const formatDateInput = (e: Event, type: string) => {
//   let input = (e.target as HTMLInputElement).value.replace(/\D/g, '')

//   if (input.length > 4) {
//     input = `${input.slice(0, 4)}-${input.slice(4)}`
//   }

//   if (input.length > 7) {
//     input = `${input.slice(0, 7)}-${input.slice(7)}`
//   }

//   if (input.length > 10) {
//     input = input.slice(0, 10)
//   }

//   const year = parseInt(input.slice(0, 4), 10)
//   const month = parseInt(input.slice(5, 7), 10)
//   const day = parseInt(input.slice(8, 10), 10)

//   if (month && (month < 1 || month > 12)) {
//     input = `${input.slice(0, 4)}-`
//   }

//   if (month && day && (day < 1 || day > daysInMonth(year, month))) {
//     input = `${input.slice(0, 7)}-`
//   }

//   if (type === 'start') {
//     searchStartDate.value = input
//   } else if (type === 'end') {
//     searchEndDate.value = input
//   }
// }

/* DateBtn 활성화 */
const activeDateBtn = (index: number) => {
  if (isDisabledDateBtn.value) return
  activeIndexDateBtn.value = index
  isDisabledDateBtn.value = true

  setTimeout(() => {
    activeIndexDateBtn.value = null
    isDisabledDateBtn.value = false
  }, 300)
}

/* ================================================== */

// const cofirmDate = () => {
//   const sdate = new Date(searchStartDate.value)
//   const edate = new Date(searchEndDate.value)

//   if (sdate > edate) {
//     searchStartDate.value = changeDate(selectedStartDate.value)
//     searchEndDate.value = ''
//   } else {
//     searchStartDate.value = changeDate(selectedStartDate.value)
//     searchEndDate.value = changeDate(selectedEndDate.value)
//   }
//   openDatePicker.value = false
// }

// const setToday = () => {
//   const today = new Date()
//   selectedStartDate.value = resetTime(today)
//   selectedEndDate.value = resetTime(today)
// }

// const resetDate = () => {
//   selectedPeriod.value = 'today'
//   const today = new Date()
//   selectedStartDate.value = resetTime(today)
//   selectedEndDate.value = resetTime(today)
// }

// const closeDate = () => {
//   if (searchStartDate.value === '' && searchEndDate.value === '') {
//     selectedStartDate.value = null
//     selectedEndDate.value = null
//   } else if (searchStartDate.value === '') {
//     selectedStartDate.value = null
//     selectedEndDate.value = resetDateValue(searchEndDate.value)
//   } else if (searchEndDate.value === '') {
//     selectedStartDate.value = resetDateValue(searchStartDate.value)
//     selectedEndDate.value = null
//   } else {
//     selectedStartDate.value = resetDateValue(searchStartDate.value)
//     selectedEndDate.value = resetDateValue(searchEndDate.value)
//   }
//   openDatePicker.value = false
// }

// const onClickDateInput = () => {
//   openDatePicker.value = true
// }

// watch(searchStartDate, (newDateStr) => {
//   if (newDateStr) {
//     const date = new Date(newDateStr)
//     selectedStartDate.value = resetTime(date)
//   } else {
//     selectedStartDate.value = null
//   }
// })

// watch(searchEndDate, (newDateStr) => {
//   if (newDateStr) {
//     const date = new Date(newDateStr)
//     selectedEndDate.value = resetTime(date)
//   } else {
//     selectedEndDate.value = null
//   }
// })

// watch(openDatePicker, () => {
//   if (selectedStartDate.value !== null && selectedEndDate.value !== null) {
//     selectedStartDate.value = resetTime(selectedStartDate.value)
//     selectedEndDate.value = resetTime(selectedEndDate.value)
//     if (selectedStartDate.value > selectedEndDate.value) {
//       selectedStartDate.value = resetDateValue(searchStartDate.value)
//       searchEndDate.value = ''
//       selectedEndDate.value = null
//     } else {
//       if (searchStartDate.value == '') {
//         selectedStartDate.value = null
//       } else {
//         selectedStartDate.value = resetDateValue(searchStartDate.value)
//       }
//       if (searchEndDate.value == '') {
//         selectedEndDate.value = null
//       } else {
//         selectedEndDate.value = resetDateValue(searchEndDate.value)
//       }
//     }
//     // else {
//     //   searchStartDate.value = changeDate(selectedStartDate.value)
//     //   searchEndDate.value = changeDate(selectedEndDate.value)
//     // }
//     // selectedStartDate.value = resetDateValue(searchStartDate.value)
//     // selectedEndDate.value = resetDateValue(searchEndDate.value)
//   }
// })
/* ================================================== */

/* ================================================== */

/* 조직 메뉴 벗어날 떄 */
const onClickOutsideOrg = () => {
  openSearchOrg.value = false
  if (selectedOrganization.value === null) {
    selectedOrganization.value = preOrganization.value
  }
  showOrgAutocomplete.value = false
}

// /* Workspace 메뉴 벗어날 때 */
const onClickOutsideWorkspace = () => {
  openSearchWorkspace.value = false
  if (selectedWorkspace.value === null) {
    selectedWorkspace.value = preWorkspace.value
  }
  showAutocomplete.value = false
}

/** 조직 메뉴 esc event */
const onKeydownOrg = (event: KeyboardEvent) => {
  if (event.key === 'Escape' || event.keyCode === 27) {
    openSearchOrg.value = false
    if (selectedOrganization.value === null) {
      selectedOrganization.value = preOrganization.value
    }
  }
}

/** Workspace 메뉴 esc event */
const onKeydownWorkspace = (event: KeyboardEvent) => {
  if (event.key === 'Escape' || event.keyCode === 27) {
    openSearchWorkspace.value = false
    if (selectedWorkspace.value === null) {
      selectedWorkspace.value = preWorkspace.value
    }
  }
}

/* Dialog */
const openDialogSearch = () => {
  searchErrDialog.value = true
}

const openExcelDialog = () => {
  excelDialog.value = true
}

const openErrorDialog = () => {
  errorDialog.value = true
}

/* 조회 초기화 */
const refreshData = () => {
  // searchStartDate.value = getTodayDate()
  // searchEndDate.value = getTodayDate()
  // selectedStartDate.value = resetTime(new Date())
  // selectedEndDate.value = resetTime(new Date())
  // selectedPeriod.value = 'today'

  if (searchStartDate.value === '' || searchEndDate.value === '') {
    selectedPeriodBtn('today', null) //  기간설정
  }
  selectedChannel.value = channelList.value[0].value
  // selectedOrganization.value = organizationList.value[0]
  selectedOrganization.value = orgListExtract(organizationList.value, 'HBU CX팀')
  selectedWorkspace.value = workspaceList.value[0]
}

/** 발송통계 조회 */
const searchData = () => {
  if (searchRangValidate(searchStartDate.value, searchEndDate.value)) {
    fetchGetStatisticsList()
  } else {
    openDialogSearch()
  }
}

/** 선택 메뉴 외부의 스크롤 발생시 선택 메뉴 close  */
const closeAllOverlays = () => {
  openSearchOrg.value = false
  // openDatePicker.value = false
  openSearchWorkspace.value = false
  isChannelOpen.value = false

  if (selectedOrganization.value === null) {
    selectedOrganization.value = preOrganization.value
  }
  if (selectedWorkspace.value === null) {
    selectedWorkspace.value = preWorkspace.value
  }
  if (refChannel.value && refChannel.value.blur) {
    refChannel.value.blur()
  }
}

/* 테이블 */
const handlePageCount = (newPageCount: number) => {
  pageCount.value = newPageCount
}

/* 엑셀 */
const replaceDate = (dateStr: string): string => {
  return dateStr.replace(/-/g, '')
}

/** 발송통계 엑셀 다운로드 */
const downloadExcel = async () => {
  if (staticList.value.length === 0) {
    openExcelDialog()
    return
  }

  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('발송통계')

  worksheet.columns = [
    { header: '발송일자', key: 'log_date', width: 15 },
    { header: '채널', key: 'channels', width: 15 },
    { header: '사용금액(원)', key: 'cost', width: 20 },
    { header: '발송건수', key: 'send_count', width: 15 },
    { header: '성공건수', key: 'success_count', width: 15 },
    { header: '실패건수', key: 'fail_count', width: 15 },
    { header: '미확인 건수', key: 'unconfirmed_count', width: 15 },
    { header: '대체채널 요금(원)', key: 'substitute_cost', width: 20 },
    { header: '대체채널', key: 'substitute_channels', width: 15 },
    { header: '대체성공', key: 'substitute_success_count', width: 15 },
    { header: '대체실패', key: 'substitute_fail_count', width: 15 },
    { header: '성공율(%)', key: 'success_rate', width: 15 },
    { header: 'Workspace', key: 'workspaces', width: 20 }
  ]

  staticList.value.forEach((item) => {
    worksheet.addRow(item)
  })

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E9E9E9' }
    }
    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } }
    }
  })

  const totalRows = worksheet.rowCount

  for (let rowIndex = 2; rowIndex <= totalRows; rowIndex++) {
    const row = worksheet.getRow(rowIndex)

    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      }
    })
  }

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })

  var orgFileName = `(Sendy)발송통계_${excelOrganization.value}_${excelChannel.value}_${excelStartDate.value}~${excelEndDate.value}.xlsx`
  var excelFileName = orgFileName.replace(/[\\?%*:|"<>]/g, '')

  saveAs(blob, excelFileName)
}

/** 조직 리스트 조회 */
const fetchGetOrganizationList = async () => {
  const requestParam = {
    pageable: false
  }
  await $axios
    .get('/api/organizations', {
      params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      if (response.data.data.length === 0) return
      const responseData = commonResponse(response)
      organizationList.value = responseData.data

      // selectedOrganization.value = organizationList.value[0]
      if (organizationList.value.length > 0) {
        selectedOrganization.value = orgListExtract(organizationList.value, 'HBU CX팀')
        // selectedOrganizationName.value = organizationList.value[0].organization_name
        // selectedOrganization.value = organizationList.value[0].organization_id
      }
      visibleLoading.value = false
      selectedWorkspace.value = workspaceList.value[0] // 시작시 강제로 전체가 보이게함
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      openErrorDialog()
    })
}
/** Workspace 리스트 조회  */
const fetchGetWorkspaceList = async () => {
  visibleLoading.value = true
  const requestParam = {
    pageable: false,
    q: `organization_id eq ${selectedOrganization.value?.organization_id}`
  }
  await $axios
    .get('/api/workspaces', {
      params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      if (response.data.data.length === 0) return
      const responseData = commonResponse(response)
      workspaceList.value = responseData.data
      let tempObj = { workspace_id: '', workspace_name: '전체' }
      workspaceList.value.unshift(tempObj)
      selectedWorkspace.value = workspaceList.value[0]
      visibleLoading.value = false
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      visibleLoading.value = false
      openErrorDialog()
    })
}

/** 발송통계 조회 */
const fetchGetStatisticsList = async () => {
  visibleLoading.value = true

  const requestParam = {
    organization_id: selectedOrganization.value?.organization_id,
    ...(selectedWorkspace.value?.workspace_id !== '' && {
      workspace_id: selectedWorkspace.value?.workspace_id
    }),
    start_date: searchStartDate.value,
    end_date: searchEndDate.value,
    channel_id: selectedChannel.value
  }

  await $axios
    .get('/api/message/statics', {
      params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      if (response.data.data.length === 0) return
      const responseData = commonResponse(response)
      totalCostInfo.value = responseData.data.total
      staticList.value = responseData.data.rows

      totalInfoStartDate.value = searchStartDate.value
      totalInfoEndDate.value =
        searchStartDate.value === searchEndDate.value ? '' : searchEndDate.value

      excelOrganization.value = selectedOrganization.value?.organization_name
      excelChannel.value = selectedChannel.value
      excelStartDate.value = replaceDate(searchStartDate.value)
      excelEndDate.value = replaceDate(searchEndDate.value)
      // 원래 배열을 복사해 두기
      // for (let i = 0; i < 99; i++) {
      //   var originalList = {
      //     log_date: '1', // 발송일자 (string)
      //     channels: '1', // 채널 (string)
      //     workspaces: '1', // workspace (string)
      //     cost: i, // 사용금액 (float)
      //     send_count: i, // 발송건수 (int)
      //     success_count: i, // 성공건수 (int)
      //     fail_count: i, // 실패건수 (int)
      //     substitute_channels: 'a', // 대체채널 (string | null)
      //     substitute_success_count: i, // 대체 성공 건수 (int)
      //     substitute_fail_count: i, // 대체 실패 건수 (int)
      //     substitute_cost: i, // 대체 발송 비용 (float)
      //     unconfirmed_count: i, // 미확인 건수 (int)
      //     success_rate: i // 성공률 (float)
      //   }
      //   staticList.value.push(originalList)
      // }
      searchResultCnt.value = staticList.value.length
      visibleLoading.value = false
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      visibleLoading.value = false
      openErrorDialog()
    })
}

/** 조직 list open */
function handleOrgMenuOpen(isOpen: boolean) {
  if (isOpen) {
    setTimeout(() => {
      showOrgAutocomplete.value = true
      nextTick(() => {
        organizationListRef.value?.focus()
      })
    }, 200)
  } else {
    showOrgAutocomplete.value = false
  }
}

/** Workspace list open  */
function handleMenuOpenWorkspace(isOpen: boolean) {
  if (isOpen) {
    setTimeout(() => {
      showAutocomplete.value = true
      nextTick(() => {
        refWorkspaceList.value?.focus()
      })
    }, 200)
  } else {
    showAutocomplete.value = false
  }
}
/* 채널 오픈 ??? */
// watch(isChannelOpen, (newVal) => {
//   if (!newVal) {
//     setTimeout(() => {
//       if (openDatePicker.value) {
//         isChannelOpen.value = true
//         openDatePicker.value = false
//       }
//     }, 0)
//   }
// })

/** 조직 선택 시 */
watch(selectedOrganization, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue !== null) {
      openSearchOrg.value = false
    } else {
      preOrganization.value = oldValue
    }
  }
  if (newValue && newValue.organization_id !== '') {
    fetchGetWorkspaceList()
    itemsPerPage.value = 10
    searchResultCnt.value = 0
    selectedChannel.value = channelList.value[0].value
    // selectedPeriodBtn('today', null) // 조직선택 시 기간설정
    // staticList.value = []
    // totalCostInfo.value = null
  }
})
/** Workspace 선택 시 */
watch(selectedWorkspace, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue !== null) {
      openSearchWorkspace.value = false
    } else {
      preWorkspace.value = oldValue
    }
  }
})

// /** 조직 메뉴 visible */
// watch(isOrganizationAutocompleteVisible, (newValue) => {
//   console.log('isOrganizationAutocompleteVisible ::', newValue)
//   if (newValue === false) {
//     isOrganizationAutocompleteVisible.value = true
//   }
// })
// /** Workspace 메뉴 visible  */
// watch(isWorkspaceAutocompleteVisible, (newValue) => {
//   if (newValue === false) {
//     isWorkspaceAutocompleteVisible.value = true
//   }
// })

/* Lifecycle Hooks  */
onUnmounted(() => {
  window.removeEventListener('scroll', closeAllOverlays)
})

onMounted(() => {
  fetchGetOrganizationList() // 시작시 조직메뉴 조회
  selectedChannel.value = channelList.value[0].value
  selectedPeriodBtn('today', null) // 시작시 기간설정
  window.addEventListener('scroll', closeAllOverlays)
})
</script>
