import { type RouteRecordRaw } from 'vue-router'
import WorkspaceView from '@/views/workspace/WorkspaceView.vue'
import Email from '@/views/workspace/messagesending/EmailView.vue'
import Push from '@/views/workspace/messagesending/PushView.vue'
import Slmms from '@/views/workspace/messagesending/SlmmsView.vue'
import Kakaotalk from '@/views/workspace/messagesending/KakaotalkView.vue'
import KakaotalkFriend from '@/views/workspace/messagesending/KakaotalkFriend.vue'
import WorkspaceDashboard from '@/views/workspace/WorkspaceDashboard.vue'
import SendingHistoryInquiry from '@/views/workspace/SendingHistoryInquiry.vue'
import Statistics from '@/views/workspace/Statistics.vue'
import EventManagement from '@/views/workspace/EventManagement.vue'
// import WorkspaceSettings from '@/views/workspace/workspace/WorkspaceSettings.vue'
// import Dashboard from '@/views/workspace/Dashboard.vue'
// import MessagingWork from '@/views/workspace/sending/MessagingWork.vue'
// import MessagingHistory from '@/views/workspace/sending/MessagingHistory.vue'
// import Workflow from '@/views/workspace/automations/Workflow.vue'
// import WorkflowHistory from '@/views/workspace/automations/WorkflowHistory.vue'
// import Email from '@/views/workspace/template/Email.vue'
// import Push from '@/views/workspace/template/Push.vue'
// import Slmms from '@/views/workspace/template/Slmms.vue'
// import Kakaotalk from '@/views/workspace/template/Kakaotalk.vue'
// import ChannelRoutings from '@/views/channelroutings/ChannelRoutings.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/workspace',
    // redirect: '/common/workspace',
    // component: () => import('@/layouts/main/MainLayout.vue'),
    component: () => import('@/layouts/workspace/WorkspaceLNBLayout.vue'),
    children: [
      {
        path: '/workspace',
        name: 'workspace',
        component: WorkspaceView,
        meta: {
          pageTitle: 'Workspace List',
          breadcrumbs: [],
          requiresAuth: true
        }
      },
      {
        path: '/workspace/workspacedashboard',
        name: 'workspacedashboard',
        component: WorkspaceDashboard,
        meta: {
          pageTitle: 'workspace dashboard',
          breadcrumbs: [],
          requiresAuth: true
        }
      },
      {
        path: '/workspace/sendinghistoryinquiry',
        name: 'sendinghistoryinquiry',
        component: SendingHistoryInquiry,
        meta: {
          pageTitle: 'Sending History Inquiry',
          breadcrumbs: []
        }
      },
      {
        path: '/workspace/statistics',
        name: 'statistics',
        component: Statistics,
        meta: {
          pageTitle: 'Statistics',
          breadcrumbs: []
        }
      },
      {
        path: '/workspace/eventmanagement',
        name: 'eventmanagement',
        component: EventManagement,
        meta: {
          pageTitle: 'Event Management',
          breadcrumbs: []
        }
      },
      {
        path: '/workspace/messagesending/email',
        name: 'email',
        component: Email,
        meta: {
          pageTitle: 'Email',
          breadcrumbs: ['Message Sending', 'Email']
        }
      },
      {
        path: '/workspace/messagesending/push',
        name: 'push',
        component: Push,
        meta: {
          pageTitle: 'Push',
          breadcrumbs: ['Message Sending', 'Push']
        }
      },
      {
        path: '/workspace/messagesending/slmms',
        name: 'slmms',
        component: Slmms,
        meta: {
          pageTitle: 'SMS/LMS/MMS',
          breadcrumbs: ['Message Sending', 'SMS/LMS/MMS']
        }
      },
      {
        path: '/workspace/messagesending/kakaotalk',
        name: 'kakaotalk',
        component: Kakaotalk,
        meta: {
          pageTitle: 'Kakaotalk',
          breadcrumbs: ['Message Sending', 'Kakaotalk']
        }
      },
      {
        path: '/workspace/messagesending/kakaotalkfriend',
        name: 'kakaotalkfriend',
        component: KakaotalkFriend,
        meta: {
          pageTitle: 'KakaotalkFriend',
          breadcrumbs: ['Message Sending', 'KakaotalkFriend']
        }
      }
      // {
      //   path: '/workspace/workspacesettings',
      //   name: 'workspacesettings',
      //   component: WorkspaceSettings,
      //   meta: {
      //     pageTitle: 'Workspace Setting',
      //     breadcrumbs: [],
      //     requiresAuth: true
      //   },
      //   children: [
      //     {
      //       path: 'profiletab',
      //       name: 'profiletab',
      //       component: () => import('@/views/workspace/workspace/ProfileTab.vue'),
      //       meta: {
      //         pageTitle: 'Workspace Setting',
      //         requiresAuth: true
      //       }
      //     },
      //     {
      //       path: 'accesskeytab',
      //       name: 'accesskeytab',
      //       component: () => import('@/views/workspace/workspace/AccessKeyTab.vue'),
      //       meta: {
      //         pageTitle: 'Workspace Setting',
      //         requiresAuth: true
      //       }
      //     },
      //     {
      //       path: 'emailtab',
      //       name: 'emailtab',
      //       component: () => import('@/views/workspace/workspace/EmailTab.vue'),
      //       meta: {
      //         pageTitle: 'Workspace Setting',
      //         requiresAuth: true
      //       }
      //     },
      //     {
      //       path: 'kakaotab',
      //       name: 'kakaotab',
      //       component: () => import('@/views/workspace/workspace/KakaoTab.vue'),
      //       meta: {
      //         pageTitle: 'Workspace Setting',
      //         requiresAuth: true
      //       }
      //     },
      //     {
      //       path: 'slmmstab',
      //       name: 'slmmstab',
      //       component: () => import('@/views/workspace/workspace/SlmmsTab.vue'),
      //       meta: {
      //         pageTitle: 'Workspace Setting',
      //         requiresAuth: true
      //       }
      //     },
      //     {
      //       path: 'pushtab',
      //       name: 'pushtab',
      //       component: () => import('@/views/workspace/workspace/PushTab.vue'),
      //       meta: {
      //         pageTitle: 'Workspace Setting',
      //         requiresAuth: true
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: '/workspace/dashboard',
      //   name: 'dashboard',
      //   component: Dashboard,
      //   meta: {
      //     pageTitle: 'Dashboard',
      //     breadcrumbs: ['Dashboard'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/sending/messagingwork',
      //   name: 'messagingwork',
      //   component: MessagingWork,
      //   meta: {
      //     pageTitle: 'Messaging Work',
      //     breadcrumbs: ['Sending', 'Messaging Work'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/sending/history',
      //   name: 'sendinghistory',
      //   component: MessagingHistory,
      //   meta: {
      //     pageTitle: 'Messaging History',
      //     breadcrumbs: ['Sending', 'Messaging History'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/automations/workflow',
      //   name: 'workflow',
      //   component: Workflow,
      //   meta: {
      //     pageTitle: 'Workflow',
      //     breadcrumbs: ['Automations', 'Workflow'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/automations/history',
      //   name: 'workflowhistory',
      //   component: WorkflowHistory,
      //   meta: {
      //     pageTitle: 'Workflow History',
      //     breadcrumbs: ['Automations', 'Workflow History'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/template/email',
      //   name: 'email',
      //   component: Email,
      //   meta: {
      //     pageTitle: 'Email',
      //     breadcrumbs: ['Template', 'Email'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/template/push',
      //   name: 'push',
      //   component: Push,
      //   meta: {
      //     pageTitle: 'Push',
      //     breadcrumbs: ['Template', 'Push'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/template/slmms',
      //   name: 'slmms',
      //   component: Slmms,
      //   meta: {
      //     pageTitle: 'SMS/LMS/MMS',
      //     breadcrumbs: ['Template', 'SMS/LMS/MMS'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/template/kakaotalk',
      //   name: 'kakaotalk',
      //   component: Kakaotalk,
      //   meta: {
      //     pageTitle: 'Kakaotalk',
      //     breadcrumbs: ['Template', 'Kakaotalk'],
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: '/workspace/channelroutings',
      //   name: 'channelroutings',
      //   component: ChannelRoutings,
      //   meta: {
      //     pageTitle: 'Channel Routings',
      //     breadcrumbs: ['Channel Routings'],
      //     requiresAuth: true
      //   },
      //   children: [
      //     {
      //       path: 'routings',
      //       name: 'routings',
      //       component: () => import('@/views/channelroutings/routings/Routings.vue'),
      //       meta: {
      //         pageTitle: 'Routings',
      //         requiresMenu: true
      //       },
      //       beforeEnter: (to, from, next) => {
      //         // 예시로 /protected-route에 직접 접근을 차단하고 /forbidden으로 리다이렉트
      //         if (from.name == 'channelroutings') {
      //           next()
      //         } else {
      //           next('/workspace/channelroutings') // Home 페이지에서 외에는 접근 불가
      //         }
      //       }
      //     }
      //   ]
      // }
    ]
  }
]

export default routes
