<template>
  <div :class="descPosition" class="gsr-switch">
    <span
      v-if="descPosition != SWITCHES_POSITION.DEFAULT && typeof description === 'string'"
      :class="{ required: required }"
      >{{ translate(description) }}</span
    >
    <v-switch v-model="switchValue" :class="computedClassName"> </v-switch>
  </div>
</template>
<script setup lang="ts">
import { defineProps, ref, watch, defineEmits, computed, type ComponentPublicInstance } from 'vue'
import { SWITCHES_SIZES, SWITCHES_COLORS, SWITCHES_POSITION } from '@/constants/switchesConstants'
import type {
  SwitchesSizes,
  SwitchesColors,
  SwitchesPositions
} from '@/constants/switchesConstants'
import { useCommonI18n } from '@/core/plugins/global-util'

interface Props {
  className?: string | string[]
  size?: SwitchesSizes
  color?: SwitchesColors
  description?: string
  required?: boolean
  descPosition: SwitchesPositions
  // outline?: string 테두리 클래스
}
const { translate } = useCommonI18n()
const props = withDefaults(defineProps<Props>(), {
  // variant: SWITCHES_VARIANTS.STANDARD,
  size: SWITCHES_SIZES.MEDIUM,
  color: SWITCHES_COLORS.PRIMARY,
  className: '',
  descPosition: SWITCHES_POSITION.DEFAULT
})

const computedClassName = computed(() => {
  const baseClassNames = Array.isArray(props.className) ? props.className : [props.className]
  return [...baseClassNames, props.size, props.color]
})

const emit = defineEmits(['updateSwitchValue'])

const switchValue = ref<Boolean>(false)

watch(switchValue, (newValue, oldValue) => {
  emit('updateSwitchValue', newValue)
})
</script>
<script lang="ts">
export default {}
</script>
