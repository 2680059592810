<template>
  <v-pagination
    length="10"
    v-if="dataLength > 10"
    :total-visible="6"
    :show-first-last-page="true"
  >
  </v-pagination>
</template>

<script setup lang="ts">
defineProps({
  dataLength: {
    type: Number,
    default: '',
  },
});
</script>