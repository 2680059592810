<template>
  <div class="sending-msg slmms">
    <PageTitle :title="'SMS / LMS / MMS'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('상세 설정') }}
          </strong>
        </div>
      </div>
      <div class="contents-data-form-wrap">
        <v-data-table hide-default-header hide-default-footer>
          <template v-slot:colgroup>
            <colgroup>
              <col style="min-width: 180px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:body>
            <tr>
              <th>
                <span>발송메시지</span>
                <!-- {{ workspaceInfo }} -->
              </th>
              <td>
                <!-- 메시지 유형 -->
                <div class="contents-date-form-item">
                  <GSRadio
                    description="메시지 유형"
                    required
                    :size="Constants.RADIO_SIZES.MEDIUM"
                    :color="Constants.RADIO_COLORS.PRIMARY"
                    :desc-position="Constants.RADIO_POSITION.TOP"
                    :items="msgTypeItems"
                    v-model="msgTypeValue"
                  ></GSRadio>
                  <GSButton
                    :class="'sending-guide-btn'"
                    :variant="Constants.BUTTON_VARIANTS.TEXT"
                    :size="Constants.BUTTON_SIZES.TINY"
                    :color="Constants.BUTTON_COLORS.PRIMARY"
                    :icon-position="Constants.BUTTON_ICONPOSITION.BOTH"
                    :text="guideText"
                    :width="244"
                  ></GSButton>
                </div>
                <!-- 수신 거부 번호 -->
                <div class="contents-date-form-item">
                  <GSSelects
                    v-model="refuseReceiveNum"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.MEDIUM"
                    :desc-position="Constants.SELECTS_POSITION.TOP"
                    :description="translate('수신 거부 번호')"
                    required
                    style="width: 320px"
                    :itemsList="refuseReceiveNumList"
                  ></GSSelects>
                </div>
                <!-- 발송 채널 -->
                <!-- <div class="contents-date-form-item">
                  <GSRadio
                    description="발송채널"
                    required
                    :size="Constants.RADIO_SIZES.MEDIUM"
                    :color="Constants.RADIO_COLORS.PRIMARY"
                    :desc-position="Constants.RADIO_POSITION.TOP"
                    :items="channelItems"
                    v-model="channelValue"
                  ></GSRadio>
                </div> -->
                <!-- 발신 번호 -->
                <div class="contents-date-form-item">
                  <GSSelects
                    v-model="callingNum"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.MEDIUM"
                    :desc-position="Constants.SELECTS_POSITION.TOP"
                    :description="translate('발신번호')"
                    required
                    style="width: 320px"
                    :itemsList="callingNumList"
                  ></GSSelects>
                </div>
                <!-- 콜백번호 -->
                <div class="contents-date-form-item">
                  <GSSelects
                    v-model="callbakNum"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.MEDIUM"
                    :desc-position="Constants.SELECTS_POSITION.TOP"
                    :description="translate('콜백번호')"
                    required
                    style="width: 320px"
                    :itemsList="callbakNumList"
                  ></GSSelects>
                  <div class="guide-info">
                    <strong class="title">수신된 문자번호로 통화 시 연결되는 번호입니다.</strong>
                  </div>
                </div>
                <!-- 발송 유형 -->
                <!-- <div class="contents-date-form-item">                  
                  <div class="gsr-radio">
                    <div class="label-box">
                      <span class="required">발송 유형</span>
                    </div>
                    <div class="gsr-radio-horizontal">
                      <v-radio-group v-model="sendingTypeValue">
                        <v-radio class="v-radio gsr-radio-primary gsr-radio-medium" value="1">
                          <template #label> 즉시발송 </template>
                        </v-radio>
                        <v-radio class="v-radio gsr-radio-primary gsr-radio-medium" value="2">
                          <template #label>
                            예약발송
                            <GSDatepicker></GSDatepicker>
                            <GSTimepicker></GSTimepicker>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div> -->
                <div class="contents-date-form-item">
                  <div class="shipping-type-box">
                    <div class="select-box">
                      <GSSelects
                        v-model="shipmentType"
                        :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                        :size="Constants.SELECTS_SIZES.MEDIUM"
                        :desc-position="Constants.SELECTS_POSITION.TOP"
                        :description="'발송유형'"
                        required
                        style="width: 320px"
                        :itemsList="shipmentTypeList"
                      ></GSSelects>
                    </div>
                    <div class="date-picker-box">
                      <GSDatePickerCaseOne></GSDatePickerCaseOne>
                    </div>
                    <div class="time-picker-box">
                      <GSTimepicker></GSTimepicker>
                    </div>
                  </div>
                  <div class="guide-info error">
                    <strong class="title">원하는 예약 발송일과 시간을 선택해 주세요.</strong>
                  </div>
                </div>
                <!-- 발송채널 -->
                <div class="contents-date-form-item">
                  <GSSelects
                    v-model="shipmentChannel"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.MEDIUM"
                    :desc-position="Constants.SELECTS_POSITION.TOP"
                    :description="'발송채널'"
                    required
                    style="width: 320px"
                    :itemsList="shipmentChannelList"
                  ></GSSelects>
                </div>
                <!-- 이미지 -->
                <!-- <div class="contents-date-form-item">
                  <GSRadio
                    description="이미지"
                    required
                    :size="Constants.RADIO_SIZES.MEDIUM"
                    :color="Constants.RADIO_COLORS.PRIMARY"
                    :desc-position="Constants.RADIO_POSITION.TOP"
                    :items="imgItems"
                    v-model="imgValue"
                  ></GSRadio>
                  <GSFileinput :placeholder="'이미지 파일을 첨부해 주세요'"></GSFileinput>
                  <div class="guide-info">
                    <strong class="title"
                      >이미지는 JPG 형식만 최대 3개까지 첨부할 수 있어요. <br />(첨부 용량 : 최대
                      1MB / 사이즈 : 최대 1500px*1440px)</strong
                    >
                  </div>
                  <div class="guide-info error">
                    <strong class="title">이미지 파일을 첨부해 주세요.</strong>
                  </div>
                </div> -->
                <!-- 메시지 제목 -->
                <div class="contents-date-form-item">
                  <div class="input-medium-size">
                    <GSInput
                      v-model="msgTitle"
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.MEDIUM"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      :description="'메시지 제목'"
                      required
                      :show-counter="true"
                      :placeholder="titlePlaceholder"
                    ></GSInput>
                  </div>
                  <div class="guide-info error">
                    <strong class="title">메시지 제목을 입력해 주세요.</strong>
                  </div>
                </div>
                <!-- 메시지 내용 -->
                <div class="contents-date-form-item">
                  <div class="gsr-textarea-counter">
                    <div class="label-box">
                      <span class="required">메시지 내용</span>
                    </div>
                    <v-textarea
                      v-model="msgContent"
                      :maxlength="45"
                      :placeholder="messagePlaceholder"
                    >
                      <template #counter="{ value, max }">
                        <span :class="{ 'error-text': value > max }">{{ value }}/{{ max }}자</span>
                      </template>
                    </v-textarea>
                  </div>
                </div>
                <!-- 이벤트 설정 -->
                <div class="contents-date-form-item">
                  <GSSelects
                    v-model="evnetSettings"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.MEDIUM"
                    :desc-position="Constants.SELECTS_POSITION.TOP"
                    :description="'이벤트 설정'"
                    style="width: 320px"
                    :itemsList="evnetSettingsList"
                  ></GSSelects>
                  <div class="guide-info">
                    <strong class="title"
                      >설정한 이벤트의 발송 결과, 통계 용도로 분석할 수 있습니다.</strong
                    >
                  </div>
                </div>
                <!-- 발송 태그 -->
                <div class="contents-date-form-item">
                  <div class="input-medium-size">
                    <GSInput
                      v-model="shipmentTag"
                      :variant="Constants.INPUT_VARIANTS.OUTLINED"
                      :size="Constants.INPUT_SIZES.MEDIUM"
                      :desc-position="Constants.INPUT_POSITION.TOP"
                      spellcheck="true"
                      clearable
                      :description="'발송태그'"
                      :placeholder="'발송태그를 입력해 주세요'"
                      @update-input="(value) => (shipmentTag = value)"
                    ></GSInput>
                  </div>
                  <div class="guide-info">
                    <strong class="title"
                      >메시지 추적, 타겟팅 관리 등으로 활용할 수 있습니다.</strong
                    >
                  </div>
                </div>
              </td>
            </tr>
            <!-- <tr>
              <th class="table-cell">템플릿</th>
              <td>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.MEDIUM"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'템플릿 불러오기'"
                  :width="127"
                  @click="openTemplateDialog"
                ></GSButton>
              </td>
            </tr> -->
            <tr>
              <th>수신자 설정</th>
              <td>
                <div class="contents-date-form-item">
                  <div class="label-box">
                    <span>수신자 정보</span>
                  </div>
                  <div class="btn-list">
                    <GSButton
                      :class="'add-btn'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'수신자 등록'"
                      :width="120"
                      @click="openRecipientDialog"
                    ></GSButton>
                    <GSButton
                      :class="'add-btn-disabled'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'수신자 등록'"
                      :width="120"
                      @click="openRecipientDialog"
                      :disabled="true"
                    ></GSButton>
                    <!-- <GSButton
                      :class="'filter-btn'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'나의 Audience 필터 조회'"
                      :width="210"
                      @click="openAudienceFilterDialog"
                    ></GSButton> -->
                    <GSButton
                      :class="'excel-btn'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'엑셀 일괄등록'"
                      :width="138"
                    ></GSButton>
                    <GSButton
                      :class="'excel-btn-disabled'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'엑셀 일괄등록'"
                      :width="138"
                      :disabled="true"
                    ></GSButton>
                    <GSButton
                      :class="'registration-btn'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                      :text="'일괄등록 양식 다운로드'"
                      :width="193"
                    ></GSButton>
                  </div>
                  <div class="guide-info-wrap">
                    <div class="guide-info-primary">
                      <strong class="title"
                        >메시지 내용 입력 후 ‘+ 수신자 등록/엑셀 일괄등록’으로 수신자 정보를 등록할
                        수 있어요.</strong
                      >
                    </div>
                    <div class="guide-info-primary">
                      <strong class="title"
                        >메시지 내용에 포함된 {변수}에 대해서도 ‘+ 수신자 등록/엑셀 일괄등록’별로
                        구성할 수 있어요.</strong
                      >
                    </div>
                  </div>
                </div>
                <div class="contents-date-form-item">
                  <div class="label-box">
                    <span>타켓팅 건수</span>
                  </div>
                  <div class="btn-list">
                    <div class="case-number">
                      <p class="num"><span>5</span>건</p>
                      <span class="text">(엑셀 일괄등록)</span>
                    </div>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'상세조회'"
                      :width="84"
                      @click="openTargetDialog"
                    ></GSButton>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.MEDIUM"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :text="'타겟팅 초기화'"
                      :width="114"
                    ></GSButton>
                  </div>
                  <div class="guide-info">
                    <strong class="title"
                      >중복 수신번호 및 등록 정보 오류 (엑셀 일괄 등록 시 {변수} 영역 누락 등) 제거
                      후 타겟팅 됩니다.</strong
                    >
                  </div>
                  <div class="guide-info">
                    <strong class="title"
                      >타겟팅한 대상 기준, 광고성인 경우 ‘마케팅 수신거부‘ 재필터링 후 발송됩니다.
                      (정보성은 제외)</strong
                    >
                  </div>
                  <div class="guide-info error">
                    <strong class="title">발송대상(타겟)을 설정해 주세요.</strong>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="preview-msg-wrap">
          <strong class="title">발송메시지 미리보기</strong>
          <div class="preview-msg-area">
            <div class="preview-msg-header">
              <div class="time-wifi-wrap">
                <div class="time-box">
                  <p class="time">9:41</p>
                </div>
                <div class="icon-box">
                  <div class="connection-status">
                    <i></i>
                  </div>
                  <div class="wifi">
                    <i></i>
                  </div>
                  <div class="battery">
                    <i></i>
                  </div>
                </div>
              </div>
              <div class="caller-icon">
                <div class="arrow-left">
                  <i></i>
                </div>
                <div class="user-profile">
                  <i></i>
                </div>
              </div>
              <div class="caller-number">
                <span>+82 1599-3333</span>
              </div>
            </div>
            <div class="preview-msg-contents">
              <div class="msg-date-area">
                <!-- <span class="msg-date-title">문자메시지</span> -->
                <span class="msg-date">(오늘) 오전 9:00</span>
              </div>
              <div class="preview-msg">
                <p class="msg-title">상상초월 판타지에스 행사안내</p>
                <p class="msg-contents">
                  [Web발신]<br />
                  (광고)GS SHOP[#수신거부번호]<br />
                  <br />
                  무료수신거부 #수신거부번호<br />
                  GSSHOP 고객센터 #발신번호<br />
                  <br />
                  작성한 문자내용 작성한 문자내용작성한 문자내용작성한 문자내용작성한 문자내용작성한
                  문자내용작성한 문자내용작성한 문자내용작성한 문자내용작성한 문자내용작성한
                  문자내용작성한 문자내용작성한 문자내용<br />
                  <br />
                  ■이벤트페이지 바로가기<br />
                  <span class="link">http://m.gsshop1223344/doeleleldzzl/fdfadfadfdaf</span><br />
                  <br />
                  무료수신거부 #수신거부번호<br />
                  GSSHOP 고객센터 #08000009999< <br />
                </p>
                <div class="img-area">
                  <img src="/src/assets/images/preview_sms_img.png" alt="메시지 미리보기 이미지" />
                </div>
                <!-- <span class="text-count">97/1000자</span> -->
                <GSNoData
                  text="작성한 메시지가 표시됩니다."
                  subText="내용을 입력해 주세요."
                  :className="'none'"
                ></GSNoData>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contents-btn-wrap">
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.MEDIUM"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :text="'초기화'"
          :width="100"
        ></GSButton>
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.MEDIUM"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'테스트 발송'"
          @click="openTestShipping"
          :width="100"
        ></GSButton>
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.MEDIUM"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'발송'"
          :width="100"
        ></GSButton>
      </div>
    </div>
    <!-- 수신자등록 -->
    <v-dialog v-model="recipientDialog" class="gsr-dialog recipient-layer" width="600">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">수신자 등록</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeRecipientDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="guide-info">
            <strong class="title">수신자 등록은 최대 5개까지 할 수 있어요.</strong>
          </div>
        </div>
        <div class="dialog-table-wrap">
          <div class="dialog-table-title-area">
            <div class="title">
              <p>수신자 정보</p>
            </div>
          </div>
          <div class="contents-data-table-wrap">
            <v-data-table
              :headers="recipientInformationHeader"
              :items="recipientInformationData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:colgroup>
                <colgroup>
                  <col style="width: 488px" />
                  <col style="width: 72px" />
                </colgroup>
              </template>
              <!-- <template v-slot:[`item.number`]="{ index }">
                {{ index + 1 }}
              </template> -->
              <!-- 수신번호 입력 -->
              <template v-slot:[`item.number`]="{ item }">
                <component :is="item.number.component" v-bind="item.number.props"></component>
              </template>

              <!-- 관리 버튼 -->
              <template v-slot:[`item.management`]="{ item }">
                <component
                  :is="item.management.component"
                  v-bind="item.management.props"
                ></component>
              </template>
            </v-data-table>
            <div class="add-recipient">
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.TEXT"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                text="수신자 추가"
                :width="108"
                style="margin-top: 16px"
              ></GSButton>
            </div>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'취소'"
            :width="59"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'등록하기'"
            :width="73"
            @click="handleRegister"
          ></GSButton>
          <!-- 휴대폰 번호 형식 외 입력 후 등록하기 버튼 클릭 시 Alert -->
          <GSAlert
            v-if="warningAlert"
            :alertType="'iconTitle'"
            :alertIconType="'warning'"
            :alertTitle="'수신번호는 10~11자리 숫자만 입력해 주세요.'"
            :btnDefaultText="'확인'"
            :isAlertDefaultBtn="true"
          />
          <!-- 데이터 입력 후 취소/X버튼 클릭시 (한 글자라도 입력시) -->
          <GSAlert
            v-if="warningDataAlert"
            :alertType="'iconTitle'"
            :alertIconType="'warning'"
            :alertTitle="'변경사항이 반영되지 않습니다.'"
            :alertSubTitle="'그대로 진행하시겠어요?'"
            :btnDefaultText="'취소'"
            :btnPrimaryText="'확인'"
            :isAlertDefaultBtn="true"
            :isAlertPrimaryBtn="true"
          />
          <!-- 오류 알럿 -->
          <!-- 이미지 형식, 갯수, 용량 초과 -->
          <GSAlert
            :alertType="'basic'"
            :alertIconType="'warning'"
            :alertTitle="'첨부파일을 다시 확인해 주세요.'"
            :alertText="'이미지는 JPG 형식만 가능하며, 최대 3개(파일 용량 : 최대 1MB)까지 첨부할 수 있어요'"
            :btnDefaultText="'취소'"
            :btnPrimaryText="'확인'"
            :isAlertDefaultBtn="true"
            :isAlertPrimaryBtn="true"
          />
          <!-- 데이터 입력 후 삭제 버튼 클릭 시 -->
          <GSAlert
            :alertType="'basic'"
            :alertIconType="'warning'"
            :alertTitle="'정보를 삭제하시겠습니까?'"
            :alertText="'설정을 저장하면 삭제 버튼을 누른 행이 삭제됩니다.'"
            :btnPrimaryText="'확인'"
            :isAlertPrimaryBtn="true"
          />
          <!-- 이미지 사이즈 초과 -->
          <GSAlert
            :alertType="'basic'"
            :alertIconType="'warning'"
            :alertTitle="'첨부파일을 다시 확인해 주세요.'"
            :alertText="'이미지 크기는 최대 1500px*1440px 이내만 첨부할 수 있어요.'"
            :btnPrimaryText="'확인'"
            :isAlertPrimaryBtn="true"
          />
          <!-- 필수 정보 오입력/입력  -->
          <GSAlert
            :alertType="'iconTitle'"
            :alertIconType="'warning'"
            :alertTitle="'필수 설정 정보를 다시 확인해 주세요.'"
            :btnPrimaryText="'확인'"
            :isAlertPrimaryBtn="true"
          />
          <!-- 작성 중 메시지 타입 변경 -->
          <!--  LMS > SMS로 변경 -->
          <GSAlert
            :alertType="'nonTitle'"
            :alertTitle="'SMS로 변경하시면 메시지 제목 입력란이 삭제되고 메시지는 최대 45자까지 입력할 수 있어요. 변경할까요?'"
            :btnDefaultText="'취소'"
            :btnPrimaryText="'확인'"
            :isAlertDefaultBtn="true"
            :isAlertPrimaryBtn="true"
          />
          <!-- MMS > LMS로 변경 -->
          <GSAlert
            :alertType="'nonTitle'"
            :alertTitle="'LMS로 변경하시면 첨부한 이미지가 삭제됩니다.'"
            :alertSubTitle="'변경할까요?'"
            :btnDefaultText="'취소'"
            :btnPrimaryText="'확인'"
            :isAlertDefaultBtn="true"
            :isAlertPrimaryBtn="true"
          />
          <!-- 완료/변경 안내 -->
          <!-- 초기화 버튼 클릭 -->
          <GSAlert
            :alertType="'nonTitle'"
            :alertTitle="'입력하신 정보가 모두 삭제돼요.'"
            :alertSubTitle="'초기화를 하시겠어요?'"
            :btnPrimaryText="'확인'"
            :isAlertPrimaryBtn="true"
          />
          <!-- 메시지 발송/예약 (테스트 발송 제외) -->
          <GSAlert
            :alertType="'basic'"
            :alertIconType="'success'"
            :alertTitle="'메시지가 성공적으로 발송되었습니다.'"
            :alertText="'발송 결과는 ‘발송내역 조회 > 채널별 조회(탭)’에서 확인할 수 있어요.'"
            :btnPrimarySize="136"
            :btnDefaultText="'닫기'"
            :btnPrimaryText="'발송 결과 확인하기'"
            :isAlertDefaultBtn="true"
            :isAlertPrimaryBtn="true"
          />
          <GSAlert
            :alertType="'basic'"
            :alertIconType="'success'"
            :alertTitle="'메시지 발송이 예약되었습니다.'"
            :alertText="'예약 결과를 확인하려면 ‘발송내역 조회 > 발송예약 조회(탭)’에서 확인해 보세요'"
            :btnPrimarySize="136"
            :btnDefaultText="'닫기'"
            :btnPrimaryText="'예약 결과 확인하기'"
            :isAlertDefaultBtn="true"
            :isAlertPrimaryBtn="true"
          />
        </div>
      </v-card>
      <!-- <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">수신자 등록</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeRecipientDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="guide-info">
            <strong class="title">수신자 등록은 최대 5개까지 할 수 있어요.</strong>
          </div>
        </div>
        <div class="dialog-tab-contents-wrap">
          <div class="dialog-tab-contents-area">
            <div class="dialog-tab-title">
              <span>수신자 정보</span>
            </div>
            <div class="dialog-tab-contents">
              <div class="recipient-info">
                <div class="info-item">
                  <GSSelects
                    :itemsList="recipientInfo"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.MEDIUM"
                    style="width: 136px"
                  ></GSSelects>
                  <GSInput
                    :variant="Constants.INPUT_VARIANTS.OUTLINED"
                    :size="Constants.INPUT_SIZES.MEDIUM"
                    style="width: 228px"
                  ></GSInput>
                  <GSButton
                    class="delete-btn"
                    :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                    :size="Constants.BUTTON_SIZES.MEDIUM"
                    :color="Constants.BUTTON_COLORS.SECONDARY"
                    :icon-position="Constants.BUTTON_ICONPOSITION.CENTER"
                    :width="40"
                  ></GSButton>
                </div>
                <GSButton
                  class="add-btn"
                  :variant="Constants.BUTTON_VARIANTS.TEXT"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                  text="수신자 추가"
                  :width="108"
                  style="margin-top: 16px"
                ></GSButton>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'취소'"
            :width="59"
            @click="closeRecipientDialog"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'등록하기'"
            :width="73"
          ></GSButton>
        </div>
      </v-card> -->
    </v-dialog>
    <!-- 테스트 발송 -->
    <v-dialog v-model="testShipping" class="gsr-dialog testshipment-layer" width="1200">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">테스트 발송</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeTestShipping"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="guide-info multiple">
            <strong class="title">수신자 등록은 최대 5개까지 할 수 있어요.</strong>
            <strong class="title">수신 번호는 숫자만 입력해 주세요.</strong>
          </div>
        </div>
        <div class="dialog-table-wrap">
          <div class="dialog-table-title-area">
            <div class="title">
              <p>수신자 등록</p>
            </div>
          </div>
          <div class="contents-data-table-wrap">
            <v-data-table
              :headers="testShippingHeader"
              :items="testShippingData"
              :items-per-page="10"
              :class="testShippingHeader.length > 4 ? 'scroll-x' : ''"
              hide-default-footer
            >
              <template v-slot:colgroup>
                <colgroup>
                  <col style="min-width: 240px" />
                  <col style="min-width: 240px" />
                  <col style="min-width: 240px" />
                  <col style="min-width: 240px" />
                  <col style="min-width: 240px" />
                  <col style="min-width: 72px" />
                </colgroup>
              </template>
              <!-- 수신번호 입력 -->
              <template v-slot:[`item.number`]="{ item }">
                <component :is="item.number.component" v-bind="item.number.props"></component>
              </template>
              <!-- name 입력 -->
              <template v-slot:[`item.names`]="{ item }">
                <component :is="item.names.component" v-bind="item.names.props"></component>
              </template>
              <!-- save_money 입력 -->
              <template v-slot:[`item.saveMoney`]="{ item }">
                <component :is="item.saveMoney.component" v-bind="item.saveMoney.props"></component>
              </template>
              <!-- save_money 입력 -->
              <template v-slot:[`item.saveMoneyTwo`]="{ item }">
                <component :is="item.saveMoney.component" v-bind="item.saveMoney.props"></component>
              </template>
              <!-- save_money 입력 -->
              <template v-slot:[`item.saveMoneyThree`]="{ item }">
                <component :is="item.saveMoney.component" v-bind="item.saveMoney.props"></component>
              </template>
              <!-- 관리 버튼 -->
              <template v-slot:[`item.management`]="{ item }">
                <component
                  :is="item.management.component"
                  v-bind="item.management.props"
                ></component>
              </template>
            </v-data-table>
            <div class="add-recipient">
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.TEXT"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :icon-position="Constants.BUTTON_ICONPOSITION.LEFT"
                text="수신자 추가"
                :width="108"
                style="margin-top: 16px"
              ></GSButton>
            </div>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'취소'"
            @click="closeTestShipping"
            :width="59"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'테스트 발송'"
            :width="100"
            @click="handleRegister"
          ></GSButton>
        </div>
      </v-card>
    </v-dialog>
    <!-- 나의 Audience 필터 조회 -->
    <v-dialog v-model="audienceFilterDialog" class="gsr-dialog audience-filter-layer" width="900">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">나의 Audience 필터 조회</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeAudienceFilterDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-table-wrap">
          <div class="dialog-table-title-area">
            <div class="title">
              <p>조회 내역 <span>999</span>건</p>
            </div>
            <div class="search">
              <!-- 검색 input -->
              <v-text-field
                class="dialog-search-input gsr-input-outlined gsr-input-small"
                placeholder="필터명을 입력해 주세요"
              >
                <template v-slot:append-inner>
                  <v-btn></v-btn>
                </template>
              </v-text-field>
              <GSSelects
                :itemsList="dataView"
                :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                :size="Constants.SELECTS_SIZES.SMALL"
              ></GSSelects>
            </div>
          </div>
          <div class="contents-data-table-wrap">
            <v-data-table
              :headers="audienceHeader"
              :items="audienceData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:colgroup>
                <colgroup>
                  <col style="width: auto" />
                  <col style="width: 112px" />
                  <col style="width: 176px" />
                  <col style="width: 176px" />
                </colgroup>
              </template>
            </v-data-table>
            <Pagination :dataLength="audienceData.length" style="margin-top: 20px"></Pagination>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'취소'"
            :width="100"
            @click="closeAudienceFilterDialog"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'적용하기'"
            :width="100"
          ></GSButton>
        </div>
      </v-card>
    </v-dialog>
    <!-- 타켓팅 상세조회 -->
    <v-dialog v-model="targetDialog" class="gsr-dialog target-layer center" width="900">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">타겟팅 상세조회</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeTargetDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="guide-info">
            <strong class="title"
              >타겟팅 등록 정보는 최대 10건까지 조회되며, 정보 변경을 원하시는 경우 타겟팅 초기화 후
              다시 등록해 주세요</strong
            >
          </div>
        </div>
        <div class="dialog-table-wrap">
          <div class="dialog-table-title-area">
            <div class="title">
              <p>조회 내역 <span>10</span>건</p>
            </div>
          </div>
          <div class="contents-data-table-wrap">
            <v-data-table
              :headers="targetHeader"
              :items="targetData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:colgroup>
                <colgroup>
                  <col style="width: 60px" />
                  <col style="width: 400px" />
                  <col style="width: 400px" />
                </colgroup>
              </template>
              <template v-slot:[`item.number`]="{ index }">
                {{ index + 1 }}
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'확인'"
            :width="100"
          ></GSButton>
        </div>
      </v-card>
    </v-dialog>
    <!-- 템플릿 불러오기 -->
    <v-dialog v-model="templateDialog" class="gsr-dialog template-layer" width="1200">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">Template 불러오기</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeTemplateDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-table-wrap">
          <div class="dialog-table-title-area">
            <div class="title">
              <p>조회 내역 <span>999</span>건</p>
            </div>
            <div class="search">
              <v-checkbox
                label="이용 중인 서비스만 보기"
                class="gsr-checkbox-medium gsr-checkbox-primary"
              ></v-checkbox>
              <!-- 검색 input -->
              <v-text-field
                class="dialog-search-input gsr-input-outlined gsr-input-small"
                placeholder="Template ID 또는 작성자명 입력"
              >
                <template v-slot:append-inner>
                  <v-btn></v-btn>
                </template>
              </v-text-field>
              <GSSelects
                :itemsList="dataView"
                :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                :size="Constants.SELECTS_SIZES.SMALL"
              ></GSSelects>
            </div>
          </div>
          <div class="contents-data-table-wrap">
            <v-data-table
              :headers="templateHeader"
              :items="templateData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:colgroup>
                <colgroup>
                  <col style="width: 84px" />
                  <col style="width: 180px" />
                  <col style="width: 200px" />
                  <col style="width: 100px" />
                  <col style="width: 280px" />
                  <col style="width: 120px" />
                  <col style="width: 120px" />
                  <col style="width: 84px" />
                  <col style="width: 57px" />
                  <col style="width: 100px" />
                  <col style="width: 176px" />
                </colgroup>
              </template>
              <template v-slot:item.state="{ item }">
                <span class="state active" v-if="item.state === true">이용중</span>
                <span class="state" v-if="item.state === false">이용안함</span>
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'취소'"
            :width="100"
            @click="closeTemplateDialog"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'적용하기'"
            :width="100"
          ></GSButton>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
// S: 다국어
// import { useI18n } from 'vue-i18n'
import { jsonParse, useCommonI18n } from '@/core/plugins/global-util'

import { ref, computed, markRaw, onMounted, watch } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSRadio from '@/components/ui/GSRadio.vue'
import GSInput from '@/components/ui/GSInput.vue'
// import GSFileinput from '@/components/ui/GSFileinput.vue'
import GSTimepicker from '@/components/ui/GSTimepicker.vue'
// import GSMultipleTimepicker from '@/components/ui/GSMultipleTimepicker.vue'
// import GSDatepicker from '@/components/ui/GSDatepicker.vue'
import GSAlert from '@/components/ui/GSAlert.vue'
import GSDatePickerCaseOne from '@/components/ui/GSDatePickerCaseOne.vue'
import GSNoData from '@/components/ui/GSNoData.vue'
import useAxios from '@/core/plugins/AxiosApi'
import { commonError, commonResponse } from '@/core/plugins/global-plugin'

const { translate } = useCommonI18n()
const { $axios } = useAxios()

const workspaceInfo = ref<any>({})
// 메시지 유형 radio 광고성 , 정보성
const msgTypeValue = ref('1')
const msgTypeItems = ref([
  { label: '광고성', value: '1', disabled: false },
  { label: '정보성', value: '2', disabled: false }
])
// 발송 채널 radio
// const channelValue = ref('1')
// const channelItems = ref([
//   { label: 'SMS', value: '1', disabled: false },
//   { label: 'LMS', value: '2', disabled: false },
//   { label: 'MMS', value: '3', disabled: false }
// ])

const refuseReceiveNum = ref<string>('') // 수신 거부 번호
const refuseReceiveNumList = ref<any[]>([]) // 수신 거부 번호 리스트
const callingNum = ref<string>('') // 발신 번호
const callingNumList = ref<any[]>([]) // 발신 번호 리스트
const callbakNum = ref<string>('') // 콜백 번호
const callbakNumList = ref<any[]>([]) // 콜백 번호 리스트
const shipmentType = ref<string>('') // 발송 유형
const shipmentTypeList = ref<any[]>([]) // 발송 유형 리스트
const shipmentChannel = ref<string>('') // 발송 채널
const shipmentChannelList = ref<any[]>([]) // 발송 채널 리스트
const msgTitle = ref<string>('') // 메시지 제목
const msgContent = ref<string>('') // 메시지 내용
const evnetSettings = ref<string>('') // 이벤트 설정
const evnetSettingsList = ref<any[]>([]) // 이벤트 설정 리스트
const shipmentTag = ref<string>('') // 발송 태그

const evnetSettingsItem = [
  {
    label: '선택',
    value: '1'
  },
  {
    label: '선택2',
    value: '2'
  }
]

const shippingTypeItmes = [
  {
    label: '즉시발송',
    value: '1'
  },
  {
    label: '예약발송',
    value: '2'
  }
]
const shippingTypeChannelItmes = [
  {
    label: 'SMS',
    value: '1'
  },
  {
    label: 'LMS',
    value: '2'
  },
  {
    label: 'MMS',
    value: '3'
  }
]
// 발송 유형 radio
const sendingTypeValue = ref('1')
// 버튼의 텍스트를 동적으로 설정하는 computed 속성
const guideText = computed(() => {
  return msgTypeValue.value === '1'
    ? '광고성 메시지 발송가이드 확인하기'
    : '정보성 메시지 발송가이드 확인하기'
})

// 메시지 내용 placeholder를 동적으로 설정하는 computed 속성
const messagePlaceholder = computed(() => {
  return msgTypeValue.value === '1'
    ? '(광고) 메시지 내용을 입력해 주세요'
    : '메시지 내용을 입력해 주세요'
})

// 메시지 제목 placeholder를 동적으로 설정하는 computed 속성
const titlePlaceholder = computed(() => {
  return msgTypeValue.value === '1'
    ? '(광고) 메시지 제목을 입력해 주세요'
    : '메시지 제목을 입력해 주세요'
})

// 이미지 radio
const imgValue = ref('1')
const imgItems = ref([
  { label: '사용안함', value: '1', disabled: false },
  { label: '이미지', value: '2', disabled: false }
])

// dialog table data view
const dataView = ref([
  {
    label: '10개씩 보기',
    value: '10'
  },
  {
    label: '20개씩 보기',
    value: '20'
  },
  {
    label: '30개씩 보기',
    value: '30'
  }
])
// 나의 audience 필터 조회 table 헤더
const audienceHeader: DataTableHeader[] = [
  {
    align: 'start',
    title: '필터명',
    value: 'filter'
  },
  {
    align: 'end',
    title: 'Audience',
    value: 'audience'
  },
  {
    align: 'center',
    title: '마지막 변경일',
    value: 'change'
  },
  {
    align: 'center',
    title: '마지막 계산일',
    value: 'calculate'
  }
]
// 나의 audience 필터 조회 table 데이터
const audienceData = [
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '20대 서울특별시 거주 고객',
    audience: '55,200,000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '20대 서울특별시 거주 고객',
    audience: '55,200,000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '20대 서울특별시 여성',
    audience: '890,000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '강남구 지역 우리동네GS 이용 고객',
    audience: '450.000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  },
  {
    filter: '20대 서울특별시 거주 고객',
    audience: '55,200,000',
    change: '2024.01.16  11:00:00',
    calculate: '2024.01.16  11:00:00'
  }
]
// 수신자 정보 table 헤더
const recipientInformationHeader: DataTableHeader[] = [
  {
    align: 'start',
    title: '수신번호',
    value: 'number'
  },
  {
    align: 'center',
    title: '관리',
    value: 'management'
  }
]
// 수신자 정보 table 데이터
const recipientInformationData = [
  {
    number: {
      component: markRaw(GSInput),
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: '숫자만 입력 (- 제외)',
        class: 'receiving-number'
      }
    },
    management: {
      component: markRaw(GSButton),
      props: {
        class: 'delete-btn',
        variant: Constants.BUTTON_VARIANTS.OUTLINED,
        size: Constants.BUTTON_SIZES.MEDIUM,
        color: Constants.BUTTON_COLORS.SECONDARY,
        'icon-position': Constants.BUTTON_ICONPOSITION.CENTER,
        width: 40,
        onClick: () => handleDeleteRegister()
      }
    }
  },
  {
    number: {
      component: GSInput,
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: '숫자만 입력 (- 제외)',
        class: 'receiving-number'
      }
    },
    management: {
      component: GSButton,
      props: {
        class: 'delete-btn',
        variant: Constants.BUTTON_VARIANTS.OUTLINED,
        size: Constants.BUTTON_SIZES.MEDIUM,
        color: Constants.BUTTON_COLORS.SECONDARY,
        'icon-position': Constants.BUTTON_ICONPOSITION.CENTER,
        width: 40
      }
    }
  },
  {
    number: {
      component: GSInput,
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: '숫자만 입력 (- 제외)',
        class: 'receiving-number'
      }
    },
    management: {
      component: GSButton,
      props: {
        class: 'delete-btn',
        variant: Constants.BUTTON_VARIANTS.OUTLINED,
        size: Constants.BUTTON_SIZES.MEDIUM,
        color: Constants.BUTTON_COLORS.SECONDARY,
        'icon-position': Constants.BUTTON_ICONPOSITION.CENTER,
        width: 40
      }
    }
  },
  {
    number: {
      component: GSInput,
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: '숫자만 입력 (- 제외)',
        class: 'receiving-number'
      }
    },
    management: {
      component: GSButton,
      props: {
        class: 'delete-btn',
        variant: Constants.BUTTON_VARIANTS.OUTLINED,
        size: Constants.BUTTON_SIZES.MEDIUM,
        color: Constants.BUTTON_COLORS.SECONDARY,
        'icon-position': Constants.BUTTON_ICONPOSITION.CENTER,
        width: 40
      }
    }
  },
  {
    number: {
      component: GSInput,
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: '숫자만 입력 (- 제외)',
        class: 'receiving-number'
      }
    },
    management: {
      component: GSButton,
      props: {
        class: 'delete-btn',
        variant: Constants.BUTTON_VARIANTS.OUTLINED,
        size: Constants.BUTTON_SIZES.MEDIUM,
        color: Constants.BUTTON_COLORS.SECONDARY,
        'icon-position': Constants.BUTTON_ICONPOSITION.CENTER,
        width: 40
      }
    }
  }
]
// 테스트 발송 Table 헤더
const testShippingHeader: DataTableHeader[] = [
  {
    align: 'start',
    title: '수신번호',
    value: 'number'
  },
  {
    align: 'start',
    title: 'name',
    value: 'names'
  },
  {
    align: 'start',
    title: 'save_money',
    value: 'saveMoney'
  },
  {
    align: 'start',
    title: 'save_money',
    value: 'saveMoneyTwo'
  },
  {
    align: 'start',
    title: 'save_money',
    value: 'saveMoneyThree'
  },
  {
    align: 'center',
    title: '관리',
    value: 'management'
  }
]
// 테스트 발송 table 데이터
const testShippingData = [
  {
    number: {
      component: markRaw(GSInput),
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: '숫자만 입력 (- 제외)',
        class: 'receiving-number'
      }
    },
    names: {
      component: markRaw(GSInput),
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: '이름 입력'
      }
    },
    saveMoney: {
      component: markRaw(GSInput),
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: 'save_money 값 입력',
        class: 'receiving-number'
      }
    },
    saveMoneyTwo: {
      component: markRaw(GSInput),
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: 'save_money 값 입력',
        class: 'receiving-number'
      }
    },
    saveMoneyThree: {
      component: markRaw(GSInput),
      props: {
        variant: Constants.INPUT_VARIANTS.OUTLINED,
        size: Constants.INPUT_SIZES.MEDIUM,
        // 'desc-position': Constants.INPUT_POSITION.TOP,
        description: '',
        required: true,
        'show-counter': false,
        placeholder: 'save_money 값 입력',
        class: 'receiving-number'
      }
    },
    management: {
      component: markRaw(GSButton),
      props: {
        class: 'delete-btn',
        variant: Constants.BUTTON_VARIANTS.OUTLINED,
        size: Constants.BUTTON_SIZES.MEDIUM,
        color: Constants.BUTTON_COLORS.SECONDARY,
        'icon-position': Constants.BUTTON_ICONPOSITION.CENTER,
        width: 40,
        onClick: () => handleDeleteRegister()
      }
    }
  }
]
// 휴대폰 번호 형식 외 입력 후 등록하기 버튼 클릭 시
const warningAlert = ref(false)
// 데이터 입력 후 취소/X 버튼 클릭 시 (한 글자라도 입력 시)
const warningDataAlert = ref(false)

// 데이터 입력 후 취소/X 버튼 클릭 시 (한 글자라도 입력 시)
const handleRegister = () => {
  warningAlert.value = true
}
// 수신자 등록 - 휴대폰 번호 형식 외 입력후 등록하기 버튼 클릭 시
const handleWarningAction = (actionType: string) => {
  if (actionType === 'confirm') {
    warningAlert.value = false
  }
}
const handleDeleteRegister = () => {
  warningDataAlert.value = true
}
// 타겟팅 상세 조회 table 헤더
const targetHeader: DataTableHeader[] = [
  {
    align: 'center',
    title: '번호',
    value: 'number'
  },
  {
    align: 'center',
    title: 'CUST ID',
    value: 'custID'
  },
  {
    align: 'center',
    title: '수신번호',
    value: 'recivingNumber'
  }
]
// 타겟팅 상세 조회 table 데이터
const targetData = [
  {
    number: '',
    custID: '7561845',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '7561845',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '2234567',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '7561845',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '2234567',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '7561845',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '2234567',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '7561845',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '2234567',
    recivingNumber: '010 - **** - 7519'
  },
  {
    number: '',
    custID: '2234567',
    recivingNumber: '010 - **** - 7519'
  }
]

interface DataTableHeader {
  align?: 'start' | 'center' | 'end'
  title?: string
  value: string
}
// 템플릿 불러오기 헤더
const templateHeader: DataTableHeader[] = [
  {
    align: 'center',
    title: '채널 ID',
    value: 'channelID'
  },
  {
    align: 'center',
    title: '템플릿 ID',
    value: 'templateID'
  },
  {
    align: 'start',
    title: '템플릿명',
    value: 'templateName'
  },
  {
    align: 'center',
    title: '메시지 종류',
    value: 'messageType'
  },
  {
    align: 'start',
    title: 'Workspace',
    value: 'workspace'
  },
  {
    align: 'start',
    title: '서비스',
    value: 'service'
  },
  {
    align: 'center',
    title: '플러스친구 ID',
    value: 'plusFriendID'
  },
  {
    align: 'center',
    title: '상태',
    value: 'state'
  },
  {
    align: 'center',
    title: '버전',
    value: 'version'
  },
  {
    align: 'center',
    title: '작성자',
    value: 'writer'
  },
  {
    align: 'center',
    title: '생성일',
    value: 'creationDate'
  }
]
const templateData = [
  {
    channelID: 'SMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: '알림톡',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: '알림톡',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: 'LMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: 'MMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: 'SMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: 'LMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: 'SMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: 'LMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  },
  {
    channelID: 'MMS',
    templateID: 'template ID_example',
    templateName: '적립금 지급',
    messageType: '정보성',
    workspace: '우리동네GS 광고성 메시지',
    service: '우리동네GS',
    plusFriendID: '@우리동네GS',
    state: true,
    version: '4',
    writer: '이*미',
    creationDate: '2024.01.16  11:00:00'
  }
]

// 템플릿 불러오기 dialog
const templateDialog = ref(false)
const openTemplateDialog = () => {
  templateDialog.value = true
}
const closeTemplateDialog = () => {
  templateDialog.value = false
}
// 수신자 등록 dialog
const recipientDialog = ref(false)
const openRecipientDialog = () => {
  recipientDialog.value = true
}
const closeRecipientDialog = () => {
  recipientDialog.value = false
}

// 테스트 발송
const testShipping = ref(false)
const openTestShipping = () => {
  testShipping.value = true
}
const closeTestShipping = () => {
  testShipping.value = false
}

// 나의 Audience 필터 조회 dialog
const audienceFilterDialog = ref(false)
const openAudienceFilterDialog = () => {
  audienceFilterDialog.value = true
}
const closeAudienceFilterDialog = () => {
  audienceFilterDialog.value = false
}
// 타겟팅 상세 조회 dialog
const targetDialog = ref(false)
const openTargetDialog = () => {
  targetDialog.value = true
}
const closeTargetDialog = () => {
  targetDialog.value = false
}
/** workspace 조회
 * (발송화면 수신거부번호 및 콜백번호등 설정정보 조회) */
const fetchGetWorkspace = async () => {
  // const requestParam = {
  //   pageable: false
  // }
  await $axios
    .get(`/api/notification/workspaces/${workspaceInfo.value?.workspace_id}`, {
      // params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      console.log('response:::', response)
      if (response.data.data.length === 0) return
      // const responseData = commonResponse(response)
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      // openErrorDialog()
    })
}

/** Event 목록 조회 (이벤트 셀렉트 박스 목록 조회) */
const fetchGetEventList = async () => {
  const requestParam = {
    pageable: false
  }
  await $axios
    .get(`/api/notification/workspaces/${workspaceInfo.value?.workspace_id}/events`, {
      params: requestParam,
      headers: Constants.requestHeaders
    })
    .then((response) => {
      console.log('response:::', response)
      if (response.data.data.length === 0) return
      // const responseData = commonResponse(response)
    })
    .catch((error) => {
      console.error(error)
      commonError(error)
      // openErrorDialog()
    })
}

onMounted(() => {
  workspaceInfo.value = jsonParse(window.sessionStorage.getItem('workspace') || '{}')
  fetchGetWorkspace()
})
</script>
