export const SWITCHES_SIZES = {
  SMALL: 'gsr-switches-small',
  MEDIUM: 'gsr-switches-medium',
  LARGE: 'gsr-switches-large'
} as const

export const SWITCHES_COLORS = {
  PRIMARY: 'gsr-switches-primary',
  SECONDARY: 'gsr-switches-secondary',
  INHERIT: 'gsr-switches-inherit'
} as const

export const SWITCHES_POSITION = {
  DEFAULT: 'gsr-switches-default-position',
  LEFT: 'gsr-switches-left-position',
  TOP: 'gsr-switches-top-position'
} as const

// 타입 정의
export type SwitchesSizes = (typeof SWITCHES_SIZES)[keyof typeof SWITCHES_SIZES]
export type SwitchesColors = (typeof SWITCHES_COLORS)[keyof typeof SWITCHES_COLORS]
export type SwitchesPositions = (typeof SWITCHES_POSITION)[keyof typeof SWITCHES_POSITION]
