<template>
  <!-- 부서 -->
  <div class="department">
    <PageTitle :title="'부서'"></PageTitle>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('기본정보') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <GSSelects
            :variant="Constants.SELECTS_VARIANTS.OUTLINED"
            :size="Constants.SELECTS_SIZES.SMALL"
            style="width: 226px"
          ></GSSelects>
          <v-list>
            <v-list-item class="divider">
              <GSButton
                class="edit-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'편집'"
                width="67"
                @click="openAddDepartmentDialog"
              ></GSButton>
            </v-list-item>
            <v-list-item class="divider">
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'부서 추가'"
                width="96"
                @click="openAddDepartmentDialog"
              ></GSButton>
            </v-list-item>
          </v-list>
          <v-dialog v-model="addDepartmentDialog" class="gsr-dialog" width="600">
            <v-card>
              <div class="dialog-title-wrap">
                <strong class="dialog-title">부서 추가</strong>
                <div class="dialog-close-btn-box">
                  <v-btn class="dialog-close-btn" @click="closeAddDepartmentDialog"></v-btn>
                </div>
              </div>
              <div class="dialog-tab-contents-area">
                <div class="dialog-tab-title">
                  <span>부서정보</span>
                </div>
                <div class="dialog-tab-contents">
                  <ul class="dialog-tab-contents-list">
                    <li>
                      <GSInput
                        :variant="Constants.INPUT_VARIANTS.OUTLINED"
                        :size="Constants.INPUT_SIZES.MEDIUM"
                        :desc-position="Constants.INPUT_POSITION.TOP"
                        :description="'부서명'"
                        :required="true"
                        :placeholder="'부서명 입력'"
                      ></GSInput>
                    </li>
                    <li>
                      <GSSwitches
                        :size="Constants.SWITCHES_SIZES.LARGE"
                        :desc-position="Constants.SWITCHES_POSITION.TOP"
                        :description="'상태 활성화'"
                        :required="true"
                      ></GSSwitches>
                    </li>
                    <li>
                      <GSInput
                        :variant="Constants.INPUT_VARIANTS.OUTLINED"
                        :size="Constants.INPUT_SIZES.MEDIUM"
                        :desc-position="Constants.INPUT_POSITION.TOP"
                        :description="'설명'"
                        :placeholder="'설명 입력'"
                      ></GSInput>
                    </li>
                    <li>
                      <GSChipAutocompletes></GSChipAutocompletes>
                      <!-- <div class="gsr-input-top-position">
                        <div class="label-box">
                          <span>구성원</span>
                        </div>
                        <v-autocomplete
                        v-model="members"
                        class="gsr-chips-autocomplete":des
                        :items="organizationMembers"
                        item-title="name"
                        clearable
                        chips
                        closable-chips
                        multiple
                        placeholder="구성원 검색"
                        :menu-props="{ class: 'gsr-chips-autocomplete-layer'}"
                      >
                      <template v-slot:chip="{ props, item }">
                        <v-chip v-bind="props" :class="item.raw.active === true ? 'active' : 'deactivate'">
                          {{ item.raw.name }} &lt;{{ item.raw.id }}@gsretail.com&gt;
                          <i class="state-icon"></i>
                        </v-chip>
                      </template>
                        <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props">
                            <div class="org-member-info">
                              <v-avatar :image="item.raw.img" transition=""></v-avatar>
                              <div class="org-member-info-text">
                                <p class="text">{{ item.raw.name }} / {{ item.raw.org }}</p>
                                <p class="sub-text">({{ item.raw.id }}) {{ item.raw.position }}</p>
                              </div>
                            </div>
                          </v-list-item>
                        </template>
                        </v-autocomplete>
                      </div> -->
                    </li>
                  </ul>
                </div>
              </div>
              <div class="dialog-button-wrap">
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'닫기'"
                  width="57"
                  @click="closeAddDepartmentDialog"
                ></GSButton>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :text="'저장하기'"
                  width="81"
                ></GSButton>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="contents-profile-wrap">
        <div class="contents-profile-left">
          <div class="contents-profile-text">
            <span class="state">이용중</span>
            <span class="title">플랫폼 PBU</span>
            <span class="sub-title">PBU 내 메시징 부서</span>
          </div>
        </div>
        <div class="contents-profile-right">
          <div class="contents-profile-info">
            <span>생성일자</span>
            <p>2024.01.03 13:32:05</p>
          </div>
          <div class="contents-profile-info">
            <span>구성원</span>
            <v-expansion-panels>
              <v-expansion-panel>
                <template v-slot:title>
                  <p>{{ member.length }}명</p>
                </template>
                <template v-slot:text>
                  <ul class="accordion-contents-list">
                    <li v-for="(item, index) in member" :key="index">
                      <span class="info">{{ item.info }}</span>
                      <span class="state active" v-if="item.state === true">이용중</span>
                      <span class="state" v-if="item.state === false">이용안함</span>
                    </li>
                  </ul>
                </template>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('하위 부서목록') }} <span>34</span>{{ translate('건') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <GSAutocompletes></GSAutocompletes>
          <v-autocomplete
            class="gsr-input-autocomplete"
            :clearable="true"
            :items="deptDate"
            style="width: 200px"
            :menu-props="{ class: 'gsr-input-autocomplete-layer' }"
          ></v-autocomplete>
          <v-list>
            <v-list-item class="divider">
              <GSButton
                class="edit-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :text="'선택삭제'"
                width="73"
              ></GSButton>
            </v-list-item>
            <v-list-item>
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'부서 추가'"
                width="96"
              ></GSButton>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <div class="contents-data-table-wrap">
        <v-data-table
          :headers="deptListHeader"
          :items="deptListDate"
          :items-per-page="10"
          hide-default-footer
        >
          <template v-slot:colgroup>
            <colgroup>
              <col style="width: 240px" />
              <col style="width: 532px" />
              <col style="width: 420px" />
              <col style="width: 108px" />
              <col style="width: 180px" />
              <col style="width: auto" />
            </colgroup>
          </template>
          <template v-slot:item.member="{ item }">
            <ul class="member-list">
              <li v-for="(subItem, subIndex) in item.member" :key="subIndex">
                <span>{{ subItem }}</span>
              </li>
            </ul>
          </template>
          <template v-slot:item.state="{ item }">
            <span class="state active" v-if="item.state === true">이용중</span>
            <span class="state" v-if="item.state === false">이용안함</span>
          </template>
          <template v-slot:no-data>
            <div class="no-data-msg">
              <p>검색결과가 없습니다.<br />관리자에게 문의해주세요.</p>
            </div>
          </template>
        </v-data-table>
        <Pagination :dataLength="deptListDate.length"></Pagination>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">


import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSInput from '@/components/ui/GSInput.vue'
import GSSwitches from '@/components/ui/GSSwitches.vue'
import GSAutocompletes from '@/components/ui/GSAutocompletes.vue'
import GSChipAutocompletes from '@/components/ui/GSChipAutocompletes.vue'

import { ref } from 'vue'
import { useCommonI18n } from '@/core/plugins/global-util'

const { translate } = useCommonI18n()

const member = [
  { info: '서*수 (seohm1004@gsretail.com)', state: true },
  { info: '김*미 (haemi1004@gsretail.com)', state: true },
  { info: '조*주 (chomj90@gsretail.com)', state: true },
  { info: '박*복 (park.drangonb1@gsretail.com)', state: false }
]

const addDepartmentDialog = ref(false)
const openAddDepartmentDialog = () => {
  addDepartmentDialog.value = true
}
const closeAddDepartmentDialog = () => {
  addDepartmentDialog.value = false
}

// const members = ref();
// const organizationMembers = [
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미1', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동1', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리1', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미2', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동2', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리2', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미3', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동3', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리3', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미4', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동4', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리4', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미5', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동5', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리5', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미6', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동6', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리6', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미7', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동7', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리7', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미8', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동8', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리8', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미9', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동9', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리9', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
//   { img: '/src/assets/images/users_profile_1.jpg', active: true, name: '이해미10', org: 'DX COE부문', id: 'cutie7777', position: '본부장'},
//   { img: '/src/assets/images/users_profile_2.jpg', active: true, name: '김길동10', org: '플랫폼DX부문', id: 'Turf.haemi1004', position: '부문장'},
//   { img: '/src/assets/images/users_profile_3.jpg', name: '박빛나리10', org: '통합서비스TFT', id: 'smile7777', position: '팀장'},
// ]

const deptListHeader = [
  {
    align: 'start',
    title: '부서명',
    value: 'deptName'
  },
  {
    align: 'start',
    title: '설명',
    value: 'explain'
  },
  {
    align: 'start',
    title: '구성원',
    value: 'member'
  },
  {
    align: 'center',
    title: 'Workspace',
    value: 'workspace'
  },
  {
    align: 'center',
    title: '생성일',
    value: 'creationDate'
  },
  {
    align: 'center',
    title: '상태',
    value: 'state'
  }
]
const deptListDate = [
  {
    deptName: '우리동네GS팀',
    explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
    member: [
      '서*미 (seohm1004@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '조*주 (chomj90@gsretail.com)'
    ],
    workspace: '2',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    deptName: '수퍼DX팀',
    explain: 'GS더프레시 광고성 / 정보성 메시지 대량 발송 진행',
    member: [
      '서*미 (seohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)'
    ],
    workspace: '2',
    creationDate: '2024.01.16  11:00:00',
    state: false
  },
  {
    deptName: 'O4O기획팀',
    explain: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    member: [
      '서*미 (seohm1004@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)'
    ],
    workspace: '10',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    deptName: '우리동네GS팀',
    explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
    member: ['서*미 (seohm1004@gsretail.com)'],
    workspace: '2',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    deptName: '우리동네GS팀',
    explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
    member: [
      '서*미 (seohm1004@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '조*주 (chomj90@gsretail.com)'
    ],
    workspace: '2',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    deptName: '수퍼DX팀',
    explain: 'GS더프레시 광고성 / 정보성 메시지 대량 발송 진행',
    member: [
      '서*미 (seohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)'
    ],
    workspace: '2',
    creationDate: '2024.01.16  11:00:00',
    state: false
  },
  {
    deptName: 'O4O기획팀',
    explain: 'GS SHOP 상상초월 행사 고객 메시지 대량 발송 진행',
    member: [
      '서*미 (seohm1004@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)'
    ],
    workspace: '10',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    deptName: '통합서비스TFT팀',
    explain: '샌디 플랫폼 테스트 메시지 발송 진행',
    member: ['제갈*명 (jjjseohm1004@gsretail.com)'],
    workspace: '8',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    deptName: '통합서비스TFT팀',
    explain: '샌디 플랫폼 테스트 메시지 발송 진행',
    member: ['제갈*명 (jjjseohm1004@gsretail.com)', '제갈*명 (jjjseohm1004@gsretail.com)'],
    workspace: '9',
    creationDate: '2024.01.16  11:00:00',
    state: true
  },
  {
    deptName: '우리동네GS팀',
    explain: '우리동네GS 앱 광고메시지 / APP PUSH 대량 발송 진행',
    member: [
      '서*미 (seohm1004@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '제갈*명 (jjjseohm1004@gsretail.com)',
      '조*주 (chomj90@gsretail.com)',
      '조*주 (chomj90@gsretail.com)'
    ],
    workspace: '8',
    creationDate: '2024.01.16  11:00:00',
    state: true
  }
]
const deptDate = [
  '수퍼 DX팀',
  '수퍼 사업부',
  '수퍼 가맹영업부문',
  '수퍼 영업부문',
  '수퍼 지원부문',
  '수퍼 통합운영 차세대 TFT',
  '수퍼 신선 물류개선 TFT',
  '수퍼 지원부문',
  '수퍼 통합운영 차세대 TFT',
  '수퍼 신선 물류개선 TFT',
  '수퍼 지원부문',
  '수퍼 통합운영 차세대 TFT',
  '수퍼 신선 물류개선 TFT'
]
</script>
