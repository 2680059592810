<template>
  <div class="scroll-wrapper">
    <!-- 오전/오후 리스트 -->
    <div ref="amPmContainer" class="scroll-container ampm">
      <div
        v-for="(item, index) in amPmItems"
        :key="'amPm' + index"
        class="list-item"
        :class="{ selected: index === selectedAmPmIndex }"
        @click="moveToAmPmCenter(index)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 숫자 리스트 -->
    <div ref="scrollContainer" class="scroll-container" @scroll="onScroll">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="list-item"
        :class="{ selected: index === centerIndex }"
        @click="moveToCenter(index)"
      >
        {{ item }}
      </div>
    </div>
    <div ref="minuteContainer" class="scroll-container" @scroll="onScrollMinute">
      <div
        v-for="(item, index) in minutes"
        :key="'minute' + index"
        class="list-item"
        :class="{ selected: index === selectedMinuteIndex }"
        @click="moveToMinuteCenter(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="time-selected-section"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, watch, inject } from 'vue'
const emit = defineEmits(['update:time'])

// 오전/오후 리스트 생성
const amPmItems = ref(['오전', '오후'])
const selectedAmPmIndex = ref<number | null>(0)

// 01~12 리스트를 세 번 반복하여 생성
const baseList = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'))
const items = ref<string[]>([...baseList, ...baseList, ...baseList]) // 세 번 반복
const changeArray = ref<string[]>([])

const baseMinuteList = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'))
const minutes = ref<string[]>([...baseMinuteList, ...baseMinuteList, ...baseMinuteList]) // 세 번 반복

// 스크롤 컨테이너 참조
const amPmContainer = ref<HTMLDivElement | null>(null)
const scrollContainer = ref<HTMLDivElement | null>(null)
const minuteContainer = ref<HTMLDivElement | null>(null)

// 항목 높이
const itemHeight = 32

const centerIndex = ref<number | null>(17)
const selectedTime = ref<string>('')
const firstTime = ref<string>('')
const lastTime = ref<string>('')

const selectedMinuteIndex = ref<number | null>(17)
const selectedMinute = ref<string>('')

// 디바운싱 타이머
let scrollTimer: ReturnType<typeof setTimeout> | null = null
let minuteScrollTimer: ReturnType<typeof setTimeout> | null = null
const scrollType = ref<string>('')

let prevScrollTop = 448
// 이전 스크롤 위치 저장
let prevScrollCenter = 14 * itemHeight // 초기값 (중앙)
let prevScrollMinuteCenter = 87 * itemHeight // 초기값 (중앙)

// 애니메이션 실행 중인지 상태
let isScrolling = false
let clickFlag = false

// 스크롤 이벤트 핸들러
const onScroll = () => {
  if (isScrolling) return // 애니메이션 중에는 이벤트 무시

  const container = scrollContainer.value
  if (!container) return

  if (clickFlag) return

  // console.log('centerIndex :: ', centerIndex.value)
  if (centerIndex.value !== null) {
    selectedTime.value = items.value[centerIndex.value]
    // console.log('현재 선택된 값:', selectedValue)
  } else {
    if (selectedTime.value != '') {
      firstTime.value = selectedTime.value
    }
  }
  centerIndex.value = null

  const scrollTop = container.scrollTop

  // console.log('scrollTop :: ', scrollTop)

  if (scrollTop > prevScrollTop) {
    scrollType.value = 'down'
  } else if (scrollTop < prevScrollTop) {
    scrollType.value = 'up'
  }

  console.log('scrollType :: ', scrollType.value)
  console.log('첫번째 :: ', firstTime.value)
  console.log('종료   :: ', selectedTime.value)

  const movedItems = (() => {
    const diff = scrollTop - prevScrollCenter
    const sign = Math.sign(diff) // 스크롤 방향 (1: 아래, -1: 위)
    const absItems = Math.floor(Math.abs(diff) / itemHeight) // 절대 이동량
    return absItems >= 1 ? sign * absItems : 0 // 이동량이 1 이상일 때만 반환
  })()

  // console.log('movedItems :: ', movedItems)

  if (movedItems !== 0) {
    updateItems(movedItems) // 항목 업데이트
    container.scrollTop = prevScrollCenter // 스크롤 위치 고정
  }

  if (scrollTimer) {
    clearTimeout(scrollTimer)
  }

  scrollTimer = setTimeout(() => {
    centerScrollPosition()
  }, 200)
}

const onClickScroll = (currentScrollTop: number) => {
  clickFlag = true
  const container = scrollContainer.value
  if (!container) return

  const scrollTop = container.scrollTop
  const movedItems = (() => {
    const diff = scrollTop - currentScrollTop
    const sign = Math.sign(diff) // 스크롤 방향 (1: 아래, -1: 위)
    const absItems = Math.floor(Math.abs(diff) / itemHeight) // 절대 이동량
    return absItems >= 1 ? sign * absItems : 0 // 이동량이 1 이상일 때만 반환
  })()

  console.log('centerIndex :: ', centerIndex.value)
  console.log('firstTime :: ', firstTime.value)
  console.log('lastTime :: ', centerIndex.value)

  // if (centerIndex.value !== null) {
  //   selectedTime.value = items.value[centerIndex.value]
  //   // console.log('현재 선택된 값:', selectedValue)
  // } else {
  //   if (selectedTime.value != '') {
  //     firstTime.value = selectedTime.value
  //   }
  // }
  firstTime.value = lastTime.value

  if (currentScrollTop > scrollTop) {
    scrollType.value = 'up'
  } else {
    scrollType.value = 'down'
  }

  if (movedItems !== 0) {
    updateItems(movedItems) // 항목 업데이트
    container.scrollTop = currentScrollTop // 스크롤 위치 고정
  }

  if (scrollTimer) {
    clearTimeout(scrollTimer)
  }

  scrollTimer = setTimeout(() => {
    centerScrollPosition()
    clickFlag = false
  }, 200)
}

const onClickScrollMinute = (currentScrollTop: number) => {
  clickFlag = true
  const container = minuteContainer.value
  if (!container) return

  const scrollTop = container.scrollTop
  const movedItems = (() => {
    const diff = scrollTop - currentScrollTop
    const sign = Math.sign(diff) // 스크롤 방향 (1: 아래, -1: 위)
    const absItems = Math.floor(Math.abs(diff) / itemHeight) // 절대 이동량
    return absItems >= 1 ? sign * absItems : 0 // 이동량이 1 이상일 때만 반환
  })()

  if (movedItems !== 0) {
    updateMinutes(movedItems) // 항목 업데이트
    container.scrollTop = currentScrollTop // 스크롤 위치 고정
  }

  if (minuteScrollTimer) {
    clearTimeout(minuteScrollTimer)
  }

  minuteScrollTimer = setTimeout(() => {
    centerScrollPositionMinute()
    clickFlag = false
  }, 200)
}

// 스크롤 이벤트 핸들러 (분)
const onScrollMinute = () => {
  if (isScrolling) return // 애니메이션 중에는 이벤트 무시

  const container = minuteContainer.value
  if (!container) return

  if (clickFlag) return

  if (selectedMinuteIndex.value !== null) {
    selectedMinute.value = minutes.value[selectedMinuteIndex.value]
  }
  selectedMinuteIndex.value = null

  const scrollTop = container.scrollTop

  const movedItems = (() => {
    const diff = scrollTop - prevScrollMinuteCenter
    const sign = Math.sign(diff) // 스크롤 방향 (1: 아래, -1: 위)
    const absItems = Math.floor(Math.abs(diff) / itemHeight) // 절대 이동량
    return absItems >= 1 ? sign * absItems : 0 // 이동량이 1 이상일 때만 반환
  })()

  if (movedItems !== 0) {
    updateMinutes(movedItems) // 항목 업데이트
    container.scrollTop = prevScrollMinuteCenter // 스크롤 위치 고정
  }

  if (minuteScrollTimer) {
    clearTimeout(minuteScrollTimer)
  }

  minuteScrollTimer = setTimeout(() => {
    centerScrollPositionMinute()
  }, 200)
}

// 클릭 시 화면 중앙으로 이동
const moveToCenter = (index: number) => {
  const container = scrollContainer.value
  if (!container) return
  centerIndex.value = null
  const currentScrollTop = container.scrollTop
  // console.log('DOM 변경 전 scrollTop:', currentScrollTop)

  // 클릭된 요소를 중앙으로 스크롤
  const targetScrollTop = index * itemHeight - (7 * itemHeight) / 2 + itemHeight / 2

  isScrolling = true // 애니메이션 시작
  smoothScroll(container, targetScrollTop, () => {
    // console.log('targetScrollTop :: ', targetScrollTop)
    prevScrollCenter = targetScrollTop // 스크롤 완료 후 중앙값 업데이트
    isScrolling = false // 애니메이션 종료
    prevScrollCenter = currentScrollTop
    onClickScroll(currentScrollTop)
  })
  // onClickScroll(currentScrollTop)
}

const moveToMinuteCenter = (index: number) => {
  const container = minuteContainer.value
  if (!container) return
  selectedMinuteIndex.value = null
  const currentScrollTop = container.scrollTop

  const targetScrollTop = index * itemHeight - (7 * itemHeight) / 2 + itemHeight / 2

  isScrolling = true // 애니메이션 시작
  smoothScroll(container, targetScrollTop, () => {
    prevScrollMinuteCenter = targetScrollTop // 스크롤 완료 후 중앙값 업데이트
    isScrolling = false // 애니메이션 종료
    prevScrollMinuteCenter = currentScrollTop
    onClickScrollMinute(currentScrollTop)
  })
}

const changeAmPmArray = () => {
  if (amPmItems.value[0] === '오전') {
    amPmItems.value = ['오후', '오전']
  } else {
    amPmItems.value = ['오전', '오후']
  }
}

const moveToAmPmCenter = (index: number) => {
  const container = amPmContainer.value
  if (!container) return

  const targetScrollTop = index * itemHeight - (2 * itemHeight) / 2 + itemHeight / 2

  isScrolling = true // 애니메이션 시작
  smoothScroll(container, targetScrollTop, () => {
    isScrolling = false // 애니메이션 종료
    selectedAmPmIndex.value = index // 선택된 오전/오후 인덱스 업데이트
  })
}

// 부드러운 스크롤 애니메이션 함수
const smoothScroll = (element: HTMLElement, target: number, callback: () => void) => {
  const duration = 300 // 애니메이션 지속 시간 (ms)
  const start = element.scrollTop
  const change = target - start
  const startTime = performance.now()

  const animateScroll = (currentTime: number) => {
    const elapsedTime = currentTime - startTime
    const progress = Math.min(elapsedTime / duration, 1)

    // 이징 함수 (EaseInOutQuad)
    const ease =
      progress < 0.5
        ? 2 * progress * progress // 가속
        : -1 + (4 - 2 * progress) * progress // 감속

    element.scrollTop = start + change * ease

    if (progress < 1) {
      requestAnimationFrame(animateScroll)
    } else {
      callback() // 애니메이션 종료 시 콜백 호출
    }
  }

  requestAnimationFrame(animateScroll)
}

// 스크롤 중앙으로 위치시키는 함수
const centerScrollPosition = () => {
  const container = scrollContainer.value
  if (!container) return

  const scrollTop = container.scrollTop

  // console.log('scrollTop::', scrollTop)

  // 가장 가까운 항목의 중앙값으로 이동
  const closestItemIndex = Math.round(scrollTop / itemHeight)
  const targetScrollTop = closestItemIndex * itemHeight

  centerIndex.value = closestItemIndex + 3 // 4번째 요소는 +3

  isScrolling = true
  // 정확히 중앙으로 정렬
  container.scrollTop = targetScrollTop

  // 중앙 정렬 후 중앙값 갱신
  prevScrollCenter = targetScrollTop

  if (container.scrollTop > 480 || container.scrollTop < 416) {
    container.scrollTop = 448 // 스크롤 위치를 중앙(448)으로 설정
    prevScrollCenter = 448
    adjustItems() // 배열 조정
  }

  setTimeout(() => {
    isScrolling = false
    if (centerIndex.value !== null) {
      lastTime.value = items.value[centerIndex.value]
    }
    console.log('scrollType :: ', scrollType.value)
    console.log('첫번째 :: ', firstTime.value)
    console.log('마지막 :: ', lastTime.value)
    console.log('현재 changeArray:', changeArray.value)

    const firstNum = parseInt(firstTime.value, 10)

    // 문자열을 숫자로 변환
    const changeNumArray = changeArray.value.map((item) => parseInt(item, 10))
    const upQuotient = Math.floor((changeNumArray.length - firstNum) / 12)
    const downQuotient = Math.floor((changeNumArray.length - (13 - firstNum)) / 12)

    if (scrollType.value == 'up') {
      if (firstNum <= changeNumArray.length) {
        if (upQuotient % 2 === 0) {
          changeAmPmArray()
        }
      }
    } else if (scrollType.value == 'down') {
      if (12 - firstNum < changeNumArray.length) {
        if (downQuotient % 2 === 0) {
          changeAmPmArray()
        }
      }
    } else {
    }

    // if (changeNumArray.length >= 12) {
    //   const quotient = Math.floor(changeNumArray.length / 12)
    //   console.log("몫 :: ", quotient)
    //   if (quotient % 2 === 1) {
    //     if (selectedAmPmIndex.value === 0) {
    //       selectedAmPmIndex.value = 1
    //     } else {
    //       selectedAmPmIndex.value = 0
    //     }
    //   } else {

    //   }
    // } else {
    //   if (scrollType.value == 'up') {
    //     if (lastNum > firstNum) {
    //       console.log('마우스 스크롤 업 ! 첫번째 숫자가 마지막 수보다 작음!')
    //       if (selectedAmPmIndex.value === 0) {
    //         selectedAmPmIndex.value = 1
    //       } else {
    //         selectedAmPmIndex.value = 0
    //       }
    //     }
    //   } else if (scrollType.value == 'down') {
    //     if (firstNum > lastNum) {
    //       console.log('마우스 스크롤 다운 ! 첫번째 숫자가 마지막 수보다 큼!')
    //       if (selectedAmPmIndex.value === 0) {
    //         selectedAmPmIndex.value = 1
    //       } else {
    //         selectedAmPmIndex.value = 0
    //       }
    //     }
    //   } else {
    //   }
    // }

    changeArray.value = []

    // if (firstNum > lastNum) {
    //   if (scrollType.value == 'up') {
    //   } else if (scrollType.value == 'down') {
    //   } else {
    //   }
    // } else if (firstNum < lastNum) {
    //   if (scrollType.value == 'up') {
    //   } else if (scrollType.value == 'down') {
    //   } else {
    //   }
    // } else if (firstNum == lastNum) {
    //   if (scrollType.value == 'up') {
    //   } else if (scrollType.value == 'down') {
    //   } else {
    //   }
    // } else {
    //   if (scrollType.value == 'up') {
    //   } else if (scrollType.value == 'down') {
    //   } else {
    //   }
    // }

    // setTimeout(() => {
    //   centerIndex.value = closestItemIndex + 3 // 스크롤 이벤트가 끝난 후 0.5초 뒤에 중앙 인덱스 설정
    // }, 600)
  }, 0) // 다음 이벤트 루프에서 해제
  // console.log('container.scrollTop::', container.scrollTop)

  // isScrolling = true
  // if (container.scrollTop >= 480 || container.scrollTop <= 416) {
  //   smoothScroll(container, 448, () => {
  //     adjustItems() // 스크롤 애니메이션 완료 후 배열 조정
  //     isScrolling = false
  //   })
  // } else {
  //   // 정확히 중앙으로 정렬
  //   smoothScroll(container, 448, () => {
  //     prevScrollCenter = 448 // 중앙값 갱신
  //     isScrolling = false
  //   })
  // }
}

const centerScrollPositionMinute = () => {
  const container = minuteContainer.value
  if (!container) return

  const scrollTop = container.scrollTop

  console.log('scrollTop :: ', scrollTop, selectedMinuteIndex)

  const closestItemIndex = Math.round(scrollTop / itemHeight)
  const targetScrollTop = closestItemIndex * itemHeight

  selectedMinuteIndex.value = closestItemIndex + 3

  isScrolling = true
  container.scrollTop = targetScrollTop

  prevScrollMinuteCenter = targetScrollTop

  setTimeout(() => {
    isScrolling = false
  }, 0)
}

const adjustItems = () => {
  const numItemsToAdjust = 1 // 위아래로 자를 항목 수

  // 마지막 4개를 잘라서 맨 위로 추가
  const bottomItems = items.value.splice(-numItemsToAdjust)
  items.value.unshift(...bottomItems)

  // 첫 4개를 잘라서 맨 아래로 추가
  const topItems = items.value.splice(0, numItemsToAdjust)
  items.value.push(...topItems)

  // console.log('Adjusted items:', items.value)
}

// 배열 업데이트 함수
const updateItems = (movedItems: number) => {
  if (movedItems > 0) {
    for (let i = 0; i < movedItems; i++) {
      const removedItem = items.value.shift()
      if (removedItem) {
        changeArray.value.push(removedItem)
        const lastValue = parseInt(items.value[items.value.length - 1])
        const nextItem = String((lastValue % 12) + 1).padStart(2, '0')
        items.value.push(nextItem)
      }
    }
  } else if (movedItems < 0) {
    for (let i = 0; i < Math.abs(movedItems); i++) {
      const removedItem = items.value.pop()
      if (removedItem) {
        changeArray.value.push(removedItem)
        const firstValue = parseInt(items.value[0])
        const prevItem = String(((firstValue - 2 + 12) % 12) + 1).padStart(2, '0')
        items.value.unshift(prevItem)
      }
    }
  }
}

const updateMinutes = (movedItems: number) => {
  if (movedItems > 0) {
    for (let i = 0; i < movedItems; i++) {
      const removedItem = minutes.value.shift()
      if (removedItem) {
        const lastValue = parseInt(minutes.value[minutes.value.length - 1])
        const nextItem = String((lastValue + 1) % 60).padStart(2, '0')
        minutes.value.push(nextItem)
      }
    }
  } else if (movedItems < 0) {
    for (let i = 0; i < Math.abs(movedItems); i++) {
      const removedItem = minutes.value.pop()
      if (removedItem) {
        const firstValue = parseInt(minutes.value[0])
        const prevItem = String((firstValue - 1 + 60) % 60).padStart(2, '0')
        minutes.value.unshift(prevItem)
      }
    }
  }
}

// 초기 스크롤 위치 설정
const initScrollPosition = () => {
  const container = scrollContainer.value
  if (!container) return

  container.scrollTop = 14 * itemHeight // 중앙에 위치하도록 설정
  prevScrollCenter = container.scrollTop // 초기 스크롤 위치 저장
}

const initMinuteScrollPosition = () => {
  const container = minuteContainer.value
  if (!container) return

  container.scrollTop = 87 * itemHeight // 중앙에 위치하도록 설정

  prevScrollMinuteCenter = container.scrollTop // 초기 스크롤 위치 저장

  console.log('????', prevScrollMinuteCenter)
}

const handleTimeWheel = (event: any) => {
  event.preventDefault() // 기본 스크롤 방지
  const speedMultiplier = 0.5 // 원하는 속도 조절 비율
  scrollContainer.value?.scrollBy({
    top: event.deltaY * speedMultiplier,
    behavior: 'smooth'
  })
}

const handleMinuteWheel = (event: any) => {
  event.preventDefault() // 기본 스크롤 방지
  const speedMultiplier = 1.8 // 원하는 속도 조절 비율
  minuteContainer.value?.scrollBy({
    top: event.deltaY * speedMultiplier,
    behavior: 'smooth'
  })
}

onMounted(() => {
  initScrollPosition()
  initMinuteScrollPosition()
  if (scrollContainer.value) {
    scrollContainer.value.addEventListener('wheel', handleTimeWheel, { passive: false })
  }

  if (minuteContainer.value) {
    minuteContainer.value.addEventListener('wheel', handleMinuteWheel, { passive: false })
  }
})

onUnmounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('wheel', handleTimeWheel)
  }
  if (minuteContainer.value) {
    minuteContainer.value.removeEventListener('wheel', handleMinuteWheel)
  }
})
type TimeData = {
  ampm: string
  hour: string
  minute: string
}
type UpdateTimeFn = (time: TimeData) => void

// inject 시 타입 명시
const updateTime = inject<UpdateTimeFn>('updateTime')

// watch를 사용하여 값 변경 감지 및 emit
watch(
  [selectedAmPmIndex, centerIndex, selectedMinuteIndex],
  ([ampm, hour, minute]) => {
    if (ampm !== null && hour !== null && minute !== null) {
      const ampmText = amPmItems.value[ampm]
      const hourText = items.value[hour]
      const minuteText = minutes.value[minute]

      // inject된 함수를 사용하여 시간 업데이트
      updateTime?.({
        ampm: ampmText,
        hour: hourText,
        minute: minuteText
      })
    }
  },
  { immediate: true }
)
</script>
