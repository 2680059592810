export const RADIO_COLORS = {
  PRIMARY: 'gsr-radio-primary',
  SECONDARY: 'gsr-radio-secondary',
  INHERIT: 'gsr-radio-inherit'
} as const

export const RADIO_SIZES = {
  SMALL: 'gsr-radio-small',
  MEDIUM: 'gsr-radio-medium',
  LARGE: 'gsr-radio-large'
} as const

export const RADIO_POSITION = {
  DEFAULT: 'gsr-radio-default-position',
  LEFT: 'gsr-radio-left-position',
  TOP: 'gsr-radio-top-position'
} as const

export const RADIO_DIRECTION = {
  HORIZONTAL: 'gsr-radio-horizontal',
  VERTICAL: 'gsr-radio-vertical'
} as const

export const RADIO_VALIDTYPE = {
  SYNC: 'sync',
  MENUAL: 'menual'
} as const

export const RADIO_VALIDSTYLE = {
  BASIC: 'basic',
  TABLE: 'table'
} as const

// 타입 정의
export type RadioColors = (typeof RADIO_COLORS)[keyof typeof RADIO_COLORS]
export type RadioSizes = (typeof RADIO_SIZES)[keyof typeof RADIO_SIZES]
export type RadioPositions = (typeof RADIO_POSITION)[keyof typeof RADIO_POSITION]
export type Radiodirection = (typeof RADIO_DIRECTION)[keyof typeof RADIO_DIRECTION]
export type RadioValidtype = (typeof RADIO_VALIDTYPE)[keyof typeof RADIO_VALIDTYPE]
export type RadioValidstyle = (typeof RADIO_VALIDSTYLE)[keyof typeof RADIO_VALIDSTYLE]
