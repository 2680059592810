<template>
  <div class="sending-event-management">
    <!-- 타이틀 -->
    <div class="contents-title-wrap">
      <strong class="contents-title">{{ translate('이벤트 관리') }}</strong>
    </div>
    <!-- 검색 영역 -->
    <div class="data-table-search-area">
      <div class="search-option-area">
        <!-- 검색 - 기간 -->
        <div class="option-date">
          <div class="label-box">
            <span class="label required">{{ translate('기간') }}</span>
          </div>
          <v-btn-toggle mandatory class="date-range-btn-toggle">
            <v-btn
              v-for="(item, index) in datePeriod"
              :key="index"
              :class="{ active: activeIndex === index }"
              @click="activeDataBtn(index)"
              :disabled="isDisabled"
            >
              {{ item }}
            </v-btn>
          </v-btn-toggle>
          <v-menu v-model="openDatePicker" class="gsr-select-date" :close-on-content-click="false">
            <template #activator="{ props }">
              <v-form class="gsr-select-date-form" v-bind="props" @click="handleDateFormClick">
                <div class="gsr-select-date-input">
                  <v-text-field
                    placeholder="YYYY-MM-DD"
                    @input="(e) => formatDateInput(e, 'start')"
                    v-model="searchStartDate"
                    maxlength="10"
                  ></v-text-field>
                  ~
                  <v-text-field
                    placeholder="YYYY-MM-DD"
                    @input="(e) => formatDateInput(e, 'end')"
                    v-model="searchEndDate"
                  ></v-text-field>
                </div>
                <div class="gsr-select-date-clear-btn">
                  <v-btn></v-btn>
                </div>
              </v-form>
            </template>
            <v-card class="gsr-select-date-layer">
              <v-row no-gutters>
                <v-col>
                  <v-date-picker
                    hide-header
                    show-adjacent-months
                    v-model="selectedStartDate"
                    :max="selectedEndDate"
                  >
                  </v-date-picker>
                </v-col>
                <v-col>
                  <v-date-picker
                    hide-header
                    show-adjacent-months
                    v-model="selectedEndDate"
                    :min="selectedStartDate"
                  >
                  </v-date-picker>
                </v-col>
              </v-row>
              <v-row no-gutters class="gsr-select-date-btn-area">
                <GSButton
                  :class="'select-date-today-btn'"
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'오늘'"
                  :width="48"
                ></GSButton>
                <v-list class="gsr-select-date-btn-list">
                  <v-list-item>
                    <GSButton
                      :class="'select-date-reset-btn'"
                      :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.SECONDARY"
                      :width="32"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.INHERIT"
                      :text="'취소'"
                      :width="48"
                    ></GSButton>
                  </v-list-item>
                  <v-list-item>
                    <GSButton
                      :class="'select-date-confirm-btn'"
                      :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                      :size="Constants.BUTTON_SIZES.SMALL"
                      :color="Constants.BUTTON_COLORS.PRIMARY"
                      :text="'선택완료'"
                      :width="69"
                      @click="cofirmDate()"
                    ></GSButton>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-card>
          </v-menu>
        </div>
        <!-- 검색 - 이벤트 -->
        <div class="gsr-select-autocomplete">
          <div class="label-box">
            <span>{{ translate('이벤트') }}</span>
          </div>
          <v-menu :close-on-content-click="false" transition="fade-transition">
            <template #activator="{ props }">
              <v-btn v-bind="props" class="gsr-select-autocomplete-activator">
                <span>{{ translate('전체') }}</span>
              </v-btn>
            </template>
            <v-autocomplete
              :offset-y="true"
              transition="slide-y-transition"
              menu
              :clearable="true"
              :items="eventList"
              placeholder="이벤트명 입력"
              class="gsr-select-autocomplete-search"
              :menu-props="{ class: 'gsr-select-autocomplete-search-list' }"
              :close-on-content-click="false"
            >
              <template v-slot:prepend-inner>
                <span class="title">이벤트 검색</span>
              </template>
            </v-autocomplete>
          </v-menu>
        </div>
      </div>
      <div class="search-btn-area">
        <!-- 리셋 버튼 -->
        <GSButton
          class="reset-btn"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
          :width="32"
        ></GSButton>
        <!-- 조회 버튼 -->
        <GSButton
          :variant="Constants.BUTTON_VARIANTS.CONTAINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.PRIMARY"
          :text="'조회'"
          :width="128"
          @click="openDialog"
        ></GSButton>
      </div>
      <!-- 조회 기간 dialog -->
      <v-dialog v-model="dialog" class="dialog-layer" width="400">
        <v-card class="gsr-popup">
          <div class="gsr-popup-icon">
            <i></i>
          </div>
          <p class="gsr-popup-text">1년 이내로 설정하여 조회해 주세요.</p>
          <div class="gsr-popup-btn">
            <v-btn ref="confirmBtn" class="confirm" @click="closeDialog">확인</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
  <!-- 테이블 영역 -->
    <div class="contents-area">
      <div class="contents-sub-title-wrap">
        <div class="contents-sub-title-text">
          <strong class="contents-sub-title">
            {{ translate('조회내역') }} <span>2,999</span
            >{{ translate('건') }}
          </strong>
        </div>
        <div class="contents-sub-title-button">
          <ul>
            <li>
              <v-checkbox
                label="내가 등록한 이벤트만 보기"
                class="gsr-checkbox-medium gsr-checkbox-primary"
              ></v-checkbox>
            </li>
            <li>
              <GSSelects
                :itemsList="dataView"
                :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                :size="Constants.SELECTS_SIZES.SMALL"
                :width="114"
              ></GSSelects>
            </li>
            <li>
              <GSButton
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :text="'선택삭제'"
                :width="73"
              ></GSButton>
              <GSButton
                class="add-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.LEFT"
                :text="'이벤트 추가'"
                :width="106"
                @click="openAddEventDialog"
              ></GSButton>
            </li>
          </ul>
        </div>
      </div>
      <div class="contents-data-table-wrap">
        <v-data-table
          :headers="eventTableHeader"
          :items="eventTableData"
          :items-per-page="10"
          hide-default-footer
        >
          <template v-slot:colgroup>
            <col style="width: auto; min-width: 300px;" />
            <col style="width: 280px" />
            <col style="width: 120px" />
            <col style="width: 280px" />
            <col style="width: 300px" />
          </template>
          <template v-slot:item.name="{ item }">
            <button type="button" @click="openEditEventDialog">{{ item.name }}</button>
          </template>
          <template v-slot:item.state="{ item }">
            <span v-if="item.state === true" class="state active">이용중</span>
            <span v-else-if="item.state === false">이용안함</span>
          </template>
          <template #no-data>
            <p>해당 내역이 없습니다.</p>
          </template>
        </v-data-table>
        <Pagination :dataLength="eventTableData.length"></Pagination>
      </div>
    </div>
    <v-dialog v-model="addEventDialog" class="gsr-dialog event-management-layer" width="600">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">이벤트 등록</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeAddEventDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-tab-contents-wrap">
          <div class="dialog-tab-contents-area">
            <div class="guide-info">
              <strong class="title">이벤트란?</strong>
              <p class="text">
                이벤트를 설정하여 발송하면 이벤트의 발송 결과, 통계 용도로 분석할 수 있습니다.
              </p>
            </div>
          </div>
          <div class="dialog-tab-contents-area">
            <div class="dialog-tab-title">
              <span>기본정보</span>
            </div>
            <div class="dialog-tab-contents">
              <ul class="dialog-tab-contents-list">
                <li>
                  <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'이벤트명'"
                  :required="true"
                  :placeholder="'이벤트명 입력'"
                  ></GSInput>
                </li>
                <li>
                  <div class="gsr-input-top-position">
                    <div class="label-box">
                      <span class="required">이벤트 기간</span>
                    </div>
                    <GSDatepicker
                    ></GSDatepicker>
                  </div>
                </li>
                <li>
                  <GSSwitches
                    :size="Constants.SWITCHES_SIZES.LARGE"
                    :desc-position="Constants.SWITCHES_POSITION.TOP"
                    :description="'진행상태'"
                    :required="true"
                  ></GSSwitches>
                </li>
                <li>
                  <div class="gsr-textarea-counter">
                    <div class="label-box">
                      <span class="required">메시지 내용</span>
                    </div>
                    <v-textarea
                      :maxlength="150"
                      :placeholder="'이벤트 설명 입력'"
                      :height="200"
                    >
                      <template v-slot:counter="{ value, max }">
                        <span>{{ value }}/{{ max }}자</span>
                      </template>
                    </v-textarea>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'취소'"
            :width="57"
            @click="closeAddWorkspaceDialog"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'저장하기'"
            :width="81"
          ></GSButton>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editEventDialog" class="gsr-dialog event-management-layer" width="600">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">이벤트 수정</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeAddEventDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-tab-contents-wrap">
          <div class="dialog-tab-contents-area">
            <div class="guide-info">
              <strong class="title">이벤트란?</strong>
              <p class="text">
                이벤트를 설정하여 발송하면 이벤트의 발송 결과, 통계 용도로 분석할 수 있습니다.
              </p>
            </div>
          </div>
          <div class="dialog-tab-contents-area">
            <div class="dialog-tab-title">
              <span>기본정보</span>
            </div>
            <div class="dialog-tab-contents">
              <ul class="dialog-tab-contents-list">
                <li>
                  <GSInput
                  :variant="Constants.INPUT_VARIANTS.OUTLINED"
                  :size="Constants.INPUT_SIZES.MEDIUM"
                  :desc-position="Constants.INPUT_POSITION.TOP"
                  :description="'이벤트명'"
                  :required="true"
                  :placeholder="'이벤트명 입력'"
                  ></GSInput>
                </li>
                <li>
                  <div class="gsr-input-top-position">
                    <div class="label-box">
                      <span class="required">이벤트 기간</span>
                    </div>
                    <GSDatepicker
                    ></GSDatepicker>
                  </div>
                </li>
                <li>
                  <GSSwitches
                    :size="Constants.SWITCHES_SIZES.LARGE"
                    :desc-position="Constants.SWITCHES_POSITION.TOP"
                    :description="'진행상태'"
                    :required="true"
                  ></GSSwitches>
                </li>
                <li>
                  <div class="gsr-textarea-counter">
                    <div class="label-box">
                      <span class="required">메시지 내용</span>
                    </div>
                    <v-textarea
                      :maxlength="150"
                      :placeholder="'이벤트 설명 입력'"
                      :height="200"
                    >
                      <template v-slot:counter="{ value, max }">
                        <span>{{ value }}/{{ max }}자</span>
                      </template>
                    </v-textarea>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'취소'"
            :width="57"
            @click="closeAddWorkspaceDialog"
          ></GSButton>
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.CONTAINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.PRIMARY"
            :text="'저장하기'"
            :width="81"
          ></GSButton>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
// S: 다국어
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()
const i18n = useI18n()

i18n.locale.value = 'kr'

// @ts-ignore
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}
// E: 다국어

import { ref } from 'vue'
import * as Constants from '@/constants/index'

import GSButton from '@/components/ui/GSButton.vue'
import GSMultipleTimepicker from '@/components/ui/GSMultipleTimepicker.vue';
import GSDatepicker from '@/components/ui/GSDatepicker.vue';
import GSInput from '@/components/ui/GSInput.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSSwitches from '@/components/ui/GSSwitches.vue'
import Pagination from '@/components/common/Pagination.vue'

const getTodayDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 +1
  const day = String(today.getDate()).padStart(2, '0') // 날짜를 2자리로 맞춤
  return `${year}-${month}-${day}`
}

const cofirmDate = () => {
  console.log('확인')
  searchStartDate.value = changeDate(selectedStartDate.value)
  searchEndDate.value = changeDate(selectedEndDate.value)
  openDatePicker.value = false
}

const openDatePicker = ref(false)
const searchStartDate = ref(getTodayDate())
const searchEndDate = ref(getTodayDate())
const selectedStartDate = ref<Date | null>(null)
const selectedEndDate = ref<Date | null>(null)

const changeDate = (date: Date | null) => {
  return date ? date.toLocaleDateString('en-CA') : ''
}

const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate() // month는 1월이 0, 2월이 1로 시작됨
}

const formatDateInput = (e: Event, type: string) => {
  let input = (e.target as HTMLInputElement).value.replace(/\D/g, '') // 숫자 외 문자 제거

  // 4번째 입력 시 하이픈(-) 추가
  if (input.length > 4) {
    input = `${input.slice(0, 4)}-${input.slice(4)}`
  }

  // 7번째 입력 시 하이픈(-) 추가
  if (input.length > 7) {
    input = `${input.slice(0, 7)}-${input.slice(7)}`
  }

  // 입력 길이를 10자로 제한 (YYYY-MM-DD)
  if (input.length > 10) {
    input = input.slice(0, 10)
  }

  // 유효한 MM과 DD 입력을 위한 제어
  const year = parseInt(input.slice(0, 4), 10)
  const month = parseInt(input.slice(5, 7), 10)
  const day = parseInt(input.slice(8, 10), 10)

  // MM에 대한 유효성 검사 (01~12)
  if (month && (month < 1 || month > 12)) {
    input = `${input.slice(0, 4)}-` // 잘못된 월 입력 시, 하이픈 뒤로 잘라냄
  }

  // DD에 대한 유효성 검사 (1~월별 최대 일수)
  if (month && day && (day < 1 || day > daysInMonth(year, month))) {
    input = `${input.slice(0, 7)}-` // 잘못된 일 입력 시, 일 부분을 잘라냄
  }

  // 필드에 따라 값을 저장 (start 또는 end)
  if (type === 'start') {
    searchStartDate.value = input
  } else if (type === 'end') {
    searchEndDate.value = input
  }
}

// 검색 영역 - 기간
const datePeriod = ref(['오늘', '1개월', '3개월', '6개월'])
// 검색 영역 - 이벤트
const eventList = ref([
  '전체',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_주문완료_M커머스_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_출고완료_M커머스_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_출고완료_M커머스_알림톡'
])
// 조회 내역
const dataView = ref([
  {
    label: '10개씩 보기',
    value: '10'
  },
  {
    label: '20개씩 보기',
    value: '20'
  },
  {
    label: '30개씩 보기',
    value: '30'
  }
])

interface DataTableHeader {
  align?: 'start' | 'center' | 'end'
  title?: string
  value: string
  replaceChannelCharge?: string
  sortable?: boolean
}
// 데이터 테이블 header
const eventTableHeader = ref<DataTableHeader[]>([
  {
    align: 'start',
    title: '이벤트명',
    value: 'name',
  },
  {
    align: 'center',
    title: '이벤트 기간',
    value: 'period',
  },
  {
    align: 'center',
    title: '진행상태',
    value: 'state',
  },
  {
    align: 'center',
    title: '등록일',
    value: 'registrationDate',
  },
  {
    align: 'start',
    title: '등록자',
    value: 'registrant',
  },
])
// 데이터 테이블 data
const eventTableData = ref([
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '김*해미/고객마케팅팀'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: false,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '김*해미/고객마케팅팀'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '김*해미/고객마케팅팀'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '서*미/플랫폼DX부문'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '김*해미/고객마케팅팀'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: false,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '이*/전시DX팀'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: false,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '김*빛나리/고객마케팅팀'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '김*빛나리/고객마케팅팀'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '조*미/통합서비스TFT'
  },
  {
    name: '(무통장)입금확인 완료안내_T커머스, TC리모콘',
    period: '2024.08.01 ~ 2024.08.31',
    state: true,
    registrationDate: '2024.08.01 11:00:00',
    registrant: '조*미/통합서비스TFT'
  },
])
// 검색 영역 - 기간 캘린더
const handleDateFormClick = (event) => {
  if (openDatePicker.value) {
    event.stopPropagation() // 메뉴가 열렸다면 클릭 전파를 막음
  } else {
    openDatePicker.value = true // 메뉴를 열기
  }
}

const addEventDialog = ref(false);
const openAddEventDialog = () => {
  addEventDialog.value = true
}
const closeAddEventDialog = () => {
  addEventDialog.value = false
}
const editEventDialog = ref(false);
const openEditEventDialog = () => {
  editEventDialog.value = true
}
const closeEditEventDialog = () => {
  editEventDialog.value = false
}
</script>
