<template>
  <div class="sending-history-inquiry">
    <PageTitle :title="'발송내역 조회'"></PageTitle>
    <v-tabs height="54" v-model="tab" class="contents-tabs">
      <v-tab width="122">
        {{ translate('채널별 조회') }}
      </v-tab>
      <v-tab width="138">
        {{ translate('발송예약 조회') }}
      </v-tab>
    </v-tabs>
    <v-tabs-window v-model="tab">
      <!-- 채널별 조회 -->
      <v-tabs-window-item class="channel">
        <div class="data-table-search-area">
          <div class="search-option-area">
            <!-- 검색 - 기간 -->
            <div class="option-date">
              <div class="label-box">
                <span class="label required">{{ translate('기간') }}</span>
              </div>
              <v-btn-toggle mandatory class="date-range-btn-toggle">
                <!-- <v-btn
                  v-for="(item, index) in datePeriod"
                  :key="index"
                  :class="{ active: activeIndex === index }"
                  @click="activeDataBtn(index)"
                  :disabled="isDisabled"
                > -->
                <v-btn
                  v-for="(item, index) in datePeriod"
                  :key="index"
                  :class="{ active: activeIndex === index }"
                  :disabled="isDisabled"
                >
                  {{ item }}
                </v-btn>
              </v-btn-toggle>
              <GSDatepicker></GSDatepicker>
            </div>
            <!-- 검색 - 채널 -->
            <div class="option-select">
              <div class="label-box">
                <span class="label">{{ translate('채널') }}</span>
              </div>
              <v-select
                class="gsr-selects-outlined gsr-selects-small"
                v-model="channelList[0]"
                :items="channelList"
              >
              </v-select>
            </div>
            <!-- 검색 - 발송상태 -->
            <div class="option-select">
              <div class="label-box">
                <span class="label">{{ translate('발송상태') }}</span>
              </div>
              <v-select
                class="gsr-selects-outlined gsr-selects-small"
                v-model="stateList[0]"
                :items="stateList"
              >
              </v-select>
            </div>
            <!-- 검색 - 이벤트 -->
            <div class="gsr-select-autocomplete">
              <div class="label-box">
                <span>{{ translate('이벤트') }}</span>
              </div>
              <v-menu :close-on-content-click="false" transition="fade-transition">
                <template #activator="{ props }">
                  <v-btn v-bind="props" class="gsr-select-autocomplete-activator">
                    <span>{{ translate('전체') }}</span>
                  </v-btn>
                </template>
                <v-autocomplete
                  :offset-y="true"
                  transition="slide-y-transition"
                  menu
                  :clearable="true"
                  :items="workspaceList"
                  placeholder="검색어 입력"
                  class="gsr-select-autocomplete-search"
                  :menu-props="{ class: 'gsr-select-autocomplete-search-list' }"
                  :close-on-content-click="false"
                >
                  <template v-slot:prepend-inner>
                    <span class="title">이벤트 검색</span>
                  </template>
                </v-autocomplete>
              </v-menu>
            </div>
          </div>
          <div class="search-btn-area">
            <!-- 리셋 버튼 -->
            <GSButton
              class="reset-btn"
              :variant="Constants.BUTTON_VARIANTS.OUTLINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.SECONDARY"
              :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
              :width="32"
            ></GSButton>
            <!-- 조회 버튼 -->
            <GSButton
              :variant="Constants.BUTTON_VARIANTS.CONTAINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.PRIMARY"
              :text="'조회'"
              :width="128"
              @click="openDialog"
            ></GSButton>
          </div>
        </div>
        <div class="contents-area">
          <div class="contents-sub-title-wrap">
            <div class="contents-sub-title-text">
              <strong class="contents-sub-title">
                {{ translate('조회내역') }} <span>{{ channelResult }}</span
                >{{ translate('건') }}
              </strong>
            </div>
            <div class="contents-sub-title-button">
              <ul>
                <li>
                  <v-checkbox
                    label="내가 등록한 메시지만 보기"
                    class="gsr-checkbox-medium gsr-checkbox-primary"
                  ></v-checkbox>
                </li>
                <li>
                  <GSSelects
                    :defaultValue="{ label: '10개씩 보기', value: '10' }"
                    :itemsList="dataView"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.SMALL"
                    :width="114"
                  ></GSSelects>
                </li>
              </ul>
            </div>
          </div>
          <div class="contents-data-table-wrap">
            <v-data-table
              :headers="channelTableHeader"
              :items="channelTableData"
              :items-per-page="10"
              :class="channelTableHeader.length > 12 ? 'scroll-x' : ''"
              hide-default-footer
            >
              <template v-slot:colgroup>
                <col style="width: 84px" />
                <col style="width: 190px" />
                <col style="width: 101px" />
                <col style="width: 150px" />
                <col style="width: 376px" />
                <col style="width: 81px" />
                <col style="width: 160px" />
                <col style="width: 160px" />
                <col style="width: 160px" />
                <col style="width: 101px" />
                <col style="width: 160px" />
                <col style="width: 140px" />
                <col style="width: 140px" />
                <col style="width: 240px" />
              </template>
              <template v-slot:item.contents="{ item }">
                <button type="button" @click="openMsgContentsDialog">{{ item.contents }}</button>
              </template>
              <template v-slot:item.state="{ item }">
                <span v-if="item.state === '취소'" class="cancel">취소</span>
                <span v-else-if="item.state === '처리중'" class="processing">처리중</span>
                <span v-else>{{ item.state }}</span>
              </template>
              <template v-slot:item.success="{ item }">
                <button type="button" @click="openSuccessTargetDialog">{{ item.success }}</button>
              </template>
              <template v-slot:item.fail="{ item }">
                <button type="button" @click="openFailTargetDialog">{{ item.fail }}</button>
              </template>
              <template v-slot:item.replaceSuccess="{ item }">
                <button>{{ item.replaceSuccess }}</button>
              </template>
              <template v-slot:item.replaceFail="{ item }">
                <button>{{ item.replaceFail }}</button>
              </template>
              <template #no-data>
                <p>해당 내역이 없습니다.</p>
              </template>
            </v-data-table>
            <Pagination :dataLength="channelTableData.length"></Pagination>
          </div>
        </div>
      </v-tabs-window-item>
      <!-- 발송예약 조회 -->
      <v-tabs-window-item class="reservation">
        <div class="data-table-search-area no-wrap">
          <div class="search-option-area">
            <!-- 검색 - 이벤트 -->
            <div class="gsr-select-autocomplete">
              <div class="label-box">
                <span class="required">{{ translate('조직') }}</span>
              </div>
              <v-menu :close-on-content-click="false" transition="fade-transition">
                <template #activator="{ props }">
                  <v-btn v-bind="props" class="gsr-select-autocomplete-activator">
                    <span>{{ translate('전체') }}</span>
                  </v-btn>
                </template>
                <v-autocomplete
                  :offset-y="true"
                  transition="slide-y-transition"
                  menu
                  :clearable="true"
                  :items="organizationList"
                  placeholder="검색어 입력"
                  class="gsr-select-autocomplete-search"
                  :menu-props="{ class: 'gsr-select-autocomplete-search-list' }"
                  :close-on-content-click="false"
                >
                  <template v-slot:prepend-inner>
                    <span class="title">이벤트 검색</span>
                  </template>
                </v-autocomplete>
              </v-menu>
            </div>
            <!-- 검색 - 기간 -->
            <div class="option-date">
              <div class="label-box">
                <span class="label required">{{ translate('기간') }}</span>
              </div>
              <v-btn-toggle mandatory class="date-range-btn-toggle">
                <v-btn
                  v-for="(item, index) in datePeriod"
                  :key="index"
                  :class="{ active: activeIndex === index }"
                  :disabled="isDisabled"
                >
                  {{ item }}
                </v-btn>
              </v-btn-toggle>
              <GSDatepicker></GSDatepicker>
            </div>
            <div class="search-btn-area">
              <!-- 리셋 버튼 -->
              <GSButton
                class="reset-btn"
                :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.SECONDARY"
                :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
                :width="32"
              ></GSButton>
              <!-- 조회 버튼 -->
              <GSButton
                :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                :size="Constants.BUTTON_SIZES.SMALL"
                :color="Constants.BUTTON_COLORS.PRIMARY"
                :text="'조회'"
                :width="128"
                @click="openDialog"
              ></GSButton>
            </div>
          </div>
        </div>
        <div class="guide-info">
          <strong class="title"
            >내가 등록한 메시지는 발송 예약한 시간 6분 전까지 예약 취소할 수 있어요</strong
          >
        </div>
        <div class="contents-area">
          <div class="contents-sub-title-wrap">
            <div class="contents-sub-title-text">
              <strong class="contents-sub-title">
                {{ translate('조회내역') }} <span>{{ reservationResult }}</span
                >{{ translate('건') }}
              </strong>
            </div>
            <div class="contents-sub-title-button">
              <ul>
                <li>
                  <v-checkbox
                    label="내가 등록한 메시지만 보기"
                    class="gsr-checkbox-medium gsr-checkbox-primary"
                  ></v-checkbox>
                </li>
                <li>
                  <GSSelects
                    :defaultValue="{ label: '10개씩 보기', value: '10' }"
                    :itemsList="dataView"
                    :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                    :size="Constants.SELECTS_SIZES.SMALL"
                    :width="114"
                  ></GSSelects>
                </li>
              </ul>
            </div>
          </div>
          <div class="contents-data-table-wrap">
            <v-data-table
              :headers="reservationTableHeader"
              :items="reservationTableData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:colgroup>
                <col style="width: 100px" />
                <col style="width: 240px" />
                <col style="width: 180px" />
                <col style="width: auto; min-width: 220px" />
                <col style="width: 200px" />
                <col style="width: 240px" />
                <col style="width: 140px" />
              </template>
              <template v-slot:item.contents="{ item }">
                <button type="button" @click="openMsgCheckDialog">{{ item.contents }}</button>
              </template>
              <template v-slot:item.reservation="{ item }">
                <button type="button" @click="openTargetingInfoDialog">
                  {{ item.reservation }}
                </button>
              </template>
              <template v-slot:item.cancel="{ item }">
                <span v-if="item.cancel === false" class="cancel">취소불가</span>
                <GSButton
                  v-else-if="item.cancel === true"
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.TINY"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :text="'예약취소'"
                  :width="73"
                ></GSButton>
              </template>
              <template #no-data>
                <p>해당 내역이 없습니다.</p>
              </template>
            </v-data-table>
            <Pagination :dataLength="channelTableData.length"></Pagination>
          </div>
        </div>
      </v-tabs-window-item>
    </v-tabs-window>

    <!-- 메시지 내용 dialog -->
    <v-dialog v-model="msgContentsDialog" class="gsr-dialog msg-contents-layer" width="1200">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">메시지 내용</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeMsgContentsDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="data-table-search-area no-wrap">
            <div class="search-option-area">
              <div class="option-radio">
                <div class="label-box">
                  <span class="label">{{ translate('수신인') }}</span>
                </div>
                <div class="gsr-radio">
                  <div class="gsr-radio-horizontal">
                    <v-radio-group v-model="sendingTypeValue">
                      <v-radio class="v-radio gsr-radio-primary gsr-radio-large" value="1">
                        <template #label>CUST ID</template>
                      </v-radio>
                      <v-radio class="v-radio gsr-radio-primary gsr-radio-large" value="2">
                        <template #label>
                          수신번호
                          <GSInput
                            :placeholder="'CUST ID 입력'"
                            :variant="Constants.INPUT_VARIANTS.OUTLINED"
                            :size="Constants.INPUT_SIZES.SMALL"
                            :desc-position="Constants.INPUT_POSITION.TOP"
                            :width="284.5"
                          ></GSInput>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <div class="search-btn-area">
                <GSButton
                  class="reset-btn"
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
                  :width="32"
                ></GSButton>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :text="'조회'"
                  :width="128"
                  @click="openDialog"
                ></GSButton>
              </div>
            </div>
          </div>
          <div class="msg-view-history-wrap">
            <div class="msg-view-table-area">
              <div class="contents-area">
                <div class="contents-sub-title-wrap">
                  <div class="contents-sub-title-text">
                    <strong class="contents-sub-title">
                      {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
                    </strong>
                  </div>
                  <div class="contents-sub-title-button">
                    <ul>
                      <li>
                        <GSSelects
                          :defaultValue="{ label: '10개씩 보기', value: '10' }"
                          :itemsList="dataView"
                          :variant="Constants.SELECTS_VARIANTS.OUTLINED"
                          :size="Constants.SELECTS_SIZES.SMALL"
                          :width="114"
                        ></GSSelects>
                      </li>
                      <li>
                        <GSButton
                          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                          :size="Constants.BUTTON_SIZES.SMALL"
                          :color="Constants.BUTTON_COLORS.SECONDARY"
                          :text="'발송 Templates 확인'"
                          :width="146"
                        ></GSButton>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="contents-data-table-wrap">
                  <v-data-table
                    :headers="msgContentsHeader"
                    :items="msgContentsData"
                    :items-per-page="10"
                    hide-default-footer
                  >
                    <template v-slot:colgroup>
                      <colgroup>
                        <col style="width: 60px" />
                        <col style="width: auto" />
                      </colgroup>
                    </template>
                    <template v-slot:body="{ items }">
                      <tr
                        v-for="(item, index) in items"
                        :key="index"
                        :class="{ active: selectedRow === index }"
                        @click="selectRow(index)"
                      >
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td class="id">
                          {{ item.id }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <p>발송 대상자 목록을 클릭하시면 발송한 메시지 내용을 확인할 수 있습니다.</p>
                </div>
              </div>
            </div>
            <div class="msg-view-area">
              <div class="msg-view-box">
                <span class="channel-name">발송 채널명</span>
                <div class="msg-area">
                  <div class="msg-header">
                    <span>알림톡 도착</span>
                  </div>
                  <div class="msg-contents">
                    <strong class="title"> [GS SHOP] 티꿀#{교환기간}안내 </strong>
                    <p class="text">
                      #{O월}지급된 ‘티꿀‘ 교환기간이 오늘 종료됩니다.<br />
                      <br />
                      ■ 교환가능 티꿀#{: 5,000티꿀}<br />
                      ■ 교환기간#{:8/31(토) 23:59까지}<br />
                      #{주의사항}<br />
                      <br />
                      #{□티꿀사용방법}<br />
                      <br />
                      #{유의사항}<br />
                      #{유의사항}<br />
                      - 이 메시지는 고객님의 이벤트 신청으로 지급된 티꿀 교환기간 안내
                      메시지입니다.<br />
                    </p>
                    <div class="btn">
                      <span>버튼명</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination :dataLength="msgContentsData.length"></Pagination>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            @click="closeDialog"
            :text="'확인'"
            :width="100"
          >
          </GSButton>
        </div>
      </v-card>
    </v-dialog>

    <!-- 성공 대상자 확인 dialog -->
    <v-dialog v-model="successTargetDialog" class="gsr-dialog target-check-layer" width="900">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">성공 대상자 확인</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeSuccessTargetDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="data-table-search-area no-wrap">
            <div class="search-option-area">
              <div class="option-radio">
                <div class="label-box">
                  <span class="label">{{ translate('수신인') }}</span>
                </div>
                <div class="gsr-radio">
                  <div class="gsr-radio-horizontal">
                    <v-radio-group v-model="sendingTypeValue">
                      <v-radio class="v-radio gsr-radio-primary gsr-radio-large" value="1">
                        <template #label>CUST ID</template>
                      </v-radio>
                      <v-radio class="v-radio gsr-radio-primary gsr-radio-large" value="2">
                        <template #label>
                          수신번호
                          <GSInput
                            :placeholder="'CUST ID 입력'"
                            :variant="Constants.INPUT_VARIANTS.OUTLINED"
                            :size="Constants.INPUT_SIZES.SMALL"
                            :desc-position="Constants.INPUT_POSITION.TOP"
                            :width="284.5"
                          ></GSInput>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <div class="search-btn-area">
                <GSButton
                  class="reset-btn"
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
                  :width="32"
                ></GSButton>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :text="'조회'"
                  :width="128"
                  @click="openDialog"
                ></GSButton>
              </div>
            </div>
          </div>
          <div class="contents-area">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="successTargetHeader"
                :items="successTargetData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 60px" />
                    <col style="width: 400px" />
                    <col style="width: 400px" />
                  </colgroup>
                </template>
                <template v-slot:item.num="{ index }">
                  {{ index + 1 }}
                </template>
              </v-data-table>
            </div>
          </div>
          <Pagination :dataLength="successTargetData.length"></Pagination>
        </div>
        <div class="dialog-button-wrap center">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'확인'"
            :width="100"
          >
          </GSButton>
        </div>
      </v-card>
    </v-dialog>

    <!-- 실패 대상자 확인 dialog -->
    <v-dialog v-model="failTargetDialog" class="gsr-dialog target-check-layer" width="900">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">실패 대상자 확인</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeFailTargetDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="data-table-search-area no-wrap">
            <div class="search-option-area">
              <div class="option-radio">
                <div class="label-box">
                  <span class="label">{{ translate('수신인') }}</span>
                </div>
                <div class="gsr-radio">
                  <div class="gsr-radio-horizontal">
                    <v-radio-group v-model="sendingTypeValue">
                      <v-radio class="v-radio gsr-radio-primary gsr-radio-large" value="1">
                        <template #label>CUST ID</template>
                      </v-radio>
                      <v-radio class="v-radio gsr-radio-primary gsr-radio-large" value="2">
                        <template #label>
                          수신번호
                          <GSInput
                            :placeholder="'CUST ID 입력'"
                            :variant="Constants.INPUT_VARIANTS.OUTLINED"
                            :size="Constants.INPUT_SIZES.SMALL"
                            :desc-position="Constants.INPUT_POSITION.TOP"
                            :width="284.5"
                          ></GSInput>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <div class="search-btn-area">
                <GSButton
                  class="reset-btn"
                  :variant="Constants.BUTTON_VARIANTS.OUTLINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.SECONDARY"
                  :iconPosition="Constants.BUTTON_ICONPOSITION.CENTER"
                  :width="32"
                ></GSButton>
                <GSButton
                  :variant="Constants.BUTTON_VARIANTS.CONTAINED"
                  :size="Constants.BUTTON_SIZES.SMALL"
                  :color="Constants.BUTTON_COLORS.PRIMARY"
                  :text="'조회'"
                  :width="128"
                  @click="openDialog"
                ></GSButton>
              </div>
            </div>
          </div>
          <div class="contents-area">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="failTargetHeader"
                :items="failTargetData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 60px" />
                    <col style="width: 120px" />
                    <col style="width: 160px" />
                    <col style="width: 120px" />
                    <col style="width: auto" />
                  </colgroup>
                </template>
                <template v-slot:item.num="{ index }">
                  {{ index + 1 }}
                </template>
              </v-data-table>
            </div>
          </div>
          <Pagination :dataLength="failTargetData.length"></Pagination>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'확인'"
            :width="100"
          >
          </GSButton>
        </div>
      </v-card>
    </v-dialog>

    <!-- 타겟팅 정보 dialog -->
    <v-dialog v-model="targetingInfoDialog" class="gsr-dialog targeting-info-layer" width="900">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">타겟팅 정보</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeTargetingInfoDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="guide-info">
            <strong class="title"
              >타겟팅 등록 정보는 최대 10건까지 조회되며, 정보 변경을 원하시는 경우 타겟팅 초기화 후
              다시 등록해 주세요</strong
            >
          </div>
          <div class="contents-area">
            <div class="contents-sub-title-wrap">
              <div class="contents-sub-title-text">
                <strong class="contents-sub-title">
                  {{ translate('조회내역') }} <span>10</span>{{ translate('건') }}
                </strong>
              </div>
            </div>
            <div class="contents-data-table-wrap">
              <v-data-table
                :headers="targetingInfoHeader"
                :items="targetingInfoData"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:colgroup>
                  <colgroup>
                    <col style="width: 60px" />
                    <col style="width: 400px" />
                    <col style="width: 400px" />
                  </colgroup>
                </template>
                <template v-slot:item.num="{ index }">
                  {{ index + 1 }}
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'확인'"
            :width="100"
          >
          </GSButton>
        </div>
      </v-card>
    </v-dialog>

    <!-- 메시지 확인 dialog -->
    <v-dialog v-model="msgCheckDialog" class="gsr-dialog msg-check-layer" width="444">
      <v-card>
        <div class="dialog-title-wrap">
          <strong class="dialog-title">메시지 확인</strong>
          <div class="dialog-close-btn-box">
            <v-btn class="dialog-close-btn" @click="closeMsgCheckDialog"></v-btn>
          </div>
        </div>
        <div class="dialog-contents-wrap">
          <div class="msg-check-wrap">
            <div class="msg-area">
              <span class="msg-title">발송 채널명</span>
              <div class="msg kakaotalk">
                <div class="msg-header">
                  <span>알림톡 도착</span>
                </div>
                <div class="msg-contents">
                  <p class="title">[GS SHOP] 티꿀#{교환기간}안내</p>
                  <p class="text">
                    #{O월}지급된 ‘티꿀‘ 교환기간이 오늘 종료됩니다.<br />
                    <br />
                    ■ 교환가능 티꿀#{: 5,000티꿀}<br />
                    ■ 교환기간#{:8/31(토) 23:59까지}<br />
                    #{주의사항}<br />
                    <br />
                    #{□티꿀사용방법}<br />
                    <br />
                    #{유의사항}<br />
                    #{유의사항}<br />
                    - 이 메시지는 고객님의 이벤트 신청으로 지급된 티꿀 교환기간 안내 메시지입니다.
                  </p>
                  <div class="btn">
                    <div>버튼명</div>
                    <div>버튼명</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="msg-area">
              <span class="msg-title">발송 채널명</span>
              <div class="msg kakaotalk">
                <div class="msg-header">
                  <span>알림톡 도착</span>
                </div>
                <div class="msg-contents">
                  <p class="text">
                    #{O월}지급된 ‘티꿀‘ 교환기간이 오늘 종료됩니다.<br />
                    ■ 교환가능 티꿀#{: 5,000티꿀}<br />
                    ■ 교환기간#{:8/31(토) 23:59까지}<br />
                  </p>
                </div>
              </div>
            </div>
            <div class="msg-area">
              <span class="msg-title">발송 채널명</span>
              <div class="msg kakaotalk">
                <div class="msg-img">
                  <img src="@/assets/images/msg_img.jpg" alt="" />
                </div>
                <div class="msg-contents">
                  <p class="text">
                    #{O월}지급된 ‘티꿀‘ 교환기간이 오늘 종료됩니다.<br />
                    ■ 교환가능 티꿀#{: 5,000티꿀}<br />
                    ■ 교환기간#{:8/31(토) 23:59까지}<br />
                  </p>
                  <div class="btn flex">
                    <div>쿠폰받기</div>
                    <div>공유하기</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="msg-area">
              <span class="msg-title">발송 채널명</span>
              <div class="msg slmms">
                <div class="msg-header">
                  <i></i>
                  <span class="phone-num">000-000-000</span>
                </div>
                <div class="msg-contents-wrap">
                  <span>문자메시지</span>
                  <span>(오늘) 오전 0:00</span>
                  <div class="msg-contents">
                    <p class="title">상상초월 판타지에스 행사안내</p>
                    <p class="text">
                      [Web발신]<br />
                      (광고)GS SHOP[#수신거부번호]<br />
                      <br />
                      무료수신거부 #수신거부번호<br />
                      GSSHOP 고객센터 #발신번호<br />
                      <br />
                      작성한 문자내용 작성한 문자내용작성한 문자내용작성한 문자내용작성한
                      문자내용작성한 문자내용작성한 문자내용작성한 문자내용작성한 문자내용작성한
                      문자내용작성한 문자내용작성한 문자내용작성한 문자내용<br />
                      <br />
                      ■이벤트페이지 바로가기<br />
                      http://m.gsshop1223344/doeleleldzzl/fdfadfadfdaf<br />
                      <br />
                      무료수신거부 #수신거부번호<br />
                      GSSHOP 고객센터 #08000009999<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-button-wrap">
          <GSButton
            :variant="Constants.BUTTON_VARIANTS.OUTLINED"
            :size="Constants.BUTTON_SIZES.SMALL"
            :color="Constants.BUTTON_COLORS.SECONDARY"
            :text="'확인'"
            :width="100"
          >
          </GSButton>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
// S: 다국어
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n()
const i18n = useI18n()

i18n.locale.value = 'kr'

// @ts-ignore
const translate = (text: string) => {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}
// E: 다국어

import { ref } from 'vue'

import * as Constants from '@/constants/index'

import PageTitle from '@/components/common/PageTitle.vue'
import Pagination from '@/components/common/Pagination.vue'

import GSButton from '@/components/ui/GSButton.vue'
import GSInput from '@/components/ui/GSInput.vue'
import GSSelects from '@/components/ui/GSSelects.vue'
import GSDatepicker from '@/components/ui/GSDatepicker.vue'

// 검색 영역 - 기간
const datePeriod = ref(['오늘', '1개월'])

// 검색 영역 - 채널
const channelList = ref(['전체', '채널1', '채널2', '채널3', '채널4'])

// 검색 영역 - 조직
const organizationList = ref([
  '[HBU] 전사배부',
  '[HBU] TV홈쇼핑 마케팅팀',
  '[HBU] 마케팅팀',
  '[HBU] 모바일 사업부',
  '[PBU] 우리동네GS팀',
  '[PBU] 통합서비스TFT',
  '[HBU] 모바일 사업부',
  '[PBU] 우리동네GS팀',
  '[PBU] 통합서비스TFT',
  '[HBU] 전사배부 2',
  '[HBU] TV홈쇼핑 마케팅팀 2',
  '[HBU] 마케팅팀 2',
  '[HBU] 모바일 사업부 2',
  '[PBU] 우리동네GS팀 2',
  '[PBU] 통합서비스TFT 2',
  '[HBU] 모바일 사업부 2',
  '[PBU] 우리동네GS팀 2',
  '[PBU] 통합서비스TFT 2'
])

// 검색 영역 - 발송상태
const stateList = ref(['전체', '발송완료', '발송실패', '발송취소', '발송처리중'])

// 검색 영역 - 이벤트
const workspaceList = ref([
  '전체',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_주문완료_M커머스_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_출고완료_M커머스_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_주문완료(Mobile)_알림톡',
  'GS SHOP_무통장 계좌안내(Mobile)_알림톡',
  'GS SHOP_출고완료_M커머스_알림톡'
])

// 조회 내역 건 수
const channelResult = ref('2,999')
const reservationResult = ref('2,999')

// 조회 내역
const selectedItem = ref({ label: '10개씩 보기', value: '10' }) // 기본값 설정
const dataView = ref([
  {
    label: '10개씩 보기',
    value: '10'
  },
  {
    label: '20개씩 보기',
    value: '20'
  },
  {
    label: '30개씩 보기',
    value: '30'
  }
])

// 데이터 테이블 header
const channelTableHeader = ref<DataTableHeader[]>([
  {
    align: 'center',
    title: '발송채널',
    value: 'channel'
  },
  {
    align: 'center',
    title: '발송일시',
    value: 'date'
  },
  {
    align: 'center',
    title: '발송유형',
    value: 'type',
    sortable: true
  },
  {
    align: 'start',
    title: '발신정보',
    value: 'info'
  },
  {
    align: 'start',
    title: '메시지 내용',
    value: 'contents'
  },
  {
    align: 'center',
    title: '발송상태',
    value: 'state'
  },
  {
    align: 'end',
    title: '발송건수',
    value: 'send'
  },
  {
    align: 'end',
    title: '성공건수',
    value: 'success'
  },
  {
    align: 'end',
    title: '실패건수',
    value: 'fail',
    sortable: true
  },
  {
    align: 'center',
    title: '대체채널',
    value: 'replaceChannel'
  },
  {
    align: 'end',
    title: '미확인 건수',
    value: 'unconfirm'
  },
  {
    align: 'end',
    title: '대체성공 건수',
    value: 'replaceSuccess'
  },
  {
    align: 'end',
    title: '대체실패 건수',
    value: 'replaceFail',
    sortable: true
  },
  {
    align: 'start',
    title: '등록자',
    value: 'registrant'
  }
])

interface DataTableHeader {
  align?: 'start' | 'center' | 'end'
  title?: string
  value: string
  sortable?: boolean
}

const reservationTableHeader = ref<DataTableHeader[]>([
  {
    align: 'center',
    title: '발송채널',
    value: 'channel'
  },
  {
    align: 'center',
    title: '발송예약 일시',
    value: 'date'
  },
  {
    align: 'start',
    title: '발신정보',
    value: 'info'
  },
  {
    align: 'start',
    title: '메시지 내용',
    value: 'contents'
  },
  {
    align: 'end',
    title: '예약건수',
    value: 'reservation'
  },
  {
    align: 'start',
    title: '등록자',
    value: 'registrant'
  },
  {
    align: 'center',
    title: '취소 가능여부',
    value: 'cancel',
    sortable: true
  }
])
// 데이터 테이블 data
const channelTableData = ref([
  {
    channel: 'SMS',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 티꿀#{교환기간}안내',
    state: '완료',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9,099',
    replaceChannel: '-',
    unconfirm: '1',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '김*해미/고객마케팅팀'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '완료',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '취소',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '예약',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '완료',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '완료',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '취소',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '완료',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '처리중',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '완료',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    type: '즉시',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    state: '완료',
    send: '9,999,999,999',
    success: '9,999,999,000',
    fail: '9',
    replaceChannel: '-',
    unconfirm: '33',
    replaceSuccess: '0',
    replaceFail: '0',
    registrant: '박*영/플랫폼프로젝트파트'
  }
])
const reservationTableData = ref([
  {
    channel: 'SMS',
    date: '2024.01.16  11:00:00',
    info: '080-414-4545',
    contents: '[GS SHOP] 티꿀#{교환기간}안내',
    reservation: '9,999,999,000',
    registrant: '김*해미/고객마케팅팀',
    cancel: false
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    reservation: '9,999,999,000',
    registrant: '박*영/플랫폼프로젝트파트',
    cancel: false
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '@gsshoptalk',
    contents: '[GS SHOP] 적립금 소멸 안내',
    reservation: '9,999,999,000',
    registrant: '김*해미/고객마케팅팀',
    cancel: true
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '@gsshoptalk',
    contents: '[GS SHOP] 적립금 소멸 안내',
    reservation: '9,999,999,000',
    registrant: '김*해미/고객마케팅팀',
    cancel: true
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '@gsshoptalk',
    contents: '[GS SHOP] 적립금 소멸 안내',
    reservation: '9,999,999,000',
    registrant: '김*해미/고객마케팅팀',
    cancel: true
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '@gsshoptalk',
    contents: '[GS SHOP] 적립금 소멸 안내',
    reservation: '9,999,999,000',
    registrant: '김*해미/고객마케팅팀',
    cancel: true
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    reservation: '9,999,999,000',
    registrant: '박*영/플랫폼프로젝트파트',
    cancel: false
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    reservation: '9,999,999,000',
    registrant: '박*영/플랫폼프로젝트파트',
    cancel: false
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '@gsshoptalk',
    contents: '[GS SHOP] 적립금 소멸 안내',
    reservation: '9,999,999,000',
    registrant: '김*해미/고객마케팅팀',
    cancel: true
  },
  {
    channel: '알림톡',
    date: '2024.01.16  11:00:00',
    info: '080-414-4545',
    contents: '[GS SHOP] 단하루! 매직딜데이 프로모션 안내',
    reservation: '9,999,999,000',
    registrant: '박*영/플랫폼프로젝트파트',
    cancel: false
  }
])

const dialog = ref(false)
const openDialog = () => {
  dialog.value = true
}
const closeDialog = () => {
  dialog.value = false
}

const activeIndex = ref(null)
const isDisabled = ref(false)

const tab = ref([0])
const sendingTypeValue = ref('1')

const msgContentsDialog = ref(false)
const openMsgContentsDialog = () => {
  msgContentsDialog.value = true
}
const closeMsgContentsDialog = () => {
  msgContentsDialog.value = false
}

const successTargetDialog = ref(false)
const openSuccessTargetDialog = () => {
  successTargetDialog.value = true
}
const closeSuccessTargetDialog = () => {
  successTargetDialog.value = false
}

const failTargetDialog = ref(false)
const openFailTargetDialog = () => {
  failTargetDialog.value = true
}
const closeFailTargetDialog = () => {
  failTargetDialog.value = false
}

const targetingInfoDialog = ref(false)
const openTargetingInfoDialog = () => {
  targetingInfoDialog.value = true
}
const closeTargetingInfoDialog = () => {
  targetingInfoDialog.value = false
}

const msgCheckDialog = ref(false)
const openMsgCheckDialog = () => {
  msgCheckDialog.value = true
}
const closeMsgCheckDialog = () => {
  msgCheckDialog.value = false
}

// table header
const msgContentsHeader: DataTableHeader[] = [
  {
    align: 'center',
    title: '번호',
    value: 'num'
  },
  {
    align: 'center',
    title: 'CUST ID',
    value: 'id'
  }
]
const successTargetHeader: DataTableHeader[] = [
  {
    align: 'center',
    title: '번호',
    value: 'num'
  },
  {
    align: 'center',
    title: 'CUST ID',
    value: 'id'
  },
  {
    align: 'center',
    title: '수신번호',
    value: 'receivingNum'
  }
]
const failTargetHeader: DataTableHeader[] = [
  {
    align: 'center',
    title: '번호',
    value: 'num'
  },
  {
    align: 'center',
    title: 'CUST ID',
    value: 'id'
  },
  {
    align: 'center',
    title: '수신번호',
    value: 'receivingNum'
  },
  {
    align: 'center',
    title: '에러코드',
    value: 'errorCode'
  },
  {
    align: 'start',
    title: '에러사유',
    value: 'errorReason'
  }
]
const targetingInfoHeader: DataTableHeader[] = [
  {
    align: 'center',
    title: '번호',
    value: 'num'
  },
  {
    align: 'center',
    title: 'CUST ID',
    value: 'id'
  },
  {
    align: 'center',
    title: '수신번호',
    value: 'receivingNum'
  }
]
// table data
const msgContentsData = [
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' },
  { id: '7561845' }
]
const successTargetData = [
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  }
]
const failTargetData = [
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: '185',
    errorReason: '전화번호 오류'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: '185',
    errorReason: '메시지 내용이 템플릿과 일치하지 않습니다'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: 'E00805',
    errorReason: '수신자 정보(receiveList/receiveExcel)가 유효하지 않습니다'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: 'E00805',
    errorReason: '수신자 정보(receiveList/receiveExcel)가 유효하지 않습니다'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: 'E00805',
    errorReason: '수신자 정보(receiveList/receiveExcel)가 유효하지 않습니다'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: 'E00805',
    errorReason: '수신자 정보(receiveList/receiveExcel)가 유효하지 않습니다'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: '185',
    errorReason: '전화번호 오류'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: '185',
    errorReason: '1056790'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: '185',
    errorReason: '7561845'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: '185',
    errorReason: '전화번호 오류'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519',
    errorCode: '185',
    errorReason: '전화번호 오류'
  }
]
const targetingInfoData = [
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  },
  {
    id: '7561845',
    receivingNum: '010 - **** - 7519'
  }
]
// 클릭된 행의 index
const selectedRow = ref<number | null>(null)

// 행 클릭 핸들러
const selectRow = (index: number) => {
  selectedRow.value = index
}
</script>
