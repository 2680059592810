<template>
  <v-menu v-model="openDatePicker" class="gsr-select-date" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-form class="gsr-select-date-form" v-bind="props" @click="handleDateFormClick">
        <div class="gsr-select-date-input">
          <v-text-field
            placeholder="YYYY-MM-DD"
            @input="(e: any) => formatDateInput(e)"
            v-model="searchStartDate"
            maxlength="10"
          ></v-text-field>
        </div>
        <div
          :class="
            searchStartDate ? 'gsr-select-date-clear-btn on' : 'gsr-select-date-clear-btn off'
          "
        >
          <v-btn @click="clearDate" @click.stop></v-btn>
        </div>
      </v-form>
    </template>
    <v-card class="gsr-select-date-layer">
      <v-row no-gutters>
        <v-col>
          <v-date-picker
            hide-header
            :show-adjacent-months="false"
            :key="datePickerKey1"
            v-model="selectedDate1"
          >
          </v-date-picker>
        </v-col>
      </v-row>
      <v-row no-gutters class="gsr-select-date-btn-area">
        <GSButton
          :class="'select-date-today-btn'"
          :variant="Constants.BUTTON_VARIANTS.OUTLINED"
          :size="Constants.BUTTON_SIZES.SMALL"
          :color="Constants.BUTTON_COLORS.SECONDARY"
          :text="'오늘'"
          :width="48"
          @click="setToday()"
        ></GSButton>
        <v-list class="gsr-select-date-btn-list">
          <v-list-item>
            <GSButton
              :class="'select-date-reset-btn'"
              :variant="Constants.BUTTON_VARIANTS.OUTLINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.SECONDARY"
              :width="32"
              @click="resetDate()"
            ></GSButton>
          </v-list-item>
          <v-list-item>
            <GSButton
              :variant="Constants.BUTTON_VARIANTS.CONTAINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.INHERIT"
              :text="'취소'"
              :width="48"
              @click="closeDate()"
            ></GSButton>
          </v-list-item>
          <v-list-item>
            <GSButton
              :class="'select-date-confirm-btn'"
              :variant="Constants.BUTTON_VARIANTS.CONTAINED"
              :size="Constants.BUTTON_SIZES.SMALL"
              :color="Constants.BUTTON_COLORS.PRIMARY"
              :text="'선택완료'"
              :width="69"
              @click="cofirmDate()"
            ></GSButton>
          </v-list-item>
        </v-list>
      </v-row>
    </v-card>
  </v-menu>
</template>
<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue'
import * as Constants from '@/constants/index'
import GSButton from '@/components/ui/GSButton.vue'
import { resetTime } from '@/core/plugins/global-util'

interface Props {
  startDate?: string
  endDate?: string
  className?: string | string[]
}

const props = withDefaults(defineProps<Props>(), {
  className: ''
})


const openDatePicker = ref(false)
const searchStartDate = ref<string>(props.startDate || null) // props로 받은 시작일
// const searchEndDate = ref<string>(props.endDate || null) // props로 받은 종료일
const selectedDate1 = ref<Date[]>([])
// 확인전 변수 값 , emit으로 부모에게 전달
const selectedStartDate = ref<string>('')
// const selectedDate2 = ref<Date[]>([])

const currentDate1 = ref(new Date())

// 오늘 날짜에서 한 달 더한 날짜 계산
// const currentDate2 = computed(() => {
//   const date = new Date(currentDate1.value) // 현재 날짜 복사
//   date.setMonth(date.getMonth() + 1) // 한 달 추가
//   return date
// })

const currentMonth1 = ref(currentDate1.value.getMonth()) // 0 = 1월, 11 = 12월
// const currentYear1 = ref(new Date().getFullYear())

// const currentMonth2 = ref(new Date().getMonth() + 1) // 기본값: 다음 달
// const currentYear2 = ref(new Date().getFullYear()) // 현재 연도

const datePickerKey1 = ref(0) // key 값 초기화
// const datePickerKey = ref(0) // key 값 초기화

// const forceUpdateDatePicker1 = () => {
//   datePickerKey1.value += 1 // key 값 변경 -> v-date-picker 강제 재렌더링
// }

// const forceUpdateDatePicker2 = () => {
//   datePickerKey.value += 1 // key 값 변경 -> v-date-picker 강제 재렌더링
// }

const emit = defineEmits(['update-start-date'])


// watch(
//   selectedDate1,
//   (newValue) => {
//     if (newValue.length === 1 && newValue[0]) {
//       console.log('length 1')
//       const firstDate = new Date(newValue[0])
//       const nextMonth = firstDate.getMonth() + 1 // 다음 달 계산

//       if (nextMonth > 11) {
//         currentMonth2.value = 0 // 12월이면 1월로 변경
//         currentYear2.value = firstDate.getFullYear() + 1 // 연도 증가
//       } else {
//         currentMonth2.value = nextMonth // 다음 달
//         currentYear2.value = firstDate.getFullYear() // 동일 연도
//       }
//     } else if (newValue.length > 1 && newValue[0]) {
//       console.log('length 2')
//     } else {
//       // console.log('else')
//     }

//     // console.log('currentMonth1 :: ', currentMonth1.value)
//     // console.log('currentMonth2 :: ', currentMonth2.value)
//     //  else {
//     //   // 첫 번째 값이 없을 경우, 현재 날짜 기준으로 설정
//     //   const today = new Date()
//     //   currentMonth2.value = today.getMonth() + 1 > 11 ? 0 : today.getMonth() + 1
//     //   curre ntYear2.value = today.getMonth() + 1 > 11 ? today.getFullYear() + 1 : today.getFullYear()
//     // }
//   },
//   { immediate: true }
// )

// 범위 내 모든 날짜를 계산
// const calculateDateRange = (start: Date, end: Date): Date[] => {
//   const range = []
//   const current = new Date(start)

//   while (current <= end) {
//     range.push(new Date(current))
//     current.setDate(current.getDate() + 1)
//   }

//   return range
// }

// 날짜를 기준으로 두 배열로 분리
// const splitDatesByMonth = (dates: Date[], referenceDate: Date) => {
//   const referenceMonth = referenceDate.getMonth()
//   const referenceYear = referenceDate.getFullYear()

//   const datesBeforeReferenceMonth = dates.filter(
//     (date) =>
//       date.getFullYear() < referenceYear ||
//       (date.getFullYear() === referenceYear && date.getMonth() < referenceMonth)
//   )

//   const datesAfterReferenceMonth = dates.filter(
//     (date) =>
//       date.getFullYear() > referenceYear ||
//       (date.getFullYear() === referenceYear && date.getMonth() >= referenceMonth)
//   )

//   return { datesBeforeReferenceMonth, datesAfterReferenceMonth }
// }

// `@update:modelValue`로 연결
// const handleDateChange1 = () => {
//   console.log('첫 번째 피커 변경:', selectedDate1.value)
// }

const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate() // month는 1월이 0, 2월이 1로 시작됨
}
const formatDateInput = (e: Event) => {
  let input = (e.target as HTMLInputElement).value.replace(/\D/g, '') // 숫자 외 문자 제거

  // 4번째 입력 시 하이픈(-) 추가
  if (input.length > 4) {
    input = `${input.slice(0, 4)}-${input.slice(4)}`
  }

  // 7번째 입력 시 하이픈(-) 추가
  if (input.length > 7) {
    input = `${input.slice(0, 7)}-${input.slice(7)}`
  }

  // 입력 길이를 10자로 제한 (YYYY-MM-DD)
  if (input.length > 10) {
    input = input.slice(0, 10)
  }

  // 유효한 MM과 DD 입력을 위한 제어
  const year = parseInt(input.slice(0, 4), 10)
  const month = parseInt(input.slice(5, 7), 10)
  const day = parseInt(input.slice(8, 10), 10)

  // MM에 대한 유효성 검사 (01~12)
  if (month && (month < 1 || month > 12)) {
    input = `${input.slice(0, 4)}-` // 잘못된 월 입력 시, 하이픈 뒤로 잘라냄
  }

  // DD에 대한 유효성 검사 (1~월별 최대 일수)
  if (month && day && (day < 1 || day > daysInMonth(year, month))) {
    input = `${input.slice(0, 7)}-` // 잘못된 일 입력 시, 일 부분을 잘라냄
  }

  searchStartDate.value = input
}

// const onMonthUpdate1 = (newMonth: number) => {
//   console.log('월 변경됨 1:', newMonth)
//   currentMonth1.value = newMonth
//   if (currentMonth1.value + 1 === currentMonth2.value) {
//     return
//   } else {
//     forceUpdateDatePicker2()
//     currentMonth2.value = currentMonth1.value + 1
//   }
// }

// const onYearUpdate1 = (newYear: number) => {
//   console.log('연도 변경됨 1:', newYear)
// }

// const onMonthUpdate2 = (newMonth: number) => {
//   console.log('월 변경됨 1:', newMonth)
//   currentMonth2.value = newMonth
//   if (currentMonth1.value + 1 === currentMonth2.value) {
//     return
//   } else {
//     forceUpdateDatePicker1()
//     currentMonth1.value = currentMonth2.value - 1
//   }
//   // currentMonth1.value = newMonth
//   // console.log('월 변경됨 1:', currentMonth1.value)
// }

// watch(currentMonth1, (newValue, oldValue) => {
//   console.log('currentMonth1 Watch ::: ', newValue, oldValue)
//   console.log('currentMonth1 Value ::: ', currentMonth1.value)
//   if (currentMonth1.value === currentMonth2.value) {
//     forceUpdateDatePicker1()
//     console.log('동일1', currentMonth1.value, currentMonth2.value)
//     currentMonth1.value = currentMonth2.value - 1
//   } else {
//     console.log('다름1', currentMonth1.value, currentMonth2.value)
//   }
// })

// watch(currentMonth2, (newValue, oldValue) => {
//   if (currentMonth2.value === currentMonth1.value) {
//     forceUpdateDatePicker2()
//     console.log('동일2', currentMonth1.value, currentMonth2.value)
//     currentMonth2.value = currentMonth1.value + 1
//   } else {
//     console.log('다름2', currentMonth1.value, currentMonth2.value)
//   }
// })

// const onYearUpdate2 = (newYear: number) => {
//   console.log('연도 변경됨 2:', newYear)
// }

const handleDateFormClick = (e: Event) => {
  if (openDatePicker.value) {
    e.stopPropagation() // 메뉴가 열렸다면 클릭 전파를 막음
  } else {
    openDatePicker.value = true // 메뉴를 열기
  }
}

const handlePrevClick = () => {
  console.log('이전 버튼이 클릭되었습니다!')
  const prevButtons = document.querySelectorAll('i.mdi-chevron-left.mdi.v-icon')
  const triggerButton = prevButtons[1] as HTMLElement
  triggerButton.click()
}

const handleNextClick = () => {
  console.log('다음 버튼이 클릭되었습니다!')
  const nextButtons = document.querySelectorAll('i.mdi-chevron-right.mdi.v-icon')
  const triggerButton = nextButtons[0] as HTMLElement
  triggerButton.click()
}

watch(openDatePicker, (isOpen) => {
  if (isOpen) {
    console.log('v-menu 열림')
    setTimeout(() => {
      const prevButtons = document.querySelectorAll('i.mdi-chevron-left.mdi.v-icon')
      prevButtons.forEach((button) => {
        const prevButton = button as HTMLElement // 타입 캐스팅
        if (!prevButton.hasAttribute('data-event-bound')) {
          prevButton.addEventListener('click', handlePrevClick)
          prevButton.setAttribute('data-event-bound', 'true')
          console.log('이전 버튼 이벤트 연결 완료:', prevButton)
        }
      })

      // 다음 버튼 탐지 및 이벤트 추가
      const nextButtons = document.querySelectorAll('i.mdi-chevron-right.mdi.v-icon')

      nextButtons.forEach((button) => {
        const nextButton = button as HTMLElement // 타입 캐스팅
        if (!nextButton.hasAttribute('data-event-bound')) {
          nextButton.addEventListener('click', handleNextClick)
          nextButton.setAttribute('data-event-bound', 'true')
          console.log('다음 버튼 이벤트 리스너 연결 완료:', nextButton)
        }
      })
    }, 50) // DOM 렌더링 대기 시간
  } else {
    console.log('v-menu 닫힘')
    // 이전 버튼 이벤트 제거
    const prevButtons = document.querySelectorAll('i.mdi-chevron-left.mdi.v-icon')
    prevButtons.forEach((button) => {
      const prevButton = button as HTMLElement // 타입 캐스팅
      if (prevButton.hasAttribute('data-event-bound')) {
        prevButton.removeEventListener('click', handlePrevClick)
        prevButton.removeAttribute('data-event-bound')
        console.log(`이전 버튼 이벤트 제거 완료: `, prevButton)
      }
    })

    // 다음 버튼 이벤트 제거
    const nextButtons = document.querySelectorAll('i.mdi-chevron-right.mdi.v-icon')
    nextButtons.forEach((button) => {
      const nextButton = button as HTMLElement // 타입 캐스팅
      if (nextButton.hasAttribute('data-event-bound')) {
        nextButton.removeEventListener('click', handleNextClick)
        nextButton.removeAttribute('data-event-bound')
        console.log(`다음 버튼 이벤트 제거 완료: `, nextButton)
      }
    })
  }
})

const clearDate = () => {
  searchStartDate.value = ''
  emit('update-start-date', '')
}
/* 오늘 */
const setToday = () => {
  const today = new Date()
  selectedDate1.value = [resetTime(today)]
}
/* 초기화 */
const resetDate = () => {
  const today = new Date()
  selectedDate1.value = [resetTime(today)]
}

/* 취소 */
const closeDate = () => {
  selectedStartDate.value = ''

  openDatePicker.value = false
}

onMounted(() => {
  // if (currentMonth2.value == 12) {
  //   currentYear2.value += 1
  // }
})
</script>
