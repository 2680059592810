<template>
  <div class="contents-title-wrap">
    <strong class="contents-title">
      {{ translate( title ) }}
    </strong>
  </div>
</template>

<script setup lang="ts">
import { useCommonI18n } from '@/core/plugins/global-util';


const { translate } = useCommonI18n()

defineProps({
  title: {
    type: String,
    default: '',
  },
});
</script>