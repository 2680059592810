export const AUTOCOMPLETES_VARIANTS = {
  OUTLINED: 'gsr-autocompletes-outlined',
  STANDARD: 'gsr-autocompletes-standard'
} as const

export const AUTOCOMPLETES_SIZES = {
  SMALL: 'gsr-autocompletes-small',
  MEDIUM: 'gsr-autocompletes-medium',
  LARGE: 'gsr-autocompletes-large'
} as const

export const AUTOCOMPLETES_POSITION = {
  DEFAULT: 'gsr-autocompletes-default-position',
  LEFT: 'gsr-autocompletes-left-position',
  TOP: 'gsr-autocompletes-top-position'
} as const

export const AUTOCOMPLETES_VALIDTYPE = {
  SYNC: 'sync',
  MENUAL: 'menual'
} as const

export const AUTOCOMPLETES_VALIDSTYLE = {
  BASIC: 'basic',
  TABLE: 'table'
} as const

// 타입 정의
export type AutocompletesSizes = (typeof AUTOCOMPLETES_SIZES)[keyof typeof AUTOCOMPLETES_SIZES]
export type AutocompletesVariants =
  (typeof AUTOCOMPLETES_VARIANTS)[keyof typeof AUTOCOMPLETES_VARIANTS]
export type AutocompletesPositions =
  (typeof AUTOCOMPLETES_POSITION)[keyof typeof AUTOCOMPLETES_POSITION]
export type AutocompletesValidtype =
  (typeof AUTOCOMPLETES_VALIDTYPE)[keyof typeof AUTOCOMPLETES_VALIDTYPE]
export type AutocompletesValidstyle =
  (typeof AUTOCOMPLETES_VALIDSTYLE)[keyof typeof AUTOCOMPLETES_VALIDSTYLE]
