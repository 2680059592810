export const validationRuleMap: Record<string, (v: string) => boolean | string> = {
  required: (v: string) => !!v || 'Required.',
  maxLength20: (v: string) => v.length <= 20 || 'Max 20 characters.',
  email: (v: string) =>
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || 'Invalid email format.'
}

// 문자열 규칙을 기반으로 실제 유효성 검사 함수 배열로 변환
export const getMappedRules = (rules: string[]) => {
  // console.log('string :: ', rules)
  return (rules || []).map((rule) => validationRuleMap[rule])
}
