<template>
  <div class="service-ready-area">
    <div>
      <strong>현재 페이지는 서비스 준비 중입니다.</strong>
      <p>
        보다 나은 서비스로<br />
        빠른 시일 내에 찾아뵙겠습니다.
      </p>
    </div>
  </div>
</template>