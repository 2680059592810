export const CHECKBOX_COLORS = {
  PRIMARY: 'gsr-checkbox-primary',
  SECONDARY: 'gsr-checkbox-secondary',
  INHERIT: 'gsr-checkbox-inherit'
} as const

export const CHECKBOX_SIZES = {
  SMALL: 'gsr-checkbox-small',
  MEDIUM: 'gsr-checkbox-medium',
  LARGE: 'gsr-checkbox-large'
} as const

export const CHECKBOX_POSITION = {
  DEFAULT: 'gsr-checkbox-default-position',
  LEFT: 'gsr-checkbox-left-position',
  TOP: 'gsr-checkbox-top-position'
} as const

export const CHECKBOX_DIRECTION = {
  HORIZONTAL: 'gsr-checkbox-horizontal',
  VERTICAL: 'gsr-checkbox-vertical'
} as const

export const CHECKBOX_VALIDTYPE = {
  SYNC: 'sync',
  MENUAL: 'menual'
} as const

export const CHECKBOX_VALIDSTYLE = {
  BASIC: 'basic',
  TABLE: 'table'
} as const

// 타입 정의
export type CheckboxColors = (typeof CHECKBOX_COLORS)[keyof typeof CHECKBOX_COLORS]
export type CheckboxSizes = (typeof CHECKBOX_SIZES)[keyof typeof CHECKBOX_SIZES]
export type CheckboxPositions = (typeof CHECKBOX_POSITION)[keyof typeof CHECKBOX_POSITION]
export type CheckboxDirection = (typeof CHECKBOX_DIRECTION)[keyof typeof CHECKBOX_DIRECTION]
export type CheckboxValidtype = (typeof CHECKBOX_VALIDTYPE)[keyof typeof CHECKBOX_VALIDTYPE]
export type CheckboxValidstyle = (typeof CHECKBOX_VALIDSTYLE)[keyof typeof CHECKBOX_VALIDSTYLE]
